import { Link } from 'react-router-dom';
import USER_TYPES from '../../../constants/types/UserTypes';
// styles
import styles from '../../../styles/table.module.scss';

const COMMON_COLUMN = [
  {
    title: 'Name',
    field: 'name',
    name: 'name',
    render: (_, rowData) => (
      <div className={styles['user_details']}>
        <div className={styles['avatar']}>
          <p>{rowData?.name?.split('')[0]}</p>
        </div>
        <Link className={styles['cell-link']}>{rowData?.name || ''}</Link>
      </div>
    ),
  },
];

const tableConfig = [
  { title: 'Id', field: 'id', name: 'id' },
  ...COMMON_COLUMN,
  { title: 'Contact Number', field: 'phoneNumber', name: 'phoneNumber' },
  {
    title: 'Email Id',
    field: 'email',
    name: 'email',
  },
  {
    title: 'User Role',
    field: 'role.name',
    // name: 'name',
    render: (columnProps, rowData) => {
      const user_type = USER_TYPES[rowData.role.name];
      return <p>{user_type}</p>;
    },
  },
];

export default tableConfig;
