import React, { Fragment, useState, useEffect } from 'react';
import OverviewStatCard from './OverviewStatCard';
import OverviewCard from './OverviewCard';
import CustomerEditModal from './CustomerEditModal';
//SVGs Imports

import TotalSites from '../../../../../assets/svgComponent/totalSites';
import TotalRepeaters from '../../../../../assets/svgComponent/totalRepeaters';

import TotalAccessPoint from '../../../../../assets/svgComponent/totalAccessPoints';
// import Device from '../../../../../assets/images/dashboard/device.svg';
//
import Device from './Device';
import Door from './Door';
import Devices from './Devices';
import styles from './style.module.scss';
import ModalAlert from '../../../../../components/Modals/ModalAlert';
//redux
import { useSelector, useDispatch } from 'react-redux';
import {
  doDeleteOrganisation,
  doPartnerHandover,
  doUpdateOrganisation,
} from '../../../../../redux/thunks/organisation';
import { selectCurrentPartner } from '../../../../../redux/selectors/authentication';
//
import { useParams, useHistory } from 'react-router-dom';

import { axiosInfraInstance } from '../../../../../services/api';
import { selectOrganisation } from '../../../../../redux/selectors/organisation';
import HandOverPopup from './HandoverPopUp/HandOverPopup';
import NewAdminPopup from './HandoverPopUp/NewAdminPopup';

const OverviewTab = () => {
  const [loadData, setLoadData] = useState({});
  const { customerId } = useParams();

  const { currentPartner, organisation } = useSelector((state) => ({
    currentPartner: selectCurrentPartner(state),
    organisation: selectOrganisation(state),
  }));

  const url = `/infrastructureManagement/v1/organisations/${customerId}/overview`;
  const history = useHistory();
  //redux
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showEditCustomerModal, setEditCustomerModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showHandOverModal, setShowHandOverModal] = useState(false);
  const [showNewAdimModal, setShowNewAdminModal] = useState(false);
  const [showHandoverSuccess, setShowHandoverSuccess] = useState(false);
  const [showNewAdminSuccess, setShowNewAdminSuccess] = useState(false);
  const [customerAdmin, setcustomerAdmin] = useState({});

  useEffect(() => {
    if (organisation) {
      const { generalSetting } = organisation;
      const { admins: adm } = generalSetting;
      const index = adm?.findIndex((admins) => admins?.adminType === 2);

      if (index !== -1 && adm && adm[index]) {
        // Set the 'admin' for the first matching element
        setcustomerAdmin(adm[index]);
      } else {
        // Handle the case where there is no matching element or 'adm' is undefined
        setcustomerAdmin({});
      }
    }
  }, [organisation]);

  useEffect(() => {
    const getData = async () => {
      const res = await axiosInfraInstance({ method: 'GET', url: url });
      setLoadData(res.data.message);
    };
    getData();
  }, [url]);

  function displayEditCustomerModal() {
    setEditCustomerModal(true);
  }

  function displayModal() {
    if (customerAdmin?.adminType !== 2) {
      setShowNewAdminModal(true);
    } else {
      setShowHandOverModal(true);
    }
  }

  const handleDeleteOrganisation = () => {
    dispatch(
      doDeleteOrganisation(
        { partnerId: currentPartner.partnerId, organisationId: customerId },
        (status) => {
          if (status === 'success') {
            history.goBack();
          }
        },
      ),
    );
    setShowModal(false);
  };

  const handleNewAdmin = (data) => {
    const payload = { admins: [data] };

    dispatch(
      doUpdateOrganisation(
        {
          partnerId: currentPartner.partnerId,
          organisationId: customerId,
          payload,
          setLoading: false,
          isIndividual: true,
        },
        (status) => {
          if (status === 'success') {
            setShowNewAdminSuccess(true);
          }
        },
      ),
    );
  };
  const handleHandOverPopUp = () => {
    dispatch(
      doPartnerHandover(
        { organisationId: customerId, payload: {}, partnerId: currentPartner.partnerId },
        (status) => {
          if (status === 'success') {
            setShowHandoverSuccess(true);
          }
        },
      ),
    );
  };

  return (
    <Fragment>
      <div className={styles['container']}>
        <div className={styles['left_section']}>
          <div className={styles['left_inner']}>
            {/* {stat_data.map((data, idx) => (
              <OverviewStatCard key={idx} data={data} />
            ))} */}
            <OverviewStatCard
              name="Total Access Points"
              stat={loadData.NoOfAccessPoints}
              icon={<Door />}
            />
            <OverviewStatCard
              name="No. of Detached Devices"
              stat={loadData.NoOfDetachedDevices}
              icon={<TotalRepeaters />}
            />
            <OverviewStatCard
              name="Total no. of Devices"
              stat={loadData.NoOfDevices}
              icon={<Devices />}
            />
            <OverviewStatCard
              name="Total no. of Gatways"
              stat={loadData.NoOfGateways}
              icon={<Device />}
            />
            <OverviewStatCard
              name="Total no. of Mesh IOs"
              stat={loadData.NoOfMeshIOs}
              icon={<TotalAccessPoint />}
            />
            <OverviewStatCard
              name="Total No. of Sites "
              stat={loadData.NoOfSites}
              icon={<TotalSites />}
            />
          </div>
        </div>
        <div className={styles['right_section']}>
          <OverviewCard
            displayDeleteModal={() => setShowModal(true)}
            displayEditCustomerModal={displayEditCustomerModal}
            displayPartnerHandoverModal={displayModal}
          />
        </div>
      </div>
      {showModal && (
        <ModalAlert
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          handleSubmit={() => handleDeleteOrganisation()}
          title={`Delete Customer`}
          description={` Are you sure you want to delete ${
            organisation?.generalSetting ? organisation?.generalSetting?.name : ''
          } ? This action is non
              reversible and will cause you to lose all data associated with this customer/ s`}
          modalType={'danger'}
        />
      )}

      {/* to show success method */}
      {showCustomerModal && (
        <ModalAlert
          showModal={showCustomerModal}
          closeModal={() => setShowCustomerModal(false)}
          title={'Customer Added'}
          description={' We successfully added the customer “New Customer”'}
          modalType={'default'}
        />
      )}
      {showHandoverSuccess && (
        <ModalAlert
          showModal={showHandoverSuccess}
          closeModal={() => setShowHandoverSuccess(false)}
          title={'Handover Successful'}
          description={'Partner organisation successfully handed over to customer super admin'}
          modalType={'default'}
        />
      )}
      {showNewAdminSuccess && (
        <ModalAlert
          showModal={showNewAdminSuccess}
          closeModal={() => setShowNewAdminSuccess(false)}
          title={'Successful'}
          description={
            'Successfully added user. Please initiate partner handover after customer super admin signs up'
          }
          modalType={'default'}
        />
      )}
      {showEditCustomerModal && (
        <CustomerEditModal
          showModal={showEditCustomerModal}
          closeModal={() => setEditCustomerModal(false)}
        />
      )}
      {showHandOverModal && (
        <HandOverPopup
          showModal={showHandOverModal}
          closeModal={() => setShowHandOverModal(false)}
          onClick={handleHandOverPopUp}
          adminData={customerAdmin}
          handleUpdateAdmin={handleNewAdmin}
        />
      )}
      {showNewAdimModal && (
        <NewAdminPopup
          showModal={showNewAdimModal}
          closeModal={() => setShowNewAdminModal(false)}
          onClick={handleNewAdmin}
        />
      )}
    </Fragment>
  );
};
export default OverviewTab;
