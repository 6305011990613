import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import voca from 'voca';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Avatar from '../../components/Avatar';
import Popover from '@material-ui/core/Popover';
import Button from '../../components/Buttons/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { mainListItems } from '../../helpers/listItems';
import { doSelectPartners } from '../../redux/actionCreators/authentication';
import { doLogout } from '../../redux/thunks/authentication';
import {
  // selectCurrentUser,
  selectCurrentPartner,
  selectPartners,
} from '../../redux/selectors/authentication';
import TriangleSvg from '../../assets/svgComponent/triangle';

import LoadingBar from '../../components/LoadingBar';
import NotificationSnackbar from '../../components/NotificationSnackbar';
import MessageModal from '../../components/messageModal';
import MenuIcon from '@material-ui/icons/Menu';

import classname from 'classnames';
import style from './style.module.scss';

// icons

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    padding: 8, // keep right padding when drawer closed
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  partnerContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'rgb(244, 244, 244)',
    zIndex: 50,
    boxShadow: 'none',
    color: '#cf480d',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: '#232e86',
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: 'rgb(244, 244, 244)',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  list: {
    width: '100%',
    maxWidth: 250,
    backgroundColor: theme.palette.background.paper,
  },
  partnerName: {
    color: '#1b1b1b',
    fontFamily: 'UrbanistSemiBold',
    fontSize: 14,
  },
  partner: {
    color: '#1b1b1b',
    fontFamily: 'UrbanistSemiBold',
    fontSize: 16,
  },
  partnerProfile: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '3rem',
  },
  partnerDesc: {
    display: 'flex',
    height: '2.5rem',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '0.5rem',
    width: 'max-content',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  section: {
    marginBottom: '0.7rem',
  },
  parterRole: {
    color: '#1b1b1b',
    fontFamily: 'UrbanistSemiBold',
    fontSize: 10,
  },
  partnerHeader: {
    color: '#353f94',
    fontFamily: 'UrbanistSemiBold',
    fontSize: 12,
  },
  alignRight: {
    textAlign: 'right',
  },
  MuiPopover_paper: {
    borderRadius: '22px',
  },
}));

const DashboardLayout = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { width } = useWindowDimensions();
  const [open, setOpen] = useState(true);
  const { currentPartner, partners } = useSelector((state) => ({
    // currentUser: selectCurrentUser(state),
    currentPartner: selectCurrentPartner(state),
    partners: selectPartners(state),
  }));

  const [anchorEl, setAnchorEl] = useState(null);

  const [anhorLeft, setAnchorLeft] = useState(null);

  const handleLogout = () => {
    dispatch(doLogout());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePartnerClick = (event) => {
    setAnchorLeft(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorLeft(null);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  return (
    <Route
      render={(props) => (
        <Fragment>
          <LoadingBar />
          <MessageModal />
          <div className={classes.root}>
            <CssBaseline />

            <AppBar
              position="absolute"
              className={clsx(classes.appBar, open && classes.appBarShift)}
            >
              <Toolbar className={classes.toolbar} disableGutters>
                <div className={clsx(style['container'], style['header-logo'])}>
                  <IconButton
                    edge="start"
                    // color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(open && classes.menuButtonHidden)}
                  >
                    <MenuIcon fontSize="medium" className={style['grey-icon']} />
                  </IconButton>
                  <div className={classes.partnerContainer} onClick={handlePartnerClick}>
                    <p className={classes.partnerName}>
                      {!!currentPartner && currentPartner.partnerName}
                    </p>
                    {/* <ArrowDropDown fontSize="medium" className={style['grey-icon']} /> */}
                    <div className={style['icon']}>
                      <TriangleSvg className={style['svg']} />
                    </div>
                  </div>
                </div>

                <div>
                  <div className={clsx(style['profile'])} onClick={handleClick}>
                    <div className={classes.partnerProfile}>
                      <div className={classes.partnerDesc}>
                        <p className={clsx(classes.partner, classes.alignRight)}>
                          {!!currentPartner && currentPartner?.name}
                        </p>
                        <p className={clsx(classes.parterRole, classes.alignRight)}>
                          {!!currentPartner &&
                            voca
                              .chain(currentPartner?.role?.name)
                              .replaceAll('_', ' ')
                              .upperCase()
                              .value()}
                        </p>
                      </div>
                    </div>
                    <Avatar
                      size="xsmall"
                      name={!!currentPartner && currentPartner?.name?.charAt(0)}
                    />
                  </div>

                  <Popover
                    id="simple-menu"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    classes={{ paper: style['popover-root'] }}
                  >
                    <div className={style['popover']}>
                      <div className={clsx(classes.sectionContainer)}>
                        <div onClick={handleClose} className={style['popover-content-pic']}>
                          <Avatar
                            size="xsmall"
                            name={!!currentPartner && currentPartner?.name?.charAt(0)}
                          />
                        </div>
                        <div>
                          <p className={clsx(classes.partner)}>
                            {!!currentPartner && currentPartner?.name}
                          </p>
                          <p className={clsx(classes.parterRole)}>
                            {!!currentPartner &&
                              voca
                                .chain(currentPartner?.role?.name)
                                .replaceAll('_', ' ')
                                .upperCase()
                                .value()}
                          </p>
                        </div>
                      </div>
                      <div style={{ marginLeft: '1rem' }}>
                        <div className={clsx(classes.section)}>
                          <p className={clsx(classes.partnerHeader)}>Partner Name</p>
                          <p className={clsx(classes.partner)}>
                            {!!currentPartner && currentPartner?.partnerName}
                          </p>
                        </div>

                        <div className={clsx(classes.section)}>
                          <p className={clsx(classes.partnerHeader)}>Partner Email</p>
                          <p className={clsx(classes.parterRole)}>
                            {!!currentPartner && currentPartner?.email}
                          </p>
                        </div>
                      </div>
                      <div className={style['popover-content-button']}>
                        <Button onClick={handleLogout} text="Sign out" />
                      </div>
                      <div className="spacer__0-5" />
                    </div>
                  </Popover>

                  <Popover
                    id="simple-menu"
                    open={Boolean(anhorLeft)}
                    anchorEl={anhorLeft}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    classes={{ paper: style['popover-root'] }}
                  >
                    <div className={classname(style['popover'], 'pd-2')}>
                      <div className={style['popover-content-partner']}>
                        <p className={clsx(classes.partner)}>Partners</p>
                      </div>

                      <List component="nav" className={classes.list} aria-label="partners">
                        {!!partners &&
                          partners.map((partner, idx) => (
                            <Fragment key={idx}>
                              <ListItem
                                classes={{ root: style['list-item'] }}
                                button
                                disabled={partner.partnerName === currentPartner.partnerName}
                                onClick={() => {
                                  handleClose();
                                  dispatch(doSelectPartners({ partnerId: partner.partnerId }));
                                }}
                              >
                                <ListItemText
                                  primary={`${partner.partnerName}`}
                                  classes={{ root: style['sub-header'] }}
                                />
                              </ListItem>
                              {/* <Divider /> */}
                            </Fragment>
                          ))}
                      </List>
                    </div>
                  </Popover>
                </div>
              </Toolbar>
            </AppBar>

            <Drawer
              variant="persistent"
              anchor="left"
              classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
              }}
              style={{ background: 'rgb(244, 244, 244', zIndex: 0 }}
              open={open}
            >
              <List component="nav" disablePadding>
                {mainListItems}
              </List>
            </Drawer>
            <main className={classes.content} id="scrollable-win">
              <div className={classes.appBarSpacer} />
              <Box py={2} px={3}>
                <Component {...props} />
              </Box>
            </main>
          </div>
          <NotificationSnackbar />
        </Fragment>
      )}
      {...rest}
    />
  );
};

export default DashboardLayout;
