import React, { useState, useEffect, useCallback, useMemo } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { doAddMesh, doGetMeshList } from '../../../../../../redux/thunks/mesh';
import { selectIsLoading } from '../../../../../../redux/selectors/miscellaneous';

// styles
import styles from '../../../../../../styles/modals.module.scss';
import meshStyles from './style.module.scss';
// components
import Button from '../../../../../../components/Buttons/Button';
import TextInput from '../../../../../../components/Inputs/TextInput';
import Modal from '../../../../../../components/Modals/Modal';
import Dropdown from '../../../../../../components/Inputs/Dropdown';
import classnames from 'classnames';
// helpers
import { isHex, textVerification } from '../../../../../../utils/formValdations';

const AddMesh = ({ togglePopup, showAddMesh, partnerId, customerId }) => {
  //redux store
  const dispatch = useDispatch();
  const sites = useSelector((state) => state?.organisation?.sites);
  //state
  const [meshIoName, setMeshIoName] = useState({ description: '', value: '', error: '' });
  const [serialNumber, setSerialNumber] = useState({ description: '', value: '', error: '' });
  const [siteSelected, setSite] = useState({ value: '', error: '' });
  const [networkSelected, setNetwork] = useState({ value: '', error: '' });
  const [siteId, setSiteId] = useState();
  const [networkId, setNetworkId] = useState();

  const isLoading = useSelector(selectIsLoading);
  useEffect(() => {
    if (siteSelected.value) {
      const selectedSiteIdObj = sites.filter((site) => site.name === siteSelected.value);
      setSiteId(selectedSiteIdObj[0].id);
    }
    if (networkSelected.value) {
      const selectedSite = siteSelected?.value
        ? sites.find((siteValue) => siteValue.name === siteSelected?.value)
        : [];
      const currentNetworks = selectedSite?.networks?.length > 0 ? selectedSite.networks : [];
      const selectedNetwork = networkSelected?.value
        ? currentNetworks.find((networkValue) => networkValue.name === networkSelected?.value)
        : [];
      setNetworkId(selectedNetwork.id);
    }
  }, [siteId, siteSelected, sites, networkSelected]);

  const selectedSite = siteSelected?.value
    ? sites.find((siteValue) => siteValue.name === siteSelected?.value)
    : [];
  const currentNetworks = selectedSite?.networks?.length > 0 ? selectedSite.networks : [];

  const handleSubmit = useCallback(() => {
    dispatch(
      doAddMesh(
        partnerId,
        customerId,
        networkId,
        {
          name: meshIoName.value,
          serialNumber: serialNumber.value,
        },
        (status) => {
          if (status === 'success') {
            if (partnerId && customerId) dispatch(doGetMeshList(partnerId, customerId));
            togglePopup && togglePopup('AddOrEditGateway');
          }
        },
      ),
    );
  }, [
    customerId,
    dispatch,
    meshIoName.value,
    partnerId,
    serialNumber.value,

    togglePopup,
    networkId,
  ]);

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;
    const errorText = 'Please enter a valid';
    let error = '';
    switch (name) {
      case 'meshIoName':
        if (textVerification(value, 1)) error = `${errorText} Name`;
        // if (value === '1018') {description = 'MeshIO'}else{error = `${errorText} ${placeholder}`}
        setMeshIoName({ value, error });
        break;
      case 'serialNumber':
        if (textVerification(value, 1)) error = `${errorText} SerialNumber`;
        if (!isHex(value)) error = error = `${errorText} SerialNumber`;
        setSerialNumber({ value, error });
        break;
      default:
        break;
    }
  };

  const handleSerialDescription = (value) => {
    const switchCase = value.substring(0, 4);

    const errorText = 'Please enter a valid Serial Number';
    let description = '';

    // console.log(switchCase)
    let error = '';
    if (textVerification(value, 13, 14)) error = errorText;
    // if (!isHex(value)) error    = errorText;
    if (switchCase === '1018') {
      description = 'MeshIO';
    } else {
      error = errorText;
    }

    setSerialNumber({ description: description, value: value, error: error });
  };

  const disabled = useMemo(() => {
    if (isLoading) return true;
    if (
      meshIoName?.value === '' ||
      meshIoName?.error !== '' ||
      serialNumber?.value === '' ||
      serialNumber?.error !== '' ||
      siteSelected?.value === '' ||
      siteSelected?.error !== ''
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    isLoading,
    meshIoName?.error,
    meshIoName?.value,
    serialNumber?.error,
    serialNumber?.value,
    siteSelected?.error,
    siteSelected?.value,
  ]);

  return (
    <Modal showModal={showAddMesh} closeModal={togglePopup}>
      <div className={classnames(styles['wrapper'], meshStyles['width-35'])}>
        <h1>Add Mesh I/O</h1>
        <TextInput
          placeholder="Mesh I/O name"
          name="meshIoName"
          description={meshIoName.description}
          value={meshIoName.value}
          onChange={handleChange}
          error={meshIoName.error}
        />
        <TextInput
          name="serialNumber"
          placeholder="Serial Number"
          description={serialNumber?.description}
          value={serialNumber.value}
          onChange={(e) => handleSerialDescription(e.target.value)}
          error={serialNumber.error}
        />
        <Dropdown
          placeholder="Site"
          value={siteSelected.value}
          data={sites}
          onChange={(data) => setSite({ value: data?.name, error: '' })}
        />
        <Dropdown
          placeholder="Network"
          value={networkSelected.value}
          disabled={siteSelected.value === ''}
          data={currentNetworks}
          onChange={(data) => setNetwork({ value: data?.name, error: '' })}
        />
        <div className={styles['button-container']}>
          <Button buttonType="disabled" text="Cancel" onClick={togglePopup} />
          <Button text="Add" disabled={disabled} onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default AddMesh;
