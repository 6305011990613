import { Container } from '@material-ui/core';
import { useImmer } from 'use-immer';

import Modal from '../../../../../../components/Modals/Modal/Modal';
import styles from '../../../../../../styles/modals.module.scss';
import CustomButton from '../../../../../../components/Buttons/Button/Button';

import '../Controllers.scss';
import ControllerChannel from '../Components/ControllerChannel';
import OutlineButton from '../../../../../../components/OutlineButton/OutlineButton';
import { CONTROLLER_CONFIGS } from '../../../../../../constants/list/AccessPointTypeList';
import { useDispatch } from 'react-redux';

import {
  doAddController,
  doEditController,
  doGetControllerList,
} from '../../../../../../redux/thunks/controllers';
import SingleChannelContoller from '../Components/SingleChannelContoller';
import { useEffect, useState } from 'react';

const EditControllerDialog = ({ currentUser, customerId, isOpen, handleClose, data }) => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useImmer(1);
  const [serialNumber, setSerialNumber] = useImmer({ value: '', description: '', error: '' });
  const [installationMethod, setInstallationMethod] = useImmer({ name: '', value: '' });
  const [configuration, setConfiguration] = useImmer({ name: '', value: '', validSerials: [] });
  const [site, setSite] = useImmer({ name: '', value: '' });
  const [network, setNetwork] = useImmer({ name: '', value: '' });
  const [channel1enabled, setChannel1enabled] = useImmer(false);
  const [channel2enabled, setChannel2enabled] = useImmer(false);
  const [error, setError] = useState('');

  const [type, setType] = useImmer('');

  const [channel1, setChannel1] = useState({
    id: '',
    accessPointName: '',
    serialNumber: '',
    lockingMechanism: '',
    relayTime: 6,
    invertRelay: false,
  });
  const [channel2, setChannel2] = useState({
    id: '',
    accessPointName: '',
    serialNumber: '',
    lockingMechanism: '',
    relayTime: 6,
    invertRelay: false,
  });

  const submitForm = () => {
    let payload;

    if (type === '1door') {
      if (configuration.value === 3) {
        payload = [
          {
            id: channel1.id,
            name: channel1.accessPointName,
            installationMethod: installationMethod.value,
            configuration: configuration.value,
            lockingMechanism: channel2.lockingMechanism.value,
            siteId: site?.value,
            networkId: network.value,
            channelNo: 0,
            devices: [
              {
                serialNumber: serialNumber.value,
                deviceType: 3,
              },
              {
                serialNumber: channel1.serialNumber,
                deviceType: 1,
              },
            ],
            RelaySettings: {
              relayOnTime: channel1?.relayTime,
              invertRelayLogic: channel1?.invertRelay,
            },
            enableAttendance: false,
          },
        ];
      } else {
        payload = [
          {
            id: channel1.id,
            name: channel1.accessPointName,
            installationMethod: installationMethod.value,
            configuration: configuration.value,
            lockingMechanism: channel2.lockingMechanism.value,
            siteId: site?.value,
            networkId: network.value,
            channelNo: 0,
            devices: [
              {
                serialNumber: serialNumber.value,
                deviceType: 3,
              },
            ],
            RelaySettings: {
              relayOnTime: channel1?.relayTime,
              invertRelayLogic: channel1?.invertRelay,
            },
            enableAttendance: false,
          },
        ];
      }
    } else {
      if (configuration.value === 3) {
        if (channel2enabled) {
          payload = [
            {
              id: channel1.id,
              name: channel1.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel2.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.value,
              channelNo: 1,
              devices: [
                {
                  serialNumber: channel1.serialNumber,
                  deviceType: 1,
                },
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel1?.relayTime,
                invertRelayLogic: channel1?.invertRelay,
              },
              enableAttendance: false,
            },
            {
              id: channel2.id,
              name: channel2.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel2.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.value,
              channelNo: 2,
              devices: [
                {
                  serialNumber: channel2.serialNumber,
                  deviceType: 1,
                },
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel2?.relayTime,
                invertRelayLogic: channel2?.invertRelay,
              },
              enableAttendance: false,
            },
          ];
        } else {
          payload = [
            {
              id: channel1.id,
              name: channel1.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel2.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.value,
              channelNo: 1,
              devices: [
                {
                  serialNumber: channel1.serialNumber,
                  deviceType: 1,
                },
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel1?.relayTime,
                invertRelayLogic: channel1?.invertRelay,
              },
              enableAttendance: false,
            },
          ];
        }
      } else {
        if (channel2enabled) {
          payload = [
            {
              id: channel1.id,
              name: channel1.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel2.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.value,
              channelNo: 1,
              devices: [
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel1?.relayTime,
                invertRelayLogic: channel1?.invertRelay,
              },
              enableAttendance: false,
            },
            {
              id: channel2.id,
              name: channel2.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel2.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.value,
              channelNo: 2,
              devices: [
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel2?.relayTime,
                invertRelayLogic: channel2?.invertRelay,
              },
              enableAttendance: false,
            },
          ];
        } else {
          payload = [
            {
              id: channel1.id,
              name: channel1.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel2.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.value,
              channelNo: 1,
              devices: [
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel1?.relayTime,
                invertRelayLogic: channel1?.invertRelay,
              },
              enableAttendance: false,
            },
          ];
        }
      }
    }

    handleCreateController(payload);
  };

  useEffect(() => {
    // Find the data for channel 1
    const channel1Data = data.find((item) => item.channelNo === 1 || item.channelNo === 0);
    setConfiguration({
      value: channel1Data.configuration,
      validSerials: CONTROLLER_CONFIGS.find((config) => config.value === channel1Data.configuration)
        .validSerials.entry,
    });
    setInstallationMethod({ value: channel1Data.installationMethod });
    setSite({ value: channel1Data.siteId });
    setNetwork({ value: channel1Data.networkId });
    setInstallationMethod({ value: channel1Data.installationMethod });

    const doors = channel1Data.devices.find((dev) => dev.type === 3 || dev.type === 4);
    if (doors) {
      setSerialNumber({ value: doors.serialNumber, error: '' });
      setType('1door');
    }

    if (channel1Data) {
      setChannel1enabled(true);
      const device = channel1Data.devices.find((dev) => dev.type !== 3 && dev.type !== 4);

      setChannel1({
        id: channel1Data.id,
        accessPointName: channel1Data.name,
        serialNumber: device?.serialNumber ? device?.serialNumber : '',
        lockingMechanism: channel1Data.lockingMechanism,
        relayTime: channel1Data.relaySettings.relayOnTime,
        invertRelay: channel1Data.relaySettings.invertRelayLogic,
      });
    }

    // Find the data for channel 2
    const channel2Data = data.find((item) => item.channelNo === 2);
    if (channel2Data) {
      setChannel2enabled(true);
      const device = channel2Data.devices.find((dev) => dev.type !== 3 && dev.type !== 4);
      setChannel2({
        id: channel2Data.id,
        accessPointName: channel2Data.name,
        serialNumber: device?.serialNumber ? device?.serialNumber : '',
        lockingMechanism: channel2Data.lockingMechanism,
        relayTime: channel2Data.relaySettings.relayOnTime,
        invertRelay: channel2Data.relaySettings.invertRelayLogic,
      });
    }
  }, [
    data,
    setType,
    setSerialNumber,
    setConfiguration,
    setChannel1enabled,
    setChannel2enabled,
    setInstallationMethod,
    setNetwork,
    setSite,
  ]);

  const handleCreateController = (payload) => {
    if (payload.length > data.length) {
      dispatch(
        doAddController(
          currentUser.partnerId,
          customerId,
          network.value,
          [payload[1]],
          handleClose,
        ),
      );

      dispatch(
        doEditController(
          currentUser.partnerId,
          customerId,
          network.value,
          payload[0],
          handleClose,
          channel1.id,
        ),
      );
      // doGetControllerList(currentUser.partnerId, customerId),
      dispatch(doGetControllerList(currentUser.partnerId, customerId));
    } else {
      dispatch(
        doEditController(
          currentUser.partnerId,
          customerId,
          network.value,
          payload[0],
          handleClose,
          channel1.id,
        ),
      );
      dispatch(
        doEditController(
          currentUser.partnerId,
          customerId,
          network.value,
          payload[1],
          handleClose,
          channel2.id,
        ),
      );
    }
    dispatch(doGetControllerList(currentUser.partnerId, customerId));
  };

  const nextStepClicked = () => {
    if (!activeStep) {
      setActiveStep(1);
    } else {
      submitForm();
    }
  };

  useEffect(() => {
    const checkDoors = (val) => {
      val = val.substring(0, 4);
      if (String(val) === '1027') {
        setType('1door');
      } else {
        setType('');
      }
    };
    checkDoors(serialNumber.value);
  }, [serialNumber.value, setType]);

  const renderSteps = () => {
    if (type === '1door') {
      return (
        <Container className="step step2">
          <form>
            <SingleChannelContoller
              channelenabled={channel1enabled}
              channelName="Channel 1"
              value={channel1}
              onChange={setChannel1}
              configuration={configuration}
              validSerials={configuration.validSerials}
              onEnable={setChannel1enabled}
              setError={setError}
              module={'edit'}
            />
          </form>
        </Container>
      );
    } else {
      return (
        <Container className="step step2">
          <form>
            <ControllerChannel
              channelenabled={channel1enabled}
              channelName="Channel 1"
              value={channel1}
              onChange={setChannel1}
              onEnable={setChannel1enabled}
              configuration={configuration}
              validSerials={configuration.validSerials}
              setError={setError}
              module={data.length > 0 ? 'edit' : ''}
            />
            <ControllerChannel
              channelenabled={channel2enabled}
              channelName="Channel 2"
              value={channel2}
              onChange={setChannel2}
              validSerials={configuration.validSerials}
              onEnable={setChannel2enabled}
              setError={setError}
              configuration={configuration}
              module={data.length === 2 ? 'edit' : ''}
            />
          </form>
        </Container>
      );
    }
  };

  const validateForm = () => {
    if (activeStep === 1) {
      if (!channel1enabled && !channel2enabled) {
        return false;
      }

      if (channel1enabled) {
        if (channel1.accessPointName === '') {
          return false;
        }
        if (configuration?.value === 3 && channel1.serialNumber === '') {
          return false;
        }
        if (channel1.lockingMechanism === '') {
          return false;
        }
      }

      if (!channel1enabled && channel2enabled) {
        return false;
      }

      if (channel2enabled) {
        if (channel2.accessPointName === '') {
          return false;
        }
        if (configuration?.value === 3 && channel2.serialNumber === '') {
          return false;
        }
        if (channel2.lockingMechanism === '') {
          return false;
        }
      }
    }
    if (error !== '') {
      return false;
    }
    if (serialNumber.error !== '') {
      return false;
    }

    if (serialNumber.value.length === 0) {
      return false;
    }
    if (installationMethod.value === '') {
      return false;
    }
    if (configuration.value === '') {
      return false;
    }
    if (site.value === '') {
      return false;
    }
    if (network.value === '') {
      return false;
    }
    return true;
  };

  return (
    <Modal
      showModal={isOpen}
      closeModal={() => handleClose()}
      style={{ width: '100%', maxWidth: '694px' }}
      className="add-controller"
    >
      <div className={styles['wrapper']}>
        <div className="add-controller">
          <h1
            style={{
              color: '#353f94',
              fontFamily: 'UrbanistSemiBold',
              fontSize: '1.5rem',
            }}
          >
            Edit Controller
          </h1>

          {renderSteps()}

          <div className={styles['button-container']}>
            {activeStep !== 0 && (
              <OutlineButton text="Cancel" color="secondary" onClick={handleClose} />
            )}

            <CustomButton
              text={activeStep ? 'Save' : 'Next'}
              onClick={nextStepClicked}
              disabled={!validateForm()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditControllerDialog;
