import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { reverse } from 'named-urls';
import routes from '../../../constants/routes';
import { statusRenderer } from '../../../helpers/renderers';
import { statusSearcher } from '../../../helpers/searchers';

// styles
import styles from '../../../styles/table.module.scss';

const COMMON_COLUMN = [
  {
    title: 'Customer Name',
    field: 'name',
    name: 'name',
    render: (_, row) => {
      return (
        <div className={styles['user_details']}>
          <div className={styles['avatar']}>
            <p>{row?.name?.split('')[0]}</p>
          </div>
          <Link
            to={reverse(`${routes.customer.customerId.details}`, {
              customerId: row.id || 1,
            })}
            className={clsx('link--invisible', styles['cell-link'])}
          >
            {row.name}
          </Link>
        </div>
      );
    },
  },
];

const tableConfig = [
  { title: 'Id', field: 'id', name: 'id' },
  ...COMMON_COLUMN,
  {
    title: 'Status',
    field: 'status',
    // name: 'status',
    render: (columnProps, row) => statusRenderer(row.resourceState),
    customFilterAndSearch: (term, row) => statusSearcher(term, row.resourceState),
  },
  { title: 'User Count', field: 'user_count' },
  {
    title: 'Device Count',
    field: 'device_count',
    // name: 'search',
  },
  {
    title: 'Offline Devices',
    field: 'offline_devices',
    // name: 'search',
  },
  {
    title: 'Count of Doors',
    field: 'door_count',
    // name: 'search',
  },
  {
    title: 'Customer Type',
    field: 'cus_type',
    // name: 'search',
  },
  {
    title: 'Software Modules',
    field: 'sw_mod',
    // name: 'search',
  },
];

export default tableConfig;
