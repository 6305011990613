import { useState, useEffect } from 'react';
import debounce from '../utils/Debounce';

const getDeviceConfig = (width) => {
  if (width === 0 || width <= 600) {
    return 'xs';
  } else if (width >= 600 && width <= 960) {
    return 'sm';
  } else if (width >= 960 && width <= 1280) {
    return 'md';
  } else if (width >= 1280 && width <= 1920) {
    return 'lg';
  } else {
    return 'xl';
  }
};

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return width;
}

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(getWindowDimensions()));

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setBrkPnt(getDeviceConfig(getWindowDimensions()));
    }, 250);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
