import React from 'react';

import Modal from '../../../../../../components/Modals/Modal';
import UiButton from '../../../../../../components/UIButton';

import styles from './DeleteMeshIO.module.scss';

const DeletMeshIOModal = ({ showModal, title, description, onSubmit, onClose }) => {
  return (
    <Modal showModal={showModal} closeModal={onClose} type="danger">
      <div className={styles['wrapper']}>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className={styles['button-container']}>
        <UiButton type="disabled" text="Cancel" buttonSmall={true} handleSubmit={onClose} />
        <UiButton handleSubmit={onSubmit} text="Delete" buttonSmall={true} type="danger" />
      </div>
    </Modal>
  );
};

export default DeletMeshIOModal;
