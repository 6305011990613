import React, { useCallback } from 'react';
import styles from './HardwareIntegration.module.scss';
import PropTypes from 'prop-types';
import FirePanel from './FirePanel/FirePanel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const HardwareIntegration = () => {
  const value = 0;

  const tabPanel = useCallback(() => {
    switch (value) {
      case 0:
        return <FirePanel />;

      default:
        return <></>;
    }
  }, [value]);

  return (
    <div className={styles.container}>
      <div className="pd-2">{tabPanel()}</div>
    </div>
  );
};

export default HardwareIntegration;
