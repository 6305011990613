import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import Button from '@material-ui/core/Button';
import styles from './EditFirePanel-styles.module.scss';
import classnames from 'classnames';
import Add from '@material-ui/icons/Add';
import Mesh from '../Mesh/Mesh';
// import { intersectionMesh } from '../../../../../../utils/Common';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { doGetMeshList } from '../../../../../../redux/thunks/mesh';
import { editFirePanel } from '../../../../../../redux/thunks/firepanel';
import Modal from '../../../../../../components/Modals/Modal';
// import TextInput from '../../../../../../components/TextInput';
import TextInput from '../../../../../../components/Inputs/TextInput';
import SquareDelete from '../../../../../../components/SquareDelete/SquareDelete';

import Dropdown from '../../../../../../components/Inputs/Dropdown';
import ButtonCustom from '../../../../../../components/Buttons/Button';

const EditFirePanel = ({ togglePopup, data, useMesh }) => {
  const { customerId } = useParams();
  //redux
  const dispatch = useDispatch();

  const currentPartner = useSelector((state) => state.auth.currentPartner);

  //state
  const [firePanelData, setFirePanelData] = useImmer({
    name: '',
    siteId: '',
    siteName: '',
    networkId: '',
    meshIos: {},
  });

  const [, setDisableButton] = useImmer(true);
  // maintaining a state to not delete a meshio in case if there is only one meshio
  const [errorDelete, setErrorDelete] = useState('');
  const meshIOs = useSelector((state) => state.mesh.meshIOs);

  const popupToggle = () => {
    togglePopup && togglePopup();
  };
  // const getMeshIoUnderSite = useCallback(() => {
  //   const siteId = data.siteId; // Assuming you have site information in the data object
  //   if (siteId) {
  //     const meshData = data.meshios; // Assuming you have meshData in the data object
  //     if (meshData?.meshIOs) {
  //       setMeshIO(meshData.meshios);
  //       // getDetail();
  //     }
  //   }
  // }, [data.meshios, data.siteId, setMeshIO]);

  useEffect(() => {
    // getMeshIoUnderSite();
    if (data?.name && data?.siteName) {
      setFirePanelData((draft) => {
        draft.name = data.name;
        draft.siteName = data.siteName;
        draft.siteId = data.siteId;
        draft.networkId = data.networkId;
      });
    }
    if (data?.meshios && data?.meshios.length > 0) {
      const t = {};
      data.meshios.forEach((item, index) => {
        const filtered = meshIOs.filter((i) => i.serialNumber === item.serialNumber);
        t[index] = {
          serialNumber: item.serialNumber,
          pins: item.pins,
          show: false,
          local: false,
          selected: JSON.parse(JSON.stringify(filtered)),
          previous: JSON.parse(JSON.stringify(filtered)),
        };
      });
      setFirePanelData((draft) => {
        draft.meshIos = t;
      });
    } else {
      setFirePanelData((draft) => {
        draft.meshIos = {};
      });
    }
  }, [
    data.name,
    data.siteName,
    data.siteId,
    data.networkId,
    data.meshios,
    meshIOs,
    setFirePanelData,
  ]);

  const getFilteredMesh = (serialNumber) => {
    const filtered = meshIOs.filter((item) => item.serialNumber === serialNumber);
    if (filtered.length > 0) {
      return filtered[0];
    } else {
      return {};
    }
  };

  // useEffect(() => {
  //   if (data.siteId !== '') {
  //     getMeshIoUnderSite(data.siteId);
  //   }
  // }, [data.siteId, getMeshIoUnderSite]);

  const getMeshList = useCallback(() => {
    if (currentPartner && customerId) dispatch(doGetMeshList(currentPartner.partnerId, customerId));
  }, [currentPartner, customerId, dispatch]);

  useEffect(() => {
    getMeshList();
  }, [getMeshList]);

  // useEffect(() => {
  //   const arr = meshIOs.filter(
  //     (item) => item.function === 'no_function' && item.meshIOState === 'unassigned',
  //   );

  //   const a = [];
  //   for (const key in firePanelData.meshIos) {
  //     if (firePanelData.meshIos[key].serialNumber !== '') {
  //       a.push(firePanelData.meshIos[key].serialNumber);
  //     }
  //   }
  //   const filtered = intersection(arr, a);
  // }, [firePanelData.meshIos, firePanelData.siteId, meshIOs]);

  useEffect(() => {
    if (data && data?.siteId) {
      setFirePanelData((draft) => {
        draft.siteId = data?.siteId;
      });
    }
  }, [data?.site, data, setFirePanelData]);

  const handleChange = useCallback(
    (name, value) => {
      if (name === 'name') {
        setFirePanelData((draft) => {
          draft.name = value;
        });
      } else if (name === 'site') {
        setFirePanelData((draft) => {
          draft.siteId = value;
        });
      }
    },
    [setFirePanelData],
  );

  const addMesh = useCallback(() => {
    let last = Object.keys(firePanelData.meshIos).length - 1;
    if (last < 0) {
      last = 0;
    } else {
      last = last + 1;
    }
    setFirePanelData((draft) => {
      draft.meshIos[last] = {
        serialNumber: '',
        pins: [],
        show: false,
        name: '',
        selected: [],
        previous: [],
        local: true,
      };
    });
  }, [firePanelData.meshIos, setFirePanelData]);

  const hideShowMesh = useCallback(
    (key, status) => {
      setFirePanelData((draft) => {
        draft.meshIos[key].show = status;
      });
    },
    [setFirePanelData],
  );

  const deleteMesh = useCallback(
    (key) => {
      if (Object.keys(firePanelData.meshIos).length < 2) {
        setErrorDelete(
          'Cannot delete Mesh I/O. Minimum one Mesh I/O is required to assign with fire panel.',
        );
      } else {
        setFirePanelData((draft) => {
          delete draft.meshIos[key];
        });
      }
    },
    [setFirePanelData, firePanelData.meshIos],
  );

  const updateMeshData = useCallback(
    (key, id, status) => {
      setFirePanelData((draft) => {
        const item = draft.meshIos[key].pins.find((it) => it.id === id);
        item.isEnabled = status;
      });
    },
    [setFirePanelData],
  );

  const updatePinName = useCallback(
    (key, id, text) => {
      setFirePanelData((draft) => {
        const item = draft.meshIos[key].pins.find((it) => it.id === id);
        item.name = text;
      });
    },
    [setFirePanelData],
  );

  const addPins = useCallback(
    (key, serialNumber) => {
      const filtered = meshIOs.filter((item) => item.serialNumber === serialNumber);
      if (filtered.length > 0) {
        setFirePanelData((draft) => {
          draft.meshIos[key].serialNumber = serialNumber;
          draft.meshIos[key].pins = [
            {
              id: 9,
              name: 'DI.04',
              type: 'DI',
              number: 4,
              isEnabled: false,
            },
            {
              id: 10,
              name: 'DI.03',
              type: 'DI',
              number: 3,
              isEnabled: false,
            },
            {
              id: 11,
              name: 'DI.02',
              type: 'DI',
              number: 2,
              isEnabled: false,
            },
            {
              id: 12,
              name: 'DI.01',
              type: 'DI',
              number: 1,
              isEnabled: false,
            },
          ];
          draft.meshIos[key].selected = JSON.parse(JSON.stringify(filtered));
        });
      }
    },
    [meshIOs, setFirePanelData],
  );

  const Update = useCallback(() => {
    const body = {};
    const firePanelId = data.id;
    body.name = firePanelData.name;
    const tempMesh = [];
    Object.keys(firePanelData.meshIos).map((key) => {
      const temp = {};
      temp.serialNumber = firePanelData.meshIos[key].serialNumber;
      const arr = firePanelData.meshIos[key].pins.filter((item) => item.type === 'DI');
      temp.pins = arr;
      tempMesh.push(temp);
      return null;
    });
    body.meshIOs = tempMesh;

    dispatch(
      editFirePanel(currentPartner.partnerId, customerId, firePanelId, body, (status) => {
        if (status === 'success') {
          dispatch(doGetMeshList(currentPartner.partnerId, customerId));
          togglePopup && togglePopup('success');
        }
      }),
    );
  }, [
    currentPartner.partnerId,
    customerId,
    data.id,
    dispatch,
    firePanelData.meshIos,
    firePanelData.name,
    togglePopup,
  ]);

  useEffect(() => {
    if (Object.keys(firePanelData.meshIos).length > 0) {
      for (const key in firePanelData.meshIos) {
        if (firePanelData.meshIos.hasOwnProperty(key)) {
          if (firePanelData.meshIos[key].serialNumber === '') {
            setDisableButton(true);
            return;
          } else {
            setDisableButton(false);
          }
          // pins shudnt have empty string
          firePanelData.meshIos[key].pins.map((item) => {
            if (item.name === '') {
              setDisableButton(true);
            }
            return null;
          });
        }
      }
    } else {
      setDisableButton(true);
    }
  }, [firePanelData.meshIos, setDisableButton]);

  useEffect(() => {
    if (errorDelete !== '') {
      setTimeout(() => {
        setErrorDelete('');
      }, 5000);
    }
  }, [errorDelete]);

  // const getMeshIosToMap = useCallback((list1, list2, list3) => {
  //   const tempList = intersectionMesh(list1, list2);
  //   if (tempList.length > 0) {
  //     return [...list1, ...list3];
  //   } else {
  //     return [...list1, ...list2, ...list3];
  //   }
  // }, []);

  const filteredMeshData = meshIOs.filter((item) => item.networkId === firePanelData.networkId);

  const usedSerialNumbers = useMesh.flatMap((item) =>
    item.meshios.map((meshio) => meshio.serialNumber),
  );

  // Filter 'filteredMeshData' to remove the mesh IOs with used serial numbers
  const filteredUnusedMeshData = filteredMeshData.filter((meshItem) => {
    return !usedSerialNumbers.includes(meshItem.serialNumber);
  });

  // console.log(firePanelData.name);

  return (
    <Modal showModal={true} closeModal={() => popupToggle(false)}>
      <div className={classnames(styles['container'])}>
        <div className={classnames(styles['header'])}>
          <p className="modal-title">Edit Fire Panel</p>
        </div>
        <div className="mrb-2">
          <div className={classnames(styles['details_input'])}>
            <div className={classnames(styles['input_container'])}>
              <TextInput
                name="firePanelName"
                placeholder="Fire Panel Name"
                value={firePanelData.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </div>
            <div className={classnames(styles['input_container'])}>
              <TextInput
                placeholder="Site name"
                value={firePanelData.siteName}
                onChange={(value) => handleChange('siteName', value)}
              />
            </div>
          </div>
          <div className={classnames(styles['header'])}>
            <p className="sub-title">Select Mesh I/O</p>

            <div className={classnames(styles['meshButton-container'])}>
              <div className={classnames(styles['mrg-r2'])}>
                {filteredUnusedMeshData.length === 0 && <p>No meshIOs</p>}
              </div>

              <Button
                id="cancel-gateway"
                type="button"
                className="outline"
                variant="outlined"
                onClick={() => {
                  addMesh();
                }}
                disabled={
                  firePanelData.name === '' ||
                  firePanelData.siteId === '' ||
                  filteredUnusedMeshData.length === 0
                }
                startIcon={<Add />}
                disableElevation
              >
                Add Mesh I/O
              </Button>
            </div>
          </div>
          {Object.keys(firePanelData.meshIos).map((key, index) => {
            const mesh = getFilteredMesh(firePanelData.meshIos[key].serialNumber);
            const selectedvalue = mesh?.name ? mesh?.name : '';

            // const isSerialNumberExisting = Object.keys(firePanelData.meshIos)
            //   .filter((otherKey) => otherKey !== key) // Exclude the current key
            //   .some((otherKey) => {
            //     const otherMesh = firePanelData.meshIos[otherKey];
            //     const otherSerialNumber = otherMesh.serialNumber;
            //     return otherSerialNumber === mesh.serialNumber;
            //   });

            const filteredOptions = filteredUnusedMeshData.filter((option) => {
              const serialNumberMatch =
                firePanelData.meshIos[key].serialNumber !== option.serialNumber;

              return serialNumberMatch;
            });

            return (
              <>
                <div className="box-grey">
                  <div className={classnames(styles['header'])}>
                    <div className={classnames(styles['checkbox-width'])}>
                      <Dropdown
                        placeholder="Mesh I/O"
                        name="Mesh I/O"
                        value={selectedvalue}
                        onChange={(e) => {
                          addPins(key, e.serialNumber);
                        }}
                        data={filteredOptions}
                        disabled={data.meshios.some(
                          (item) => item.serialNumber === mesh?.serialNumber,
                        )}
                      />
                    </div>
                    <div className={classnames(styles['meshButton-container'])}>
                      <div>
                        <ButtonCustom
                          buttonType="secondary"
                          text={firePanelData.meshIos[key].show ? 'Hide Details' : 'Show Details'}
                          disabled={firePanelData.meshIos[key].serialNumber === ''}
                          onClick={() => {
                            hideShowMesh(key, !firePanelData.meshIos[key].show);
                          }}
                        />
                      </div>

                      <SquareDelete
                        color={'#ff535a'}
                        onPress={() => {
                          deleteMesh(key);
                        }}
                      />
                    </div>
                  </div>
                  {errorDelete !== '' && <p className={styles['error']}>{errorDelete}</p>}
                  {firePanelData.meshIos[key].show && (
                    <div className="mesh-container">
                      <Mesh
                        isEditable
                        pinData={firePanelData.meshIos[key].pins}
                        handleChange={(id, status) => {
                          updateMeshData(key, id, status);
                        }}
                        onTextChange={(id, text) => {
                          updatePinName(key, id, text);
                        }}
                        title={firePanelData.meshIos[key].name}
                        isTextChangeable={true}
                      />
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>

        <div className={styles['button-container']}>
          <div className={styles['button_width']}>
            <ButtonCustom
              text="Cancel"
              buttonType="disabled"
              onClick={() => {
                popupToggle();
              }}
            />
          </div>

          <div className={styles['button_width']}>
            <ButtonCustom
              text={`Save`}
              type="submit"
              onClick={() => {
                Update();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditFirePanel;
