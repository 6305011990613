import { useCallback, useState, useMemo } from 'react';

// routing

// styles
import styles from '../styles.module.scss';

// components
import Button from '../../../components/Buttons/Button';
import TextInput from '../../../components/Inputs/TextInput';

// redux
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../../redux/selectors/miscellaneous';

const NewPasswordForm = ({ handleResetPassword }) => {
  const [password, setPassword] = useState({
    value: '',
    error: '',
  });

  const [confirmPassword, setConfirmPassword] = useState({
    value: '',
    error: '',
  });

  const isLoading = useSelector(selectIsLoading);

  const disabled = useMemo(() => {
    if (password.value === '' && confirmPassword.value === '') {
      return true;
    } else if (isLoading) {
      return true;
    } else if (password.value === confirmPassword.value) {
      return false;
    } else {
      return true;
    }
  }, [password.value, confirmPassword.value, isLoading]);

  const handlesubmit = useCallback(
    (e) => {
      if (!disabled) {
        e.preventDefault();
        handleResetPassword({ newPassword: password?.value });
      }
    },
    [disabled, handleResetPassword, password?.value],
  );

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;
    const errorText = 'Please enter a valid';

    if (name === 'password') {
      const error = value.length < 4 ? `${errorText} Password` : '';
      setPassword({ value, error });
    }
    if (name === 'confirmPassword') {
      const error = value.length < 4 ? `${errorText} Confirm Password` : '';
      setConfirmPassword({ value, error });
    }
  };

  return (
    <div className={styles['form']}>
      <h1>New Password</h1>

      <TextInput
        placeholder="Password"
        name="password"
        type="password"
        onChange={handleChange}
        error={password.error}
      />

      <TextInput
        placeholder="Confirm Password"
        name="confirmPassword"
        type="password"
        onChange={handleChange}
        error={confirmPassword.error}
      />

      <div className={styles['buttonContainer']}>
        <Button onClick={handlesubmit} type="submit" text="Change Password" disabled={disabled} />
      </div>
    </div>
  );
};

export default NewPasswordForm;
