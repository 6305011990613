import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectOrganisationGateways,
  selectOrganisationSites,
} from '../../../../../redux/selectors/organisation';

import AddOrEditGateway from './AddOrEditGateway';
import GatewayLogPopup from './GatewayLogPopup';

import {
  doDeleteOrganisationGateway,
  doReadOrganisationGateways,
} from '../../../../../redux/thunks/organisation';
import { selectCurrentPartner } from '../../../../../redux/selectors/authentication';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
// import useBreakpoint from '../../../../../hooks/useBreakpoint';
import Table from '../../../../../components/Table/Table';

import SquareDelete from '../../../../../components/SquareDelete/SquareDelete';
import { textStatusRender } from '../../../../../helpers/renderers';
import '../../../../../styles/common.scss';
import Button from '../../../../../components/Buttons/Button';
import ModalAlert from '../../../../../components/Modals/ModalAlert';
import { Search, SearchStatus } from '../../../../../utils/Search';
import { meshConfiguredStateRender } from '../../../../../utils/Common';

const GatewayTab = () => {
  const dispatch = useDispatch();
  const [, setPopupTitle] = useState('Add');
  const [showGatewayPopup, setShowGatewayPopup] = useState(false);
  const [showDeleteGatewayPopup, setShowDeleteGatewayPopup] = useState(false);
  const [showGatewayLogPopup, setShowGatewayLogPopup] = useState(false);
  const [gateway, setGateway] = useState(null);
  const { customerId } = useParams();
  const [filters, setFilters] = useImmer({
    serialNumber: '',
    cloudStatus: '',
    siteName: '',
    networkName: '',
    resourceState: '',
  });
  const [pagination, setPagination] = useImmer({ total: 0, currentPage: 1, perPage: 7 });

  const { gateways, currentUser, sites } = useSelector((state) => ({
    gateways: selectOrganisationGateways(state),
    sites: selectOrganisationSites(state),
    currentUser: selectCurrentPartner(state),
  }));

  /// table Columns

  const columns = [
    {
      title: 'Gateway Serial Number',
      field: 'serialNumber',
      name: 'serialNumber',
    },
    {
      title: 'Site',
      field: 'siteName',
      name: 'siteName',
      render: (columnProps, row) => row?.siteName,
    },
    {
      title: 'Network',
      name: 'networkName',
      render: (columnProps, rowData) => {
        return rowData.networkName;
      },
    },
    {
      title: 'State',
      field: 'meshConfiguredState',
      name: 'meshConfiguredState',
      render: (columnProps, row) => {
        return meshConfiguredStateRender(row.configurationStatus);
      },
      // customFilterAndSearch: (term, row) => statusSearcher(term, row.cloudStatus),
    },
    {
      title: 'Status',
      field: 'cloudStatus',
      name: 'cloudStatus',
      render: (columnProps, row) => {
        return textStatusRender(row.connectivityStatus);
      },
      // customFilterAndSearch: (term, row) => statusSearcher(term, row.cloudStatus),
    },
    {
      title: 'Action',
      render: (columnProps, row) => {
        return (
          <SquareDelete
            color={'#ff535a'}
            onPress={() => {
              setPopupTitle('Delete');
              setGateway(row);
              togglePopup('DeleteGateway');
            }}
          />
        );
      },
      // customFilterAndSearch: (term, row) => statusSearcher(term, row.cloudStatus),
    },
  ];
  ///

  const updatedGateways = useMemo(
    () =>
      gateways?.map((currentGateway) => {
        const foundSite = sites.find((site) => site.id === currentGateway.siteId);
        return { ...currentGateway, site: foundSite };
      }),
    [gateways, sites],
  );

  const togglePopup = (popupName) => {
    if (popupName === 'AddOrEditGateway') {
      setShowGatewayPopup(!showGatewayPopup);
      if (showGatewayPopup) {
        setGateway(null);
      }
    }

    if (popupName === 'DeleteGateway') {
      setShowDeleteGatewayPopup(!showDeleteGatewayPopup);
      if (showDeleteGatewayPopup) {
        setGateway(null);
      }
    }

    if (popupName === 'GatewayLog') {
      setShowGatewayLogPopup(!showGatewayLogPopup);
    }
  };

  const handleDelete = (e) => {
    dispatch(
      doDeleteOrganisationGateway({
        partnerId: currentUser.partnerId,
        organisationId: customerId,
        serialNumber: gateway.serialNumber,
      }),
    );
    setGateway(null);
    setShowDeleteGatewayPopup(false);
  };

  useEffect(() => {
    if (currentUser)
      dispatch(
        doReadOrganisationGateways({
          partnerId: currentUser.partnerId,
          organisationId: customerId,
        }),
      );
  }, [dispatch, currentUser, customerId]);

  useEffect(() => {
    setPagination((draft) => {
      draft.total = updatedGateways?.length ? updatedGateways?.length : 0;
    });
  }, [updatedGateways?.length, setPagination]);

  const filteredData = useCallback(() => {
    let data = updatedGateways ? updatedGateways : [];
    if (filters.serialNumber !== '') {
      data = Search(data, 'serialNumber', filters.serialNumber);
    }
    if (filters.cloudStatus !== '') {
      data = SearchStatus(data, 'cloudStatus', filters.cloudStatus);
    }
    if (filters.siteName !== '') {
      data = Search(data, 'siteName', filters.siteName);
    }
    if (filters.networkName !== '') {
      data = Search(data, 'networkName', filters.networkName);
    }
    if (filters.resourceState !== '') {
      data = SearchStatus(data, 'resourceState', filters.resourceState);
    }
    data = data.slice(
      (pagination.currentPage - 1) * 7,
      pagination.currentPage * pagination.perPage,
    );

    return data;
  }, [
    filters.cloudStatus,
    filters.serialNumber,
    filters.siteName,
    filters.resourceState,
    filters.networkName,
    pagination.currentPage,
    pagination.perPage,
    updatedGateways,
  ]);

  const hSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFilters((draft) => {
      draft[name] = value;
    });
  };

  const search = {
    onChange: hSearchChange,
  };

  const changePage = (p) => {
    const { page, per_page } = p;

    setPagination((draft) => {
      draft.currentPage = page;
      draft.perPage = per_page;
    });
  };

  return (
    <Fragment>
      <div className="spacer__1" />
      <div>
        {showGatewayPopup && (
          <AddOrEditGateway
            {...{
              showGatewayPopup,
              onClose: () => togglePopup('AddOrEditGateway'),
              gateway,
              togglePopup,
            }}
          />
        )}
        {showDeleteGatewayPopup && (
          <ModalAlert
            showModal={showDeleteGatewayPopup}
            closeModal={() => setShowDeleteGatewayPopup(false)}
            title={'Delete Gateway'}
            description={`Are you sure you want to delete Gateway ${
              gateway ? gateway.serialNumber : ''
            } ? This action is
            non reversible and will cause you to lose all data associated the Gateway.`}
            modalType="danger"
            handleSubmit={handleDelete}
          />
        )}
        {showGatewayLogPopup && (
          <GatewayLogPopup
            {...{
              showGatewayLogPopup,
              togglePopup,
            }}
          />
        )}
        <div>
          <div className="header-container">
            <p className="header">Gateways</p>
            <div className="button-cont">
              <Button
                text={'Add Gateway'}
                onClick={() => {
                  setPopupTitle('Add');
                  togglePopup('AddOrEditGateway');
                }}
                disabled={sites == null || sites.length === 0}
              />
            </div>
          </div>

          <Table
            columns={columns}
            data={filteredData() || []}
            pagination={pagination}
            defaultMessage={'No Gateways Found'}
            options={{ search }}
            changePage={changePage}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default GatewayTab;
