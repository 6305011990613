import * as React from 'react';
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41.592} height={45.557} {...props}>
    <g data-name="noun_on site_2998622">
      <g data-name="Group 9179" fill="#353f94">
        <path
          data-name="Path 3671"
          d="M27.887 17.633V7.475a1.309 1.309 0 0 0-.383-.91L20.748.335A1.359 1.359 0 0 0 19.933 0H1.246A1.228 1.228 0 0 0 0 1.2v33.444a1.228 1.228 0 0 0 1.246 1.2h19.071a.432.432 0 0 0 .24-.048l2.348-.527c-1.917-4.5-1.917-6.229-1.917-7a11.69 11.69 0 0 1 6.899-10.636ZM8.385 2.827a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431v2.731a.429.429 0 0 1-.431.431h-2.73a.429.429 0 0 1-.431-.431Zm0 6.038a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431V11.6a.429.429 0 0 1-.431.431h-2.73a.429.429 0 0 1-.431-.431Zm0 5.99a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431v2.731a.429.429 0 0 1-.431.431h-2.73a.429.429 0 0 1-.431-.431Zm0 5.99a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431v2.731a.429.429 0 0 1-.431.431h-2.73a.429.429 0 0 1-.431-.431ZM2.4 2.827a.429.429 0 0 1 .427-.427h2.731a.429.429 0 0 1 .431.431v2.727a.429.429 0 0 1-.431.431H2.827a.429.429 0 0 1-.427-.431V2.827Zm0 6.038a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431V11.6a.429.429 0 0 1-.431.431H2.827A.429.429 0 0 1 2.4 11.6V8.865Zm0 5.99a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431v2.731a.429.429 0 0 1-.431.431H2.827a.429.429 0 0 1-.427-.431v-2.732Zm0 8.721v-2.732a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431v2.731a.429.429 0 0 1-.431.431H2.827a.429.429 0 0 1-.427-.431Zm7.235 9.535a.429.429 0 0 1-.431.431h-3.31a.429.429 0 0 1-.431-.431v-6.277a.429.429 0 0 1 .431-.431H9.2a.429.429 0 0 1 .431.431Zm5.319 0a.429.429 0 0 1-.431.431h-3.311a.429.429 0 0 1-.431-.431v-6.277a.429.429 0 0 1 .431-.431h3.306a.429.429 0 0 1 .431.431Zm3.067-15.573a.429.429 0 0 1-.431.431h-2.736a.429.429 0 0 1-.431-.431v-2.732a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431Zm0-5.99a.429.429 0 0 1-.431.431h-2.736a.429.429 0 0 1-.431-.431V8.865a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431Zm0-5.99a.429.429 0 0 1-.431.431h-2.736a.429.429 0 0 1-.431-.431V2.827a.429.429 0 0 1 .431-.431h2.731a.429.429 0 0 1 .431.431Z"
        />
        <path
          data-name="Path 3672"
          d="M32.775 19.55a8.794 8.794 0 0 0-8.817 8.817c0 3.45 4.456 11.692 7.044 16.148a2.029 2.029 0 0 0 3.546 0c2.587-4.456 7.044-12.7 7.044-16.148a8.824 8.824 0 0 0-8.817-8.817Zm0 12.89a4.121 4.121 0 1 1 4.121-4.121 4.121 4.121 0 0 1-4.121 4.121Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgComponent;
