import React from 'react';
import style from './SquareEdit-styles.module.scss';
import { IconButton } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';

const SquareEdit = ({ onPress, children, ...props }) => {
  return (
    <IconButton onClick={onPress} className={style['button-container']} {...props}>
      <Edit style={{ color: '#8B94AC' }} />
    </IconButton>
  );
};

export default SquareEdit;
