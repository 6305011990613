import { axiosInstance } from '../api';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from '../../constants/UserPool';
import { doHideModal, doShowModal } from '../../redux/actionCreators/showModal';
import { doDisplayLoadingBar } from '../../redux/actionCreators/miscellaneous';
import { doLogin } from '../../redux/thunks/authentication';
// Authentication services
export const Login = async ({ phoneNumber, password }, dispatch) => {
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: phoneNumber,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: phoneNumber,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        resolve(data);
        dispatch(doHideModal());
      },

      onFailure: (err) => {
        reject(err);
        dispatch(doHideModal());
      },

      newPasswordRequired: (data) => {
        dispatch(doShowModal());
        dispatch(doDisplayLoadingBar({ isLoading: false }));
      },
    });
  });
};

export const ReadProfile = async () => {
  const url = `/partnerManagement/${process.env.REACT_APP_BASE_API_VER_V3}/users/profile`;
  return await axiosInstance({ method: 'GET', url: url });
};

export const ReadErrorMessage = async () => {
  const url = `/partnerManagement/${process.env.REACT_APP_BASE_API_VER_V4}/configurations`;
  return await axiosInstance({ method: 'GET', url: url });
};

export const ReadPartners = async () => {
  const url = `/partnerManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners`;
  return await axiosInstance({ method: 'GET', url: url });
};

export const SendForgotPasswordOTP = async ({ phoneNumber }) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: phoneNumber,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
      inputVerificationCode: (data) => {
        resolve(data);
      },
    });
  });

export const ResetPassword = async ({ phoneNumber, code, newPassword }) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: phoneNumber,
      Pool: UserPool,
    });
    user.confirmPassword(code, newPassword, {
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });

export const NewPassword = async ({ phoneNumber, password }, { newPassword }, dispatch) => {
  // return { setPassword, setPhoneNumber };
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: phoneNumber,
      Pool: UserPool,
    });
    const authenticationDetails = new AuthenticationDetails({
      Username: phoneNumber,
      Password: password,
    });

    user.authenticateUser(authenticationDetails, {
      onSuccess: function () {
        resolve();
      },
      newPasswordRequired: function (data) {
        user.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: function () {
              const payload = { phoneNumber, password: newPassword };
              dispatch(doLogin(payload));
            },
            onFailure: function (err) {
              reject(err);
            },
          },
        );
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};
