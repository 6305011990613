import React, { Fragment, useEffect, useState } from 'react';

import StatCard from '../../../components/StatCard';

// libraries
import { useHistory } from 'react-router-dom';

// icons
import Users from '../../../assets/images/dashboard/users.svg';
import Device from '../../../assets/images/dashboard/device.svg';
import Door from '../../../assets/images/dashboard/door.svg';
import Issues from '../../../assets/images/dashboard/issues.svg';

// styles
import styles from './dashboard_styles.module.scss';
import routes from '../../../constants/routes';
import { useSelector } from 'react-redux';
import { selectCurrentPartner } from '../../../redux/selectors/authentication';

import { Box } from '@material-ui/core';
import { axiosInfraInstance } from '../../../services/api';

const DashboardHome = () => {
  const [loadData, setLoadData] = useState({});
  const { currentPartner } = useSelector((state) => ({
    currentPartner: selectCurrentPartner(state),
  }));

  const url = `/infrastructureManagement/v1/partners/${currentPartner.partnerId}/dashboard`;
  useEffect(() => {
    const getData = async () => {
      const res = await axiosInfraInstance({ method: 'GET', url: url });
      setLoadData(res.data.message);
    };
    getData();
  }, [url]);
  const history = useHistory();

  return (
    <Fragment>
      <h1 className={styles['header']}>Dashboard</h1>
      <div className={styles['container']}>
        <div className={styles['div']}>
          <Box
            sx={{
              cursor: 'pointer',
            }}
            className={styles['grid_cards']}
            onClick={() => {
              history.push(routes.customer.list);
            }}
          >
            <StatCard
              title="Total Active Customers"
              // monthTotal={'N/A'}
              total={loadData.NoOforganisations}
              linkName="View Customers"
              icon={Users}
            />
          </Box>

          <Box className={styles['grid_cards']}>
            <StatCard
              title="Total Active Doors"
              total={loadData.NoOfAccessPoints}
              linkName="View Doors"
              icon={Door}
            />
          </Box>
        </div>

        <div className={styles['div']}>
          <Box className={styles['grid_cards']}>
            <StatCard
              title="Total no. of Gateways"
              total={loadData.NoOfGateways}
              linkName="View Warnings"
              icon={Device}
            />
          </Box>
          <Box className={styles['grid_cards']}>
            <StatCard
              title="Total Detached Devices"
              total={loadData.NoOfDetachedDevices}
              linkName="View Issues"
              icon={Issues}
            />
          </Box>
        </div>
      </div>
    </Fragment>
  );
};

export default DashboardHome;
