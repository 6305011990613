import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCurrentPartner } from '../../../../../../redux/selectors/authentication';
import { selectOrganisation } from '../../../../../../redux/selectors/organisation';
import { doUpdateOrganisation } from '../../../../../../redux/thunks/organisation';

// styles
import styles from '../../../../../../styles/modals.module.scss';
import {
  emailVerification,
  phonenumberVerification,
  textVerification,
} from '../../../../../../utils/formValdations';
import ORG_TYPE_LIST from '../../../../../../constants/list/OrganisationTypeList';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import TextInput from '../../../../../../components/Inputs/TextInput';
import PhoneInput from '../../../../../../components/Inputs/PhoneInput';
import Button from '../../../../../../components/Buttons/Button';
import Dropdown from '../../../../../../components/Inputs/Dropdown';
import Modal from '../../../../../../components/Modals/Modal';

const CustomerEditModal = ({ showModal, closeModal }) => {
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const { organisation, currentPartner } = useSelector((state) => ({
    organisation: selectOrganisation(state),
    currentPartner: selectCurrentPartner(state),
  }));

  // const [editForm, setEditForm] = useState(true);
  const [organisationName, setOrganisationName] = useState({ value: '', error: '' });
  const [organisationType, setOrganisationType] = useState({ value: '', error: '' });
  const [customerName, setCustomerName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });
  const [phoneNumber, setPhoneNumber] = useState({ value: '', error: '' });
  const [countryCode, setCountryCode] = useState('91');
  // const [admin, setAdmin] = useState({});

  useEffect(() => {
    const generalSetting = organisation?.generalSetting;
    const admins = generalSetting?.admins;
    const index = admins?.findIndex((i) => i?.adminType === 1);
    let admin;

    if (index !== -1 && admins && admins[index]) {
      // Set the 'admin' for the first matching element
      admin = admins[index];
    } else {
      // Handle the case where there is no matching element or 'adm' is undefined
      admin = {};
    }

    if (generalSetting) {
      setOrganisationName({ ...organisationName, value: generalSetting?.name || '' });
      setOrganisationType({ ...organisationType, value: generalSetting?.type || '' });
    }
    if (admin) {
      setCustomerName({ ...customerName, value: admin?.name || '' });
      setEmail({ ...email, value: admin?.email || '' });
      try {
        const mobileNumber = findPhoneNumbersInText(admin?.phone)[0];

        const { number } = mobileNumber;
        setCountryCode(number?.countryCallingCode || '91');
        setPhoneNumber({ ...phoneNumber, value: number?.nationalNumber || '' });
      } catch (error) {
        setPhoneNumber({ ...phoneNumber, value: admin.phone || '' });
      }
      // setEditForm(admin?.editable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;
    const errorText = 'Please enter a valid';
    let error = '';
    switch (name) {
      case 'organisationName':
        error = textVerification(value, 2) ? `${errorText} Organisation Name` : '';
        setOrganisationName({ value, error });
        break;
      case 'customerName':
        error = textVerification(value, 2) ? `${error} Customer Name` : '';
        setCustomerName({ value, error });
        break;
      case 'email':
        error = !emailVerification(value) ? `${error} Email` : '';
        setEmail({ value, error });
        break;
      case 'phoneNumber':
        error = !phonenumberVerification(`+${countryCode}${value}`) ? `${error} Phone Number` : '';
        setPhoneNumber({ value, error });
        break;
      default:
        break;
    }
  };

  const handlesubmit = () => {
    const payload = {
      name: organisationName?.value,
    };
    dispatch(
      doUpdateOrganisation(
        {
          partnerId: currentPartner.partnerId,
          organisationId: customerId,
          payload,
          setLoading: false,
          isIndividual: true,
        },
        (status) => {
          if (status === 'success') {
          }
        },
      ),
    );
    closeModal();
  };

  const disabled = () => {
    if (
      organisationName.value === '' ||
      organisationName.error !== '' ||
      organisationType.value === '' ||
      organisationType.error !== '' ||
      customerName.value === '' ||
      customerName.error !== '' ||
      email.value === '' ||
      email.error !== '' ||
      phoneNumber.value === '' ||
      phoneNumber.error !== ''
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <div className={styles['wrapper']}>
        <h1>Edit Customer</h1>
        {/* {!editForm && <p>You don't have edit access for this customer</p>} */}
        <TextInput
          name="organisationName"
          placeholder="Organisation name"
          value={organisationName.value}
          onChange={handleChange}
          error={organisationName.error}
        />
        <Dropdown
          disabled={true}
          name="organisationType"
          placeholder="Organisation type"
          value={organisationType?.value}
          data={ORG_TYPE_LIST}
          onChange={(data) => setOrganisationType({ value: data?.type, error: '' })}
        />
        <TextInput
          name="customerName"
          placeholder="name"
          value={customerName.value}
          onChange={handleChange}
          //disabled={!editForm}
          disabled={true}
          error={customerName.error}
        />
        <TextInput
          name="email"
          placeholder="Email"
          value={email.value}
          onChange={handleChange}
          //disabled={!editForm}
          disabled={true}
          error={email.error}
        />
        <PhoneInput
          name="phoneNumber"
          placeholder="Phone Number"
          type="number"
          value={phoneNumber.value}
          onChange={handleChange}
          defaultCountryCode={countryCode}
          disabled={true}
          error={phoneNumber.erorr}
          handleCountryCode={(code) => setCountryCode(code)}
        />

        <div className={styles['button-container']}>
          <Button onClick={closeModal} shadow={false} text="Cancel" buttonType="disabled" />
          <Button disabled={disabled()} onClick={handlesubmit} shadow={false} text="Done" />
        </div>
      </div>
    </Modal>
  );
};

CustomerEditModal.propTypes = {
  closeModal: PropTypes.func,
};

export default CustomerEditModal;
