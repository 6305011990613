import React, { Fragment } from 'react';
// import PropTypes from "prop-types";
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../redux/selectors/miscellaneous';
import style from './style.module.scss';

const LoadingBar = () => {
  const isLoading = useSelector(selectIsLoading);
  return (
    <Fragment>
      {isLoading && (
        <LinearProgress
          classes={{
            root: style['loader'],
            barColorPrimary: style['primary_color'],
          }}
        />
      )}
    </Fragment>
  );
};

export default LoadingBar;
