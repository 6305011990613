import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectOrganisationSites } from '../../../../../../redux/selectors/organisation';

//Lists
import {
  NEW_INSTALL_CONFIGS,
  RETROFIT_TYPE1_CONFIGS,
} from '../../../../../../constants/list/AccessPointTypeList';

import styles from './styles.module.scss';

// components
import Dropdown from '../../../../../../components/Inputs/Dropdown';
import TextInput from '../../../../../../components/Inputs/TextInput';
import Button from '../../../../../../components/Buttons/Button';
import Modal from '../../../../../../components/Modals/Modal';
import AccessPointConfiguration from './AccessPointConfiguration';
import InfoIcon from '../../../../../../components/InfoIcon';
import { doUpdateOrganisationAccessPoint } from '../../../../../../redux/thunks/organisation';
import Switch from '../../../../../../components/Inputs/Switch/Switch';
import OutlineButton from '../../../../../../components/OutlineButton/OutlineButton';
import { Box, Typography } from '@material-ui/core';

// const NETWORK_NAME_SEPARATOR = ' - ';

const AddOrEditAccessPointPopup1 = ({
  popupTitle,
  showAccessPointPopup,
  togglePopup,
  accessPoint,
  mode = 'add',
  customerId,
  currentUser,
}) => {
  const { sites } = useSelector((state) => ({
    sites: selectOrganisationSites(state),
  }));

  // console.log(accessPoint);

  //states
  const [accessPointName, setAccessPointName] = useState('');
  const [installationMethod, setInstallationMethod] = useState({ name: '', value: '' });
  const [relayTimeError, setRelayTimeError] = useState('');

  const [configuration, setConfiguration] = useState({
    name: '',
    value: '',
    formOptions: {
      entry: false,
      exit: false,
      control: false,
      lockingMechanism: false,
      relayTime: false,
      attendanceAvailable: false,
    },
    validSerials: {
      entry: [],
      exit: [],
      control: [],
    },
    lockingMech: {},
  });
  // const [devicesInfo, setDevicesInfo] = useState([]);
  // const [selectedLockingMechanism, setSelectedLockingMechanism] = useState();
  const [site, setSite] = useState({ name: '', value: '' });
  const [disabled, setDisabled] = useState(true);
  const [flag, setFlag] = useState(false);
  const [relayOnTime, setRelayOntime] = useState(0);
  const [invertRelayLogic, setInvertRelayLogic] = useState();
  const [toggleRelaytime, setToggleRelayTime] = useState(true);
  const [selectedConfiguration, setSelectedConfiguration] = useState({ value: '' });
  const [currentInstallationMethod, setCurrentInstallationMethod] = useState({ value: '' });
  const [network, setNetwork] = useState({
    name: accessPoint ? accessPoint.networkName : '',
    value: '',
  });
  const [enableAttendance, setEnableAttendance] = useState(false);

  const dispatch = useDispatch();

  // console.log(accessPoint);
  const ACCESS_POINT = accessPoint
    ? {
        id: accessPoint.id,
        name: accessPoint.name,
        installationMethod: accessPoint.installationMethod,
        configuration: accessPoint.configuration,
        siteId: accessPoint.Id,
        siteName: accessPoint.siteName,
        networkName: accessPoint.networkName,
        deviceInfo: accessPoint.devices,
        enableAttendance: accessPoint.enableAttendance,
        deviceSettings: {
          lockingMechanism: accessPoint.lockingMechanism,
          relaySettings: {
            relayOnTime: accessPoint.relaySettings.relayOnTime,
            invertRelayLogic: accessPoint.relaySettings.invertRelayLogic,
          },
        },
      }
    : {
        id: '',
        name: '',
        installationMethod: '',
        configuration: '',
        siteId: '',
        siteName: '',
        deviceInfo: [
          {
            type: '',
            serialNumber: '',
          },
        ],
        deviceSettings: {
          lockingMechanism: '',
          relaySettings: {
            relayOnTime: '',
            invertRelayLogic: '',
          },
        },
      };

  useEffect(() => {
    if (popupTitle === 'Edit') {
      setAccessPointName(ACCESS_POINT.name);
      setSite({ name: ACCESS_POINT.siteName, value: ACCESS_POINT.siteId });
      setSelectedConfiguration({ value: ACCESS_POINT.configuration });
      setRelayOntime(ACCESS_POINT.deviceSettings.relaySettings.relayOnTime);
      setInvertRelayLogic(ACCESS_POINT.deviceSettings.relaySettings.invertRelayLogic);
      setEnableAttendance(ACCESS_POINT.enableAttendance);
      setCurrentInstallationMethod({ value: ACCESS_POINT.installationMethod });

      // const selectedData = Configdata.find((i) => i.value === ACCESS_POINT.configuration);

      // console.log(selectedData);

      // console.log(Configdata);

      // const selectedInstallation = installationData.find(
      //   (i) => i.value === ACCESS_POINT.installationMethod,
      // );

      // setInstallationMethod({
      //   name: selectedInstallation.name,
      //   value: selectedInstallation.value,
      // });

      // setConfiguration({
      //   name: selectedData.name,
      //   value: selectedData.value,
      //   formOptions: {
      //     entry: selectedData.formOptions.entry,
      //     exit: selectedData.formOptions.exit,
      //     control: selectedData.formOptions.control,
      //     lockingMechanism: selectedData.formOptions.lockingMechanism,
      //     relayTime: selectedData.formOptions.relayTime,
      //     attendanceAvailable: selectedData.formOptions?.attendanceAvailable,
      //   },
      //   validSerials: {
      //     entry: selectedData.validSerials.entry,
      //     exit: selectedData.validSerials.exit,
      //     control: selectedData.validSerials.control,
      //   },
      //   lockingMech: selectedData?.lockingMech,
      // });

      // setDevicesInfo(ACCESS_POINT.deviceInfo);
    }
  }, [
    popupTitle,
    ACCESS_POINT.name,
    ACCESS_POINT.siteName,
    ACCESS_POINT.configuration,
    ACCESS_POINT.siteId,
    ACCESS_POINT.deviceSettings.relaySettings.invertRelayLogic,
    ACCESS_POINT.deviceSettings.relaySettings.relayOnTime,
    ACCESS_POINT.enableAttendance,
    ACCESS_POINT.installationMethod,
    // ACCESS_POINT.deviceSettings,

    // Configdata,
    // installationData,
  ]);

  const popupToggle = () => {
    togglePopup('AddOrEditAccessPoint');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFlag(!flag);

    popupToggle();
  };

  const handleUpdateAccessPoint = () => {
    const updatedPayload = {};

    if (accessPointName !== ACCESS_POINT.name) {
      updatedPayload.name = accessPointName;
      updatedPayload.enableAttendance = enableAttendance;
    }

    if (
      relayOnTime !== ACCESS_POINT.deviceSettings.relaySettings.relayOnTime ||
      invertRelayLogic !== ACCESS_POINT.deviceSettings.relaySettings.invertRelayLogic
    ) {
      updatedPayload.relaySettings = {
        relayOnTime: parseInt(relayOnTime),
        invertRelayLogic: invertRelayLogic,
      };
      updatedPayload.enableAttendance = enableAttendance;
    }
    if (enableAttendance !== ACCESS_POINT.enableAttendance) {
      updatedPayload.enableAttendance = enableAttendance;
    }
    if (Object.keys(updatedPayload).length === 0) {
      // No changes, return early or handle it as needed
      return;
    }

    return dispatch(
      doUpdateOrganisationAccessPoint({
        accessPointId: accessPoint?.id,
        partnerId: currentUser.partnerId,
        organisationId: customerId,
        payload: updatedPayload,
        callback: popupToggle,
      }),
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    setFlag(!flag);
  };

  useEffect(() => {
    const value =
      accessPointName !== '' &&
      (mode === 'add'
        ? installationMethod?.value !== '' && configuration?.value !== '' && site?.value !== ''
        : true)
        ? false
        : true;
    setDisabled(value);

    if (relayTimeError !== '') {
      setDisabled(true);
    }
  }, [
    accessPointName,
    installationMethod.value,
    configuration.value,
    site.value,
    mode,
    relayTimeError,
  ]);

  const controlDisable = (val) => {
    setDisabled(val);
  };

  const setRelayValue = useCallback((value) => {
    if (value <= 240) {
      setRelayOntime(value);
    }
  }, []);

  const selectedSite = site?.value ? sites.find((siteValue) => siteValue.id === site?.value) : [];
  const currentNetworks = selectedSite?.networks?.length > 0 ? selectedSite.networks : [];

  return (
    <Modal showModal={showAccessPointPopup} onClose={popupToggle}>
      <form
        action=""
        onSubmit={(event) => {
          handleSubmit(event);
        }}
        className={styles['wrapper']}
      >
        <h1
          style={{
            color: '#353f94',
            fontFamily: 'UrbanistSemiBold',
            fontSize: '1.5rem',
          }}
        >
          {popupTitle} Access Point
        </h1>
        {mode === 'add' && (
          <div className={styles['page-flag-container']}>
            <div className={styles['page-flag']}>1</div>
            <div
              className={
                flag === false ? styles['page-flag-separator'] : styles['page-flag-separator-next']
              }
            ></div>
            <div className={flag === false ? styles['page-flag-next'] : styles['page-flag']}>2</div>
          </div>
        )}
        {!flag ? (
          <>
            <div>
              {mode === 'add' && (
                <div className={styles['form-name-container']}>
                  <div className={styles['circular-div']}>1</div>
                  <h2 className={styles['form-name']}>Basic Details</h2>
                </div>
              )}
              <div>
                <TextInput
                  name=""
                  placeholder="Access Point Name"
                  value={accessPointName}
                  onChange={(e) => {
                    setAccessPointName(e.target.value);
                  }}
                />
                {mode !== 'add' && (
                  <TextInput
                    name=""
                    placeholder="Network"
                    value={accessPoint.networkName}
                    disabled={true}
                  />
                )}

                {mode !== 'add' &&
                  configuration.value !== 6 &&
                  selectedConfiguration.value !== 5 &&
                  currentInstallationMethod.value === 1 && (
                    <div className={styles.relay_container}>
                      <div className={styles.header}>
                        <h1 className={styles.header__title}>
                          {selectedConfiguration.value === 6 ? 'Door Settings' : 'Relay Settings'}
                        </h1>
                        {selectedConfiguration.value !== 6 && (
                          <>
                            <OutlineButton
                              onClick={(e) => {
                                e.preventDefault();
                                setToggleRelayTime(!toggleRelaytime);
                              }}
                              type="outlined"
                              variant="outlined"
                              buttonSmaller
                              text="Hide Details"
                            />
                          </>
                        )}
                      </div>

                      {/* {toggleRelaytime && (lockingMech?.name !== '' || configuration.value === 6) && ( */}

                      {toggleRelaytime && (
                        <div className={styles.relay_container__body}>
                          <br />
                          <div className={styles.row}>
                            <div className={styles.column}>
                              <p>Relay on Time</p>
                            </div>
                            <div className={styles.column}>
                              <div className={styles.relay_input}>
                                <div className={styles.relay_input_container}>
                                  <TextInput
                                    name=""
                                    value={relayOnTime}
                                    type={'number'}
                                    min="0"
                                    max="240"
                                    onChange={(e) => {
                                      if (e.target.value <= 0) {
                                        setRelayTimeError('Relay time cannot be set to 0');
                                      } else {
                                        setRelayTimeError('');
                                      }

                                      setRelayValue(e.target.value);
                                    }}
                                  />
                                  <p className={styles.allowedText}>
                                    Allowed range is 1-240 seconds.
                                  </p>
                                </div>
                                <p>Seconds</p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                          {relayTimeError !== '' ? (
                            <Box width="80%">
                              <Typography style={{ color: 'red', textAlign: 'right' }}>
                                {relayTimeError}
                              </Typography>
                            </Box>
                          ) : (
                            <></>
                          )}
                          {selectedConfiguration.value !== 6 && (
                            <>
                              <div className={styles.row}>
                                <div className={styles.column}>
                                  <p>Invert relay logic</p>
                                </div>
                                <div className={styles.column}>
                                  <Switch
                                    checked={invertRelayLogic}
                                    onChange={() => setInvertRelayLogic(!invertRelayLogic)}
                                  />
                                </div>
                              </div>
                              <p className={styles.access_alert}>
                                Enabling this changes the logic of the relay and you will have to
                                wire fail-safe locks to NO and fail-secure to NC. This will unlock
                                the doors if the Spintly reader/controller fails.
                              </p>
                            </>
                          )}
                        </div>
                      )}

                      {selectedConfiguration.value === 7 || selectedConfiguration.value === 1 ? (
                        <>
                          <div className={styles.border} />
                          <div className={styles.row}>
                            <div className={styles.column}>
                              <p className={styles.enabledText}>Enable Attendance for device</p>
                            </div>
                            <div className={styles.column}>
                              <Switch
                                checked={enableAttendance}
                                onChange={() => setEnableAttendance(!enableAttendance)}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}
                    </div>
                  )}

                {mode === 'add' && (
                  <>
                    <div className={styles['dropdown-container']}>
                      <div className={styles.dropdown_column}>
                        <Dropdown
                          placeholder={'Installation Method'}
                          info={
                            <InfoIcon title="Select the type of installation. New Install is for fresh Spintly wireless ACS installation. Retrofit Type 1 is for Spintly mobile credentials over Weigand if the site had an old access control system." />
                          }
                          value={installationMethod?.name}
                          onChange={(data) => {
                            setInstallationMethod({ name: data.name, value: data.value });
                            setConfiguration({ name: '', value: '' });
                          }}
                          data={[
                            { name: 'New install', value: 1 },
                            { name: 'Retrofit Type 1', value: 2 },
                            { name: 'Retrofit Type 2', value: 3 },
                          ]}
                          disabled={mode !== 'add'}
                        />
                      </div>
                      <div style={{ display: 'flex', flex: 0.2 }} />
                      <div className={styles.dropdown_column}>
                        <Dropdown
                          placeholder="Configuration"
                          info={<InfoIcon title="Select configuration for the access point" />}
                          value={configuration?.name}
                          onChange={(data) => {
                            setConfiguration({
                              name: data.name,
                              value: data.value,
                              formOptions: {
                                entry: data.formOptions.entry,
                                exit: data.formOptions.exit,
                                control: data.formOptions.control,
                                lockingMechanism: data.formOptions.lockingMechanism,
                                relayTime: data.formOptions.relayTime,
                                attendanceAvailable: data.formOptions?.attendanceAvailable,
                              },
                              validSerials: {
                                entry: data.validSerials.entry,
                                exit: data.validSerials.exit,
                                control: data.validSerials.control,
                              },
                              lockingMech: data?.lockingMech,
                            });
                          }}
                          data={
                            installationMethod.value === 2 || installationMethod.value === 3
                              ? RETROFIT_TYPE1_CONFIGS
                              : NEW_INSTALL_CONFIGS
                          }
                          disabled={mode !== 'add'}
                        />
                      </div>
                    </div>

                    <div className={styles['dropdown-container']}>
                      <div className={styles.dropdown_column}>
                        <Dropdown
                          placeholder="Site"
                          info={<InfoIcon title="Select site for the access point" />}
                          value={site?.name}
                          onChange={(data) => {
                            setSite({ name: data.name, value: data.id });
                            setNetwork({ name: '', value: '' });
                          }}
                          data={sites}
                          disabled={mode !== 'add'}
                        />
                      </div>
                      <div style={{ display: 'flex', flex: 0.2 }} />
                      <div className={styles.dropdown_column}>
                        <Dropdown
                          placeholder="Network"
                          info={<InfoIcon title="Select network for the access point" />}
                          value={network.name}
                          disabled={site.value === '' || mode !== 'add'}
                          onChange={(data) => {
                            setNetwork({ ...data });
                          }}
                          data={currentNetworks}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={styles['button-container']}>
              <div className={styles['button_width']}>
                <Button text="Cancel" buttonType="disabled" onClick={popupToggle} />
              </div>

              {/* <div className={styles['button_width']}>
                <Button text="Next" type="submit" disabled={disabled} onClick={handleClick} />
              </div> */}
              <div className={styles['button_width']}>
                <Button
                  text={mode === 'add' ? 'Next' : 'Edit'}
                  type="submit"
                  disabled={disabled}
                  onClick={mode === 'add' ? handleClick : handleUpdateAccessPoint}
                />
              </div>
            </div>
          </>
        ) : (
          <div>
            <div className={styles['form-name-container']}>
              <div className={styles['circular-div']}>2</div>
              <h2 className={styles['form-name']}>Configuration</h2>
            </div>
            <AccessPointConfiguration
              accessPointName={accessPointName}
              installationMethod={installationMethod}
              site={site}
              network={network}
              configuration={configuration}
              lockingMechanisms={configuration?.lockingMech}
              controlDisable={controlDisable}
              validSerials={configuration?.validSerials}
              onClose={popupToggle}
              handleClick={handleClick}
              popupTitle={popupTitle}
              // mode={mode}
              // devicesInfo={devicesInfo}
              // selectedLockingMechanism={selectedLockingMechanism}
            />
          </div>
        )}
      </form>
    </Modal>
  );
};

export default AddOrEditAccessPointPopup1;
