// todo compare with original file

import React from 'react';
import styles from './OutlineButton-styles.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const types = ['primary', 'disabled', 'secondary', 'danger', 'outlined'];

const OutlineButton = ({
  type = 'primary',
  handleSubmit,
  text = '',
  disabled = false,
  shadow = true,
  buttonSmall,
  buttonSmaller,
  ...props
}) => {
  const disabledStyles = {
    backgroundColor: `#8c95aabe`,
    boxShadow: 'null',
  };

  return (
    <button
      style={disabled ? disabledStyles : {}}
      onClick={!disabled ? handleSubmit : null}
      className={classnames(
        buttonSmaller && styles['button-smaller'],
        buttonSmall && styles['button-small'],
        !buttonSmall && styles['button'],
        styles[`${type}`],
        shadow && styles[`shadow`],
      )}
      {...props}
    >
      {text}
    </button>
  );
};

OutlineButton.propTypes = {
  type: PropTypes.oneOf(types),
  handleSubmit: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  shadow: PropTypes.bool,
};

export default OutlineButton;
