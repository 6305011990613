import * as misc from '../actionCreators/miscellaneous';
import * as mesh from '../actionCreators/mesh';
import * as meshApis from '../../services/api/mesh';
import { extractErrorMessage } from '../helpers';

export const doGetMeshList = (partnerId, organisationId) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    const { data } = await meshApis.GetMeshList(partnerId, organisationId);
    dispatch(mesh.getMeshList(data.message.meshios));
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({
        isVisible: true,
        message: 'Mesh I/O List Successfully',
      }),
    );
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doAddMesh =
  (partnerId, organisationId, networkId, payload, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      await meshApis.AddMesh(partnerId, organisationId, networkId, payload);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Mesh I/O List Successfully',
        }),
      );
      callBack('success');
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };

export const doGetMeshPins =
  (partnerId, organisationId, serialNumber, callBack) => async (dispatch) => {
    try {
      const { data } = await meshApis.GetMeshIOPins(partnerId, organisationId, serialNumber);
      callBack('success', data.message);
    } catch (error) {
      // const message = extractErrorMessage(error);
      // callBack('error', '');
    }
  };

export const doGetMeshIoUnderSite =
  (partnerId, organisationId, siteId, callBack) => async (dispatch) => {
    try {
      const { data } = await meshApis.GetMeshIoUnderSite(partnerId, organisationId, siteId);
      callBack('success', data.message);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: message,
        }),
      );
      callBack('error', '');
    }
  };

export const doDeleteMesh =
  (partnerId, organisationId, serialNumber, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      const { data } = await meshApis.DeleteMesh(partnerId, organisationId, serialNumber);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      if (data.type === 'error') {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: data.message.errorMessage,
          }),
        );
        callBack('error');
      } else {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Mesh Deleted',
          }),
        );
        callBack('success');
      }
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };
