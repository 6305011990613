import { axiosInfraInstance } from '../api';

export const ReadOrganisationList = async (partnerId) => {
  const url = `/infrastructureManagement/v1/partners/${partnerId}/organisations`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const CreateOrganisation = async ({ partnerId, payload }) => {
  const url = `/infrastructureManagement/v1/organisations`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};

export const HandOverPartner = async ({ organisationId, payload }) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}/handover`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};
export const CreateNetwork = async ({ siteId, payload }) => {
  const url = `/infrastructureManagement/v1/sites/${siteId}/networks`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};
export const DeleteNetwork = async ({ networkId }) => {
  const url = `/infrastructureManagement/v1/networks/${networkId}`;
  return await axiosInfraInstance({ method: 'DELETE', url: url });
};

export const UpdateOrganisation = async ({ partnerId, organisationId, payload }) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}`;
  return await axiosInfraInstance({ method: 'PATCH', url: url, data: payload });
};

export const DeleteOrganisation = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}`;
  return await axiosInfraInstance({ method: 'DELETE', url: url });
};

export const ReadOrganisation = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const ReadOrganisationSites = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}/sites`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const ReadOrganisationGateways = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}/gateways`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const ReadOrganisationAccessPoints = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}/accessPoints`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const ReadOrganisationAccessPointsV4 = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V5}/partners/${partnerId}/organisations/${organisationId}/accessPoints`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};
export const ReadOrganisationAccessPointsV5 = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}/accessPoints`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const ReadOrganisationAdmins = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/admins`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const ReadOrganisationACUS = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/acus`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const ReadOrganisationRepeaters = async ({ partnerId, organisationId }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/repeaters`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const ReadOrganisationGatewayLog = async ({ partnerId, organisationId, serialNumber }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/gatewayLog/${serialNumber}`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const ReadOrganisationACULog = async ({ partnerId, organisationId, serialNumber }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/acuLog/${serialNumber}`;
  return await axiosInfraInstance({ method: 'GET', url: url });
};

export const CreateOrganisationSite = async ({ partnerId, organisationId, payload }) => {
  const url = `infrastructureManagement/v1/organisations/${organisationId}/sites`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};

export const UpdateOrganisationSite = async ({ partnerId, organisationId, siteId, payload }) => {
  const url = `/infrastructureManagement/v1/sites/${siteId}`;
  return await axiosInfraInstance({ method: 'PATCH', url: url, data: payload });
};

export const DeleteOrganisationSite = async ({ partnerId, organisationId, siteId }) => {
  const url = `/infrastructureManagement/v1/sites/${siteId}`;
  return await axiosInfraInstance({ method: 'DELETE', url: url });
};

export const CreateOrganisationAccessPoint = async ({ partnerId, organisationId, payload }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V4}/partners/${partnerId}/organisations/${organisationId}/accessPoints`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};

export const CreateOrganisationAccessPointV4 = async ({ partnerId, organisationId, payload }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V5}/partners/${partnerId}/organisations/${organisationId}/accessPoints`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};
export const CreateOrganisationAccessPointV5 = async ({
  partnerId,
  networkId,
  organisationId,
  payload,
}) => {
  const url = `/infrastructureManagement/v1/networks/${networkId}/accessPoints`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};

export const UpdateOrganisationAccessPoint = async ({
  partnerId,
  organisationId,
  accessPointId,
  payload,
}) => {
  const url = `/infrastructureManagement/v1/accessPoints/${accessPointId}`;
  return await axiosInfraInstance({ method: 'PATCH', url: url, data: payload });
};

export const UpdateOrganisationAccessPointV4 = async ({
  partnerId,
  organisationId,
  accessPointId,
  payload,
}) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V5}/partners/${partnerId}/organisations/${organisationId}/accessPoints/${accessPointId}`;
  return await axiosInfraInstance({ method: 'PATCH', url: url, data: payload });
};

export const DeleteOrganisationAccessPoint = async ({
  partnerId,
  organisationId,
  accessPointId,
}) => {
  const url = `/infrastructureManagement/v1/accessPoints/${accessPointId}`;
  return await axiosInfraInstance({ method: 'DELETE', url: url });
};

export const CreateOrganisationGateway = async ({
  partnerId,
  organisationId,
  networkId,
  payload,
}) => {
  const url = `/infrastructureManagement/v1/networks/${networkId}/gateways`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};

export const UpdateOrganisationGateway = async ({
  partnerId,
  organisationId,
  gatewayId,
  payload,
}) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/gateways/${gatewayId}`;
  return await axiosInfraInstance({ method: 'PATCH', url: url, data: payload });
};

export const DeleteOrganisationGateway = async ({ partnerId, organisationId, serialNumber }) => {
  const url = `/infrastructureManagement/v1/gateways/${serialNumber}`;
  return await axiosInfraInstance({ method: 'DELETE', url: url });
};

export const CreateOrganisationRepeater = async ({ partnerId, organisationId, payload }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/repeaters`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};

export const UpdateOrganisationRepeater = async ({
  partnerId,
  organisationId,
  repeaterId,
  payload,
}) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/repeaters/${repeaterId}`;
  return await axiosInfraInstance({ method: 'PATCH', url: url, data: payload });
};

export const DeleteOrganisationRepeater = async ({ partnerId, organisationId, serialNumber }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/repeaters/${serialNumber}`;
  return await axiosInfraInstance({ method: 'DELETE', url: url });
};

export const CreateOrganisationAdmin = async ({ partnerId, organisationId, payload }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/admins`;
  return await axiosInfraInstance({ method: 'POST', url: url, data: payload });
};

export const UpdateOrganisationAdmin = async ({ partnerId, organisationId, adminId, payload }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/admins/${adminId}`;
  return await axiosInfraInstance({ method: 'PATCH', url: url, data: payload });
};

export const DeleteOrganisationAdmin = async ({ partnerId, organisationId, adminId }) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/Admins/${adminId}`;
  return await axiosInfraInstance({ method: 'DELETE', url: url });
};
