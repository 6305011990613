import React from 'react';
import classnames from 'classnames';
import styles from './avatar_styles.module.scss';

const Avatar = ({ name, img, size = 'medium' }) => {
  return (
    <div className={classnames(styles['avatar'], styles[`${size}`])}>
      {img ? <img src={img} alt="name" /> : <>{name && <p>{name.charAt(0)}</p>}</>}
    </div>
  );
};

export default Avatar;
