import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
// import Select from '@material-ui/core/Select';
import style from './Table.module.scss';

function calButtons({ current_page, per_page, totalPage }) {
  const bCount = 7;

  const bLength = totalPage < bCount ? totalPage : bCount;
  let bIndex = 1;

  if (current_page > Math.ceil(bCount / 2) && totalPage > bCount) {
    if (current_page + Math.floor(bCount / 2) <= totalPage) {
      bIndex = current_page - Math.floor(bCount / 2);
    } else {
      bIndex = 1 + totalPage - bCount;
    }
  }

  return { bIndex, bLength };
}

const TableFooter = ({ pagination, changePage, dataLength }) => {
  const current_page = pagination.current_page || pagination.currentPage;
  const per_page = pagination.per_page || pagination.perPage;
  const total = pagination.total;

  const hasMore = per_page < total;

  const totalPage = Math.ceil(+total / per_page);

  const { bIndex, bLength } = hasMore && calButtons({ current_page, per_page, totalPage });

  const hChange = (changes) => {
    const reset = {
      page: 1,
      current_page: 1,
      per_page,
    };
    changePage({ ...reset, ...changes });
  };

  // const start = current_page * per_page + 1 - per_page;
  // const end =
  //   current_page === 1 ? current_page * dataLength : (current_page - 1) * per_page + dataLength;

  return (
    <div className={style['TableFooter']}>
      <div className={style['buttons']}>
        {hasMore ? (
          <>
            <IconButton
              size="small"
              disabled={current_page === 1}
              onClick={() => hChange({ page: 1 })}
            >
              <FirstPageIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={current_page === 1}
              onClick={() => hChange({ page: current_page - 1 })}
            >
              <ArrowLeftIcon />
            </IconButton>
            &nbsp;
            {[...Array(bLength)].map((d, i) => (
              <IconButton
                className={i + bIndex === current_page ? 'active' : ''}
                key={i}
                onClick={() => hChange({ page: i + bIndex })}
                disabled={i + bIndex === current_page}
              >
                {i + bIndex}
              </IconButton>
            ))}
            &nbsp;
            <IconButton
              size="small"
              disabled={current_page === totalPage}
              onClick={() => changePage({ page: current_page + 1, per_page })}
            >
              <ArrowRightIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={current_page === totalPage}
              onClick={() => changePage({ page: totalPage, per_page })}
            >
              <LastPageIcon />
            </IconButton>
          </>
        ) : null}
      </div>

      {/* <div className={style['dropdown']}>
        <span className={style['info']}>
          {dataLength && `Items ${start || 1}-${end || dataLength} of ${total || dataLength}`}
        </span>
        <Select
          value={pagination.per_page || pagination.perPage}
          options={[7, 25, 50, 100]}
          onChange={(e) => hChange({ per_page: e.target.value })}
        />
      </div> */}
    </div>
  );
};

export default TableFooter;
