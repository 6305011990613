import * as actionCreators from '../actionCreators/authentication';
import { AUTH_LOGIN_REQUESTED } from '../actionTypes/authentication';
import {
  selectIntegrationId,
  selectNewIntegrationId,
  selectPartner,
  selectNewPartner,
} from '../utils/authentication';

const defaultState = {
  currentUser: null,
  currentPartner: null,
  profiles: undefined,
  partners: undefined,
  integrationId: '',
  isLoggedIn: false,
};

const AuthenticationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUESTED:
      return { ...state, currentUser: null, isLoggedIn: false };
    case actionCreators.doLoginSuccess().type:
      return {
        ...state,
        isLoggedIn: true,
      };
    case actionCreators.doLoginFailure().type:
      return { ...state, currentUser: null, isLoggedIn: false };
    case actionCreators.doReadProfile().type:
      return { ...state, currentUser: null };
    case actionCreators.doReadProfileSuccess().type:
      const {
        payload: { message: profile },
      } = action;
      const { partners } = profile;
      const currentPartners = selectPartner(partners, state.currentPartner);

      return {
        ...state,
        currentUser: profile,
        currentPartner: currentPartners,
        partners,
      };
    case actionCreators.doReadProfileFailure().type:
      return { ...state };
    case actionCreators.doLogout().type:
      return { ...state, currentUser: null };
    case actionCreators.doLogoutSuccess().type:
      return {
        ...state,
        currentUser: null,
        currentPartner: null,
        profiles: undefined,
        integrationId: '',
        isLoggedIn: false,
      };
    case actionCreators.doLogoutFailure().type:
      return { ...state };
    case actionCreators.doReadPartners().type:
      return { ...state, partners: undefined };
    case actionCreators.doReadPartnersSuccess().type:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        partners,
        integrationId: selectIntegrationId(message?.partners, state.currentUser),
        currentPartner: selectPartner(message?.partners, state.currentUser),
      };
    case actionCreators.doReadPartnersFailure().type:
      return { ...state };
    case actionCreators.doSelectPartners().type:
      const {
        payload: { partnerId },
      } = action;
      return {
        ...state,
        integrationId: selectNewIntegrationId(state.partners, partnerId),
        currentPartner: selectNewPartner(state.partners, partnerId),
      };
    case actionCreators.doSendForgotPasswordOTP().type:
      return { ...state };
    case actionCreators.doSendForgotPasswordOTPSuccess().type:
      return {
        ...state,
      };
    case actionCreators.doSendForgotPasswordOTPFailure().type:
      return { ...state };
    case actionCreators.doResetPassword().type:
      return { ...state };
    case actionCreators.doResetPasswordSuccess().type:
      return {
        ...state,
      };
    case actionCreators.doResetPasswordFailure().type:
      return { ...state };
    default:
      return state;
  }
};

export default AuthenticationReducer;
