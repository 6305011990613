import { include } from 'named-urls';

const routes = {
  public: include('/', {
    login: 'login',
    forgotPassword: 'forgot-password',
    changePassword: 'change-password',
    resetPassword: include('reset-password', {
      phoneNumber: ':phoneNumber',
    }),
  }),
  dashboard: include('/dashboard', {
    home: 'home',
  }),
  customer: include('/customer', {
    list: 'list',
    customerId: include(':customerId/', {
      details: 'details',
    }),
    addNew: 'add-new',
  }),
  user: include('/user', {
    list: 'list',
  }),
  detached: include('/detached', {
    list: 'list',
  }),
};

export default routes;
