import React, { useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';

import inputStyles from './modal.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const types = ['default', 'danger'];

const Modal = ({ showModal, closeModal, children, type = 'default', minWidth = 20, ...props }) => {
  const animatedStyles = useSpring({
    tranform: showModal ? 'translateY(0px)' : 'translateY(-40px)',
    opacity: showModal ? 1 : 0,
  });

  const closeOnEscape = useCallback(
    (e) => {
      if ((e.charCode || e.keyCode) === 27 || e.code === 'Escape') {
        closeModal();
      }
    },
    [closeModal],
  );

  useEffect(() => {
    if (showModal) {
      document.body.addEventListener('keydown', closeOnEscape);
    }
    return () => {
      if (showModal) {
        document.body.removeEventListener('keydown', closeOnEscape);
      }
    };
  }, [closeOnEscape, showModal]);

  if (!showModal) return null;

  return (
    <animated.div className={classnames(inputStyles['modal_container'])} style={animatedStyles}>
      <div
        style={({ minWidth: `${minWidth}rem` }, props.style)}
        className={classnames(inputStyles['modal_body'], inputStyles[`${type}`])}
      >
        {children}
      </div>
    </animated.div>
  );
};

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  type: PropTypes.oneOf(types),
};

export default Modal;
