const Door = () => {
  return (
    <svg
      id="Group_8166"
      data-name="Group 8166"
      xmlns="http://www.w3.org/2000/svg"
      width="37.27"
      height="39.932"
      viewBox="0 0 37.27 39.932"
    >
      <g id="Group_132" data-name="Group 132" transform="translate(0)">
        <path
          id="Path_1971"
          data-name="Path 1971"
          d="M53.672,38.6H49.678V.666A.665.665,0,0,0,49.013,0H22.391a.665.665,0,0,0-.666.666V38.6H17.733a.666.666,0,0,0,0,1.331H53.672a.666.666,0,0,0,0-1.331ZM44.354,22.628a.665.665,0,0,1-.666.666H41.026a.665.665,0,0,1-.666-.666v-.666H39.03a.666.666,0,0,1,0-1.331h1.331v-.666a.665.665,0,0,1,.666-.666h2.662a.665.665,0,0,1,.666.666ZM47.016,38.6H45.685V3.993H25.719V38.6H24.388V3.328a.665.665,0,0,1,.666-.666h21.3a.665.665,0,0,1,.666.666V38.6Z"
          transform="translate(-17.067 0)"
          fill="#353f94"
        />
        <rect
          id="Rectangle_269"
          data-name="Rectangle 269"
          width="1.751"
          height="1.751"
          transform="translate(24.337 20.407)"
          fill="#353f94"
        />
      </g>
    </svg>
  );
};

export default Door;
