import React from 'react';

const OpenBox = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.335"
      height="27.251"
      viewBox="0 0 36.335 27.251"
    >
      <path
        fill="#8b94ac"
        d="M0 9v26.843a.407.407 0 00.409.409h35.472a.407.407 0 00.409-.409V9H22.8v4.678h-9.22V9H-.045z"
        data-name="Icon open-box"
        transform="translate(.045 -9)"
      ></path>
    </svg>
  );
};

export default OpenBox;
