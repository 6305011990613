import React, { useEffect, useState, useMemo, useCallback } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { selectRoleList } from '../../../../../redux/selectors/role';
import { selectCurrentPartner } from '../../../../../redux/selectors/authentication';
import { doCreateUser, doUpdateUser } from '../../../../../redux/thunks/user';

// packages
import { findPhoneNumbersInText } from 'libphonenumber-js';

// Components
import Modal from '../../../../../components/Modals/Modal';

// styles
import styles from '../../../../../styles/modals.module.scss';

// helpers
import {
  emailVerification,
  phonenumberVerification,
  textVerification,
} from '../../../../../utils/formValdations';
import TextInput from '../../../../../components/Inputs/TextInput';
import PhoneInput from '../../../../../components/Inputs/PhoneInput';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Button from '../../../../../components/Buttons/Button';
import { getCountryCode } from '../../../../../utils/countryCode';

const UserFormModal = ({ popupTitle = 'Add', showUserModal, togglePopup, user, setUser }) => {
  const dispatch = useDispatch();
  const { currentPartner, roles } = useSelector((state) => ({
    currentPartner: selectCurrentPartner(state),
    roles: selectRoleList(state),
  }));

  // states
  const [userName, setUserName] = useState({ value: user?.name || '', error: '' });
  const [email, setEmail] = useState({ value: user?.email || '', error: '' });
  const [roleId, setRoleId] = useState(user?.role?.id || '');
  const [phoneNumber, setPhoneNumber] = useState({ value: '', error: '' });
  const [countryCode, setCountryCode] = useState('91');

  useEffect(() => {
    if (user?.phoneNumber) {
      try {
        const mobileNumber = findPhoneNumbersInText(user?.phoneNumber)[0];
        const { number } = mobileNumber;
        setCountryCode(number?.countryCallingCode || '91');
        setPhoneNumber({ ...phoneNumber, value: number?.nationalNumber || '' });
      } catch (error) {
        setPhoneNumber({ ...phoneNumber, value: user?.phoneNumber || '' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.phoneNumber]);

  useEffect(() => {
    try {
      const code = getCountryCode();
      if (code) {
        setCountryCode(code?.countryCode);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const popupToggle = useCallback(() => {
    togglePopup('AddOrEditUser');
  }, [togglePopup]);

  const resetAndClose = useCallback(() => {
    setUser(null);
    popupToggle();
  }, [popupToggle, setUser]);

  const handleCreateUser = useCallback(
    (payload) => {
      dispatch(
        doCreateUser({ partnerId: currentPartner.partnerId, payload, callback: resetAndClose }),
      );
    },
    [currentPartner.partnerId, dispatch, resetAndClose],
  );

  const handleUpdateUser = useCallback(
    (payload) => {
      dispatch(
        doUpdateUser({
          partnerId: currentPartner.partnerId,
          userId: user?.id,
          payload,
          callback: resetAndClose,
        }),
      ).then(() => {
        togglePopup('AddOrEditUser');
      });
    },
    [currentPartner.partnerId, dispatch, resetAndClose, togglePopup, user?.id],
  );

  const handlesubmit = useCallback(() => {
    const payload = {
      name: userName.value,
      roleId,
      phoneNumber: `+${countryCode}${phoneNumber.value}`,
      email: email.value,
    };
    popupTitle === 'Add' && handleCreateUser(payload);
    popupTitle === 'Edit' && handleUpdateUser(payload);
  }, [
    countryCode,
    email.value,
    handleCreateUser,
    handleUpdateUser,
    phoneNumber.value,
    popupTitle,
    roleId,
    userName.value,
  ]);

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;
    const errorText = 'Please enter a valid';
    let error = '';
    switch (name) {
      case 'userName':
        error = textVerification(value, 2) ? `${errorText} User name` : '';
        setUserName({ value, error });
        break;
      case 'email':
        error = !emailVerification(value) ? `${errorText} Email` : '';
        setEmail({ value, error });
        break;
      case 'phoneNumber':
        error = !phonenumberVerification(`+${countryCode}${value}`)
          ? `Please enter a valid Phone Number`
          : '';
        setPhoneNumber({ value, error });
        break;
      default:
        break;
    }
  };

  const disabled = useMemo(() => {
    if (
      userName.value === '' ||
      userName.error !== '' ||
      phoneNumber.value === '' ||
      phoneNumber.error !== '' ||
      email.value === '' ||
      email.error !== '' ||
      roleId === ''
    ) {
      return true;
    }
    return false;
  }, [
    email.error,
    email.value,
    phoneNumber.error,
    phoneNumber.value,
    roleId,
    userName.error,
    userName.value,
  ]);

  const selectedUser = useMemo(() => roles?.find((role) => role.id === roleId), [roleId, roles]);

  return (
    <Modal minWidth={30} showModal={showUserModal} closeModal={() => togglePopup('AddOrEditUser')}>
      <div className={styles['wrapper']}>
        <h1>{popupTitle} User /s</h1>
        <TextInput
          name="userName"
          placeholder="User name"
          value={userName.value}
          onChange={handleChange}
          error={userName.error}
        />
        <Dropdown
          placeholder="User Roles"
          value={selectedUser?.name}
          data={roles}
          name="roleId"
          onChange={(value) => setRoleId(value?.id)}
        />
        <PhoneInput
          placeholder="User Phone Number"
          value={phoneNumber.value}
          type="number"
          name="phoneNumber"
          onChange={handleChange}
          error={phoneNumber.error}
          defaultCountryCode={countryCode}
          handleCountryCode={(code) => setCountryCode(code)}
        />
        <TextInput
          placeholder="Email"
          value={email.value}
          type="email"
          name="email"
          onChange={handleChange}
          error={email.error}
        />

        <div className={styles['button-container']}>
          <Button
            onClick={() => togglePopup('AddOrEditUser')}
            text="Cancel"
            buttonSmall={true}
            buttonType="disabled"
          />
          <Button disabled={disabled} onClick={handlesubmit} text={`${popupTitle} User`} />
        </div>
      </div>
    </Modal>
  );
};

export default UserFormModal;
