import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ORG_TYPES from '../../../../../../constants/types/OrganisationTypes';

import { selectOrganisation } from '../../../../../../redux/selectors/organisation';

import styles from './overview.module.scss';
import Avatar from '../../../../../../components/Avatar/Avatar';
import UIButton from '../../../../../../components/UIButton';

const OverviewCard = ({
  displayDeleteModal,
  displayEditCustomerModal,
  displayPartnerHandoverModal,
}) => {
  const { organisation } = useSelector((state) => ({
    organisation: selectOrganisation(state),
  }));

  const [admin, setAdmin] = useState({});
  const [org, setOrg] = useState({});

  useEffect(() => {
    if (organisation) {
      const { generalSetting } = organisation;
      const { admins: adm } = generalSetting;
      const type = organisation?.handoverStatus === 1 ? 2 : 1;
      const index = adm?.findIndex((admins) => admins?.adminType === type);

      if (index !== -1 && adm && adm[index]) {
        setAdmin(adm[index]);
      } else {
        const indexi = adm?.findIndex((admins) => admins?.adminType === 1);
        if (indexi !== -1 && adm && adm[indexi]) {
          setAdmin(adm[indexi]);
        } else {
          setAdmin({});
        }
      }

      setOrg(generalSetting);
    }
  }, [organisation]);

  const topSection = () => {
    return (
      <div className={styles['top_section']}>
        <Avatar name={org?.name} img="" size="small" />
        <div className={styles['text_container']}>
          <h2>{org?.name || ''}</h2>
          <p>{ORG_TYPES[org?.type] || ''}</p>
          <span>{org.country || ''}</span>
        </div>
      </div>
    );
  };

  const handOverStatusRender = (value) => {
    let status;
    switch (value) {
      case 1:
        status = <p style={{ color: 'green' }}>Complete</p>;
        break;
      case 0:
        status = <p style={{ color: 'red' }}>Pending</p>;
        break;
      default:
        status = '';
        break;
    }

    return status;
  };

  const bottomSection = () => {
    return (
      <div className={styles['bottom_section']}>
        <div className={styles['column']}>
          <p className={styles['label']}>Name</p>
          <p className={styles['text']}>{admin && admin.name ? admin.name : ''}</p>
        </div>
        <div className={styles['column']}>
          <p className={styles['label']}>Email</p>
          <p className={styles['text']}>{admin && admin.email ? admin.email : ''}</p>
        </div>
        <div className={styles['column']}>
          <p className={styles['label']}>Phone</p>
          <p className={styles['text']}>{admin && admin.phone ? admin.phone : ''}</p>
        </div>
        {organisation?.saamsIntegration && (
          <div className={styles['column']}>
            <p className={styles['label']}>Handover Status</p>
            <p className={styles['text']}>{handOverStatusRender(organisation?.handoverStatus)}</p>
          </div>
        )}
        <div className={styles['button_container']}>
          <UIButton type="secondary" text="Delete Customer" handleSubmit={displayDeleteModal} />
          <UIButton type="primary" text="Edit Customer" handleSubmit={displayEditCustomerModal} />
        </div>
        {organisation?.handoverStatus === 0 && organisation?.saamsIntegration ? (
          <div className={styles['button_container']}>
            <UIButton
              type="dark"
              text="Partner Handover"
              handleSubmit={displayPartnerHandoverModal}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div className={styles['container']}>
      {topSection()}
      <div className={styles['border']}></div>
      {bottomSection()}
    </div>
  );
};

export default OverviewCard;
