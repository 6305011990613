import React from 'react';
import PropTypes from 'prop-types';
import './Switch.scss';

const Switch = ({ checked = false, onChange, disabled = false, ...otherProps }) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...otherProps}
      />
      <span className="slider round"></span>
    </label>
  );
};

Switch.protoTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Switch;
