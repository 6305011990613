import React from 'react';
import TableHead from './TableHead';
import TableData from './TableData';
import TableFooter from './TableFooter';
import TableSearch from './TableSearch';
import style from './Table.module.scss';

const Table = ({
  columns,
  data,
  options,
  emptyContent = '-',
  pagination,
  changePage,
  defaultMessage = '',
}) => {
  return (
    <>
      <div className={style['wrapper']}>
        <div className={style['table-container']}>
          <table className={style['t_table']}>
            <TableHead columns={columns} options={options} />

            <tbody className={style['t_body']}>
              {options?.search && <TableSearch columns={columns} options={options} />}
              <TableData columns={columns} data={data} emptyContent={emptyContent} />
            </tbody>
          </table>
          {!data?.length ? (
            <div className={style['emptyContent']}>{defaultMessage || 'No records to display'}</div>
          ) : null}
        </div>
      </div>
      {pagination && data.length > 0 && (
        <TableFooter pagination={pagination} changePage={changePage} dataLength={data?.length} />
      )}
    </>
  );
};

export default Table;
