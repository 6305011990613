import React from 'react';
import styles from './Mesh.module.scss';
import classnames from 'classnames';
import Checkbox from '../../../../../../components/Inputs/Checkbox/Checkbox';
import Wifi from '../../../../../../assets/svgComponent/Wifi';
import OpenBox from '../../../../../../assets/svgComponent/OpenBox';

const Mesh = ({
  pinData,
  isEditable = false,
  handleChange,
  title = '',
  isTextChangeable = false,
  onTextChange,
}) => {
  return (
    <div className={styles.container}>
      {pinData.length > 0 && (
        <>
          <div className={styles.left_container}>
            <div className={styles.right_align}>
              {pinData[0] && (
                <div className={styles.border_box}>
                  <>
                    <div>
                      <Checkbox
                        disabled={pinData[0].type === 'DO'}
                        checked={pinData[0].isEnabled}
                        onChange={() => {
                          if (isEditable) handleChange(pinData[0].id, !pinData[0].isEnabled);
                        }}
                      />
                    </div>
                    {isTextChangeable && pinData[0].type !== 'DO' ? (
                      <input
                        disabled={pinData[0].type === 'DO'}
                        name="name"
                        type="text"
                        onChange={(e) => {
                          onTextChange && onTextChange(pinData[0].id, e.target.value);
                        }}
                        value={pinData[0].name}
                        className={classnames(styles['mrgt-02'], styles['mesh_input'])}
                      />
                    ) : (
                      <p className={styles['mrgt-02']}>{pinData[0].name}</p>
                    )}
                  </>
                </div>
              )}
              {pinData[2] && (
                <div className={styles.border_box}>
                  <>
                    <div>
                      <Checkbox
                        disabled={pinData[2].type === 'DO'}
                        checked={pinData[2].isEnabled}
                        onChange={() => {
                          if (isEditable) handleChange(pinData[2].id, !pinData[2].isEnabled);
                        }}
                      />
                    </div>

                    {isTextChangeable && pinData[2].type !== 'DO' ? (
                      <input
                        disabled={pinData[2].type === 'DO'}
                        name="name"
                        type="text"
                        onChange={(e) => {
                          onTextChange && onTextChange(pinData[2].id, e.target.value);
                        }}
                        value={pinData[2].name}
                        className={classnames(styles['mrgt-02'], styles['mesh_input'])}
                      />
                    ) : (
                      <p className={styles['mrgt-02']}>{pinData[2].name}</p>
                    )}
                  </>
                </div>
              )}
            </div>
            <div className={styles.right_align}>
              {pinData[4] && (
                <div className={styles.border_box}>
                  <>
                    <div>
                      <Checkbox
                        disabled={pinData[4].type === 'DO'}
                        checked={pinData[4].isEnabled}
                        onChange={() => {
                          if (isEditable) handleChange(pinData[4].id, !pinData[4].isEnabled);
                        }}
                      />
                    </div>

                    {isTextChangeable && pinData[4].type !== 'DO' ? (
                      <input
                        disabled={pinData[4].type === 'DO'}
                        name="name"
                        type="text"
                        onChange={(e) => {
                          onTextChange && onTextChange(pinData[4].id, e.target.value);
                        }}
                        value={pinData[4].name}
                        className={classnames(styles['mrgt-02'], styles['mesh_input'])}
                      />
                    ) : (
                      <p className={styles['mrgt-02']}>{pinData[4].name}</p>
                    )}
                  </>
                </div>
              )}
              {pinData[5] && (
                <div className={styles.border_box}>
                  <>
                    <div>
                      <Checkbox
                        disabled={pinData[5].type === 'DO'}
                        checked={pinData[5].isEnabled}
                        onChange={() => {
                          if (isEditable) handleChange(pinData[5].id, !pinData[5].isEnabled);
                        }}
                      />
                    </div>

                    {isTextChangeable && pinData[5].type !== 'DO' ? (
                      <input
                        disabled={pinData[5].type === 'DO'}
                        name="name"
                        type="text"
                        onChange={(e) => {
                          onTextChange && onTextChange(pinData[5].id, e.target.value);
                        }}
                        value={pinData[5].name}
                        className={classnames(styles['mrgt-02'], styles['mesh_input'])}
                      />
                    ) : (
                      <p className={styles['mrgt-02']}>{pinData[5].name}</p>
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
          <div className={styles.center_box}>
            <div className={classnames(styles['mrgb-05'])}>
              <Wifi />
            </div>
            <div className={classnames(styles['mrgb-1'])}>
              <OpenBox />
            </div>

            <p className={styles.mesh_name}>{title}</p>
          </div>
          <div className={styles.right_container}>
            <div className={styles.width_100}>
              {pinData[1] && (
                <div className={styles.border_box}>
                  <>
                    <div>
                      <Checkbox
                        disabled={pinData[1].type === 'DO'}
                        checked={pinData[1].isEnabled}
                        onChange={() => {
                          if (isEditable) handleChange(pinData[1].id, !pinData[1].isEnabled);
                        }}
                      />
                    </div>

                    {isTextChangeable && pinData[1].type !== 'DO' ? (
                      <input
                        disabled={pinData[1].type === 'DO'}
                        name="name"
                        type="text"
                        onChange={(e) => {
                          onTextChange && onTextChange(pinData[1].id, e.target.value);
                        }}
                        value={pinData[1].name}
                        className={classnames(styles['mrgt-02'], styles['mesh_input'])}
                      />
                    ) : (
                      <p className={styles['mrgt-02']}>{pinData[1].name}</p>
                    )}
                  </>
                </div>
              )}

              {pinData[3] && (
                <div className={styles.border_box}>
                  <>
                    <div>
                      <Checkbox
                        disabled={pinData[3].type === 'DO'}
                        checked={pinData[3].isEnabled}
                        onChange={() => {
                          if (isEditable) handleChange(pinData[3].id, !pinData[3].isEnabled);
                        }}
                      />
                    </div>

                    {isTextChangeable && pinData[3].type !== 'DO' ? (
                      <input
                        disabled={pinData[3].type === 'DO'}
                        name="name"
                        type="text"
                        onChange={(e) => {
                          onTextChange && onTextChange(pinData[3].id, e.target.value);
                        }}
                        value={pinData[3].name}
                        className={classnames(styles['mrgt-02'], styles['mesh_input'])}
                      />
                    ) : (
                      <p className={styles['mrgt-02']}>{pinData[3].name}</p>
                    )}
                  </>
                </div>
              )}
            </div>
            <div className={styles.width_100}>
              {pinData[6] && (
                <div className={styles.border_box}>
                  <>
                    <div>
                      <Checkbox
                        disabled={pinData[6].type === 'DO'}
                        checked={pinData[6].isEnabled}
                        onChange={() => {
                          if (isEditable) handleChange(pinData[6].id, !pinData[6].isEnabled);
                        }}
                      />
                    </div>

                    {isTextChangeable && pinData[6].type !== 'DO' ? (
                      <input
                        disabled={pinData[6].type === 'DO'}
                        name="name"
                        type="text"
                        onChange={(e) => {
                          onTextChange && onTextChange(pinData[6].id, e.target.value);
                        }}
                        value={pinData[6].name}
                        className={classnames(styles['mrgt-02'], styles['mesh_input'])}
                      />
                    ) : (
                      <p className={styles['mrgt-02']}>{pinData[6].name}</p>
                    )}
                  </>
                </div>
              )}
              {pinData[7] && (
                <div className={styles.border_box}>
                  <>
                    <div>
                      <Checkbox
                        disabled={pinData[7].type === 'DO'}
                        checked={pinData[7].isEnabled}
                        onChange={() => {
                          if (isEditable) handleChange(pinData[7].id, !pinData[7].isEnabled);
                        }}
                      />
                    </div>

                    {isTextChangeable && pinData[7].type !== 'DO' ? (
                      <input
                        disabled={pinData[7].type === 'DO'}
                        name="name"
                        type="text"
                        onChange={(e) => {
                          onTextChange && onTextChange(pinData[7].id, e.target.value);
                        }}
                        value={pinData[7].name}
                        className={classnames(styles['mrgt-02'], styles['mesh_input'])}
                      />
                    ) : (
                      <p className={styles['mrgt-02']}>{pinData[7].name}</p>
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Mesh;
