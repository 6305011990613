import React from 'react';
import styles from './Table.module.scss';

const TableHead = ({ columns, options }) => {
  return (
    <thead className={styles['t_head']} {...options?.thead?.props}>
      <tr>
        {/* {options?.selection?.enable ? (
              <th className="selection">
  
                <Checkbox
                  size="small"
                  checked={selectedLen > 0 && selectedLen == data.length}
                  indeterminate={selectedLen > 0 && selectedLen !== data.length}
                  onChange={hSelectionAll}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </th>
            ) : null} */}

        {columns?.map((c, i) => (
          <th key={i} className={styles['t_h']} {...columns?.props?.head}>
            {c.title}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
