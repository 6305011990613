import React, { Fragment, useState, useEffect, useCallback } from 'react';

// components
import UserFormModal from './components/UserFormModal';
import ModalAlert from '../../../components/Modals/ModalAlert';
import Table from '../../../components/Table/Table';
import SquareDelete from '../../../components/SquareDelete/SquareDelete';
import SquareEdit from '../../../components/SquareEdit/SquareEdit';
import Button from '../../../components/Buttons/Button';
import { useImmer } from 'use-immer';
import { Search } from '../../../utils/Search';
// table data
import tableConfig from './tableConfig';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { selectUserList } from '../../../redux/selectors/user';
import { selectCurrentPartner } from '../../../redux/selectors/authentication';
import { doDeleteUser, doReadUserList } from '../../../redux/thunks/user';
import { doUserListReset } from '../../../redux/actionCreators/user';
import { doReadRoleListSuccess, doRoleListReset } from '../../../redux/actionCreators/role';
import { getAuthorizationToken } from '../../../utils/Auth';
import axios from 'axios';

const UserList = () => {
  const dispatch = useDispatch();
  const { currentPartner, userList } = useSelector((state) => ({
    currentPartner: selectCurrentPartner(state),
    userList: selectUserList(state),
  }));

  const token = getAuthorizationToken();

  const url = `${process.env.REACT_APP_BASE_API_URL}/partnerManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${currentPartner.partnerId}/roles`;
  // eslint-disable-next-line no-unused-vars
  const [popupTitle, setPopupTitle] = useState('Add');
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [user, setUser] = useState(null);

  const [filters, setFilters] = useImmer({ name: '', id: '', email: '', phoneNumber: '' });
  const [pagination, setPagination] = useImmer({ total: 0, currentPage: 1, perPage: 7 });

  useEffect(() => {
    setPagination((draft) => {
      draft.total = userList?.users.length ? userList?.users.length : 0;
    });
  }, [setPagination, userList?.users.length]);

  const handleDeleteUser = useCallback(() => {
    dispatch(doDeleteUser({ partnerId: currentPartner?.partnerId, userId: user?.id }));
    setUser(null);
    setShowDeleteUser(false);
  }, [currentPartner?.partnerId, dispatch, user?.id]);

  const actions_column = [
    {
      title: 'Action',
      render: (_, rowData, row) => {
        return (
          <div className="button-row">
            <SquareEdit
              color={'#ff535a'}
              onPress={() => {
                setPopupTitle('Edit');
                setUser(rowData);
                togglePopup('AddOrEditUser');
              }}
            />
            <SquareDelete
              color={'#ff535a'}
              onPress={() => {
                setPopupTitle('Delete');
                setUser(rowData);
                setShowDeleteUser(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const getRoles = async () => {
      try {
        const res = await axios.get(url, {
          headers: {
            Authorization: token,
          },
        });
        dispatch(doReadRoleListSuccess(res.data));
      } catch (err) {
        console.err(err);
      }
    };
    getRoles();
    return () => {
      dispatch(doRoleListReset());
    };
  }, [token, url, dispatch]);

  // setting up phoneNumber

  const togglePopup = (popupName) => {
    if (popupName === 'AddOrEditUser') {
      setShowUserModal(!showUserModal);
      if (showUserModal) {
        setUser(null);
      }
    }
    if (popupName === 'DeleteUser') {
      setShowDeleteUser(!showDeleteUser);
      if (showDeleteUser) {
        setUser(null);
      }
    }
  };

  useEffect(() => {
    if (currentPartner) dispatch(doReadUserList(currentPartner.partnerId));

    return () => {
      dispatch(doUserListReset());
    };
  }, [dispatch, currentPartner]);

  const filteredData = useCallback(() => {
    let data = userList?.users ? userList?.users : [];
    if (filters.id !== '') {
      data = Search(data, 'id', filters.id);
    }
    if (filters.name !== '') {
      data = Search(data, 'name', filters.name);
    }
    if (filters.phoneNumber !== '') {
      data = Search(data, 'phoneNumber', `+91${filters.phoneNumber}`);
    }
    if (filters.email !== '') {
      data = Search(data, 'email', filters.email);
    }

    data = data.slice(
      (pagination.currentPage - 1) * 7,
      pagination.currentPage * pagination.perPage,
    );

    return data;
  }, [
    filters.email,
    filters.id,
    filters.name,
    filters.phoneNumber,
    pagination.currentPage,
    pagination.perPage,
    userList?.users,
  ]);

  const hSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((draft) => {
      draft[name] = value;
    });
  };
  const search = {
    onChange: hSearchChange,
  };

  const changePage = (p) => {
    const { page, per_page } = p;

    setPagination((draft) => {
      draft.currentPage = page;
      draft.perPage = per_page;
    });
  };

  return (
    <Fragment>
      <div className="spacer__0-5" />
      <div>
        <div>
          {showUserModal && (
            <UserFormModal {...{ popupTitle, showUserModal, togglePopup, user, setUser }} />
          )}
          {showDeleteUser && (
            <ModalAlert
              showModal={showDeleteUser}
              closeModal={() => setShowDeleteUser(false)}
              handleSubmit={handleDeleteUser}
              title={`Delete User`}
              description={`Are you sure you want to delete ${
                user ? user.name : ''
              } ? This action is non
                reversible and will cause you to lose all data associated with this user/ s`}
              modalType={'danger'}
            />
          )}
          <div className="header-container">
            <p className="header">Users</p>
            <div className="button-cont">
              <Button
                text={'Add User'}
                onClick={() => {
                  setPopupTitle('Add');
                  togglePopup('AddOrEditUser');
                }}
              />
            </div>
          </div>

          <Table
            columns={[...tableConfig, ...actions_column]}
            // data={(!!userList && userList.users) || []}
            data={filteredData()}
            pagination={pagination}
            options={{ search }}
            emptyContent="No Records found"
            changePage={changePage}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default UserList;
