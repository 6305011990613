import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { doReadOrganisationAccessPoints } from '../../../../../redux/thunks/organisation';
import { selectOrganisationAccessPoints } from '../../../../../redux/selectors/organisation';
import { selectCurrentPartner } from '../../../../../redux/selectors/authentication';

import ACULogPopup from './ACULogPopup';
import useBreakpoint from '../../../../../hooks/useBreakpoint';

import Table from '../../../../../components/Table/Table';
import { deviceTypeRender, readerStatusRenderer } from '../../../../../helpers/renderers';
import { statusSearcher } from '../../../../../helpers/searchers';
import { Search } from '../../../../../utils/Search';

const ACUTab = () => {
  const dispatch = useDispatch();
  const [showACULogPopup, setShowACULogPopup] = useState(false);
  const [readers, setReaders] = useState([]);
  const { customerId } = useParams();
  const { accessPoints, currentUser } = useSelector((state) => ({
    accessPoints: selectOrganisationAccessPoints(state),
    currentUser: selectCurrentPartner(state),
  }));
  const [pagination, setPagination] = useImmer({ total: 0, currentPage: 1, perPage: 7 });
  const [filters, setFilters] = useImmer({
    serialNumber: '',
    accessPoint: '',
    fwVersion: '',
    macId: '',
    status: '',
    deviceType: '',
  });

  const convertToHex = (value) => {
    let hexString = value.toString(16);

    // Ensure the hexadecimal string has at least two characters
    if (hexString.length % 2 !== 0) {
      hexString = '0' + hexString;
    }

    // Split the hexadecimal string into groups of two characters
    const hexGroups = [];
    for (let i = 0; i < hexString.length; i += 2) {
      hexGroups.push(hexString.slice(i, i + 2));
    }

    // Join the groups with periods to get the "xx.xx.xx" format
    const formattedHex = hexGroups.join('.');

    return formattedHex;
  };

  const columns = [
    {
      title: 'Serial Number',
      field: 'serialNumber',
      name: 'serialNumber',
    },
    {
      title: 'Access Point',
      field: 'accessPoint',
      name: 'accessPoint',
    },
    {
      title: 'Device Type',
      field: 'deviceType',
      name: 'deviceType',
      // render: (rowData) =>
      //   voca.chain(rowData.deviceType).kebabCase().replaceAll('-', ' ').titleCase().value(),
      render: (columnProps, rowData) => {
        return deviceTypeRender(rowData.deviceType);
      },
    },
    {
      title: 'FW Version',
      field: 'fwVersion',
      name: 'fwVersion',
      search: false,
      render: (columnProps, rowData) => {
        return convertToHex(rowData.firmwareVersion);
      },
    },
    {
      title: 'Status',
      field: 'status',
      name: 'status',
      render: (_, column) => readerStatusRenderer(column.status, column?.statusColor),
      customFilterAndSearch: (term, row) => statusSearcher(term, row.status),
    },
  ];

  const togglePopup = (popupName) => {
    if (popupName === 'ACULog') {
      setShowACULogPopup(!showACULogPopup);
    }
  };

  useEffect(() => {
    const list = [];
    if (accessPoints?.length > 0) {
      accessPoints?.map((currentAccessPoint) => {
        if (currentAccessPoint?.devices) {
          currentAccessPoint.devices.map((device) => {
            const reader = {
              serialNumber: device?.serialNumber || '',
              accessPoint: currentAccessPoint?.name || '',
              deviceType: device?.type || '',
              macId: device?.macId || '',
              firmwareVersion: device?.firmwareVersion || '',
              status: device?.connectivityStatus,
              statusColor: device?.resourceState || '',
            };
            list.push(reader);
            return null;
          });
        }

        return null;
      });
    }

    setReaders(list);
    setPagination((draft) => {
      draft.total = list?.length ? list?.length : 0;
    });
  }, [accessPoints, setPagination]);

  const point = useBreakpoint();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (point === 'sm' || point === 'xs' || point === 'md')
      if (currentUser)
        dispatch(
          doReadOrganisationAccessPoints({
            partnerId: currentUser.partnerId,
            organisationId: customerId,
            displayNotification: false,
          }),
        );
  }, [dispatch, point, currentUser, customerId]);

  const scrapeData = readers?.filter((item) => {
    const configuration = item.deviceType;
    return configuration !== 3 && configuration !== 4;
  });

  const filteredData = useCallback(() => {
    let data = scrapeData ? scrapeData : [];
    if (filters.serialNumber !== '') {
      data = Search(data, 'serialNumber', filters.serialNumber);
    }
    if (filters.accessPoint !== '') {
      data = Search(data, 'accessPoint', filters.accessPoint);
    }

    if (filters.fwVersion !== '') {
      data = Search(data, 'fwVersion', filters.fwVersion);
    }
    if (filters.macId !== '') {
      data = Search(data, 'macId', filters.macId);
    }

    if (filters.status !== '') {
      data = Search(data, 'status', filters.status);
    }

    if (filters.deviceType !== '') {
      data = Search(data, 'deviceType', filters.deviceType);
    }

    data = data.slice(
      (pagination.currentPage - 1) * 7,
      pagination.currentPage * pagination.perPage,
    );
    return data;
  }, [
    filters.accessPoint,
    filters.deviceType,
    filters.fwVersion,
    filters.macId,
    filters.serialNumber,
    filters.status,
    pagination.currentPage,
    pagination.perPage,
    scrapeData,
  ]);

  const hSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((draft) => {
      draft[name] = value;
    });
  };

  const search = {
    onChange: hSearchChange,
  };

  const changePage = (p) => {
    const { page, per_page } = p;

    setPagination((draft) => {
      draft.currentPage = page;
      draft.perPage = per_page;
    });
  };

  return (
    <Fragment>
      <div className="spacer__1" />
      <div>
        {showACULogPopup && (
          <ACULogPopup
            {...{
              showACULogPopup,
              togglePopup,
            }}
          />
        )}
        <div className="header-container">
          <p className="header">Readers</p>
        </div>
        <div item xs={12} sm={12} md={12} lg={12}>
          <Table
            columns={columns}
            data={filteredData() || []}
            defaultMessage={'No Readers Found'}
            pagination={pagination}
            options={{ search }}
            changePage={changePage}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ACUTab;
