import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import BackButton from '../../../components/BackButton';
import OverviewTab from './components/OverviewTab';
import SiteTab from './components/SiteTab';
import AccessPointTab from './components/AccessPointTab';
import GatewayTab from './components/GatewayTab';
import HardwareIntegration from './components/HardwareIntegration';
import MeshIo from './components/MeshIo';
import Controllers from './components/Controllers';
// import AdminTab from './components/AdminTab';
import ACUTab from './components/ACUTab';
// import RepeaterTab from './components/RepeaterTab';

import routes from '../../../constants/routes';

import { selectCurrentPartner } from '../../../redux/selectors/authentication';
import { doReadOrganisationChaining } from '../../../redux/thunks/organisation';
import { selectOrganisation } from '../../../redux/selectors/organisation';
import { Breadcrumbs, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    backgroundColor: '#FFFF',
    border: 'solid 0.5px #232e86',
    borderRadius: '0.4rem',
    overflow: 'hidden',
    padding: '0',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const CustomTab = withStyles({
  root: {
    backgroundColor: '#FFFF',
    color: '#232e86',
  },
  selected: {
    backgroundColor: '#232e86',
    borderRadius: '0.2rem',
    color: '#FFFF',
  },
})(Tab);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(tab) {
  return {
    id: `${tab}`,
    // 'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const CustomerDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { customerId } = useParams();
  const { currentPartner } = useSelector((state) => ({
    currentPartner: selectCurrentPartner(state),
  }));

  useEffect(() => {
    // eslint-disable-next-line

    if (currentPartner)
      dispatch(
        doReadOrganisationChaining({
          partnerId: currentPartner.partnerId,
          organisationId: customerId,
          displayNotification: false,
        }),
      );
    // return () => {
    //   dispatch(doOrganisationListReset());
    // };
  }, [dispatch, currentPartner, customerId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { organisation } = useSelector((state) => ({
    organisation: selectOrganisation(state),
  }));

  const [org, setOrg] = useState({});

  useEffect(() => {
    if (organisation) {
      const { generalSetting } = organisation;
      setOrg(generalSetting);
    }
  }, [organisation]);

  return (
    <Grid style={{ backgroundColor: '#F4F4F4' }} className={classes.root}>
      <Grid item xs={12} style={{ marginLeft: 8 }}>
        <Typography variant="h5" style={{ textTransform: 'capitalize', fontWeight: 500 }}>
          {org?.name}
        </Typography>
      </Grid>
      <Grid container className="spacer__0-5">
        <Breadcrumbs
          separator={<ChevronRightIcon fontSize="small" />} // Use the arrow icon as the separator
          aria-label="breadcrumb"
        >
          <BackButton route={routes.customer.list} label="Customers" />
          <BackButton label={org?.name} color="#353f94" />
        </Breadcrumbs>
      </Grid>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className={classes.tabs}
        >
          <CustomTab label="Overview" {...a11yProps('overview')} />
          <CustomTab label="Access Points" {...a11yProps('access-points')} />
          <CustomTab label="Readers" {...a11yProps('readers')} />
          <CustomTab label="Gateways" {...a11yProps('gateways')} />
          {/* <CustomTab label="Repeaters" {...a11yProps('repeaters')} /> */}
          <CustomTab label="Sites" {...a11yProps('sites')} />
          <CustomTab label="Mesh I/O" {...a11yProps('mesh-io')} />
          <CustomTab label="Controllers" {...a11yProps('controllers')} />
          <CustomTab label="Hardware Integration" {...a11yProps('hardware-integration')} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OverviewTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AccessPointTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ACUTab />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GatewayTab />
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <RepeaterTab />
      </TabPanel> */}
      <TabPanel value={value} index={4}>
        <SiteTab />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <MeshIo />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Controllers />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <HardwareIntegration />
      </TabPanel>
      {/* <TabPanel value={value} index={6}>
        <AdminTab />
      </TabPanel> */}
    </Grid>
  );
};

export default CustomerDetails;
