import * as actionCreators from '../actionCreators/user';
import * as misc from '../actionCreators/miscellaneous';
import * as userAPIs from '../../services/api/user';
import { extractErrorMessage } from '../helpers';

export const doReadUserList = (partnerId) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch(actionCreators.doReadUserList());
    const { data } = await userAPIs.ReadUserList(partnerId);
    dispatch(actionCreators.doReadUserListSuccess(data));
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
  } catch (error) {
    const message = extractErrorMessage(error);

    dispatch(actionCreators.doReadUserListFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doCreateUser = (data) => async (dispatch) => {
  const { callback } = data;
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch(actionCreators.doCreateUser());
    await userAPIs.CreateUser(data);
    dispatch(doReadUserList(data.partnerId));
    callback();
    dispatch(actionCreators.doCreateUserSuccess());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({
        isVisible: true,
        message: 'User Created Successfully',
      }),
    );
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(actionCreators.doCreateUserFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doUpdateUser = (data) => async (dispatch) => {
  const { callback } = data;
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch(actionCreators.doUpdateUser());
    await userAPIs.UpdateUser(data);
    dispatch(doReadUserList(data.partnerId));
    callback();
    dispatch(actionCreators.doUpdateUserSuccess());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({
        isVisible: true,
        message: 'User Updated Successfully',
      }),
    );
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(actionCreators.doUpdateUserFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doDeleteUser = (data) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch(actionCreators.doDeleteUser());
    await userAPIs.DeleteUser(data);
    dispatch(doReadUserList(data.partnerId));
    dispatch(actionCreators.doDeleteUserSuccess());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({
        isVisible: true,
        message: 'User Deleted Successfully',
      }),
    );
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(actionCreators.doDeleteUserFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};
