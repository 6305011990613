import axios from 'axios';
import { getAuthorizationToken, setAuthorizationToken } from '../../utils/Auth';

import { configuredStore } from '../../redux/';
import { doLogout } from '../../redux/thunks/authentication';
import { refreshAccessToken } from '../../utils/Accounts';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/`,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

const axiosInfraInstance = axios.create({
  baseURL: `${process.env.REACT_APP_INFRA_API_URL}/`,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = getAuthorizationToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosInfraInstance.interceptors.request.use(
  function (config) {
    const token = getAuthorizationToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response;
    if (status === 401) {
      try {
        const token = await refreshAccessToken();
        if (token) {
          setAuthorizationToken(token);
          const originalRequest = error.config;
          originalRequest.headers['Authorization'] = `${token}`;
          return axiosInstance(originalRequest);
        } else {
          configuredStore.store.dispatch(doLogout());
        }
      } catch (err) {
        Promise.reject(err.message);
      }
    }
    return Promise.reject(error);
  },
);

axiosInfraInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response;
    if (status === 401) {
      try {
        const token = await refreshAccessToken();
        if (token) {
          setAuthorizationToken(token);
          const originalRequest = error.config;
          originalRequest.headers['Authorization'] = `${token}`;
          return axiosInfraInstance(originalRequest);
        } else {
          configuredStore.store.dispatch(doLogout());
        }
      } catch (err) {
        Promise.reject(err.message);
      }
    }
    return Promise.reject(error);
  },
);

// createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
//   statusCodes: [401, 400],
// });
// createAuthRefreshInterceptor(axiosInfraInstance, refreshAuthLogic, {
//   statusCodes: [401, 400],
// });

export { axiosInstance, axiosInfraInstance };
