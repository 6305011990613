import React, { useRef, useState, useEffect } from 'react';

// packages
import 'react-phone-input-2/lib/style.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// styles
import inputStyles from '../styles.module.scss';
import '../input.scss';

// icons
import TriangleSvg from '../icons/triangle';

const Dropdown = ({ error, name, data, onChange, info = <></>, disabled, ...props }) => {
  const inputRef = useRef(null);
  const ref = useRef(null);
  const [focused, setFocused] = useState(false);
  const [showAndHideDropDown, setShowAndHideDropDown] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event?.target)) {
        setShowAndHideDropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  const handleDropDownIconClick = () => {
    if (!disabled) {
      setShowAndHideDropDown(!showAndHideDropDown);
    }
  };

  return (
    <div style={{ width: '100%' }} ref={ref}>
      <div
        style={{ cursor: 'pointer' }}
        className={classnames(
          inputStyles['input_field'],
          error && inputStyles['danger'],
          (focused || inputRef?.current?.value) && !error && inputStyles['focused'],
          disabled && inputStyles['disabled'],
        )}
        onClick={handleDropDownIconClick}
      >
        {props?.placeholder && (
          <div
            className={classnames(
              inputStyles['label'],
              focused || inputRef?.current?.value || props?.value
                ? inputStyles['label_active']
                : inputStyles['label_inactive'],
            )}
          >
            <p className={classnames(inputStyles['placeholder'])}>{props?.placeholder}</p>
            {info}
          </div>
        )}
        <input
          ref={inputRef}
          name={name}
          placeholder={!focused ? props?.placeholder : ''}
          onClick={handleDropDownIconClick}
          onFocus={(e) => {
            props?.onFocus && props.onFocus(e);
            setFocused(true);
          }}
          onBlur={(e) => {
            props?.onBlur && props?.onBlur(e);
            setShowAndHideDropDown(false);
            setFocused(false);
          }}
          disabled={true}
          {...props}
        />
        <div className={inputStyles['icon']}>
          <TriangleSvg
            className={showAndHideDropDown ? inputStyles['svg-transform'] : inputStyles['svg']}
          />
        </div>
        {data && (
          <div
            className={showAndHideDropDown ? inputStyles['dropdown'] : inputStyles['dropdown-hide']}
          >
            {data.map((content) => (
              <li
                onClick={(e) => {
                  onChange(content);
                  handleDropDownIconClick();
                }}
              >
                {content.name}
              </li>
            ))}
          </div>
        )}
      </div>
      {error && <p className={inputStyles['error']}>{error}</p>}
    </div>
  );
};

Dropdown.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
};

export default React.memo(Dropdown);
