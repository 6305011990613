import React from 'react';

// components
import Button from '../../Buttons/Button';
import Modal from '../Modal';

// packages
import PropTypes from 'prop-types';

// styling
import styles from './ModalAlert.module.scss';

const ModalAlert = ({
  showModal,
  closeModal,
  handleSubmit,
  title,
  description,
  modalType,
  understand,
}) => {
  return (
    <Modal showModal={showModal} type={modalType}>
      <div className={styles['modal_container']}>
        {title && <p className={styles['modal_container_header']}>{title}</p>}
        <p className={styles['modal_container_text']}>{description}</p>
        <div className={styles['modal_container_button_container']}>
          <Button
            onClick={closeModal}
            shadow={false}
            buttonType={modalType === 'danger' ? 'secondary' : 'primary'}
            text={modalType === 'danger' ? 'Cancel' : 'Done'}
          />
          {handleSubmit && (
            <Button
              onClick={handleSubmit}
              shadow={false}
              buttonType="danger"
              text={understand ? 'Continue' : 'Delete'}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

ModalAlert.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  modalType: PropTypes.string,
};

export default ModalAlert;
