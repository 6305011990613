import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
// import NotificationContainer from "../../containers/NotificationContainer";
import LoadingBar from '../../components/LoadingBar';
import NotificationSnackbar from '../../components/NotificationSnackbar';
import MessageModal from '../../components/messageModal';

const BaseLayout = ({ component: Component, ...rest }) => (
  <Route
    render={(props) => (
      <Fragment>
        <LoadingBar />
        <MessageModal />
        <div>
          <Component {...props} />
        </div>
        <NotificationSnackbar />
      </Fragment>
    )}
    {...rest}
  />
);

export default BaseLayout;
