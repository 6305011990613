import React, { Fragment, useEffect, useState } from 'react';

// packages
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import ReactFlagsSelect from 'react-flags-select';

// styles
import inputStyles from '../styles.module.scss';
import '../input.scss';

const Dropdown = ({ error, name, data, onChange, fullWidth, ...props }) => {
  const [selected, setSelected] = useState(undefined);
  useEffect(() => {
    const button = document?.querySelector('#rfs-btn');
    button.setAttribute('aria-expanded', 'false');
  }, [selected]);

  return (
    <Fragment>
      <ReactFlagsSelect
        defaultCountry="IN"
        className={fullWidth ? ['flag_input', 'full-width'] : 'flag_input'}
        selected={props.value}
        selectButtonClassName="h-14"
        searchable
        onSelect={(code) => {
          setSelected(code);
          onChange(code);
        }}
      />
      {error && <p className={inputStyles['error']}>{error}</p>}
    </Fragment>
  );
};

Dropdown.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(Dropdown);
