import React from 'react';
import style from './SquareDelete-styles.module.scss';
import { IconButton } from '@material-ui/core';
import Delete from '../../assets/svgComponent/delete';

const SquareDelete = ({ onPress, children, ...props }) => {
  return (
    <IconButton onClick={onPress} className={style['button-container']} {...props}>
      <Delete style={{ color: '#ff535a' }} />
    </IconButton>
  );
};

export default SquareDelete;
