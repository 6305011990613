import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/miscellaneous';

const defaultState = {
  message: '',
  isVisible: false,
  isLoading: false,
  messageModal: {
    show: false,
    type: '',
    title: '',
    description: '',
    handleClose: null,
    handleSubmit: null,
    cancelButtontext: '',
    submitButtontext: '',
  },
};

const miscellaneousReducer = handleActions(
  {
    [actionCreators.doCreateNotification]: (state, { payload }) => {
      return { ...state, isVisible: payload.isVisible, message: payload.message };
    },
    [actionCreators.doDisplayLoadingBar]: (state, { payload }) => {
      return { ...state, isLoading: payload.isLoading };
    },
    [actionCreators.doDisplayMessageModal]: (state, { payload }) => {
      return { ...state, messageModal: payload.messageModal };
    },
  },
  defaultState,
);

export default miscellaneousReducer;
