import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

//MUI
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { styled } from '@mui/material/styles';

//styles
import './InfoIcon.css';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#EAEBF4',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
  },
}))(Tooltip);

const InfoIcon = ({ title }) => {
  return (
    <LightTooltip placement="right" title={title}>
      <div className="dropdown-icon">i</div>
    </LightTooltip>
  );
};

export default InfoIcon;
