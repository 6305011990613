// import voca from 'voca';
import '../styles/common.scss';

// const STATUS_COLORS = {
//   Good: 'status-success',
//   Attention: 'status-warning',
//   Critical: 'status-error',
//   in_sync: 'bg-green-200',
//   create_pending: 'bg-blue-200',
//   create_failed: 'bg-red-500 text-white',
//   update_pending: 'bg-blue-200',
//   update_failed: 'bg-red-500 text-white',
//   delete_pending: 'bg-blue-200',
//   delete_failed: 'bg-red-500 text-white',
//   0: 'bg-red-500 text-white',
//   1: 'bg-green-200',
// };

const customer_status = {
  in_sync: 'text-green',
  create_pending: 'text-black',
  create_failed: 'text-red',
  update_pending: 'text-black',
  update_failed: 'text-red',
  delete_pending: 'text-black',
  delete_failed: 'text-red',
};

const readerStatusRenderer = (status, statusColor) => {
  return (
    <div className="status-container">
      <p className={`status-text ${textStatus(status)}`}>{statusMapping(status)}</p>
    </div>
  );
};

const statusRenderer = (status) => {
  const status_color = customer_status[status];
  return (
    <div className="status-container">
      <p className={`status-text ${status_color}`}>{statusMapping(status)}</p>
    </div>
  );
};

const textStatusRender = (status) => {
  return (
    <div className="status-container">
      <p className={`status-text ${textStatus(status)}`}>{statusMapping(status)}</p>
    </div>
  );
};

const textRender = (status) => {
  return (
    <div className="status-container">
      <p className={`status-text ${textStatus(status)}`}>{status}</p>
    </div>
  );
};
const configTypeRender = (value) => {
  if (value === 1) {
    return 'Entry + Exit Reader';
  } else if (value === 2) {
    return 'Entry reader + Rex';
  } else if (value === 3) {
    return 'Entry Reader + Door Controller';
  } else if (value === 4) {
    return 'Door Controller + REX';
  } else if (value === 5) {
    return 'Clock-in device';
  } else if (value === 6) {
    return 'Door Lock';
  } else if (value === 7) {
    return 'Entry + Exit reader (RS485 Enabled)';
  } else {
    return;
  }
};

const deviceTypeRender = (value) => {
  if (value === 1) {
    return 'Entry';
  } else if (value === 2) {
    return 'Exit';
  } else if (value === 3) {
    return 'Controller';
  } else if (value === 4) {
    return 'Mc_controller';
  } else {
    return;
  }
};

const textStatus = (status) => {
  switch (status) {
    case 0:
      return 'text-red';
    case 1:
      return 'text-green';
    default:
      return 'text-black';
  }
};

const statusMapping = (term) => {
  let statusText = '';
  switch (term) {
    case 'in_sync':
      statusText = 'Healthy';
      break;
    case 'create_pending':
    case 'create_failed':
    case 'configure_pending':
    case 'configure_failed':
    case 'reset_pending':
    case 'reset_failed':
    case 'detach_pending':
    case 'detach_failed':
    case 'delete_pending':
    case 'update_failed':
    case 'update_pending':
    case 'delete_failed':
    case 'alive_pending':
      statusText = 'Unhealthy';
      break;
    case 1:
      statusText = 'Online';
      break;
    case 0:
      statusText = 'Offline';
      break;
    default:
      statusText = '-';
      break;
  }
  return statusText;
};

export {
  statusRenderer,
  textStatusRender,
  textRender,
  readerStatusRenderer,
  statusMapping,
  configTypeRender,
  deviceTypeRender,
};
