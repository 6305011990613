export const selectOrganisationList = (state) => state.organisation.organisationList;
export const selectOrganisation = (state) => state.organisation.organisation;
export const selectOrganisationSites = (state) => state.organisation.sites;
export const selectOrganisationGateways = (state) => state.organisation.gateways;
export const selectOrganisationAccessPoints = (state) => state.organisation.accessPoints;
export const selectOrganisationAdmins = (state) => state.organisation.admins;
export const selectOrganisationACUS = (state) => state.organisation.acus;
export const selectOrganisationRepeaters = (state) => state.organisation.repeaters;
export const selectOrganisationGatewayLog = (state) => state.organisation.gatewayLog;
export const selectOrganisationACULog = (state) => state.organisation.acuLog;
