import { createAction } from 'redux-actions';

import * as actionTypes from '../actionTypes/organisation';

export const doReadOrganisationList = createAction(actionTypes.ORGANISATION_LIST_READ_REQUESTED);
export const doReadOrganisationListSuccess = createAction(
  actionTypes.ORGANISATION_LIST_READ_SUCCEEDED,
);
export const doReadOrganisationListFailure = createAction(
  actionTypes.ORGANISATION_LIST_READ_FAILED,
);
export const doOrganisationListReset = createAction(actionTypes.ORGANISATION_LIST_RESET);

export const doCreateOrganisation = createAction(actionTypes.ORGANISATION_CREATE_REQUESTED);
export const doCreateOrganisationSuccess = createAction(actionTypes.ORGANISATION_CREATE_SUCCEEDED);
export const doCreateOrganisationFailure = createAction(actionTypes.ORGANISATION_CREATE_FAILED);

export const doUpdateOrganisation = createAction(actionTypes.ORGANISATION_UPDATE_REQUESTED);
export const doUpdateOrganisationSuccess = createAction(actionTypes.ORGANISATION_UPDATE_SUCCEEDED);
export const doUpdateOrganisationFailure = createAction(actionTypes.ORGANISATION_UPDATE_FAILED);

export const doDeleteOrganisation = createAction(actionTypes.ORGANISATION_DELETE_REQUESTED);
export const doDeleteOrganisationSuccess = createAction(actionTypes.ORGANISATION_DELETE_SUCCEEDED);
export const doDeleteOrganisationFailure = createAction(actionTypes.ORGANISATION_DELETE_FAILED);

export const doReadOrganisation = createAction(actionTypes.ORGANISATION_READ_REQUESTED);
export const doReadOrganisationSuccess = createAction(actionTypes.ORGANISATION_READ_SUCCEEDED);
export const doReadOrganisationFailure = createAction(actionTypes.ORGANISATION_READ_FAILED);
export const doOrganisationReset = createAction(actionTypes.ORGANISATION_RESET);

export const doReadOrganisationSites = createAction(actionTypes.ORGANISATION_SITES_READ_REQUESTED);
export const doReadOrganisationSitesSuccess = createAction(
  actionTypes.ORGANISATION_SITES_READ_SUCCEEDED,
);
export const doReadOrganisationSitesFailure = createAction(
  actionTypes.ORGANISATION_SITES_READ_FAILED,
);
export const doOrganisationSitesReset = createAction(actionTypes.ORGANISATION_SITES_RESET);

export const doReadOrganisationGateways = createAction(
  actionTypes.ORGANISATION_GATEWAYS_READ_REQUESTED,
);
export const doReadOrganisationGatewaysSuccess = createAction(
  actionTypes.ORGANISATION_GATEWAYS_READ_SUCCEEDED,
);
export const doReadOrganisationGatewaysFailure = createAction(
  actionTypes.ORGANISATION_GATEWAYS_READ_FAILED,
);
export const doOrganisationGatewaysReset = createAction(actionTypes.ORGANISATION_GATEWAYS_RESET);

export const doReadOrganisationAccessPoints = createAction(
  actionTypes.ORGANISATION_ACCESS_POINTS_READ_REQUESTED,
);
export const doReadOrganisationAccessPointsSuccess = createAction(
  actionTypes.ORGANISATION_ACCESS_POINTS_READ_SUCCEEDED,
);
export const doReadOrganisationAccessPointsFailure = createAction(
  actionTypes.ORGANISATION_ACCESS_POINTS_READ_FAILED,
);
export const doOrganisationAccessPointsReset = createAction(
  actionTypes.ORGANISATION_ACCESS_POINTS_RESET,
);

export const doReadOrganisationAdmins = createAction(
  actionTypes.ORGANISATION_ADMINS_READ_REQUESTED,
);
export const doReadOrganisationAdminsSuccess = createAction(
  actionTypes.ORGANISATION_ADMINS_READ_SUCCEEDED,
);
export const doReadOrganisationAdminsFailure = createAction(
  actionTypes.ORGANISATION_ADMINS_READ_FAILED,
);
export const doOrganisationAdminsReset = createAction(actionTypes.ORGANISATION_ADMINS_RESET);

export const doReadOrganisationACUS = createAction(actionTypes.ORGANISATION_ACUS_READ_REQUESTED);
export const doReadOrganisationACUSSuccess = createAction(
  actionTypes.ORGANISATION_ACUS_READ_SUCCEEDED,
);
export const doReadOrganisationACUSFailure = createAction(
  actionTypes.ORGANISATION_ACUS_READ_FAILED,
);
export const doOrganisationACUSReset = createAction(actionTypes.ORGANISATION_ACUS_RESET);

export const doReadOrganisationRepeaters = createAction(
  actionTypes.ORGANISATION_REPEATERS_READ_REQUESTED,
);
export const doReadOrganisationRepeatersSuccess = createAction(
  actionTypes.ORGANISATION_REPEATERS_READ_SUCCEEDED,
);
export const doReadOrganisationRepeatersFailure = createAction(
  actionTypes.ORGANISATION_REPEATERS_READ_FAILED,
);
export const doOrganisationRepeatersReset = createAction(actionTypes.ORGANISATION_REPEATERS_RESET);

export const doReadOrganisationGatewayLog = createAction(
  actionTypes.ORGANISATION_GATEWAY_LOG_READ_REQUESTED,
);
export const doReadOrganisationGatewayLogSuccess = createAction(
  actionTypes.ORGANISATION_GATEWAY_LOG_READ_SUCCEEDED,
);
export const doReadOrganisationGatewayLogFailure = createAction(
  actionTypes.ORGANISATION_GATEWAY_LOG_READ_FAILED,
);
export const doOrganisationGatewayLogReset = createAction(
  actionTypes.ORGANISATION_GATEWAY_LOG_RESET,
);

export const doReadOrganisationACULog = createAction(
  actionTypes.ORGANISATION_ACU_LOG_READ_REQUESTED,
);
export const doReadOrganisationACULogSuccess = createAction(
  actionTypes.ORGANISATION_ACU_LOG_READ_SUCCEEDED,
);
export const doReadOrganisationACULogFailure = createAction(
  actionTypes.ORGANISATION_ACU_LOG_READ_FAILED,
);
export const doOrganisationACULogReset = createAction(actionTypes.ORGANISATION_ACU_LOG_RESET);

export const doCreateOrganisationSite = createAction(
  actionTypes.ORGANISATION_SITE_CREATE_REQUESTED,
);
export const doCreateOrganisationSiteSuccess = createAction(
  actionTypes.ORGANISATION_SITE_CREATE_SUCCEEDED,
);
export const doCreateOrganisationSiteFailure = createAction(
  actionTypes.ORGANISATION_SITE_CREATE_FAILED,
);

export const doUpdateOrganisationSite = createAction(
  actionTypes.ORGANISATION_SITE_UPDATE_REQUESTED,
);
export const doUpdateOrganisationSiteSuccess = createAction(
  actionTypes.ORGANISATION_SITE_UPDATE_SUCCEEDED,
);
export const doUpdateOrganisationSiteFailure = createAction(
  actionTypes.ORGANISATION_SITE_UPDATE_FAILED,
);

export const doDeleteOrganisationSite = createAction(
  actionTypes.ORGANISATION_SITE_DELETE_REQUESTED,
);
export const doDeleteOrganisationSiteSuccess = createAction(
  actionTypes.ORGANISATION_SITE_DELETE_SUCCEEDED,
);
export const doDeleteOrganisationSiteFailure = createAction(
  actionTypes.ORGANISATION_SITE_DELETE_FAILED,
);

export const doCreateOrganisationAccessPoint = createAction(
  actionTypes.ORGANISATION_ACCESS_POINT_CREATE_REQUESTED,
);
export const doCreateOrganisationAccessPointSuccess = createAction(
  actionTypes.ORGANISATION_ACCESS_POINT_CREATE_SUCCEEDED,
);
export const doCreateOrganisationAccessPointFailure = createAction(
  actionTypes.ORGANISATION_ACCESS_POINT_CREATE_FAILED,
);

export const doUpdateOrganisationAccessPoint = createAction(
  actionTypes.ORGANISATION_ACCESS_POINT_UPDATE_REQUESTED,
);
export const doUpdateOrganisationAccessPointSuccess = createAction(
  actionTypes.ORGANISATION_ACCESS_POINT_UPDATE_SUCCEEDED,
);
export const doUpdateOrganisationAccessPointFailure = createAction(
  actionTypes.ORGANISATION_ACCESS_POINT_UPDATE_FAILED,
);

export const doDeleteOrganisationAccessPoint = createAction(
  actionTypes.ORGANISATION_ACCESS_POINT_DELETE_REQUESTED,
);
export const doDeleteOrganisationAccessPointSuccess = createAction(
  actionTypes.ORGANISATION_ACCESS_POINT_DELETE_SUCCEEDED,
);
export const doDeleteOrganisationAccessPointFailure = createAction(
  actionTypes.ORGANISATION_ACCESS_POINT_DELETE_FAILED,
);

export const doCreateOrganisationGateway = createAction(
  actionTypes.ORGANISATION_GATEWAY_CREATE_REQUESTED,
);
export const doCreateOrganisationGatewaySuccess = createAction(
  actionTypes.ORGANISATION_GATEWAY_CREATE_SUCCEEDED,
);
export const doCreateOrganisationGatewayFailure = createAction(
  actionTypes.ORGANISATION_GATEWAY_CREATE_FAILED,
);

export const doUpdateOrganisationGateway = createAction(
  actionTypes.ORGANISATION_GATEWAY_UPDATE_REQUESTED,
);
export const doUpdateOrganisationGatewaySuccess = createAction(
  actionTypes.ORGANISATION_GATEWAY_UPDATE_SUCCEEDED,
);
export const doUpdateOrganisationGatewayFailure = createAction(
  actionTypes.ORGANISATION_GATEWAY_UPDATE_FAILED,
);

export const doDeleteOrganisationGateway = createAction(
  actionTypes.ORGANISATION_GATEWAY_DELETE_REQUESTED,
);
export const doDeleteOrganisationGatewaySuccess = createAction(
  actionTypes.ORGANISATION_GATEWAY_DELETE_SUCCEEDED,
);
export const doDeleteOrganisationGatewayFailure = createAction(
  actionTypes.ORGANISATION_GATEWAY_DELETE_FAILED,
);

export const doCreateOrganisationRepeater = createAction(
  actionTypes.ORGANISATION_REPEATER_CREATE_REQUESTED,
);
export const doCreateOrganisationRepeaterSuccess = createAction(
  actionTypes.ORGANISATION_REPEATER_CREATE_SUCCEEDED,
);
export const doCreateOrganisationRepeaterFailure = createAction(
  actionTypes.ORGANISATION_REPEATER_CREATE_FAILED,
);

export const doUpdateOrganisationRepeater = createAction(
  actionTypes.ORGANISATION_REPEATER_UPDATE_REQUESTED,
);
export const doUpdateOrganisationRepeaterSuccess = createAction(
  actionTypes.ORGANISATION_REPEATER_UPDATE_SUCCEEDED,
);
export const doUpdateOrganisationRepeaterFailure = createAction(
  actionTypes.ORGANISATION_REPEATER_UPDATE_FAILED,
);

export const doDeleteOrganisationRepeater = createAction(
  actionTypes.ORGANISATION_REPEATER_DELETE_REQUESTED,
);
export const doDeleteOrganisationRepeaterSuccess = createAction(
  actionTypes.ORGANISATION_REPEATER_DELETE_SUCCEEDED,
);
export const doDeleteOrganisationRepeaterFailure = createAction(
  actionTypes.ORGANISATION_REPEATER_DELETE_FAILED,
);

export const doCreateOrganisationAdmin = createAction(
  actionTypes.ORGANISATION_ADMIN_CREATE_REQUESTED,
);
export const doCreateOrganisationAdminSuccess = createAction(
  actionTypes.ORGANISATION_ADMIN_CREATE_SUCCEEDED,
);
export const doCreateOrganisationAdminFailure = createAction(
  actionTypes.ORGANISATION_ADMIN_CREATE_FAILED,
);

export const doUpdateOrganisationAdmin = createAction(
  actionTypes.ORGANISATION_ADMIN_UPDATE_REQUESTED,
);
export const doUpdateOrganisationAdminSuccess = createAction(
  actionTypes.ORGANISATION_ADMIN_UPDATE_SUCCEEDED,
);
export const doUpdateOrganisationAdminFailure = createAction(
  actionTypes.ORGANISATION_ADMIN_UPDATE_FAILED,
);

export const doDeleteOrganisationAdmin = createAction(
  actionTypes.ORGANISATION_ADMIN_DELETE_REQUESTED,
);
export const doDeleteOrganisationAdminSuccess = createAction(
  actionTypes.ORGANISATION_ADMIN_DELETE_SUCCEEDED,
);
export const doDeleteOrganisationAdminFailure = createAction(
  actionTypes.ORGANISATION_ADMIN_DELETE_FAILED,
);
