import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { useHistory } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganisationList } from '../../../redux/selectors/organisation';
import { selectCurrentPartner } from '../../../redux/selectors/authentication';
import { doReadOrganisationList, doDeleteOrganisation } from '../../../redux/thunks/organisation';
import { doOrganisationListReset } from '../../../redux/actionCreators/organisation';
import { Search } from '../../../utils/Search';
// table config
import tableConfig from './tableConfig';

// components
import Table from '../../../components/Table/Table';
import SquareDelete from '../../../components/SquareDelete/SquareDelete';
import style from './style.module.scss';
import Button from '../../../components/Buttons/Button';
import ModalAlert from '../../../components/Modals/ModalAlert';

import routes from '../../../constants/routes';

const CustomerList = () => {
  const dispatch = useDispatch();
  const { currentPartner, organisationList } = useSelector((state) => ({
    currentPartner: selectCurrentPartner(state),
    organisationList: selectOrganisationList(state),
  }));
  const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false);
  const [customer, setCustomer] = useState(null);

  const [filters, setFilters] = useImmer({ name: '', id: '' });
  const [pagination, setPagination] = useImmer({ total: 0, currentPage: 1, perPage: 7 });

  const history = useHistory();

  useEffect(() => {
    if (currentPartner) dispatch(doReadOrganisationList(currentPartner?.partnerId));
    return () => {
      dispatch(doOrganisationListReset());
    };
  }, [dispatch, currentPartner]);

  const togglePopup = (popupName) => {
    if (popupName === 'DeleteCustomer') {
      setShowDeleteCustomerModal(!showDeleteCustomerModal);
      if (showDeleteCustomerModal) {
        setCustomer(null);
      }
    }
  };

  useEffect(() => {
    setPagination((draft) => {
      draft.total = organisationList?.organisations?.length
        ? organisationList?.organisations?.length
        : 0;
    });
  }, [organisationList?.organisations?.length, setPagination]);

  const delete_column = [
    {
      title: 'Action',
      render: (columnProps, row) => {
        return (
          <SquareDelete
            onPress={() => {
              setCustomer(row);
              togglePopup('DeleteCustomer');
            }}
          />
        );
      },
    },
  ];

  const handleDeleteOrganisation = useCallback(
    (event) => {
      dispatch(
        doDeleteOrganisation(
          {
            partnerId: currentPartner?.partnerId,
            organisationId: customer?.id,
          },
          (status) => {
            if (status === 'success') {
            }
          },
        ),
      );
      setCustomer(null);
      setShowDeleteCustomerModal(false);
    },
    [currentPartner?.partnerId, customer?.id, dispatch],
  );

  const filteredData = useCallback(() => {
    let data = organisationList?.organisations ? organisationList?.organisations : [];
    if (filters.id !== '') {
      data = Search(data, 'id', filters.id);
    }
    if (filters.name !== '') {
      data = Search(data, 'name', filters.name);
    }

    const sortedOrganizations = [...data].sort((a, b) => b.id - a.id);

    data = sortedOrganizations.slice(
      (pagination.currentPage - 1) * 7,
      pagination.currentPage * pagination.perPage,
    );

    return data;
  }, [
    filters.id,
    filters.name,
    organisationList?.organisations,
    pagination.currentPage,
    pagination.perPage,
  ]);

  const hSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((draft) => {
      draft[name] = value;
    });
  };

  const search = {
    onChange: hSearchChange,
  };

  const changePage = (p) => {
    const { page, per_page } = p;

    setPagination((draft) => {
      draft.currentPage = page;
      draft.perPage = per_page;
    });
  };

  return (
    <Fragment>
      <div className="spacer__0-5" />
      <div>
        <div>
          {showDeleteCustomerModal && (
            <ModalAlert
              showModal={showDeleteCustomerModal}
              closeModal={() => setShowDeleteCustomerModal(false)}
              handleSubmit={handleDeleteOrganisation}
              title={`Delete Customer`}
              description={` Are you sure you want to delete ${
                customer ? customer.name : ''
              } ? This action is non
              reversible and will cause you to lose all data associated with this customer/ s`}
              modalType={'danger'}
            />
          )}
          <div className={style[`header-container`]}>
            <p className={style[`header`]}>Customer</p>
            <div className={style[`button-cont`]}>
              <Button
                text={'Add Customer'}
                onClick={() => {
                  history.push(routes.customer.addNew);
                }}
              />
            </div>
          </div>
          <div>
            <Table
              columns={[...tableConfig, ...delete_column]}
              // data={(!!organisationList && organisationList.organisations) || []}
              data={filteredData()}
              pagination={pagination}
              options={{ search }}
              changePage={changePage}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerList;
