import React, { useCallback } from 'react';
import styles from './Table.module.scss';
import classnames from 'classnames';

const TableSearch = ({ columns, options }) => {
  const renderSearch = useCallback(
    (column) => {
      // console.log('columns', columns);
      const name = column?.searchName || column?.name;
      // console.log('name', name);
      if (column.search === false || !name) return null;
      // console.log('1');
      const extra = {
        name: name,
        placeholder: column?.title || 'Select',
        disabled: column?.disable,
      };

      const val = options?.search?.initial && options.search.initial[name];
      const isNum = typeof val == 'number';
      if (val || isNum) {
        // console.log('2');
        extra.value = (isNum ? `${val}` : val) || '';
      }
      if (options?.search?.default) {
        // console.log('3');
        extra.defaultValue = options.search.default[name || ''] || '';
      }
      if (column?.lookup?.length) {
        // console.log('lookup');
        return null;
        //   return (
        //     <SimpleSelect
        //       options={column?.lookup?.map((l) => ({
        //         value: `${l?.id || l?.value || l?.shortName || l}`,
        //         label: `${l?.label || l?.name || l?.shiftName || l}`,
        //       }))}
        //       name={name}
        //       onChange={options?.search?.onChange}
        //       autoSelect={{ ...extra, multiple: f.multiple || false }}
        //       placeholder={extra.placeholder}
        //       isDisableClear={isDisableClear ? isDisableClear : false}
        //     />
        //   );
      } else if (column?.lookup && column?.type !== 'text') {
        // console.log('text');
        return null;
      }

      switch (column.type) {
        //   case 'date':
        //     return (
        //       <DatePicker
        //         // value={value}
        //         onChange={(val) =>
        //           options?.search?.onChange({ target: { name, value: val, type: 'date' } })
        //         }
        //         variant="primary"
        //         disableFuture={f?.disableFuture}
        //         // placeholder={f?.title || 'Select'}
        //         {...extra}
        //       />
        //     );
        //   case 'time':
        //     return (
        //       <TimePicker
        //         // value={value}
        //         onChange={options?.search?.onChange}
        //         // variant="primary"
        //         // placeholder={f?.title || 'Select'}
        //         // format="p"
        //         {...extra}
        //       />
        //     );
        //   case 'dateRange':
        //     return (
        //       <>
        //         <DateRange
        //           showInput={true}
        //           from={f.from}
        //           to={f.to}
        //           onChange={(val) => options?.search?.onChange({ target: { name, value: val } })}
        //           {...extra}
        //         />
        //       </>
        //     );
        case 'none':
          // console.log('none');
          return;
        default:
          // console.log('input');
          return (
            <input
              autoComplete="off"
              className="input"
              type={column.type || 'search'}
              onChange={options.search.onChange}
              {...extra}
            />
            // <div className={styles['input-container']}>
            //   <Input
            //     // value={}
            //     onChange={options.search.onChange}
            //     type={column.type || 'search'}
            //   />
            // </div>
          );
      }
    },
    [options.search.default, options.search.initial, options.search.onChange],
  );

  const TSearch = useCallback(() => {
    return columns?.map((column, index) => (
      <td
        key={index}
        className={classnames(styles['search'], styles['TableSearch'])}
        {...column?.props?.search}
      >
        {
          // (column?.searchComp && column.searchComp(column)) ||

          renderSearch(column)
        }
      </td>
    ));
  }, [columns, renderSearch]);

  return (
    <>
      <tr>
        {options?.selection?.enable ? <td className={styles['selection']}></td> : null}
        {TSearch()}
      </tr>
    </>
  );
};

export default TableSearch;
