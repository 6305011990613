import Pool from '../constants/UserPool';
import { setAuthorizationToken, removeAuthorizationToken } from './Auth';

export const getAccessToken = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          reject();
        } else {
          resolve(session.getAccessToken().getJwtToken());
        }
      });
    } else {
      reject();
    }
  });

export const refreshAccessToken = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          reject();
        } else {
          if (session.isValid()) {
            resolve(session.getIdToken().getJwtToken());
          }

          const refresh_token = session.getRefreshToken();

          user.refreshSession(refresh_token, (error, sesh) => {
            if (error) {
              reject();
            } else {
              setAuthorizationToken(sesh.getIdToken().getJwtToken());
              resolve(sesh.getIdToken().getJwtToken());
            }
          });
        }
      });
    } else {
      reject();
    }
  });

export const setAccessToken = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          reject();
        } else {
          setAuthorizationToken(session.getIdToken().getJwtToken());
          resolve(session.getIdToken().getJwtToken());
        }
      });
    } else {
      reject();
    }
  });

export const logout = () => {
  const user = Pool.getCurrentUser();
  if (user) {
    removeAuthorizationToken();
    user.signOut();
  }
};
