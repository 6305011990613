// todo compare with original file

export const userEntersNumber = (value) => {
  if (/[0-9]/.test(value)) return true;
  return false;
};

export const checkNumber = (inputText) => {
  const key = inputText.split('');
  const keyList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  if (key.length > 10) {
    return '';
  }
  for (let i = key.length - 1; i >= 0; i--) {
    if (keyList.indexOf(key[i]) === -1) {
      return '';
    }
  }
  return inputText;
};
