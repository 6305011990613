import { isValidPhoneNumber } from 'libphonenumber-js';

export const emailVerification = (value) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value);
};

// return true if text is not valid
export const textVerification = (value, minLength = 4, maxLength = null) => {
  return value.length <= minLength || (maxLength && value.length > maxLength);
};

export const phonenumberVerification = (value) => {
  console.log(value);
  return isValidPhoneNumber(value);
};

// todo repeated function in misc.js
// to verify a valid serialNumber
export const isHex = (inputString) => {
  const regexp = /^[0-9a-fA-F]+$/;
  if (regexp.test(inputString)) {
    return true;
  } else {
    return false;
  }
};
