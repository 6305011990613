import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/user';

const defaultState = {
  userList: null,
  errorMessages: null,
};

const userReducer = handleActions(
  {
    [actionCreators.doReadUserList]: (state) => {
      return { ...state };
    },
    [actionCreators.doReadUserListSuccess]: (state, { payload: { message = {} } }) => {
      return {
        ...state,
        userList: message,
      };
    },
    [actionCreators.doReadMessageSuccess]: (state, { payload: { message = {} } }) => {
      return {
        ...state,
        errorMessages: message.partnerAppErrorMessages,
      };
    },
    [actionCreators.doReadUserListFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doUserListReset]: (state) => {
      return { ...state, userList: null };
    },
    [actionCreators.doCreateUser]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateUserSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateUserFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateUser]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateUserSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateUserFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteUser]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteUserSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteUserFailure]: (state) => {
      return { ...state };
    },
  },
  defaultState,
);

export default userReducer;
