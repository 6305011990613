import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    id="icon_Delete"
    data-name="icon Delete"
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    viewBox="0 0 45 45"
  >
    <g id="Group_453" data-name="Group 453">
      <g id="Rectangle_279" data-name="Rectangle 279" fill="none" stroke="#ff535a" strokeWidth="2">
        <rect width="45" height="45" rx="8" stroke="none" />
        <rect x="1" y="1" width="42" height="42" rx="7" fill="none" />
      </g>
    </g>
    <g id="trash" transform="translate(14 12)">
      <path
        id="Path_2146"
        data-name="Path 2146"
        d="M62.221,2.421H58.892V1.815A1.817,1.817,0,0,0,57.077,0H54.657a1.817,1.817,0,0,0-1.815,1.815v.605H49.513A1.515,1.515,0,0,0,48,3.933V6.051a.605.605,0,0,0,.605.605h.331l.523,10.979a1.813,1.813,0,0,0,1.813,1.729h9.19a1.813,1.813,0,0,0,1.813-1.729L62.8,6.657h.331a.605.605,0,0,0,.605-.605V3.933A1.515,1.515,0,0,0,62.221,2.421Zm-8.169-.605a.606.606,0,0,1,.605-.605h2.421a.606.606,0,0,1,.605.605v.605H54.051ZM49.21,3.933a.3.3,0,0,1,.3-.3H62.221a.3.3,0,0,1,.3.3V5.446H49.21ZM61.066,17.578a.6.6,0,0,1-.6.576h-9.19a.6.6,0,0,1-.6-.576l-.52-10.921H61.586Z"
        transform="translate(-48)"
        fill="#ff535a"
      />
      <path
        id="Path_2147"
        data-name="Path 2147"
        d="M240.605,217.077a.605.605,0,0,0,.605-.605v-7.867a.605.605,0,1,0-1.21,0v7.867A.605.605,0,0,0,240.605,217.077Z"
        transform="translate(-232.738 -200.133)"
        fill="#ff535a"
      />
      <path
        id="Path_2148"
        data-name="Path 2148"
        d="M320.605,217.077a.605.605,0,0,0,.605-.605v-7.867a.605.605,0,1,0-1.21,0v7.867A.605.605,0,0,0,320.605,217.077Z"
        transform="translate(-309.713 -200.133)"
        fill="#ff535a"
      />
      <path
        id="Path_2149"
        data-name="Path 2149"
        d="M160.605,217.077a.605.605,0,0,0,.605-.605v-7.867a.605.605,0,0,0-1.21,0v7.867A.605.605,0,0,0,160.605,217.077Z"
        transform="translate(-155.764 -200.133)"
        fill="#ff535a"
      />
    </g>
  </svg>
);

export default SvgComponent;
