import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    data-name="noun_Access Point_1988868"
    xmlns="http://www.w3.org/2000/svg"
    width={40.295}
    height={45.187}
    {...props}
  >
    <g data-name="Group 9175" fill="#353f94">
      <path
        data-name="Path 3652"
        d="m28.046 39.209-5.288-18.03a2.718 2.718 0 0 0-5.216 0l-5.293 18.03a3.38 3.38 0 0 0 1.151 3.609c.044.035.092.066.137.1s.063.05.1.07a10.764 10.764 0 0 0 13.038 0c.034-.022.064-.05.1-.071s.092-.065.136-.1a3.38 3.38 0 0 0 1.135-3.608Z"
      />
      <circle
        data-name="Ellipse 366"
        cx={2.612}
        cy={2.612}
        transform="translate(17.536 10.655)"
        r={2.612}
      />
      <path
        data-name="Path 3653"
        d="M14.808 13.266a5.3 5.3 0 0 1 .7-2.638 1.075 1.075 0 0 0-.18-1.292 1.09 1.09 0 0 0-1.72.243 7.5 7.5 0 0 0 0 7.375 1.09 1.09 0 0 0 1.72.243 1.075 1.075 0 0 0 .18-1.292 5.3 5.3 0 0 1-.7-2.639Z"
      />
      <path
        data-name="Path 3654"
        d="M25.483 13.266a5.3 5.3 0 0 1-.7 2.638 1.075 1.075 0 0 0 .18 1.292 1.09 1.09 0 0 0 1.72-.24 7.5 7.5 0 0 0 0-7.375 1.09 1.09 0 0 0-1.72-.243 1.075 1.075 0 0 0-.18 1.292 5.3 5.3 0 0 1 .7 2.636Z"
      />
      <path
        data-name="Path 3655"
        d="M31.487 13.267a11.284 11.284 0 0 1-2.249 6.778 1.161 1.161 0 0 0 .1 1.52 1.163 1.163 0 0 0 1.757-.138 13.653 13.653 0 0 0 0-16.32 1.163 1.163 0 0 0-1.757-.138 1.161 1.161 0 0 0-.1 1.521 11.284 11.284 0 0 1 2.249 6.777Z"
      />
      <path
        data-name="Path 3656"
        d="M8.81 13.267a11.284 11.284 0 0 1 2.249-6.778 1.161 1.161 0 0 0-.1-1.518 1.163 1.163 0 0 0-1.757.138 13.653 13.653 0 0 0 0 16.32 1.163 1.163 0 0 0 1.757.138 1.161 1.161 0 0 0 .1-1.521 11.284 11.284 0 0 1-2.249-6.779Z"
      />
      <path
        data-name="Path 3657"
        d="M33.966.338a1.143 1.143 0 0 0-.067 1.541 17.844 17.844 0 0 1 0 22.776 1.143 1.143 0 0 0 .067 1.541 1.145 1.145 0 0 0 1.687-.07 20.129 20.129 0 0 0 0-25.717 1.145 1.145 0 0 0-1.687-.07Z"
      />
      <path
        data-name="Path 3658"
        d="M6.33 26.2a1.143 1.143 0 0 0 .067-1.541 17.843 17.843 0 0 1 0-22.776A1.143 1.143 0 0 0 6.33.338a1.145 1.145 0 0 0-1.687.07 20.128 20.128 0 0 0 0 25.717 1.145 1.145 0 0 0 1.687.07Z"
      />
    </g>
  </svg>
);
export default SvgComponent;
