import voca from 'voca';

const statusSearcher = (term, status) => {
  // const formattedStatus = voca.chain(status).replaceAll('_', ' ').lowerCase().value();
  const formattedStatus = statusMapping(status);
  const formattedTerm = voca.chain(term).lowerCase().value();
  return formattedStatus.search(formattedTerm) !== -1;
};

const statusMapping = (term) => {
  let statusText = '';
  switch (term) {
    case 'in_sync':
      statusText = 'healthy';
      break;
    case 1:
      statusText = 'online';
      break;
    case 0:
      statusText = 'offline';
      break;
    default:
      statusText = 'unhealthy';
      break;
  }
  return statusText;
};

export { statusSearcher };
