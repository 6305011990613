import * as actionCreators from '../actionCreators/organisation';
import * as misc from '../actionCreators/miscellaneous';
import * as organisationAPIs from '../../services/api/organisation';
import { extractErrorMessage } from '../helpers';

export const doReadOrganisationList = (partnerId) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch(actionCreators.doReadOrganisationList());
    const { data } = await organisationAPIs.ReadOrganisationList(partnerId);

    dispatch(actionCreators.doReadOrganisationListSuccess(data));
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(actionCreators.doReadOrganisationListFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doCreateOrganisation = (data) => async (dispatch) => {
  const { callback } = data;
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch(actionCreators.doCreateOrganisation());
    await organisationAPIs.CreateOrganisation(data);
    dispatch(doReadOrganisationList(data.partnerId));
    callback();
    dispatch(actionCreators.doCreateOrganisationSuccess());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({
        isVisible: true,
        message: 'Customer Created Successfully',
      }),
    );
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(actionCreators.doCreateOrganisationFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doCreateNetwork = (siteId, payload) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    // dispatch(actionCreators.doCreateOrganisation());
    await organisationAPIs.CreateNetwork({ siteId, payload });

    // dispatch(actionCreators.doCreateOrganisationSuccess());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({
        isVisible: true,
        message: 'Network Created Successfully',
      }),
    );
  } catch (error) {
    const message = extractErrorMessage(error);
    // dispatch(actionCreators.doCreateOrganisationFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};
export const doDeleteNetwork = (networkId) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    // dispatch(actionCreators.doCreateOrganisation());
    await organisationAPIs.DeleteNetwork({ networkId });

    // dispatch(actionCreators.doCreateOrganisationSuccess());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({
        isVisible: true,
        message: 'Network deleted Successfully',
      }),
    );
  } catch (error) {
    const message = extractErrorMessage(error);
    // dispatch(actionCreators.doCreateOrganisationFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doPartnerHandover =
  ({ organisationId, payload = {}, partnerId, displayNotification = true, callBack }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      // dispatch(actionCreators.doCreateOrganisation());
      await organisationAPIs.HandOverPartner({ organisationId, payload });
      dispatch(doReadOrganisation({ partnerId, organisationId, displayNotification }));
      callBack('success');
      // dispatch(actionCreators.doCreateOrganisationSuccess());
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Partner HandOver initiated',
        }),
      );
    } catch (error) {
      const message = extractErrorMessage(error);
      // dispatch(actionCreators.doCreateOrganisationFailure());
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
    }
  };

export const doUpdateOrganisation =
  ({ partnerId, organisationId, payload, setLoading = false, isIndividual = false }, callback) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doUpdateOrganisation());
      await organisationAPIs.UpdateOrganisation({ partnerId, organisationId, payload });
      callback('success');
      if (isIndividual) {
        await Promise.allSettled([
          dispatch(doReadOrganisation({ partnerId, organisationId, setLoading, isIndividual })),
        ]);
      } else {
        await Promise.allSettled([dispatch(doReadOrganisationList(partnerId))]);
      }
      dispatch(actionCreators.doUpdateOrganisationSuccess());
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Customer Updated Successfully',
        }),
      );
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(actionCreators.doUpdateOrganisationFailure());
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
    }
  };

export const doDeleteOrganisation = (data, callBack) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch(actionCreators.doDeleteOrganisation());
    await organisationAPIs.DeleteOrganisation(data);
    dispatch(doReadOrganisationList(data.partnerId));
    callBack('success');
    dispatch(actionCreators.doDeleteOrganisationSuccess());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({
        isVisible: true,
        message: 'Customer Deleted Successfully',
      }),
    );
  } catch (error) {
    const message = extractErrorMessage(error);
    callBack('error');
    dispatch(actionCreators.doDeleteOrganisationFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doReadOrganisationChaining =
  ({ partnerId, organisationId, displayNotification = true }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      await Promise.allSettled([
        dispatch(doReadOrganisation({ partnerId, organisationId, displayNotification })),
        dispatch(doReadOrganisationSites({ partnerId, organisationId, displayNotification })),
        dispatch(doReadOrganisationGateways({ partnerId, organisationId, displayNotification })),
        dispatch(
          doReadOrganisationAccessPoints({ partnerId, organisationId, displayNotification }),
        ),
        // dispatch(doReadOrganisationAdmins({ partnerId, organisationId })),
      ]);

      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Customer Fetched Successfully',
        }),
      );
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
    }
  };

export const doReadOrganisation =
  ({
    partnerId,
    organisationId,
    setLoading = false,
    isIndividual = false,
    displayNotification = true,
  }) =>
  async (dispatch) => {
    try {
      if (setLoading && !isIndividual) dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doReadOrganisation());
      const { data } = await organisationAPIs.ReadOrganisation({ partnerId, organisationId });
      if (setLoading && !isIndividual) dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      if (displayNotification) {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Fetched Customer Successfully',
          }),
        );
      }
      if (setLoading || isIndividual) {
        dispatch(actionCreators.doReadOrganisationSuccess(data));
      } else return dispatch(actionCreators.doReadOrganisationSuccess(data));
    } catch (error) {
      const message = extractErrorMessage(error);
      if (setLoading && !isIndividual) dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      if (setLoading) {
        dispatch(misc.doCreateNotification({ isVisible: true, message }));
      } else return dispatch(actionCreators.doReadOrganisationFailure());
    }
  };

export const doReadOrganisationSites =
  ({ partnerId, organisationId, displayNotification = true }) =>
  async (dispatch) => {
    try {
      dispatch(actionCreators.doReadOrganisationSites());
      const { data } = await organisationAPIs.ReadOrganisationSites({ partnerId, organisationId });

      if (displayNotification)
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Fetched Customer Sites Successfully',
          }),
        );
      return dispatch(actionCreators.doReadOrganisationSitesSuccess(data));
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doReadOrganisationSitesFailure());
    }
  };

export const doReadOrganisationGateways =
  ({ partnerId, organisationId, displayNotification = true }) =>
  async (dispatch) => {
    try {
      dispatch(actionCreators.doReadOrganisationGateways());
      const { data } = await organisationAPIs.ReadOrganisationGateways({
        partnerId,
        organisationId,
      });
      if (displayNotification) {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Fetched Customer Gateways Successfully',
          }),
        );
      }
      return dispatch(actionCreators.doReadOrganisationGatewaysSuccess(data));
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doReadOrganisationGatewaysFailure());
    }
  };

export const doReadOrganisationAccessPoints =
  ({ partnerId, organisationId, displayNotification = true }) =>
  async (dispatch) => {
    try {
      dispatch(actionCreators.doReadOrganisationAccessPoints());
      const { data } = await organisationAPIs.ReadOrganisationAccessPointsV5({
        partnerId,
        organisationId,
      });
      if (displayNotification) {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Fetched Customer Access Points Successfully',
          }),
        );
      }
      return dispatch(actionCreators.doReadOrganisationAccessPointsSuccess(data));
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doReadOrganisationAccessPointsFailure());
    }
  };

export const doReadOrganisationAdmins =
  ({ partnerId, organisationId, displayNotification = true }) =>
  async (dispatch) => {
    try {
      dispatch(actionCreators.doReadOrganisationAdmins());
      const { data } = await organisationAPIs.ReadOrganisationAdmins({ partnerId, organisationId });
      if (displayNotification) {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Fetched Customer Admins Successfully',
          }),
        );
      }
      return dispatch(actionCreators.doReadOrganisationAdminsSuccess(data));
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doReadOrganisationAdminsFailure());
    }
  };

export const doReadOrganisationACUS =
  ({ partnerId, organisationId }) =>
  async (dispatch) => {
    try {
      dispatch(actionCreators.doReadOrganisationACUS());
      const { data } = await organisationAPIs.ReadOrganisationACUS({ partnerId, organisationId });
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Fetched Customer ACUS Successfully',
        }),
      );
      return dispatch(actionCreators.doReadOrganisationACUSSuccess(data));
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doReadOrganisationACUSFailure());
    }
  };

export const doReadOrganisationRepeaters =
  ({ partnerId, organisationId }) =>
  async (dispatch) => {
    try {
      dispatch(actionCreators.doReadOrganisationRepeaters());
      const { data } = await organisationAPIs.ReadOrganisationRepeaters({
        partnerId,
        organisationId,
      });
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Fetched Customer Repeaters Successfully',
        }),
      );
      return dispatch(actionCreators.doReadOrganisationRepeatersSuccess(data));
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doReadOrganisationRepeatersFailure());
    }
  };

export const doReadOrganisationGatewayLog =
  ({ partnerId, organisationId, serialNumber }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doReadOrganisationGatewayLog());
      const { data } = await organisationAPIs.ReadOrganisationGatewayLog({
        partnerId,
        organisationId,
        serialNumber,
      });
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Fetched Customer Gateway Log Successfully',
        }),
      );
      return dispatch(actionCreators.doReadOrganisationGatewayLogSuccess(data));
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doReadOrganisationGatewayLogFailure());
    }
  };

export const doReadOrganisationACULog =
  ({ partnerId, organisationId, serialNumber }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doReadOrganisationACULog());
      const { data } = await organisationAPIs.ReadOrganisationACULog({
        partnerId,
        organisationId,
        serialNumber,
      });
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Fetched Customer ACU Log Successfully',
        }),
      );
      return dispatch(actionCreators.doReadOrganisationACULogSuccess(data));
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doReadOrganisationACULogFailure());
    }
  };

export const doCreateOrganisationSite =
  ({ partnerId, organisationId, payload, callback = () => {} }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doCreateOrganisationSite());
      await organisationAPIs.CreateOrganisationSite({ partnerId, organisationId, payload });
      await Promise.allSettled([dispatch(doReadOrganisationSites({ partnerId, organisationId }))]);
      callback();
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Site Created Successfully',
        }),
      );
      dispatch(actionCreators.doCreateOrganisationSiteSuccess());
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doCreateOrganisationSiteFailure());
    }
  };

export const doUpdateOrganisationSite =
  ({ partnerId, organisationId, siteId, payload, callback = () => {} }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doUpdateOrganisationSite());
      await organisationAPIs.UpdateOrganisationSite({
        partnerId,
        organisationId,
        siteId,
        payload,
      });
      await Promise.allSettled([
        dispatch(
          doReadOrganisationSites({ partnerId, organisationId, displayNotification: false }),
        ),
      ]);
      callback = () => {};
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Site Updated Successfully',
        }),
      );
      dispatch(actionCreators.doUpdateOrganisationSiteSuccess());
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      dispatch(actionCreators.doUpdateOrganisationSiteFailure());
    }
  };

export const doDeleteOrganisationSite =
  ({ partnerId, organisationId, siteId, payload, siteName }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doDeleteOrganisationSite());
      await organisationAPIs.DeleteOrganisationSite({
        partnerId,
        organisationId,
        siteId,
        payload,
      });

      setTimeout(async () => {
        await Promise.allSettled([
          dispatch(doReadOrganisationSites({ partnerId, organisationId })),
        ]);
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        const messageModal = {
          messageModal: {
            show: true,
            type: 'default',
            title: 'Site Deleted Successfully',
            description: `You have successfully deleted ${siteName ? siteName : siteId}.`,
            handleClose: null,
            handleSubmit: null,
            cancelButtontext: 'OK',
            submitButtontext: '',
          },
        };
        dispatch(misc.doDisplayMessageModal(messageModal));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Site Deleted Successfully',
          }),
        );
        dispatch(actionCreators.doDeleteOrganisationSiteSuccess());
      }, 2000);
    } catch (error) {
      const errorCode = error?.response?.data?.message?.errorCode;
      let message = '';
      switch (errorCode) {
        case 1300:
          message =
            'Failed to delete the site because there are networks under it. Delete the networks and then try deleting the site again.';
          break;
        case 1018:
          message =
            'Make sure all the users of the site are deleted or assigned to a new site. Login to www.smart-access.spintly.com to delete or reassign the users';
          break;
        default:
          message = extractErrorMessage(error);
      }

      dispatch(misc.doDisplayLoadingBar({ isLoading: false, message: '' }));
      const messageModal = {
        messageModal: {
          show: true,
          type: 'danger',
          title: 'Failed to delete site',
          description: message?.errorMessage ? message.errorMessage : message,
          handleClose: null,
          handleSubmit: null,
          cancelButtontext: 'OK',
          submitButtontext: '',
        },
      };
      dispatch(misc.doDisplayMessageModal(messageModal));
      dispatch(actionCreators.doDeleteOrganisationSiteFailure());
    }
  };

export const doCreateOrganisationAccessPoint =
  ({ partnerId, networkId, organisationId, payload, callback = () => {} }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doCreateOrganisationAccessPoint());
      await organisationAPIs.CreateOrganisationAccessPointV5({
        partnerId,
        networkId,
        organisationId,
        payload,
      });
      await Promise.allSettled([
        dispatch(
          doReadOrganisationAccessPoints({ partnerId, organisationId, displayNotification: false }),
        ),
      ]);
      callback();
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Access Point Created Successfully',
          }),
        );
        dispatch(actionCreators.doCreateOrganisationAccessPointSuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doCreateOrganisationAccessPointFailure());
    }
  };

export const doUpdateOrganisationAccessPoint =
  ({ partnerId, organisationId, accessPointId, payload, callback = () => {} }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doUpdateOrganisationAccessPoint());
      await organisationAPIs.UpdateOrganisationAccessPoint({
        partnerId,
        organisationId,
        accessPointId,
        payload,
      });
      callback();
      dispatch(actionCreators.doUpdateOrganisationAccessPointSuccess());
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'Access Point Updated Successfully',
        }),
      );
      await dispatch(
        doReadOrganisationAccessPoints({ partnerId, organisationId, displayNotification: false }),
      );
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      dispatch(actionCreators.doUpdateOrganisationAccessPointFailure());
    }
  };

export const doDeleteOrganisationAccessPoint =
  ({ partnerId, organisationId, accessPointId, payload }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doDeleteOrganisationAccessPoint());
      await organisationAPIs.DeleteOrganisationAccessPoint({
        partnerId,
        organisationId,
        accessPointId,
        payload,
      });
      await Promise.allSettled([
        dispatch(
          doReadOrganisationAccessPoints({ partnerId, organisationId, displayNotification: false }),
        ),
      ]);

      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Access Point Deleted Successfully',
          }),
        );
        dispatch(actionCreators.doDeleteOrganisationAccessPointSuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      dispatch(actionCreators.doDeleteOrganisationAccessPointFailure());
    }
  };

export const doCreateOrganisationGateway =
  ({ partnerId, organisationId, payload, networkId, callback = () => {} }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doCreateOrganisationGateway());
      await organisationAPIs.CreateOrganisationGateway({
        partnerId,
        organisationId,
        networkId,
        payload,
      });
      await Promise.allSettled([
        dispatch(doReadOrganisationGateways({ partnerId, organisationId })),
      ]);
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Gateway Created Successfully',
          }),
        );
        callback();
        dispatch(actionCreators.doCreateOrganisationGatewaySuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doCreateOrganisationGatewayFailure());
    }
  };

export const doUpdateOrganisationGateway =
  ({ partnerId, organisationId, gatewayId, payload, callback = () => {} }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doUpdateOrganisationGateway());
      await organisationAPIs.UpdateOrganisationGateway({
        partnerId,
        organisationId,
        gatewayId,
        payload,
      });
      await Promise.allSettled([
        dispatch(doReadOrganisationGateways({ partnerId, organisationId })),
      ]);
      callback();
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Gateway Updated Successfully',
          }),
        );
        dispatch(actionCreators.doUpdateOrganisationGatewaySuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      dispatch(actionCreators.doUpdateOrganisationGatewayFailure());
    }
  };

export const doDeleteOrganisationGateway =
  ({ partnerId, organisationId, serialNumber, payload }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doDeleteOrganisationGateway());
      await organisationAPIs.DeleteOrganisationGateway({
        partnerId,
        organisationId,
        serialNumber,
        payload,
      });
      await Promise.allSettled([
        dispatch(doReadOrganisationGateways({ partnerId, organisationId })),
      ]);
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Gateway Deleted Successfully',
          }),
        );
        dispatch(actionCreators.doDeleteOrganisationGatewaySuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      dispatch(actionCreators.doDeleteOrganisationGatewayFailure());
    }
  };

export const doCreateOrganisationRepeater =
  ({ partnerId, organisationId, payload, callback = () => {} }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doCreateOrganisationRepeater());
      await organisationAPIs.CreateOrganisationRepeater({ partnerId, organisationId, payload });
      await Promise.allSettled([
        dispatch(doReadOrganisationRepeaters({ partnerId, organisationId })),
      ]);
      callback();
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Repeater Created Successfully',
          }),
        );
        dispatch(actionCreators.doCreateOrganisationRepeaterSuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doCreateOrganisationRepeaterFailure());
    }
  };

export const doUpdateOrganisationRepeater =
  ({ partnerId, organisationId, repeaterId, payload, callback = () => {} }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doUpdateOrganisationRepeater());
      await organisationAPIs.UpdateOrganisationRepeater({
        partnerId,
        organisationId,
        repeaterId,
        payload,
      });
      await Promise.allSettled([
        dispatch(doReadOrganisationRepeaters({ partnerId, organisationId })),
      ]);
      callback();
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Repeater Updated Successfully',
          }),
        );
        dispatch(actionCreators.doUpdateOrganisationRepeaterSuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      dispatch(actionCreators.doUpdateOrganisationRepeaterFailure());
    }
  };

export const doDeleteOrganisationRepeater =
  ({ partnerId, organisationId, serialNumber, payload }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doDeleteOrganisationRepeater());
      await organisationAPIs.DeleteOrganisationRepeater({
        partnerId,
        organisationId,
        serialNumber,
        payload,
      });
      await Promise.allSettled([
        dispatch(doReadOrganisationRepeaters({ partnerId, organisationId })),
      ]);
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Repeater Deleted Successfully',
          }),
        );
        dispatch(actionCreators.doDeleteOrganisationRepeaterSuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      dispatch(actionCreators.doDeleteOrganisationRepeaterFailure());
    }
  };

export const doCreateOrganisationAdmin =
  ({ partnerId, organisationId, payload }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doCreateOrganisationAdmin());
      await organisationAPIs.CreateOrganisationAdmin({ partnerId, organisationId, payload });
      await Promise.allSettled([dispatch(doReadOrganisationAdmins({ partnerId, organisationId }))]);
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Admin Created Successfully',
          }),
        );
        dispatch(actionCreators.doCreateOrganisationAdminSuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      return dispatch(actionCreators.doCreateOrganisationAdminFailure());
    }
  };

export const doUpdateOrganisationAdmin =
  ({ partnerId, organisationId, adminId, payload }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doUpdateOrganisationAdmin());
      await organisationAPIs.UpdateOrganisationAdmin({
        partnerId,
        organisationId,
        adminId,
        payload,
      });
      await Promise.allSettled([dispatch(doReadOrganisationAdmins({ partnerId, organisationId }))]);
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Admin Updated Successfully',
          }),
        );
        dispatch(actionCreators.doUpdateOrganisationAdminSuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      dispatch(actionCreators.doUpdateOrganisationAdminFailure());
    }
  };

export const doDeleteOrganisationAdmin =
  ({ partnerId, organisationId, adminId, payload }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doDeleteOrganisationAdmin());
      await organisationAPIs.DeleteOrganisationAdmin({
        partnerId,
        organisationId,
        adminId,
        payload,
      });
      await Promise.allSettled([dispatch(doReadOrganisationAdmins({ partnerId, organisationId }))]);
      setTimeout(() => {
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'Admin Deleted Successfully',
          }),
        );
        dispatch(actionCreators.doDeleteOrganisationAdminSuccess());
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      dispatch(actionCreators.doDeleteOrganisationAdminFailure());
    }
  };
