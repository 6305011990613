import { axiosInfraInstance } from '.';

export const GetFirePanelStatus = async (partnerId, organisationId) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/firepanels/isEnabled`;
  return await axiosInfraInstance({ method: 'Get', url: url });
};

export const SetFirePanelStatus = async (partnerId, organisationId, payload) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/firepanels/setEnabled`;
  return await axiosInfraInstance({ method: 'Post', url: url, data: payload });
};

export const GetFirePanelList = async (partnerId, organisationId) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}/firepanels`;
  return await axiosInfraInstance({ method: 'Get', url: url });
};

export const GetFirePanelDetail = async (partnerId, organisationId, firePanelId) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/firepanels/${firePanelId}/meshIOs`;
  return await axiosInfraInstance({ method: 'Get', url: url });
};

export const CreateFirePanel = async (partnerId, networkId, organisationId, payload) => {
  const url = `/infrastructureManagement/v1/networks/${networkId}/firepanels`;
  return await axiosInfraInstance({ method: 'Post', url: url, data: payload });
};

export const EditFirePanel = async (partnerId, organisationId, firePanelId, payload) => {
  const url = `/infrastructureManagement/v1/firepanels/${firePanelId}`;
  return await axiosInfraInstance({ method: 'Patch', url: url, data: payload });
};

export const DeleteFirePanel = async (partnerId, organisationId, firepanelId) => {
  const url = `/infrastructureManagement/v1/firepanels/${firepanelId}`;
  return await axiosInfraInstance({ method: 'Delete', url: url });
};

export const DetachMeshIo = async (partnerId, organisationId, firepanelId, serialNumber) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/firepanels/${firepanelId}/meshIOs/${serialNumber}`;
  return await axiosInfraInstance({
    method: 'Post',
    url: url,
    data: {
      deviceStatus: 'detached',
    },
  });
};
