import { handleActions } from 'redux-actions';
import * as ActionCreators from '../actionCreators/controllers';

const defaultState = {
  controllers: [],
};

const controllerReducer = handleActions(
  {
    [ActionCreators.getControllerList]: (state, { payload }) => {
      return {
        ...state,
        controllers: payload,
      };
    },
  },
  defaultState,
);

export default controllerReducer;
