import AUTH from '../constants/auth';

// ------------------ Token --------------------------
// setter
export const setAuthorizationToken = (token) => {
  localStorage.setItem(AUTH.TOKEN, token);
};

// getter
export const getAuthorizationToken = () => {
  return localStorage.getItem(AUTH.TOKEN);
};

// remove
export const removeAuthorizationToken = () => {
  localStorage.removeItem(AUTH.TOKEN);
};
