import { Fragment } from 'react';

// routes
import { useHistory } from 'react-router-dom';
import routes from '../../../constants/routes';

// components
import CompanyLogo from '../../../assets/images/logo.png';
import UILink from '../../../components/Buttons/UILink';
import ResetPasswordForm from './ResetPasswordForm';

// styles
import styles from '../styles.module.scss';

// redux
import { useDispatch } from 'react-redux';
import { doResetPassword } from '../../../redux/thunks/authentication';
import Footer from '../../../components/Footer';

const ResetPassword = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const displayLeftSection = () => (
    <div className={styles['left_section']}>
      <div className={styles['login_image']}></div>
    </div>
  );

  const handlesubmit = (values) => {
    dispatch(doResetPassword(values));
  };

  const displayRightSection = () => (
    <div className={styles['right_section']}>
      <div className={styles['logo_container']}>
        <img src={CompanyLogo} alt="Spintly" />
        <h2> | </h2>
        <h1> Partner</h1>
      </div>
      <div className={'ml-2'}>
        <h2 className={styles['secodary_header']}>You will receive the OTP on your email id.</h2>
        <ResetPasswordForm handleResetPassword={handlesubmit} />
        <div className="mt-8">
          <UILink
            text="Login"
            disabled={false}
            handleSubmit={() => history.push(routes.public.login)}
          />
        </div>

        <Footer />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className={styles['container']}>
        {displayLeftSection()}
        {displayRightSection()}
      </div>
    </Fragment>
  );
};

export default ResetPassword;
