export const USER_LIST_READ_REQUESTED = 'USER_LIST_READ_REQUESTED';
export const USER_LIST_READ_SUCCEEDED = 'USER_LIST_READ_SUCCEEDED';
export const USER_LIST_READ_FAILED = 'USER_LIST_READ_FAILED';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const USER_CREATE_REQUESTED = 'USER_CREATE_REQUESTED';
export const USER_CREATE_SUCCEEDED = 'USER_CREATE_SUCCEEDED';
export const USER_CREATE_FAILED = 'USER_CREATE_FAILED';

export const USER_UPDATE_REQUESTED = 'USER_UPDATE_REQUESTED';
export const USER_UPDATE_SUCCEEDED = 'USER_UPDATE_SUCCEEDED';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';

export const USER_DELETE_REQUESTED = 'USER_DELETE_REQUESTED';
export const USER_DELETE_SUCCEEDED = 'USER_DELETE_SUCCEEDED';
export const USER_DELETE_FAILED = 'USER_DELETE_FAILED';
export const ERROR_MESSAGE_READ_SUCCEEDED = 'auth/ERROR_MESSAGE_READ_SUCCEEDED';
