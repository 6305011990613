import { createTheme } from '@material-ui/core/styles';

const current = process.env.REACT_APP_THEME;

const theme = {
  palette: {
    type: current,
    primary: {
      light: '#f18656',
      main: '#f46526',
      dark: '#cf480d',
      contrastText: '#fff',
    },
    secondary: {
      light: '#737373',
      main: '#363535',
      dark: '#141414',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: '#fff',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&:hover': {
          outline: 'none',
        },
        '&:active': {
          outline: 'none',
        },
      },
    },
  },
};

const muiTheme = createTheme(theme);

export default muiTheme;
