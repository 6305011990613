export const ORGANISATION_LIST_READ_REQUESTED = 'ORGANISATION_LIST_READ_REQUESTED';
export const ORGANISATION_LIST_READ_SUCCEEDED = 'ORGANISATION_LIST_READ_SUCCEEDED';
export const ORGANISATION_LIST_READ_FAILED = 'ORGANISATION_LIST_READ_FAILED';
export const ORGANISATION_LIST_RESET = 'ORGANISATION_LIST_RESET';

export const ORGANISATION_CREATE_REQUESTED = 'ORGANISATION_CREATE_REQUESTED';
export const ORGANISATION_CREATE_SUCCEEDED = 'ORGANISATION_CREATE_SUCCEEDED';
export const ORGANISATION_CREATE_FAILED = 'ORGANISATION_CREATE_FAILED';

export const ORGANISATION_UPDATE_REQUESTED = 'ORGANISATION_UPDATE_REQUESTED';
export const ORGANISATION_UPDATE_SUCCEEDED = 'ORGANISATION_UPDATE_SUCCEEDED';
export const ORGANISATION_UPDATE_FAILED = 'ORGANISATION_UPDATE_FAILED';

export const ORGANISATION_DELETE_REQUESTED = 'ORGANISATION_DELETE_REQUESTED';
export const ORGANISATION_DELETE_SUCCEEDED = 'ORGANISATION_DELETE_SUCCEEDED';
export const ORGANISATION_DELETE_FAILED = 'ORGANISATION_DELETE_FAILED';

export const ORGANISATION_READ_REQUESTED = 'ORGANISATION_READ_REQUESTED';
export const ORGANISATION_READ_SUCCEEDED = 'ORGANISATION_READ_SUCCEEDED';
export const ORGANISATION_READ_FAILED = 'ORGANISATION_READ_FAILED';
export const ORGANISATION_RESET = 'ORGANISATION_RESET';

export const ORGANISATION_SITES_READ_REQUESTED = 'ORGANISATION_SITES_READ_REQUESTED';
export const ORGANISATION_SITES_READ_SUCCEEDED = 'ORGANISATION_SITES_READ_SUCCEEDED';
export const ORGANISATION_SITES_READ_FAILED = 'ORGANISATION_SITES_READ_FAILED';
export const ORGANISATION_SITES_RESET = 'ORGANISATION_SITES_RESET';

export const ORGANISATION_GATEWAYS_READ_REQUESTED = 'ORGANISATION_GATEWAYS_READ_REQUESTED';
export const ORGANISATION_GATEWAYS_READ_SUCCEEDED = 'ORGANISATION_GATEWAYS_READ_SUCCEEDED';
export const ORGANISATION_GATEWAYS_READ_FAILED = 'ORGANISATION_GATEWAYS_READ_FAILED';
export const ORGANISATION_GATEWAYS_RESET = 'ORGANISATION_GATEWAYS_RESET';

export const ORGANISATION_ACCESS_POINTS_READ_REQUESTED =
  'ORGANISATION_ACCESS_POINTS_READ_REQUESTED';
export const ORGANISATION_ACCESS_POINTS_READ_SUCCEEDED =
  'ORGANISATION_ACCESS_POINTS_READ_SUCCEEDED';
export const ORGANISATION_ACCESS_POINTS_READ_FAILED = 'ORGANISATION_ACCESS_POINTS_READ_FAILED';
export const ORGANISATION_ACCESS_POINTS_RESET = 'ORGANISATION_ACCESS_POINTS_RESET';

export const ORGANISATION_ADMINS_READ_REQUESTED = 'ORGANISATION_ADMINS_READ_REQUESTED';
export const ORGANISATION_ADMINS_READ_SUCCEEDED = 'ORGANISATION_ADMINS_READ_SUCCEEDED';
export const ORGANISATION_ADMINS_READ_FAILED = 'ORGANISATION_ADMINS_READ_FAILED';
export const ORGANISATION_ADMINS_RESET = 'ORGANISATION_ADMINS_RESET';

export const ORGANISATION_ACUS_READ_REQUESTED = 'ORGANISATION_ACUS_READ_REQUESTED';
export const ORGANISATION_ACUS_READ_SUCCEEDED = 'ORGANISATION_ACUS_READ_SUCCEEDED';
export const ORGANISATION_ACUS_READ_FAILED = 'ORGANISATION_ACUS_READ_FAILED';
export const ORGANISATION_ACUS_RESET = 'ORGANISATION_ACUS_RESET';

export const ORGANISATION_REPEATERS_READ_REQUESTED = 'ORGANISATION_REPEATERS_READ_REQUESTED';
export const ORGANISATION_REPEATERS_READ_SUCCEEDED = 'ORGANISATION_REPEATERS_READ_SUCCEEDED';
export const ORGANISATION_REPEATERS_READ_FAILED = 'ORGANISATION_REPEATERS_READ_FAILED';
export const ORGANISATION_REPEATERS_RESET = 'ORGANISATION_REPEATERS_RESET';

export const ORGANISATION_GATEWAY_LOG_READ_REQUESTED = 'ORGANISATION_GATEWAY_LOG_READ_REQUESTED';
export const ORGANISATION_GATEWAY_LOG_READ_SUCCEEDED = 'ORGANISATION_GATEWAY_LOG_READ_SUCCEEDED';
export const ORGANISATION_GATEWAY_LOG_READ_FAILED = 'ORGANISATION_GATEWAY_LOG_READ_FAILED';
export const ORGANISATION_GATEWAY_LOG_RESET = 'ORGANISATION_GATEWAY_LOG_RESET';

export const ORGANISATION_ACU_LOG_READ_REQUESTED = 'ORGANISATION_ACU_LOG_READ_REQUESTED';
export const ORGANISATION_ACU_LOG_READ_SUCCEEDED = 'ORGANISATION_ACU_LOG_READ_SUCCEEDED';
export const ORGANISATION_ACU_LOG_READ_FAILED = 'ORGANISATION_ACU_LOG_READ_FAILED';
export const ORGANISATION_ACU_LOG_RESET = 'ORGANISATION_ACU_LOG_RESET';

export const ORGANISATION_SITE_CREATE_REQUESTED = 'ORGANISATION_SITE_CREATE_REQUESTED';
export const ORGANISATION_SITE_CREATE_SUCCEEDED = 'ORGANISATION_SITE_CREATE_SUCCEEDED';
export const ORGANISATION_SITE_CREATE_FAILED = 'ORGANISATION_SITE_CREATE_FAILED';

export const ORGANISATION_SITE_UPDATE_REQUESTED = 'ORGANISATION_SITE_UPDATE_REQUESTED';
export const ORGANISATION_SITE_UPDATE_SUCCEEDED = 'ORGANISATION_SITE_UPDATE_SUCCEEDED';
export const ORGANISATION_SITE_UPDATE_FAILED = 'ORGANISATION_SITE_UPDATE_FAILED';

export const ORGANISATION_SITE_DELETE_REQUESTED = 'ORGANISATION_SITE_DELETE_REQUESTED';
export const ORGANISATION_SITE_DELETE_SUCCEEDED = 'ORGANISATION_SITE_DELETE_SUCCEEDED';
export const ORGANISATION_SITE_DELETE_FAILED = 'ORGANISATION_SITE_DELETE_FAILED';

export const ORGANISATION_ACCESS_POINT_CREATE_REQUESTED =
  'ORGANISATION_ACCESS_POINT_CREATE_REQUESTED';
export const ORGANISATION_ACCESS_POINT_CREATE_SUCCEEDED =
  'ORGANISATION_ACCESS_POINT_CREATE_SUCCEEDED';
export const ORGANISATION_ACCESS_POINT_CREATE_FAILED = 'ORGANISATION_ACCESS_POINT_CREATE_FAILED';

export const ORGANISATION_ACCESS_POINT_UPDATE_REQUESTED =
  'ORGANISATION_ACCESS_POINT_UPDATE_REQUESTED';
export const ORGANISATION_ACCESS_POINT_UPDATE_SUCCEEDED =
  'ORGANISATION_ACCESS_POINT_UPDATE_SUCCEEDED';
export const ORGANISATION_ACCESS_POINT_UPDATE_FAILED = 'ORGANISATION_ACCESS_POINT_UPDATE_FAILED';

export const ORGANISATION_ACCESS_POINT_DELETE_REQUESTED =
  'ORGANISATION_ACCESS_POINT_DELETE_REQUESTED';
export const ORGANISATION_ACCESS_POINT_DELETE_SUCCEEDED =
  'ORGANISATION_ACCESS_POINT_DELETE_SUCCEEDED';
export const ORGANISATION_ACCESS_POINT_DELETE_FAILED = 'ORGANISATION_ACCESS_POINT_DELETE_FAILED';

export const ORGANISATION_GATEWAY_CREATE_REQUESTED = 'ORGANISATION_GATEWAY_CREATE_REQUESTED';
export const ORGANISATION_GATEWAY_CREATE_SUCCEEDED = 'ORGANISATION_GATEWAY_CREATE_SUCCEEDED';
export const ORGANISATION_GATEWAY_CREATE_FAILED = 'ORGANISATION_GATEWAY_CREATE_FAILED';

export const ORGANISATION_GATEWAY_UPDATE_REQUESTED = 'ORGANISATION_GATEWAY_UPDATE_REQUESTED';
export const ORGANISATION_GATEWAY_UPDATE_SUCCEEDED = 'ORGANISATION_GATEWAY_UPDATE_SUCCEEDED';
export const ORGANISATION_GATEWAY_UPDATE_FAILED = 'ORGANISATION_GATEWAY_UPDATE_FAILED';

export const ORGANISATION_GATEWAY_DELETE_REQUESTED = 'ORGANISATION_GATEWAY_DELETE_REQUESTED';
export const ORGANISATION_GATEWAY_DELETE_SUCCEEDED = 'ORGANISATION_GATEWAY_DELETE_SUCCEEDED';
export const ORGANISATION_GATEWAY_DELETE_FAILED = 'ORGANISATION_GATEWAY_DELETE_FAILED';

export const ORGANISATION_REPEATER_CREATE_REQUESTED = 'ORGANISATION_REPEATER_CREATE_REQUESTED';
export const ORGANISATION_REPEATER_CREATE_SUCCEEDED = 'ORGANISATION_REPEATER_CREATE_SUCCEEDED';
export const ORGANISATION_REPEATER_CREATE_FAILED = 'ORGANISATION_REPEATER_CREATE_FAILED';

export const ORGANISATION_REPEATER_UPDATE_REQUESTED = 'ORGANISATION_REPEATER_UPDATE_REQUESTED';
export const ORGANISATION_REPEATER_UPDATE_SUCCEEDED = 'ORGANISATION_REPEATER_UPDATE_SUCCEEDED';
export const ORGANISATION_REPEATER_UPDATE_FAILED = 'ORGANISATION_REPEATER_UPDATE_FAILED';

export const ORGANISATION_REPEATER_DELETE_REQUESTED = 'ORGANISATION_REPEATER_DELETE_REQUESTED';
export const ORGANISATION_REPEATER_DELETE_SUCCEEDED = 'ORGANISATION_REPEATER_DELETE_SUCCEEDED';
export const ORGANISATION_REPEATER_DELETE_FAILED = 'ORGANISATION_REPEATER_DELETE_FAILED';

export const ORGANISATION_ADMIN_CREATE_REQUESTED = 'ORGANISATION_ADMIN_CREATE_REQUESTED';
export const ORGANISATION_ADMIN_CREATE_SUCCEEDED = 'ORGANISATION_ADMIN_CREATE_SUCCEEDED';
export const ORGANISATION_ADMIN_CREATE_FAILED = 'ORGANISATION_ADMIN_CREATE_FAILED';

export const ORGANISATION_ADMIN_UPDATE_REQUESTED = 'ORGANISATION_ADMIN_UPDATE_REQUESTED';
export const ORGANISATION_ADMIN_UPDATE_SUCCEEDED = 'ORGANISATION_ADMIN_UPDATE_SUCCEEDED';
export const ORGANISATION_ADMIN_UPDATE_FAILED = 'ORGANISATION_ADMIN_UPDATE_FAILED';

export const ORGANISATION_ADMIN_DELETE_REQUESTED = 'ORGANISATION_ADMIN_DELETE_REQUESTED';
export const ORGANISATION_ADMIN_DELETE_SUCCEEDED = 'ORGANISATION_ADMIN_DELETE_SUCCEEDED';
export const ORGANISATION_ADMIN_DELETE_FAILED = 'ORGANISATION_ADMIN_DELETE_FAILED';
