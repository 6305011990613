// styles
import styles from './styles.module.scss';

// packages
import classnames from 'classnames';
import PropTypes from 'prop-types';

const types = ['primary', 'disabled', 'secondary', 'danger'];
const sizes = ['button', 'button-small', 'button-smaller'];

export const Button = ({
  buttonType = 'primary',
  text = '',
  disabled = false,
  shadow = true,
  buttonSize = 'button',
  ...props
}) => {
  const disabledStyles = {
    opacity: 0.5,
    cursor: 'not-allowed',
  };

  return (
    <button
      style={disabled ? disabledStyles : {}}
      className={classnames(
        styles[buttonSize],
        styles[`${buttonType}`],
        shadow && styles[`shadow`],
      )}
      disabled={disabled}
      {...props}
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  buttonType: PropTypes.oneOf(types),
  buttonSize: PropTypes.oneOf(sizes),
  text: PropTypes.string,
  disabled: PropTypes.bool,
  shadow: PropTypes.bool,
};

export default Button;
