import { axiosInfraInstance } from '.';

export const GetMeshList = async (partnerId, organisationId) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}/meshios`;
  return await axiosInfraInstance({ method: 'Get', url: url });
};

export const AddMesh = async (partnerId, organisationId, networkId, payload) => {
  const url = `/infrastructureManagement/v1/networks/${networkId}/meshios`;
  return await axiosInfraInstance({ method: 'Post', url: url, data: payload });
};

export const GetMeshIOPins = async (partnerId, organisationId, serialNumber) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/meshIOs/${serialNumber}/meshIOPins`;
  return await axiosInfraInstance({ method: 'Get', url: url });
};

export const GetMeshIoUnderSite = async (partnerId, organisationId, siteId) => {
  const url = `/infrastructureManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/organisations/${organisationId}/sites/${siteId}/meshIOs`;
  return await axiosInfraInstance({ method: 'Get', url: url });
};

export const DeleteMesh = async (partnerId, organisationId, serialNumber) => {
  const url = `/infrastructureManagement/v1/meshios/${serialNumber}`;
  return await axiosInfraInstance({ method: 'Delete', url: url });
};
