import { axiosInfraInstance } from '.';

export const GetControllerList = async (partnerId, organisationId) => {
  const url = `/infrastructureManagement/v1/organisations/${organisationId}/accessPoints`;
  return await axiosInfraInstance({ method: 'Get', url: url });
};

export const AddController = async (partnerId, organisationId, payload, networkId) => {
  const url = `/infrastructureManagement/v1/networks/${networkId}/accessPoints`;
  return await axiosInfraInstance({ method: 'Post', url: url, data: payload });
};
export const EditController = async (partnerId, organisationId, payload, networkId, id) => {
  const url = `/infrastructureManagement/v1/accessPoints/${id}`;
  return await axiosInfraInstance({ method: 'Patch', url: url, data: payload });
};

export const DeleteController = async (partnerId, organisationId, controllerId) => {
  const url = `/infrastructureManagement/v1/accessPoints/${controllerId}`;
  return await axiosInfraInstance({ method: 'Delete', url: url });
};
