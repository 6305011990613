import { isEmpty } from 'voca';
import * as ActionCreators from '../actionCreators/firepanel';
const defaultState = {
  firePanelStatus: false,
  firePanelList: [],
};

const FirepanelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionCreators.setFirePanelStatus().type:
      return {
        ...state,
        firePanelStatus: action.payload,
      };
    case ActionCreators.setFirePanelList().type:
      return {
        ...state,
        firePanelList: !isEmpty(action?.payload) ? action?.payload : [],
      };
    default:
      return state;
  }
};

export default FirepanelReducer;
