import React, { Fragment, useCallback, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../../constants/routes';
import timezones from 'countries-and-timezones';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentPartner } from '../../../redux/selectors/authentication';
import { doCreateNotification } from '../../../redux/actionCreators/miscellaneous';
import { doCreateOrganisation } from '../../../redux/thunks/organisation';

import {
  emailVerification,
  phonenumberVerification,
  textVerification,
} from '../../../utils/formValdations';

// components
import TextInput from '../../../components/Inputs/TextInput';
import Dropdown from '../../../components/Inputs/Dropdown';
import PhoneInput from '../../../components/Inputs/PhoneInput';
import CountryDropdown from '../../../components/Inputs/CountryDropdown';
import { selectIsLoading } from '../../../redux/selectors/miscellaneous';
import { getCountryCode } from '../../../utils/countryCode';
import classnames from 'classnames';
import Button from '../../../components/Buttons/Button';

// styles
import style from './style.module.scss';
import styles from '../../../styles/modals.module.scss';

// Data
import ORG_TYPE_LIST from '../../../constants/list/OrganisationTypeList';
import ACCOUNT_TYPE_LIST from '../../../constants/list/AccountTypeList';
import SelectIntegrator from './SelectIntegrator';
import { Box, IconButton, Typography } from '@material-ui/core';
import SquareDelete from '../../../components/SquareDelete/SquareDelete';
// import SelectIntegrator from './SelectIntegrator';

const errorText = 'Please enter a valid';

const NewCustomer = () => {
  const [customerName, setCustomerName] = useState({ value: '', error: '' });
  const [country, setCountry] = useState('IN');
  const [countryCode, setCountryCode] = useState('91');
  const [siteName, setSiteName] = useState({ value: '', error: '' });
  const [siteLocation, setSiteLocation] = useState({ value: '', error: '' });
  const [networkName, setNetworkName] = useState({ value: '', sName: '', error: '' });
  const [customerType, setCustomerType] = useState({ value: '', error: '' });
  const [accountType, setAccountType] = useState({ value: '', error: '' });
  const [customerAdminName, setcustomerAdminName] = useState({ value: '', error: '' });
  const [customerAdminEmail, setcustomerAdminEmail] = useState({ value: '', error: '' });
  const [customerAdminPhoneNumber, setcustomerAdminPhoneNumber] = useState({
    value: '',
    error: '',
  });
  const [timezone, setTimezone] = useState('');
  const [countryTimezones, setCountryTimezones] = useState([]);
  const [integrator, setIntegrator] = useState('');
  const [admins, setAdmins] = useState([
    { id: '', name: '', phone: '', email: '', adminType: 1, countryCode: countryCode },
  ]);

  const [adminValidationErrors, setAdminValidationErrors] = useState([{}]);
  // const [partnerAdminError, setPartnerAdminError] = useState('');

  const [showSelectIntegrator, setShowSelectIntegrator] = useState(false);

  const isLoading = useSelector(selectIsLoading);

  const dispatch = useDispatch();
  const { currentPartner } = useSelector((state) => ({
    currentPartner: selectCurrentPartner(state),
  }));

  const history = useHistory();

  const maxAdmin = 5;

  useEffect(() => {
    try {
      const code = getCountryCode();
      if (code) {
        setCountryCode(code?.countryCode);
        setCountry(code?.userCountry);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (country) {
      const allTimezones = timezones.getCountry(country);
      if (allTimezones?.timezones) {
        setCountryTimezones(
          allTimezones?.timezones?.map((elem, i) => {
            return { id: i, name: elem };
          }),
        );
      }
    }
  }, [country]);

  useEffect(() => {
    if (currentPartner?.integrator.length > 1) {
      setShowSelectIntegrator(true);
    } else {
      setShowSelectIntegrator(false);
      setIntegrator(currentPartner?.integrator[0]);
    }
  }, [currentPartner?.integrator]);

  const handleCreateOrganisation = useCallback(
    (payload) => {
      dispatch(
        doCreateOrganisation({
          partnerId: currentPartner?.partnerId,
          payload,
          callback: () => {
            history.push(routes.customer.list);
          },
        }),
      );
    },
    [currentPartner?.partnerId, history, dispatch],
  );

  const handleClosePopUp = () => {
    setShowSelectIntegrator(false);
  };

  const handleSubmit = () => {
    if (!integrator) {
      dispatch(
        doCreateNotification({
          isVisible: true,
          message:
            'Partner does not have an integrator. Please add an integrator before proceeding',
        }),
      );
      return false;
    }
    // const phoneNumber = `+${countryCode}${adminPhoneNumber.value}`;
    const customerPhoneNumber = `+${countryCode}${customerAdminPhoneNumber.value}`;

    let adminsDetails;

    if (
      customerAdminName.value !== '' ||
      customerAdminPhoneNumber.value !== '' ||
      customerAdminEmail.value !== ''
    ) {
      adminsDetails = [
        ...admins,
        {
          name: customerAdminName.value,
          phone: customerPhoneNumber,
          email: customerAdminEmail.value,
          adminType: 2,
        },
      ];
    } else {
      adminsDetails = admins;
    }

    const adminsDetailsWithCountryCode = adminsDetails.map((admin) => {
      // Check if the admin has a country code and phone number
      if (countryCode && admin.phone) {
        // Assuming countryCode is in the format '+1' or '1', adjust as needed
        return {
          ...admin,
          phone: `+${countryCode}${admin.phone}`,
        };
      } else {
        return admin; // If no country code or phone number, leave it as is
      }
    });

    const payload = {
      integratorId: integrator ? integrator?.id : 0,
      partnerId: currentPartner?.partnerId,
      name: customerName.value,
      type: customerType.value,
      accountType: accountType.value,
      country: country,
      admins: adminsDetailsWithCountryCode,
      sites: [
        {
          name: siteName.value,
          location: siteLocation.value,
          timezone: timezone,
          networks: [
            {
              name: networkName.value,
            },
          ],
        },
      ],
    };

    console.log(payload);

    handleCreateOrganisation(payload);
  };

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;

    let error = '';
    switch (name) {
      case 'customerName':
        error = textVerification(value, 2) ? `${errorText} customer name` : '';
        setCustomerName({ value, error });
        break;
      case 'siteName':
        error = textVerification(value, 2) ? `${errorText} site name` : '';
        setSiteName({ value, error });
        break;
      case 'siteLocation':
        error = textVerification(value, 2) ? `${errorText} site location` : '';
        setSiteLocation({ value, error });
        break;
      case 'networkName':
        error = textVerification(value, 2) ? `${errorText} network name` : '';
        const nName = value.includes(siteName.value)
          ? value
          : siteName.value + ' - ' + value.slice(siteName.value.length + 1);
        if (nName.includes(siteName.value + ' - ')) {
          setNetworkName({ value: nName, error });
        } else {
          // Prevent further backspacing if siteName and '-' are not present
          setNetworkName({
            value: siteName.value + ' - ' + nName.slice(siteName.value.length + 3),
            error,
          });
        }
        break;
      case 'customerType':
        error = textVerification(customerType, 2) ? `${errorText} customer type` : '';
        setCustomerType({ value, error });
        break;
      case 'customerAdminName':
        error = textVerification(value, 2) ? `${errorText} admin name` : '';
        setcustomerAdminName({ value, error });
        break;
      case 'customerAdminEmail':
        error = !emailVerification(value) ? `${errorText} admin email` : '';
        setcustomerAdminEmail({ value, error });
        break;
      case 'customerAdminPhoneNumber':
        error = !phonenumberVerification(`+${countryCode}${value}`)
          ? `${errorText} phone number`
          : '';
        setcustomerAdminPhoneNumber({ value, error });
        break;
      default:
        break;
    }
  };

  const handleValidation = (name, value, code) => {
    let error = '';
    switch (name) {
      case 'name':
        error = textVerification(value, 2) ? `${errorText} admin name` : '';
        break;
      case 'email':
        error = !emailVerification(value) ? `${errorText} admin email` : '';
        break;
      case 'phone':
        error = !phonenumberVerification(`+${code}${value}`) ? `${errorText} phone number` : '';
        break;
      default:
        break;
    }

    return error;
  };

  const hasError = useMemo(() => {
    if (
      accountType.value === '' ||
      accountType.error !== '' ||
      // customerType.value === '' ||
      // customerType.error !== '' ||
      siteName.value === '' ||
      siteName.error !== '' ||
      admins.some((admin) => admin.name === '' || admin.email === '' || admin.phone === '') ||
      adminValidationErrors.some(
        (admin) => admin.name !== '' || admin.email !== '' || admin.phone !== '',
      ) ||
      // partnerAdminError !== '' ||
      siteLocation.value === '' ||
      siteLocation.error !== '' ||
      networkName.value === '' ||
      networkName.error !== '' ||
      timezone === ''
    ) {
      return true;
    }
    return false;
  }, [
    accountType.error,
    accountType.value,
    // customerType.error,
    // customerType.value,
    siteLocation.error,
    siteLocation.value,
    admins,
    adminValidationErrors,
    // partnerAdminError,
    siteName.error,
    siteName.value,
    networkName.error,
    networkName.value,
    timezone,
  ]);

  const handleMultifieldChange = (e, index) => {
    const { value, name } = e.target; // Use e.target directly instead of e.currentTarget
    const code = admins[index].countryCode;
    console.log(code);
    const errorTexts = handleValidation(name, value, code);

    setAdminValidationErrors((prevErrors) => {
      const newErrors = [...prevErrors]; // Create a new array to avoid mutating the state directly
      newErrors[index] = { ...newErrors[index], [name]: errorTexts }; // Set the error message for the specific admin and field
      return newErrors;
    });
    setAdmins((oldAdmins) => {
      const newAdmins = [...oldAdmins];
      newAdmins[index] = {
        ...newAdmins[index],
        [name]: value, // Update the specific field in the admin object
      };
      return newAdmins;
    });
  };

  const HandlePartnerAdminCountryCode = (code, index) => {
    setAdmins((oldAdmins) => {
      const newAdmins = [...oldAdmins];
      newAdmins[index] = {
        ...newAdmins[index],
        countryCode: code,
      };
      return newAdmins;
    });
  };

  const handleNetworkAddRemove = (type, index) => {
    if (type === 'add') {
      setAdmins((oldState) => {
        return [
          ...oldState,
          { id: '', name: '', phone: '', email: '', adminType: 1, countryCode: '91' },
        ];
      });
    } else {
      setAdmins((oldState) => {
        const newState = [...oldState];
        newState.splice(index, 1);
        return newState;
      });
      setAdminValidationErrors((oldErrors) => {
        const newErrors = [...oldErrors];
        newErrors.splice(index, 1);
        return newErrors;
      });
    }
  };

  return (
    <Fragment>
      <div className="spacer__0-5" />
      <div>
        {currentPartner?.integrator.length > 1 ? (
          <SelectIntegrator
            show={showSelectIntegrator}
            togglePopup={handleClosePopUp}
            list={currentPartner?.integrator}
            setIntegrator={setIntegrator}
          />
        ) : (
          <></>
        )}
        <div>
          <div className={style[`header-container`]}>
            <p className={style[`header`]}>Add Customer</p>
          </div>
          <div>
            <div className="box ">
              <div className="mt-1">
                <div className={style['container']}>
                  <div className={style['left_section']}>
                    <div className={style['wrapper']}>
                      <h3 className={style['small-heading']}>Basic Details</h3>
                      <TextInput
                        name="customerName"
                        placeholder="Customer Name"
                        onChange={handleChange}
                        disabled={isLoading}
                        error={customerName.error}
                      />
                      <Dropdown
                        name="accountType"
                        placeholder="Account Type"
                        value={accountType.value}
                        data={ACCOUNT_TYPE_LIST}
                        onChange={(data) => setAccountType({ value: data?.type, error: '' })}
                        disabled={isLoading}
                      />

                      <div className={styles['dropDown__inputs__container']}>
                        <div className={styles['row']}>
                          <CountryDropdown
                            name="country"
                            placeholder="Country"
                            value={country}
                            onChange={(value) => {
                              setCountry(value);
                              setTimezone('');
                            }}
                            fullWidth={true}
                          />
                        </div>
                        <div className={styles['row']}>
                          <Dropdown
                            name="customerType"
                            placeholder="Customer Type"
                            value={customerType.value}
                            data={ORG_TYPE_LIST}
                            onChange={(data) => setCustomerType({ value: data?.type, error: '' })}
                            disabled={isLoading}
                          />
                        </div>
                      </div>
                      <div className={style['spacing']}></div>
                      <h3 className={style['small-heading']}>Partner Admin Details</h3>

                      <div className={style['overflowControl']}>
                        {admins.map((admin, index) => {
                          let fieldType = 1; // without add/remove
                          if (maxAdmin > 1 && index > 0) {
                            fieldType = 3; // with remove
                          } else if (maxAdmin > 1 && admins.length !== maxAdmin && index === 0) {
                            fieldType = 2; // with add
                          }
                          let field;
                          switch (fieldType) {
                            case 1:
                              field = (
                                <div
                                  className={classnames(styles['multi-field-item'])}
                                  key={'network-' + index}
                                >
                                  <div className={styles['multi-field-item-input-wrapper']}>
                                    {/* <Box marginTop={3}>
                                      <Typography>Partner admin {index + 1}</Typography>
                                    </Box> */}
                                    <Box display="flex" width="100%" justifyContent="space-between">
                                      <Box display="flex" flexDirection="column">
                                        <TextInput
                                          name="name"
                                          placeholder="Partner Name"
                                          value={admin.name}
                                          onChange={(e) => handleMultifieldChange(e, index)}
                                          disabled={isLoading}
                                          // error={handleValidation('name', admin.name)}
                                        />
                                        <span style={{ color: 'red' }}>
                                          {adminValidationErrors[index]?.name}
                                        </span>
                                      </Box>
                                      <Box marginLeft={3} />
                                      <Box display="flex" flexDirection="column">
                                        <PhoneInput
                                          name="phone"
                                          placeholder="Partner Phone Number"
                                          type="number"
                                          defaultCountryCode={countryCode}
                                          value={admin.phone}
                                          onChange={(e) => handleMultifieldChange(e, index)}
                                          disabled={isLoading}
                                          // error={adminPhoneNumber.error}
                                          // error={handleValidation(
                                          //   'phone',
                                          //   admin.phone,
                                          //   admin.countryCode,
                                          // )}
                                          handleCountryCode={(code) =>
                                            HandlePartnerAdminCountryCode(code, index)
                                          }
                                        />
                                        <span style={{ color: 'red' }}>
                                          {adminValidationErrors[index]?.phone}
                                        </span>
                                      </Box>
                                    </Box>
                                    <TextInput
                                      name="email"
                                      placeholder="Partner Email Address"
                                      type="email"
                                      value={admin.email}
                                      onChange={(e) => handleMultifieldChange(e, index)}
                                      disabled={isLoading}
                                      // error={handleValidation('email', admin.email)}

                                      // error={adminEmail.error}
                                    />
                                    <span style={{ color: 'red' }}>
                                      {adminValidationErrors[index]?.email}
                                    </span>
                                  </div>
                                  <div className={style['divider']}></div>
                                </div>
                              );
                              break;
                            case 2:
                              field = (
                                <div
                                  className={classnames(
                                    styles['multi-field-item'],
                                    styles['multi-field-item-add'],
                                  )}
                                  key={'network-' + index}
                                >
                                  <div className={styles['multi-field-item-input-wrapper']}>
                                    {/* <Box marginTop={3}>
                                      <Typography>Partner admin {index + 1}</Typography>
                                    </Box> */}
                                    <Box display="flex" width="100%" justifyContent="space-between">
                                      <Box display="flex" flexDirection="column">
                                        <TextInput
                                          name="name"
                                          placeholder="Partner Name"
                                          value={admin.name}
                                          onChange={(e) => handleMultifieldChange(e, index)}
                                          disabled={isLoading}
                                          // error={handleValidation('name', admin.name)}
                                        />
                                        <span style={{ color: 'red' }}>
                                          {adminValidationErrors[index]?.name}
                                        </span>
                                      </Box>
                                      <Box marginLeft={3} />
                                      <Box display="flex" flexDirection="column">
                                        <PhoneInput
                                          name="phone"
                                          placeholder="Partner Phone Number"
                                          type="number"
                                          defaultCountryCode={countryCode}
                                          value={admin.phone}
                                          onChange={(e) => handleMultifieldChange(e, index)}
                                          disabled={isLoading}
                                          // error={adminPhoneNumber.error}
                                          // error={handleValidation(
                                          //   'phone',
                                          //   admin.phone,
                                          //   admin.countryCode,
                                          // )}
                                          handleCountryCode={(code) =>
                                            HandlePartnerAdminCountryCode(code, index)
                                          }
                                        />
                                        <span style={{ color: 'red' }}>
                                          {adminValidationErrors[index]?.phone}
                                        </span>
                                      </Box>
                                    </Box>

                                    <TextInput
                                      name="email"
                                      placeholder="Partner Email Address"
                                      type="email"
                                      value={admin.email}
                                      onChange={(e) => handleMultifieldChange(e, index)}
                                      disabled={isLoading}
                                      // error={handleValidation('email', admin.email)}

                                      // error={adminEmail.error}
                                    />
                                    <span style={{ color: 'red' }}>
                                      {adminValidationErrors[index]?.email}
                                    </span>
                                  </div>
                                  <div className={style['divider']}></div>
                                </div>
                              );
                              break;
                            default:
                              field = (
                                <div
                                  className={classnames(
                                    styles['multi-field-item'],
                                    styles['multi-field-item-remove'],
                                  )}
                                  key={'network-' + index}
                                >
                                  <div className={styles['multi-field-item-input-wrapper']}>
                                    {/* <Box marginTop={3}>
                                      <Typography>Partner admin {index + 1}</Typography>
                                    </Box> */}
                                    <Box display="flex" width="100%" justifyContent="space-between">
                                      <Box display="flex" flexDirection="column">
                                        <TextInput
                                          name="name"
                                          placeholder="Partner Name"
                                          value={admin.name}
                                          onChange={(e) => handleMultifieldChange(e, index)}
                                          disabled={isLoading}
                                          // error={handleValidation('name', admin.name)}
                                        />
                                        <span style={{ color: 'red' }}>
                                          {adminValidationErrors[index]?.name}
                                        </span>
                                      </Box>
                                      <Box marginLeft={3} />
                                      <Box display="flex" flexDirection="column">
                                        <PhoneInput
                                          name="phone"
                                          placeholder="Partner Phone Number"
                                          type="number"
                                          defaultCountryCode={countryCode}
                                          value={admin.phone}
                                          onChange={(e) => handleMultifieldChange(e, index)}
                                          disabled={isLoading}
                                          // error={adminPhoneNumber.error}
                                          // error={handleValidation(
                                          //   'phone',
                                          //   admin.phone,
                                          //   admin.countryCode,
                                          // )}
                                          handleCountryCode={(code) =>
                                            HandlePartnerAdminCountryCode(code, index)
                                          }
                                        />
                                        <span style={{ color: 'red' }}>
                                          {adminValidationErrors[index]?.phone}
                                        </span>
                                      </Box>
                                    </Box>
                                    <TextInput
                                      name="email"
                                      placeholder="Partner Email Address"
                                      type="email"
                                      value={admin.email}
                                      onChange={(e) => handleMultifieldChange(e, index)}
                                      disabled={isLoading}
                                      // error={adminEmail.error}
                                      // error={handleValidation('email', admin.email)}
                                    />
                                    <span style={{ color: 'red' }}>
                                      {adminValidationErrors[index]?.email}
                                    </span>
                                  </div>
                                  <Box width="95%" display="flex" justifyContent="flex-end">
                                    <IconButton
                                      className={styles['multi-field-remove-button']}
                                      size="medium"
                                      aria-label="Remove"
                                      onClick={() => {
                                        handleNetworkAddRemove('remove', index);
                                      }}
                                      // disabled={true}
                                    >
                                      <SquareDelete />
                                    </IconButton>
                                  </Box>
                                  <div className={style['divider']}></div>
                                </div>
                              );
                              break;
                          }
                          return field;
                        })}
                        <Box marginTop={3} width="100%" display="flex" justifyContent="flex-end">
                          {/* <Buttons
                            className={styles['multi-field-add-button']}
                            variant="outlined"
                            startIcon={<AddCircleIcon />}
                            onClick={() => {
                              handleNetworkAddRemove('add');
                            }}
                            // disabled={true}
                          >
                            Add
                          </Buttons> */}
                          <Typography
                            variant="body2"
                            style={{ color: '#343e94', marginTop: '10px' }}
                          >
                            You can add a maximum of 5 partner admins
                          </Typography>
                          <Box marginLeft={3} />

                          <Button
                            disabled={admins.length === maxAdmin}
                            onClick={() => {
                              handleNetworkAddRemove('add');
                            }}
                            text="Add"
                            buttonSize="button-smaller"
                          />
                        </Box>
                      </div>

                      <div className={style['spacing']}></div>
                      <h3 className={style['small-heading']}>
                        Customer Super Admin Details (optional)
                      </h3>
                      <div className={styles['dropDown__inputs__container']}>
                        <div className={styles['row']}>
                          <TextInput
                            name="customerAdminName"
                            placeholder="Super Admin Name"
                            value={customerAdminName.value}
                            onChange={handleChange}
                            disabled={isLoading}
                            error={customerAdminName.error}
                          />
                        </div>
                        <div className={styles['row']}>
                          <PhoneInput
                            name="customerAdminPhoneNumber"
                            placeholder="Super Admin Phone Number"
                            type="number"
                            defaultCountryCode={countryCode}
                            value={customerAdminPhoneNumber.value}
                            onChange={handleChange}
                            disabled={isLoading}
                            error={customerAdminPhoneNumber.error}
                            handleCountryCode={(code) => setCountryCode(code)}
                          />
                        </div>
                      </div>
                      <TextInput
                        name="customerAdminEmail"
                        placeholder="Super Admin Email Address"
                        type="email"
                        value={customerAdminEmail.value}
                        onChange={handleChange}
                        disabled={isLoading}
                        error={customerAdminEmail.error}
                      />
                    </div>
                  </div>
                  <div className={style['right_section']}>
                    <div className={style['wrapper']}>
                      <h3 className={style['small-heading']}>Site Details</h3>
                      <TextInput
                        name="siteName"
                        placeholder="Site Name"
                        value={siteName?.value}
                        onChange={handleChange}
                        disabled={isLoading}
                        error={siteName.error}
                      />
                      <div className={styles['dropDown__inputs__container']}>
                        <div className={styles['row']}>
                          <TextInput
                            name="siteLocation"
                            placeholder="Site Location"
                            value={siteLocation.value}
                            onChange={handleChange}
                            disabled={isLoading}
                            error={siteLocation.error}
                          />
                        </div>
                        <div className={styles['row']}>
                          <Dropdown
                            name="timezone"
                            placeholder="Site Timezone"
                            value={timezone}
                            data={countryTimezones}
                            onChange={(data) => setTimezone(data?.name)}
                          />
                        </div>
                      </div>
                      <TextInput
                        name="networkName"
                        placeholder="Network Name"
                        value={networkName.value}
                        onChange={handleChange}
                        disabled={isLoading}
                        error={networkName.error}
                      />
                    </div>
                  </div>
                </div>

                <div className={style['button-container']}>
                  <Button
                    onClick={() => {
                      history.push(routes.customer.list);
                    }}
                    text="Cancel"
                    buttonSize="button-smaller"
                  />
                  <Button
                    disabled={isLoading || hasError}
                    onClick={handleSubmit}
                    text="Add"
                    buttonSize="button-smaller"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewCustomer;
