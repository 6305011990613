import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
// import MaterialTable, { MTableToolbar } from 'material-table';
import AddMesh from './AddMesh/newAddMesh';
import { useImmer } from 'use-immer';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { doGetMeshList, doDeleteMesh } from '../../../../../redux/thunks/mesh';
import { selectCurrentPartner } from '../../../../../redux/selectors/authentication';
import Table from '../../../../../components/Table/Table';
import Button from '../../../../../components/Buttons/Button';
import SquareDelete from '../../../../../components/SquareDelete/SquareDelete';
import { Search } from '../../../../../utils/Search';
import '../../../../../styles/common.scss';
import { meshConfiguredStateRender, statusText } from '../../../../../utils/Common';
import ModalAlert from '../../../../../components/Modals/ModalAlert';

const MeshIo = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const [showAddMesh, setAddMesh] = useState(false);
  const [filters, setFilters] = useImmer({ name: '', serialNumber: '', siteName: '' });
  const [pagination, setPagination] = useImmer({ total: 0, currentPage: 1, perPage: 7 });
  const [showModal, setShowModal] = useState(false);
  const [rowDataSelected, setRowData] = useState(null);

  const { currentPartner } = useSelector((state) => ({
    currentPartner: selectCurrentPartner(state),
  }));

  const meshData = useSelector((state) => state.mesh.meshIOs);
  const sites = useSelector((state) => state.organisation.sites);

  const getMeshList = useCallback(() => {
    if (currentPartner && customerId) dispatch(doGetMeshList(currentPartner.partnerId, customerId));
  }, [currentPartner, customerId, dispatch]);

  useEffect(() => {
    getMeshList();
  }, [getMeshList]);

  useEffect(() => {
    setPagination((draft) => {
      draft.total = meshData?.length ? meshData?.length : 0;
    });
  }, [meshData?.length, setPagination]);

  const convertToHex = (value) => {
    let hexString = value.toString(16);

    // Ensure the hexadecimal string has at least two characters
    if (hexString.length % 2 !== 0) {
      hexString = '0' + hexString;
    }

    // Split the hexadecimal string into groups of two characters
    const hexGroups = [];
    for (let i = 0; i < hexString.length; i += 2) {
      hexGroups.push(hexString.slice(i, i + 2));
    }

    // Join the groups with periods to get the "xx.xx.xx" format
    const formattedHex = hexGroups.join('.');

    return formattedHex;
  };

  const columns = [
    { title: 'Mesh I/O Name', field: 'name', name: 'name' },
    {
      title: 'Serial Number',
      field: 'serialNumber',
      name: 'serialNumber',
    },
    {
      title: 'FW Version',
      field: 'firmwareVersion',
      render: (columnProps, rowData) => {
        return convertToHex(rowData.firmwareVersion);
      },
    },
    {
      title: 'Site',
      field: 'siteName',
      name: 'siteName',
    },
    {
      title: 'State',
      field: 'meshConfiguredState',
      render: (columnProps, rowData) => {
        return meshConfiguredStateRender(rowData.configurationStatus);
      },
    },
    {
      title: 'Status',
      field: 'cloudStatus',
      render: (columnProps, rowData) => {
        return statusText(rowData.connectivityStatus);
      },
    },
    {
      title: 'Networks',
      field: 'networkName',
      name: 'networkName',
    },
    {
      title: 'Function Type',
      field: 'functionType',
      name: 'function',
      render: (columnProps, rowData) => {
        const type = rowData.function === 'firepanels' ? 'Fire Integration' : rowData.function;

        return rowData.function === 'none' ? 'Repeater' : type;
      },
    },
    {
      title: 'Function Name',
      field: 'functionName',
      name: 'functionName',
    },

    {
      title: 'Action',
      render: (columnProps, rowData) => {
        return (
          <SquareDelete
            color={'#ff535a'}
            onPress={() => {
              // deleteMesh(rowData.serialNumber);
              setShowModal(true);
              setRowData(rowData);
            }}
          />
        );
      },
    },
  ];

  const deleteMesh = useCallback(
    (serialNumber) => {
      dispatch(
        doDeleteMesh(currentPartner.partnerId, customerId, serialNumber, (s) => {
          if (s === 'success') {
            getMeshList();
            setShowModal(false);
          }
        }),
      );
    },
    [currentPartner.partnerId, customerId, dispatch, getMeshList],
  );

  const hSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((draft) => {
      draft[name] = value;
    });
  };

  const search = {
    onChange: hSearchChange,
  };

  const filteredData = useCallback(() => {
    let data = meshData ? meshData : [];
    if (filters.name !== '') {
      data = Search(data, 'name', filters.name);
    }
    if (filters.serialNumber !== '') {
      data = Search(data, 'serialNumber', filters.serialNumber);
    }
    if (filters.siteName !== '') {
      data = Search(data, 'siteName', filters.siteName);
    }

    data = data.slice(
      (pagination.currentPage - 1) * 7,
      pagination.currentPage * pagination.perPage,
    );
    return data;
  }, [
    filters.name,
    filters.serialNumber,
    filters.siteName,
    meshData,
    pagination.currentPage,
    pagination.perPage,
  ]);

  const changePage = (p) => {
    const { page, per_page } = p;

    setPagination((draft) => {
      draft.currentPage = page;
      draft.perPage = per_page;
    });
  };

  return (
    <Fragment>
      <Grid container className="spacer__1" />
      <Grid container>
        {showAddMesh && (
          <AddMesh
            togglePopup={() => {
              setAddMesh(false);
            }}
            showAddMesh={showAddMesh}
            partnerId={currentPartner.partnerId}
            customerId={customerId}
          />
        )}
        <div className="header-container">
          <p className="header">Mesh I/O</p>
          <div className="button-cont">
            <Button
              text={'Add Mesh I/O'}
              onClick={() => {
                setAddMesh(true);
              }}
              disabled={sites == null || sites.length === 0}
            />
          </div>
        </div>
        <>
          <Table
            columns={columns}
            data={filteredData()}
            options={{ search }}
            pagination={pagination}
            changePage={changePage}
          />
          {showModal && (
            <ModalAlert
              showModal={showModal}
              closeModal={() => setShowModal(false)}
              handleSubmit={() => deleteMesh(rowDataSelected?.serialNumber)}
              title={`Delete Mesh I/O`}
              description={` Are you sure you want to delete ${
                rowDataSelected?.name ? rowDataSelected?.name : ''
              } ? This action is non
              reversible and will cause you to lose all data associated with this customer/ s`}
              modalType={'danger'}
            />
          )}
        </>
      </Grid>
    </Fragment>
  );
};

export default MeshIo;
