import React from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';
const Checkbox = ({ checked = false, onChange, disabled = false, ...otherProps }) => {
  return (
    <label class={styles['checkcontainer']}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...otherProps}
      />
      <span class={styles['checkmark']}></span>
    </label>
  );
};

Checkbox.protoTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Checkbox;
