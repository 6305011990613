import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MaterialTable, { MTableToolbar } from 'material-table';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

const ACULogPopup = ({ showACULogPopup, togglePopup }) => {
  const popupToggle = () => {
    togglePopup('ACULog');
  };

  return (
    <Dialog
      open={showACULogPopup}
      onClose={popupToggle}
      maxWidth={'sm'}
      fullWidth={true}
      scroll={'body'}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography variant="h6"> ACU Event Log</Typography>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={3} sm={3} md={3} lg={3}>
            <IconButton onClick={popupToggle}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid container className="spacer__0-5" />
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MaterialTable
              //   title="ACU Event Logs"
              columns={[
                {
                  title: 'Time stamp',
                  field: 'timestamp',
                },
                {
                  title: 'Event Type',
                  field: 'eventType',
                },
                {
                  title: 'Details',
                  field: 'details',
                },
              ]}
              data={[]}
              options={{
                // selection: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 20],
                filtering: true,
                // actionsColumnIndex: -1,
                toolbar: false,
              }}
              components={{
                Toolbar: (props) => (
                  <div style={{ color: '#cf480d' }}>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
            />
          </Grid>
          <Grid container className="spacer__1" />
        </Grid>
      </DialogContent>
      <Grid className="spacer__0-5" />
    </Dialog>
  );
};

export default ACULogPopup;
