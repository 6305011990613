import React, { useEffect, useCallback, useState } from 'react';
import { useImmer } from 'use-immer';
import { useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import { selectOrganisationSites } from '../../../../../../redux/selectors/organisation';

import Mesh from '../Mesh/Mesh';
import Add from '@material-ui/icons/Add';
import styles from '../EditFirePanel/EditFirePanel-styles.module.scss';
import classnames from 'classnames';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { doGetMeshList } from '../../../../../../redux/thunks/mesh';
import { createFirePanel, firePanelList } from '../../../../../../redux/thunks/firepanel';
import Modal from '../../../../../../components/Modals/Modal';
import ButtonCustom from '../../../../../../components/Buttons/Button';
import SquareDelete from '../../../../../../components/SquareDelete/SquareDelete';
import TextInput from '../../../../../../components/Inputs/TextInput';
import Dropdown from '../../../../../../components/Inputs/Dropdown';

const CreateFirePanel = ({ togglePopup, useMesh }) => {
  //redux
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const { sites } = useSelector((state) => ({
    sites: selectOrganisationSites(state),
  }));
  const meshData = useSelector((state) => state.mesh.meshIOs);

  const currentPartner = useSelector((state) => state.auth.currentPartner);
  //state
  const [disableButton, setDisableButton] = useImmer(true);

  // const [usedData, setUsedData] = useImmer(() => useMesh || {});

  const meshIOs = meshData || {};
  // const [noFuncMesh, setNoFuncMesh] = useImmer([]);
  const [firePanelData, setFirePanelData] = useImmer({
    name: '',
    siteId: '',
    meshIos: {},
  });

  const [network, setNetwork] = useState({ name: '', value: '' });

  const popupToggle = useCallback(() => {
    togglePopup && togglePopup();
  }, [togglePopup]);

  const handleChange = useCallback(
    (name, value) => {
      if (name === 'name') {
        setFirePanelData((draft) => {
          draft.name = value;
        });
      } else if (name === 'site') {
        setFirePanelData((draft) => {
          draft.siteId = value;
          draft.meshIos = {};
        });
        setNetwork({ name: '', value: '' });
      }
    },
    [setFirePanelData],
  );

  // useEffect(() => {
  //   const arr = meshIOs.filter(
  //     (item) => item.function === 'no_function' && item.meshIOState === 'unassigned',
  //   );

  //   const a = [];
  //   for (const key in firePanelData.meshIos) {
  //     if (firePanelData.meshIos[key].serialNumber !== '') {
  //       a.push(firePanelData.meshIos[key].serialNumber);
  //     }
  //   }
  //   const filtered = intersection(arr, a);
  //   if (Object.keys(firePanelData.meshIos).length === 0) {
  //     setNoFuncMesh(arr);
  //   } else {
  //     setNoFuncMesh(filtered);
  //   }
  // }, [firePanelData.meshIos, firePanelData.siteId, meshIOs, setNoFuncMesh]);

  const getMeshList = useCallback(() => {
    if (currentPartner && customerId) dispatch(doGetMeshList(currentPartner.partnerId, customerId));
  }, [currentPartner, customerId, dispatch]);

  useEffect(() => {
    getMeshList();
  }, [getMeshList]);

  const addMesh = useCallback(() => {
    let last = Object.keys(firePanelData.meshIos).length - 1;
    if (last < 0) {
      last = 0;
    } else {
      last = last + 1;
    }
    setFirePanelData((draft) => {
      draft.meshIos[last] = { serialNumber: '', pins: [], show: false, selected: [] };
    });
  }, [firePanelData.meshIos, setFirePanelData]);

  const deleteMesh = useCallback(
    (key) => {
      setFirePanelData((draft) => {
        delete draft.meshIos[key];
      });
    },
    [setFirePanelData],
  );

  const addPins = useCallback(
    (key, serialNumber) => {
      // const filtered = meshIOs.filter((item) => item.serialNumber === serialNumber);
      setFirePanelData((draft) => {
        draft.meshIos[key].serialNumber = serialNumber;
        draft.meshIos[key].pins = [
          {
            id: 9,
            name: 'DI.04',
            type: 'DI',
            number: 4,
            isEnabled: false,
          },
          {
            id: 10,
            name: 'DI.03',
            type: 'DI',
            number: 3,
            isEnabled: false,
          },
          {
            id: 11,
            name: 'DI.02',
            type: 'DI',
            number: 2,
            isEnabled: false,
          },
          {
            id: 12,
            name: 'DI.01',
            type: 'DI',
            number: 1,
            isEnabled: false,
          },
        ];
        // draft.meshIos[key].selected = JSON.parse(JSON.stringify(filtered));
      });
    },

    [setFirePanelData],
  );

  const getFilteredMesh = (serialNumber) => {
    const filtered = meshIOs.filter((item) => item.serialNumber === serialNumber);
    if (filtered.length > 0) {
      return filtered[0];
    } else {
      return {};
    }
  };

  const updatePinName = useCallback(
    (key, id, text) => {
      setFirePanelData((draft) => {
        const item = draft.meshIos[key].pins.find((it) => it.id === id);
        item.name = text;
      });
    },
    [setFirePanelData],
  );

  const hideShowMesh = useCallback(
    (key, status) => {
      setFirePanelData((draft) => {
        draft.meshIos[key].show = status;
      });
    },
    [setFirePanelData],
  );

  const updateMeshData = (key, id, status) => {
    setFirePanelData((draft) => {
      const item = draft.meshIos[key].pins.find((it) => it.id === id);
      item.isEnabled = status;
    });
  };

  const createNew = useCallback(() => {
    const body = {};
    body.name = firePanelData.name;
    body.siteId = firePanelData.siteId;
    const tempMesh = [];
    Object.keys(firePanelData.meshIos).map((key) => {
      const temp = {};
      temp.serialNumber = firePanelData.meshIos[key].serialNumber;
      const arr = firePanelData.meshIos[key].pins.filter((item) => item.type === 'DI');
      temp.pins = arr;
      tempMesh.push(temp);
      return null;
    });
    body.meshIOs = tempMesh;
    dispatch(
      createFirePanel(currentPartner.partnerId, network.id, customerId, body, (status) => {
        if (status === 'success') {
          togglePopup && togglePopup('success');
        }
      }),
    );
    dispatch(firePanelList(currentPartner.partnerId, customerId));
  }, [
    currentPartner.partnerId,
    customerId,
    dispatch,
    firePanelData.meshIos,
    firePanelData.name,
    firePanelData.siteId,
    network.id,
    togglePopup,
  ]);

  //disableButton
  useEffect(() => {
    if (Object.keys(firePanelData.meshIos).length > 0) {
      for (const key in firePanelData.meshIos) {
        if (firePanelData.meshIos.hasOwnProperty(key)) {
          if (firePanelData.meshIos[key].serialNumber === '') {
            setDisableButton(true);
            return;
          } else {
            setDisableButton(false);
          }

          firePanelData.meshIos[key].pins.map((item) => {
            if (item.name === '') {
              setDisableButton(true);
            }
            return null;
          });
        }
      }
    } else {
      setDisableButton(true);
    }
  }, [firePanelData.meshIos, setDisableButton]);

  const selectedSite = sites?.find((site) => site.id === firePanelData?.siteId);
  const filteredMeshData = meshData.filter((item) => item.networkId === network.id);

  const usedSerialNumbers = useMesh.flatMap((item) =>
    item.meshios.map((meshio) => meshio.serialNumber),
  );

  // Filter 'filteredMeshData' to remove the mesh IOs with used serial numbers
  const filteredUnusedMeshData = filteredMeshData.filter((meshItem) => {
    return !usedSerialNumbers.includes(meshItem.serialNumber);
  });

  return (
    <Modal showModal={true} closeModal={() => popupToggle(false)}>
      <div className={classnames(styles['container'])}>
        <div className={classnames(styles['header'])}>
          <p className="modal-title">Create Fire Panel</p>
        </div>

        <div className="mrb-2">
          <div className={classnames(styles['details_input'])}>
            <div className={classnames(styles['input_container'])}>
              <TextInput
                placeholder="Fire Panel Name"
                name="name"
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </div>
            <div className={classnames(styles['input_container'])}>
              <Dropdown
                placeholder="Site"
                name="site"
                data={sites}
                value={selectedSite?.name}
                onChange={(e) => handleChange('site', e?.id)}
              />
            </div>
            <div className={classnames(styles['input_container'])}>
              <Dropdown
                placeholder="Network"
                name="newtwork"
                data={selectedSite?.networks}
                value={network.name}
                onChange={(data) => {
                  setNetwork({ ...data });
                }}
              />
            </div>
          </div>

          <div className={classnames(styles['header'])}>
            <p className="sub-title">Select Mesh I/O</p>
            <div className={classnames(styles['meshButton-container'])}>
              <div className={classnames(styles['mrg-r2'])}>
                {filteredUnusedMeshData.length === 0 ||
                  (filteredUnusedMeshData.length === Object.keys(firePanelData.meshIos).length && (
                    <p>No meshIOs</p>
                  ))}
              </div>

              <Button
                id="cancel-gateway"
                type="button"
                className="outline"
                variant="outlined"
                onClick={() => {
                  addMesh();
                }}
                disabled={
                  firePanelData.name === '' ||
                  firePanelData.siteId === '' ||
                  filteredUnusedMeshData.length === 0 ||
                  filteredUnusedMeshData.length === Object.keys(firePanelData.meshIos).length
                }
                startIcon={<Add />}
                disableElevation
              >
                Add Mesh I/O
              </Button>
            </div>
          </div>
          {Object.keys(firePanelData.meshIos).map((key, index) => {
            const mesh = getFilteredMesh(firePanelData.meshIos[key].serialNumber);
            const selectedvalue = mesh?.name ? mesh?.name : '';

            const filteredOptions = filteredUnusedMeshData.filter((option) => {
              const serialNumberMatch =
                firePanelData.meshIos[key].serialNumber !== option.serialNumber;

              return serialNumberMatch;
            });

            return (
              <>
                <div className="box-grey">
                  <div className={classnames(styles['header'])}>
                    <div className={classnames(styles['checkbox-width'])}>
                      <Dropdown
                        placeholder="Select Mesh I/O"
                        name="Mesh"
                        value={selectedvalue}
                        onChange={(e) => {
                          addPins(key, e.serialNumber);
                        }}
                        className={classnames(styles['mrg-0'])}
                        data={filteredOptions}
                      />
                    </div>
                    <div className={classnames(styles['meshButton-container'])}>
                      <div>
                        <ButtonCustom
                          buttonType="secondary"
                          text={firePanelData.meshIos[key].show ? 'Hide Details' : 'Show Details'}
                          disabled={firePanelData.meshIos[key].serialNumber === ''}
                          onClick={() => {
                            hideShowMesh(key, !firePanelData.meshIos[key].show);
                          }}
                        />
                      </div>

                      <SquareDelete
                        color={'#ff535a'}
                        onPress={() => {
                          deleteMesh(key);
                        }}
                      />
                    </div>
                  </div>

                  {firePanelData.meshIos[key].show && (
                    <div className="mesh-container">
                      <Mesh
                        isEditable
                        pinData={firePanelData.meshIos[key].pins}
                        handleChange={(id, status) => {
                          updateMeshData(key, id, status);
                        }}
                        onTextChange={(id, text) => {
                          updatePinName(key, id, text);
                        }}
                        isTextChangeable={true}
                      />
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>
        <div className={styles['button-container']}>
          <div className={styles['button_width']}>
            <ButtonCustom
              text="Cancel"
              buttonType="disabled"
              onClick={() => {
                popupToggle();
              }}
            />
          </div>

          <div className={styles['button_width']}>
            <ButtonCustom
              text={`Create`}
              type="submit"
              disabled={disableButton}
              onClick={() => {
                createNew();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFirePanel;
