export const AUTH_LOGIN_REQUESTED = 'auth/AUTH_LOGIN_REQUESTED';
export const AUTH_LOGIN_SUCCEEDED = 'auth/AUTH_LOGIN_SUCCEEDED';
export const AUTH_LOGIN_FAILED = 'auth/AUTH_LOGIN_FAILED';

export const AUTH_LOGOUT_REQUESTED = 'auth/AUTH_LOGOUT_REQUESTED';
export const AUTH_LOGOUT_SUCCEEDED = 'auth/AUTH_LOGOUT_SUCCEEDED';
export const AUTH_LOGOUT_FAILED = 'auth/AUTH_LOGOUT_FAILED';

export const PROFILE_READ_REQUESTED = 'auth/PROFILE_READ_REQUESTED';
export const PROFILE_READ_SUCCEEDED = 'auth/PROFILE_READ_SUCCEEDED';
export const PROFILE_READ_FAILED = 'auth/PROFILE_READ_FAILED';

export const PARTNERS_READ_REQUESTED = 'auth/PARTNERS_READ_REQUESTED';
export const PARTNERS_READ_SUCCEEDED = 'auth/PARTNERS_READ_SUCCEEDED';
export const PARTNERS_READ_FAILED = 'auth/PARTNERS_READ_FAILED';

export const SELECT_PARTNER = 'auth/SELECT_PARTNER';

export const SEND_FORGOT_PASSWORD_OTP_REQUESTED = 'auth/SEND_FORGOT_PASSWORD_OTP_REQUESTED';
export const SEND_FORGOT_PASSWORD_OTP_SUCCEEDED = 'auth/SEND_FORGOT_PASSWORD_OTP_SUCCEEDED';
export const SEND_FORGOT_PASSWORD_OTP_FAILED = 'auth/SEND_FORGOT_PASSWORD_OTP_FAILED';

export const RESET_PASSWORD_REQUESTED = 'auth/RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCEEDED = 'auth/RESET_PASSWORD_SUCCEEDED';
export const RESET_PASSWORD_FAILED = 'auth/RESET_PASSWORD_FAILED';
