import * as misc from '../actionCreators/miscellaneous';
import * as controller from '../actionCreators/controllers';
import * as controllerApis from '../../services/api/controllers';
import { extractErrorMessage } from '../helpers';

export const doGetControllerList = (partnerId, organisationId) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    const { data } = await controllerApis.GetControllerList(partnerId, organisationId);
    dispatch(controller.getControllerList(data.message.accessPoints));
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({
        isVisible: true,
        message: 'Controller List Successfully',
      }),
    );
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doAddController =
  (partnerId, organisationId, networkId, payload, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      await controllerApis.AddController(partnerId, organisationId, payload, networkId);

      const { data } = await controllerApis.GetControllerList(partnerId, organisationId);
      dispatch(controller.getControllerList(data.message.accessPoints));
      // dispatch(controller.getControllerList(data.message.controllers));
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));

      if (data.type === 'error') {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: data.message.errorMessage,
          }),
        );
        callBack('error');
      } else {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'contoller added',
          }),
        );
        callBack('success');
      }
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };

export const doEditController =
  (partnerId, organisationId, networkId, payload, callBack, id) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      await controllerApis.EditController(partnerId, organisationId, payload, networkId, id);
      const { data } = await controllerApis.GetControllerList(partnerId, organisationId);
      // dispatch(controller.getControllerList(data.message.controllers));
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));

      if (data.type === 'error') {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: data.message.errorMessage,
          }),
        );
        callBack('error');
      } else {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'contoller edit successful',
          }),
        );
        callBack('success');
      }
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };

export const doDeleteController =
  (partnerId, organisationId, controllerId, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      const { data } = await controllerApis.DeleteController(
        partnerId,
        organisationId,
        controllerId,
      );
      // const { getData } = await controllerApis.GetControllerList(partnerId, organisationId);
      // dispatch(controller.getControllerList(getData.message.controllers));
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(
        misc.doCreateNotification({
          isVisible: true,
          message: 'controller is getting deleted',
        }),
      );
      if (data.type === 'error') {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: data.message,
          }),
        );
        callBack('error');
      } else {
        dispatch(
          misc.doCreateNotification({
            isVisible: true,
            message: 'contoller deleted',
          }),
        );
        callBack('success');
      }

      callBack('success');
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };
