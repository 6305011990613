import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { animated, useSpring } from 'react-spring';

import style from './style.module.scss';

const PageNotFound = () => {
  const [flip, set] = useState(false);
  const props = useSpring({
    to: { opacity: 1, textAlign: 'center', fontSize: '4.5rem', fontWeight: 700, margin: 0 },
    from: { opacity: 0, textAlign: 'center', fontSize: '4.5rem', fontWeight: 700, margin: 0 },
    reset: true,
    reverse: flip,
    delay: 300,
    config: { mass: 1, tension: 280, friction: 70 },
    onRest: () => set(!flip),
  });
  return (
    <Grid container justifyContent="center" alignItems="center" className={style['container']}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <animated.h1 style={props}>404</animated.h1>
        <Typography align="center" variant="h6">
          Page Not Found
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
