import { useCallback, useState, useMemo } from 'react';

// routing
import { useParams } from 'react-router-dom';

// styles
import styles from '../styles.module.scss';

// components
import Button from '../../../components/Buttons/Button';
import TextInput from '../../../components/Inputs/TextInput';

// redux
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../../redux/selectors/miscellaneous';

const ResetPasswordForm = ({ handleResetPassword }) => {
  const { phoneNumber } = useParams();

  const [code, setCode] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({
    value: '',
    error: '',
  });

  const [confirmPassword, setConfirmPassword] = useState({
    value: '',
    error: '',
  });

  const isLoading = useSelector(selectIsLoading);

  const disabled = useMemo(() => {
    if (code.value === '' && code.value.length < 4) {
      return true;
    } else if (password.value === '' && confirmPassword.value === '') {
      return true;
    } else if (isLoading) {
      return true;
    } else if (password.value === confirmPassword.value && code.value.length >= 4) {
      return false;
    } else {
      return true;
    }
  }, [code.value, password.value, confirmPassword.value, isLoading]);

  const handlesubmit = useCallback(
    (e) => {
      if (!disabled) {
        e.preventDefault();
        handleResetPassword({ phoneNumber, code: code?.value, newPassword: password?.value });
      }
    },
    [code?.value, disabled, handleResetPassword, password?.value, phoneNumber],
  );

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;
    const errorText = 'Please enter a valid';
    if (name === 'code') {
      const error = value.length < 4 && value.length > 6 ? `${errorText} Code` : '';
      setCode({ value, error });
    }
    if (name === 'password') {
      const error = value.length < 4 ? `${errorText} Password` : '';
      setPassword({ value, error });
    }
    if (name === 'confirmPassword') {
      const error = value.length < 4 ? `${errorText} Confirm Password` : '';
      setConfirmPassword({ value, error });
    }
  };

  return (
    <div className={styles['form']}>
      <h1>Reset Password</h1>

      <TextInput
        placeholder="Code"
        name="code"
        type="number"
        onChange={handleChange}
        error={code.error}
      />

      <TextInput
        placeholder="Password"
        name="password"
        type="password"
        onChange={handleChange}
        error={password.error}
      />

      <TextInput
        placeholder="Confirm Password"
        name="confirmPassword"
        type="password"
        onChange={handleChange}
        error={confirmPassword.error}
      />

      <div className={styles['buttonContainer']}>
        <Button onClick={handlesubmit} type="submit" text="Reset Password" disabled={disabled} />
      </div>
    </div>
  );
};

export default ResetPasswordForm;
