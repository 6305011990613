import { statusMapping } from '../helpers/renderers';

export const Search = (list = [], name, searchText) => {
  let searchResults = [];
  searchResults = list.filter((item) => {
    return item[name].toString().toLowerCase().startsWith(searchText.toString().toLowerCase());
  });

  return searchResults;
};

export const SearchStatus = (list = [], name, searchText) => {
  let searchResults = [];
  searchResults = list.filter((item) => {
    return statusMapping(item[name])
      .toString()
      .toLowerCase()
      .startsWith(searchText.toString().toLowerCase());
  });

  return searchResults;
};

// export const SearchSites = (list = [], searchText) => {
//   let searchResults = [];

//   searchResults = list.filter((item) => {
//     return item..toString().toLowerCase().startsWith(searchText.toString().toLowerCase());
//   });

//   return searchResults;
// };
