import './Controllers.scss';

import { Typography } from '@material-ui/core';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import SquareDelete from '../../../../../components/SquareDelete/SquareDelete';
import Table from '../../../../../components/Table/Table';
import { selectCurrentPartner } from '../../../../../redux/selectors/authentication';
import { selectControllerList } from '../../../../../redux/selectors/controllers';
import {
  selectOrganisationAccessPoints,
  selectOrganisationSites,
} from '../../../../../redux/selectors/organisation';
import { doGetControllerList, doDeleteController } from '../../../../../redux/thunks/controllers';
import AddControllerDialog from './AddController/AddControllerDialog';
import EditControllerDialog from './EditController/EditControllerDialog';
import { Search } from '../../../../../utils/Search';
import ModalAlert from '../../../../../components/Modals/ModalAlert/ModalAlert';
import Button from '../../../../../components/Buttons/Button/Button';
import { configTypeRender } from '../../../../../helpers/renderers';
import SquareEdit from '../../../../../components/SquareEdit/SquareEdit';
import { meshConfiguredStateRender, statusText } from '../../../../../utils/Common';
const Controllers = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const [currentController, setCurrentController] = useImmer(null);
  const [showControllerDialog, setShowControllerDialog] = useImmer(false);
  const [showDeleteControllerDialog, setShowDeleteControllerDialog] = useImmer(false);
  const [showEditControllerDialog, setShowEditControllerDialog] = useImmer(false);
  const { currentUser, sites, controllers } = useSelector((state) => ({
    accessPoints: selectOrganisationAccessPoints(state),
    currentUser: selectCurrentPartner(state),
    sites: selectOrganisationSites(state),
    controllers: selectControllerList(state),
  }));
  const [pagination, setPagination] = useImmer({ total: 0, currentPage: 1, perPage: 7 });
  const [filters, setFilters] = useImmer({
    serialNumber: '',
    siteName: '',
    networkName: '',
    configuration: '',
    meshConfiguredState: '',
  });

  const convertToHex = (value) => {
    let hexString = value.toString(16);

    // Ensure the hexadecimal string has at least two characters
    if (hexString.length % 2 !== 0) {
      hexString = '0' + hexString;
    }

    // Split the hexadecimal string into groups of two characters
    const hexGroups = [];
    for (let i = 0; i < hexString.length; i += 2) {
      hexGroups.push(hexString.slice(i, i + 2));
    }

    // Join the groups with periods to get the "xx.xx.xx" format
    const formattedHex = hexGroups.join('.');

    return formattedHex;
  };

  // const getControllerList = useCallback(() => {
  //   if (currentUser && customerId) dispatch(doGetControllerList(currentUser.partnerId, customerId));
  // }, [currentUser, customerId, dispatch]);

  useEffect(() => {
    if (currentUser && customerId) dispatch(doGetControllerList(currentUser.partnerId, customerId));
  }, [currentUser, customerId, dispatch]);

  const columns = [
    {
      title: 'Serial Number',
      field: 'serialNumber',
      name: 'serialNumber',
      render: (columnProps, rowData) => {
        // Find the device with type 3 or 4
        const targetDevice = rowData?.devices.find(
          (device) => device.type === 3 || device.type === 4,
        );

        if (targetDevice) {
          return targetDevice.serialNumber;
        }

        return ''; // Handle case when no device of type 3 or 4 is found
      },
    },
    {
      title: 'Site',
      field: 'siteName',
      name: 'siteName',
    },
    {
      title: 'Network',
      field: 'networkName',
      name: 'networkName',
    },
    {
      title: 'Firmware Version',
      render: (columnProps, rowData, row) => {
        // Find the device with type 3 or 4
        const targetDevice = rowData?.devices.find(
          (device) => device.type === 3 || device.type === 4,
        );

        return convertToHex(targetDevice.firmwareVersion); // Handle case when no device of type 3 or 4 is found
      },
    },
    {
      title: 'Configuration',
      field: 'configuration',
      name: 'configuration',
      render: (columnProps, rowData) => {
        return configTypeRender(rowData?.configuration);
      },
    },
    {
      title: 'Configuration State',
      render: (columnProps, rowData, row) => {
        // Find the device with type 3 or 4
        const targetDevice = rowData?.devices.find(
          (device) => device.type === 3 || device.type === 4,
        );

        return meshConfiguredStateRender(targetDevice.configurationStatus); // Handle case when no device of type 3 or 4 is found
      },
    },
    {
      title: 'Connectivity Status',
      render: (columnProps, rowData, row) => {
        // Find the device with type 3 or 4
        const targetDevice = rowData?.devices.find(
          (device) => device.type === 3 || device.type === 4,
        );

        return statusText(targetDevice.connectivityStatus); // Handle case when no device of type 3 or 4 is found
      },
    },
    {
      title: 'Actions',
      render: (columnProps, rowData, row, s) => {
        return (
          <div className="button-row">
            <SquareEdit
              color={'#ff535a'}
              onPress={() => {
                const deviceWithType4 = rowData.devices.find(
                  (device) => device.type === 4 || device.type === 3,
                );
                const objectsWithSerialNumber = getObjectsWithSerialNumber(
                  deviceWithType4.serialNumber,
                );
                setCurrentController(objectsWithSerialNumber);
                setShowEditControllerDialog(true);
              }}
            />
            <SquareDelete
              onPress={() => {
                setCurrentController(rowData);
                setShowDeleteControllerDialog(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const hSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((draft) => {
      draft[name] = value;
    });
  };

  const search = {
    onChange: hSearchChange,
  };

  const filteredData = useCallback(() => {
    let scrapeData = [];
    scrapeData = controllers?.filter((accessPoint) => {
      const configuration = accessPoint.configuration;
      return configuration === 3 || configuration === 4;
    });
    let data = scrapeData ? scrapeData : [];
    if (filters.serialNumber !== '') {
      data = Search(data, 'serialNumber', filters.serialNumber);
    }
    if (filters.siteName !== '') {
      data = Search(data, 'siteName', filters.siteName);
    }
    if (filters.networkName !== '') {
      data = Search(data, 'networkName', filters.networkName);
    }
    if (filters.configuration !== '') {
      data = Search(data, 'configuration', filters.configuration);
    }
    if (filters.meshConfiguredState !== '') {
      data = Search(data, 'meshConfiguredState', filters.meshConfiguredState);
    }
    const uniqueItems = {};
    const filteredItems = scrapeData.reduce((result, item) => {
      const filteredDevices = item.devices.filter((device) => {
        if (device.type === 3 || device.type === 4) {
          if (uniqueItems[device.serialNumber]) {
            return false;
          }
          uniqueItems[device.serialNumber] = true;
          return true;
        }
        return false;
      });

      if (filteredDevices.length > 0) {
        result.push({
          ...item,
          devices: filteredDevices,
        });
      }
      return result;
    }, []);

    data = filteredItems.slice(
      (pagination.currentPage - 1) * 7,
      pagination.currentPage * pagination.perPage,
    );
    return data;
  }, [filters, pagination.currentPage, pagination.perPage, controllers]);

  const changePage = (p) => {
    const { page, per_page } = p;

    setPagination((draft) => {
      draft.currentPage = page;
      draft.perPage = per_page;
    });
  };

  const addControllerClicked = () => {
    setShowControllerDialog(true);
  };

  const handleDeleteController = async () => {
    const deviceWithType4 = currentController.devices.find(
      (device) => device.type === 4 || device.type === 3,
    );
    const objectsWithSerialNumber = getObjectsWithSerialNumber(deviceWithType4.serialNumber);

    if (objectsWithSerialNumber) {
      for (const object of objectsWithSerialNumber) {
        await dispatch(
          doDeleteController(currentUser.partnerId, customerId, object.id, (s) => {
            if (s === 'success') {
              dispatch(doGetControllerList(currentUser.partnerId, customerId));
            }
          }),
        );
      }
      setShowDeleteControllerDialog(false);
    }
  };

  const getObjectsWithSerialNumber = useCallback(
    (serialNumber) => {
      const objects = controllers.filter((accessPoint) => {
        return accessPoint.devices.some((device) => device.serialNumber === serialNumber);
      });
      return [...objects]; // Always return an array
    },
    [controllers],
  );

  return (
    <Fragment>
      <div className="header-container">
        <Typography variant="h5" className="header">
          Controllers
        </Typography>
        <div className="button-cont">
          <Button
            text={'Add Controller'}
            onClick={() => {
              addControllerClicked();
            }}
            disabled={sites == null || sites.length === 0}
          />
        </div>
      </div>

      <div className="table-container">
        <Table
          columns={columns}
          data={filteredData()}
          pagination={pagination}
          options={{ search }}
          changePage={changePage}
          emptyContent="No Records found"
        />
      </div>

      {showControllerDialog && (
        <AddControllerDialog
          isOpen={showControllerDialog}
          handleClose={() => {
            setShowControllerDialog(false);
          }}
          currentUser={currentUser}
          customerId={customerId}
        />
      )}

      {showEditControllerDialog && (
        <EditControllerDialog
          isOpen={showEditControllerDialog}
          handleClose={() => {
            setShowEditControllerDialog(false);
          }}
          currentUser={currentUser}
          customerId={customerId}
          data={currentController}
        />
      )}

      <ModalAlert
        showModal={showDeleteControllerDialog}
        closeModal={() => {
          setShowDeleteControllerDialog(false);
        }}
        title={'Delete Controllers'}
        description={`Are you sure you want to delete this controller?
        The access points associated with it will also be deleted.`}
        modalType="danger"
        handleSubmit={handleDeleteController}
      />
    </Fragment>
  );
};

export default Controllers;
