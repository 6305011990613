import React, { useState } from 'react';
import Modal from '../../../components/Modals/Modal';
import styles from '../../../styles/modals.module.scss';
import Button from '../../../components/Buttons/Button';
import { useHistory } from 'react-router-dom';

import { Box, Typography, withStyles } from '@material-ui/core';
import Dropdown from '../../../components/Inputs/Dropdown';
import routes from '../../../constants/routes';

const SelectIntegrator = ({ show, onClose, togglePopup, list, setIntegrator }) => {
  const [value, setValue] = useState({ id: '', name: '' });
  const history = useHistory();

  const WhiteTextTypography = withStyles({
    root: {
      color: '#483f94',
    },
  })(Typography);

  const handleClick = () => {
    setIntegrator(value);
    togglePopup('View');
  };
  return (
    <Modal
      showModal={show}
      onClose={onClose}
      style={{
        width: '100%',
        maxWidth: '600px',
      }}
    >
      <div className={styles['wrapper']}>
        <h1> Add customer</h1>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <WhiteTextTypography variant="h3">Select Integrator</WhiteTextTypography>
        </Box>
        <Box>
          <Dropdown
            name="accountType"
            placeholder="Select Integrator"
            value={value.value}
            data={list}
            onChange={(data) => setValue({ value: data?.name, id: data?.id })}
            // disabled={isLoading}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          margin={3}
          width="90%"
        >
          <Button
            text="Cancel"
            buttonSize="button-small"
            onClick={() => {
              togglePopup('cancel');
              history.push(routes.customer.list);
            }}
          />
          <Button
            text="Continue"
            buttonSize="button-small"
            onClick={handleClick}
            // style={{ width: '100px' }}
            disabled={!value.id}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default SelectIntegrator;
