import React from 'react';

const Wifi = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42.23"
      height="29.854"
      viewBox="0 0 42.23 29.854"
    >
      <path
        fill="#8b94ac"
        d="M0 21.115l3.839-3.839a24.433 24.433 0 0034.552 0l3.839 3.839a29.879 29.879 0 01-42.23 0zM15.356 5.759L21.115 0l5.759 5.759a8.133 8.133 0 01-11.518 0zm-7.678 7.678L11.517 9.6a13.578 13.578 0 0019.2 0l3.839 3.839a19.023 19.023 0 01-26.878-.002z"
        data-name="Icon material-wifi"
        transform="rotate(180 21.115 14.927)"
      ></path>
    </svg>
  );
};

export default Wifi;
