import React, { useMemo } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { NavLink, matchPath } from 'react-router-dom';
// import Chip from '@material-ui/core/Chip';

import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
// import DescriptionIcon from '@material-ui/icons/Description';
// import LayersIcon from '@material-ui/icons/Layers';
import PersonIcon from '@material-ui/icons/Person';

// import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import routes from '../constants/routes';
import style from './style.module.scss';

function ListItemLink(props) {
  const { icon, primary, path, exact } = props;
  const [p] = path;

  const renderLink = useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <NavLink
          activeClassName={style['sidebar__list-item--active']}
          isActive={(match, loc) => {
            if (path.some((d) => matchPath(loc.pathname, { path: d, exact }))) {
              return true;
            }

            return false;
          }}
          to={p}
          ref={ref}
          {...itemProps}
        />
      )),
    // eslint-disable-next-line
    [p],
  );
  return (
    <ListItem button component={renderLink} className={style['sidebar__list-item']}>
      {icon && <ListItemIcon className={style['sidebar__list-item__icon']}>{icon}</ListItemIcon>}
      {primary && <ListItemText primary={primary} />}
      {/* {props.badge && <Chip label={props.badge} size="small" color="primary" />} */}
    </ListItem>
  );
}
const year = new Date().getFullYear();

export const mainListItems = (
  <div className={style['sidebar-container']}>
    <ListItemLink
      path={[routes.dashboard.home]}
      primary="Dashboard"
      icon={<DashboardIcon />}
      exact
    />

    <ListItemLink
      path={[routes.customer.list, routes.customer.customerId.details]}
      primary="Customers"
      icon={<PeopleIcon />}
      exact
    />
    <ListItemLink path={[routes.user.list]} primary="Users" icon={<PersonIcon />} exact />
    {/* <ListItemLink
      path={[routes.detached.list]}
      primary="Detached Devices"
      icon={<Detached />}
      exact
    /> */}
    {/* <ListItemLink path={['/partners']} primary="Partners" icon={<AccountCircleIcon />} exact /> */}
    {/* <ListItemLink path={['/debugging']} primary="Debugging" icon={<LayersIcon />} exact /> */}
    {/* <ListItemLink path={['/reports']} primary="Reports" icon={<DescriptionIcon />} exact /> */}
    <footer className={style['sidebar-container__footer']}>
      <p className={style['header']}>{`Spintly Partners | ${year} Admin`}</p>
      <p className={style['subheader']}>{`© ${year} All Rights Reserved`}</p>
    </footer>
  </div>
);
