import { Fragment, useCallback } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { doSendForgotPasswordOTP } from '../../../redux/thunks/authentication';

// routing
import { useHistory } from 'react-router-dom';
import routes from '../../../constants/routes';

// components
import CompanyLogo from '../../../assets/images/logo.png';
import ForgotPasswordForm from './ForgotPasswordForm';
import UILink from '../../../components/Buttons/UILink';

import styles from '../styles.module.scss';
import Footer from '../../../components/Footer';

const RecoverPassword = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const displayLeftSection = () => (
    <div className={styles['left_section']}>
      <div className={styles['login_image']}></div>
    </div>
  );

  const handleSubmitOtp = useCallback(
    (values) => {
      dispatch(doSendForgotPasswordOTP(values));
    },
    [dispatch],
  );

  const displayRightSection = () => (
    <div className={styles['right_section']}>
      <div className={styles['logo_container']}>
        <img src={CompanyLogo} alt="Spintly" />
        <h2> | </h2>
        <h1> Partner</h1>
      </div>
      <div className={'ml-2'}>
        <h2 className={styles['secodary_header']}>
          Please enter your Mobile Number. We will send you code to reset your password.
        </h2>
        <ForgotPasswordForm handleSubmitOtp={handleSubmitOtp} />

        <div className="mt-8">
          <UILink
            text="Login"
            disabled={false}
            handleSubmit={() => history.push(routes.public.login)}
          />
        </div>
        <Footer />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className={styles['container']}>
        {displayLeftSection()}
        {displayRightSection()}
      </div>
    </Fragment>
  );
};

export default RecoverPassword;
