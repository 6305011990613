import React from 'react';

// packages
import PropTypes from 'prop-types';

// styles
import styles from './styles.module.scss';

const types = ['primary', 'disabled', 'secondary', 'danger', 'submit'];

export const UILink = ({ text, handleSubmit, disabled, alternate = false, ...props }) => {
  return (
    <button
      onClick={handleSubmit}
      style={{
        color: alternate ? '#353f94' : '',
        fontFamily: alternate ? 'UrbanistMedium' : '',
      }}
      className={`${styles.link} ${disabled && styles.disabled}`}
      {...props}
    >
      {text}
    </button>
  );
};

UILink.propTypes = {
  type: PropTypes.oneOf(types),
  handleSubmit: PropTypes.func.isRequired,
  alternate: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default UILink;
