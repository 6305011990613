import React from 'react';
import styles from './overview_stat_card.module.scss';

const OverviewStatCards = ({ name, stat, icon }) => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        <div className={styles['icon__container']}>
          <div className={styles['icon']}>{icon}</div>
        </div>
        <div className={styles['text__container']}>
          <p className={styles['text']}>{name}</p>
          <p className={styles['number']}>{stat}</p>
        </div>
      </div>
    </div>
  );
};

export default OverviewStatCards;
