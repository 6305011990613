import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import timezones from 'countries-and-timezones';
import classnames from 'classnames';

// styles
import styles from '../../../../../../styles/modals.module.scss';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentPartner } from '../../../../../../redux/selectors/authentication';
import {
  doCreateNetwork,
  doCreateOrganisationSite,
  doDeleteNetwork,
  doReadOrganisationSites,
  doUpdateOrganisationSite,
} from '../../../../../../redux/thunks/organisation';
// components
import Modal from '../../../../../../components/Modals/Modal';
import ButtonCustom from '../../../../../../components/Buttons/Button';
import TextInput from '../../../../../../components/Inputs/TextInput';
import { textVerification } from '../../../../../../utils/formValdations';
import { selectIsLoading } from '../../../../../../redux/selectors/miscellaneous';
import { selectOrganisation } from '../../../../../../redux/selectors/organisation';
import Dropdown from '../../../../../../components/Inputs/Dropdown';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SquareDelete from '../../../../../../components/SquareDelete/SquareDelete';
import ModalAlert from '../../../../../../components/Modals/ModalAlert';

const maxNetworks = 2;
// const NETWORK_NAME_SEPARATOR = ' - ';

const AddOrEditSiteModal = ({
  showSitePopup,
  onClose,
  togglePopup,
  site,
  setSite,
  mode = 'add',
}) => {
  const [siteName, setSiteName] = useState({ value: site?.name ? site?.name : '', error: '' });
  const [location, setLocation] = useState({
    value: site?.location ? site?.location : '',
    error: '',
  });
  const [timezone, setTimezone] = useState(site?.timezone ? site?.timezone : '');
  const [countryTimezones, setCountryTimezones] = useState([]);
  const [showDeleteNetworkPopup, setShowDeleteNetworkPopup] = useState(false);
  const [currentNetworkId, setcurrentNetworkId] = useState({ id: '', name: '' });
  const [networks, setNetworks] = useState(
    site?.networks
      ? site?.networks.map((network) => {
          return { id: network.id, value: network.name, error: '' };
        })
      : [{ id: '', value: '', error: '' }],
  );

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentPartner);
  const org = useSelector(selectOrganisation);
  const { customerId } = useParams();
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (site?.name && site?.location) {
      setSiteName({ value: site?.name, error: '' });
      setLocation({ value: site?.location, error: '' });
    }
  }, [site?.name, site?.location]);

  useEffect(() => {
    if (org?.generalSetting?.country) {
      const allTimezones = timezones.getCountry(org?.generalSetting?.country);
      if (allTimezones?.timezones) {
        setCountryTimezones(
          allTimezones?.timezones?.map((elem, i) => {
            return { id: i, name: elem };
          }),
        );
      }
    }
  }, [org]);

  const popupToggle = useCallback(() => {
    togglePopup('AddOrEditSite');
  }, [togglePopup]);

  const resetAndClose = useCallback(() => {
    setSite(null);
    popupToggle();
  }, [popupToggle, setSite]);

  const handleNetworkAddRemove = (type, index) => {
    if (type === 'add') {
      setNetworks((oldState) => {
        return [...oldState, { id: '', value: '', error: '' }];
      });
    } else {
      // const item = networks.splice(index, 1);
      if (networks[index].id !== '') {
        setShowDeleteNetworkPopup(true);
        setcurrentNetworkId({ id: networks[index].id, name: networks[index].value });
      }

      if (mode === 'add' || networks[index].id === '') {
        setNetworks((oldState) => {
          const newState = [...oldState];
          newState.splice(index, 1);
          return newState;
        });
      }
    }
  };

  const handleDeleteNetwork = () => {
    dispatch(doDeleteNetwork(currentNetworkId?.id));
    setTimeout(() => {
      dispatch(
        doReadOrganisationSites({
          partnerId: currentUser.partnerId,
          organisationId: customerId,
          displayNotification: false,
        }),
      );
    }, 2000);
    setShowDeleteNetworkPopup(false);
  };
  const handleMultifieldChange = (e, index) => {
    const { value } = e?.currentTarget;
    const errorText = 'Please enter a valid';

    let error = '';
    if (value && networks.filter((network) => network.value === value).length > 0) {
      error = `Network Name Should Be Unique`;
    } else if (textVerification(value, 2)) error = `${errorText} Network Name`;
    const nName = value.includes(siteName.value)
      ? value
      : siteName.value + ' - ' + value.slice(siteName.value.length);

    if (nName.includes(siteName.value + ' - ')) {
      setNetworks((oldState) => {
        const newState = [...oldState];
        newState[index] = { id: newState[index].id, value: nName, error };
        return newState;
      });
    } else {
      // Prevent further backspacing if siteName and '-' are not present
      setNetworks((oldState) => {
        const newState = [...oldState];
        newState[index] = {
          id: newState[index].id,
          value: siteName.value + ' - ' + nName.slice(siteName.value.length + 3),
          error,
        };
        return newState;
      });
    }
    // setNetworks((oldState) => {
    //   const newState = [...oldState];
    //   newState[index] = { id: newState[index].id, value: nName, error };
    //   return newState;
    // });
  };

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;
    const errorText = 'Please enter a valid';
    let error = '';
    switch (name) {
      case 'name':
        if (textVerification(value, 2)) error = `${errorText} site name`;
        setSiteName({ value, error });
        break;
      case 'location':
        if (textVerification(value, 2)) error = `${errorText} location`;
        setLocation({ value, error });
        break;
      default:
        break;
    }
  };

  const handleCreateSite = useCallback(() => {
    const payload = {
      name: siteName?.value,
      location: location?.value,
      timezone: timezone,
      networks: networks
        ? networks.map((network) => {
            return { name: network.value };
          })
        : [],
    };
    dispatch(
      doCreateOrganisationSite({
        partnerId: currentUser.partnerId,
        organisationId: customerId,
        payload,
        callback: resetAndClose,
      }),
    );
  }, [
    currentUser.partnerId,
    customerId,
    dispatch,
    location?.value,
    resetAndClose,
    siteName?.value,
    timezone,
    networks,
  ]);

  const handleUpdateSite = useCallback(() => {
    const payload = {
      name: siteName?.value,
      location: location?.value,
      networks: networks
        ? networks.map((network) => {
            return { id: network.id, name: network.value };
          })
        : [],
    };
    dispatch(
      doUpdateOrganisationSite({
        partnerId: currentUser.partnerId,
        organisationId: customerId,
        siteId: site?.id,
        payload,
        callback: togglePopup('AddOrEditSite'),
      }),
    );

    networks.map((item) => {
      if (!item.id) {
        dispatch(doCreateNetwork(site.id, { name: item.value }));
      }
      return 'success';
    });
  }, [
    currentUser.partnerId,
    customerId,
    dispatch,
    location?.value,
    siteName?.value,
    site?.id,
    togglePopup,
    networks,
  ]);

  const disabled = useMemo(() => {
    if (isLoading) return true;
    // const hasNetworkError = networks
    //   ? networks.filter((network) => network.value === '' || network.error !== '')
    //   : [];
    // if (hasNetworkError.length > 0) {
    //   return true;
    // }
    if (
      siteName.value === '' //||
      // location.value === '' ||
      // siteName?.error !== '' ||
      // location?.error !== '' ||
      // timezone === ''
    ) {
      return true;
    }
    return false;
  }, [
    isLoading,
    // location?.error,
    // location.value,
    // siteName?.error,
    siteName?.value,
    // timezone,
    // networks,
  ]);

  return (
    <>
      <Modal
        showModal={showSitePopup}
        closeModal={onClose}
        style={{ width: '100%', maxWidth: '694px' }}
      >
        <div className={styles['wrapper']}>
          <h1>{mode === 'add' ? 'Add' : 'Edit'} Sites</h1>
          <TextInput
            name="name"
            placeholder="Site Name"
            value={siteName.value}
            onChange={handleChange}
            error={siteName.error}
          />
          <div className={styles['dropDown__inputs__container']}>
            <div className={styles['row']}>
              <TextInput
                name="location"
                placeholder="Site Location"
                value={location.value}
                onChange={handleChange}
                error={location.error}
                // disabled={true}
                // disabled={mode !== 'add'}
              />
            </div>
            <div className={styles['row']}>
              <Dropdown
                name="timezone"
                placeholder="Site Timezone"
                value={timezone}
                data={countryTimezones}
                onChange={(data) => setTimezone(data?.name)}
                disabled={mode !== 'add'}
                // disabled={true}
              />
            </div>
          </div>

          <div className={styles['multi-field']}>
            {mode !== 'add' && (
              <div className={styles['multi-field-title-wrapper']}>
                <div className={styles['multi-field-title']}>
                  <h3>Networks</h3>
                  <p>Only {maxNetworks} networks are allowed per site.</p>
                </div>
                {maxNetworks > 1 && networks.length !== maxNetworks && (
                  <Button
                    className={styles['multi-field-add-button']}
                    variant="outlined"
                    startIcon={<AddCircleIcon />}
                    onClick={() => {
                      handleNetworkAddRemove('add');
                    }}
                  >
                    Add
                  </Button>
                )}
              </div>
            )}

            {networks.map((network, index) => {
              let fieldType = 1; // without add/remove
              if (mode !== 'add' || (maxNetworks > 1 && index > 0)) {
                fieldType = 3; // with remove
              } else if (maxNetworks > 1 && networks.length !== maxNetworks && index === 0) {
                fieldType = 2; // with add
              }
              let field;
              switch (fieldType) {
                case 1:
                  field = (
                    <div
                      className={classnames(styles['multi-field-item'])}
                      key={'network-' + index}
                    >
                      <div className={styles['multi-field-item-input-wrapper']}>
                        <TextInput
                          className={styles['multi-field-item-input']}
                          key={'network-' + index}
                          name="networks[]"
                          placeholder={'Network Name'}
                          value={network.value}
                          onChange={(e) => handleMultifieldChange(e, index)}
                          error={network.error}
                          // disabled={mode !== 'add'}
                          disabled={network.id ? true : false}

                          // disabled={true}
                        />
                      </div>
                    </div>
                  );
                  break;
                case 2:
                  field = (
                    <div
                      className={classnames(
                        styles['multi-field-item'],
                        styles['multi-field-item-add'],
                      )}
                      key={'network-' + index}
                    >
                      <div className={styles['multi-field-item-input-wrapper']}>
                        <TextInput
                          className={styles['multi-field-item-input']}
                          name="networks[]"
                          placeholder={'Network Name'}
                          value={network.value}
                          onChange={(e) => handleMultifieldChange(e, index)}
                          error={network.error}
                          // disabled={mode !== 'add'}
                          disabled={network.id ? true : false}

                          // disabled={true}
                        />
                      </div>
                      <Button
                        className={styles['multi-field-add-button']}
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                        onClick={() => {
                          handleNetworkAddRemove('add', index);
                        }}
                        // disabled={true}
                      >
                        Add
                      </Button>
                    </div>
                  );
                  break;
                default:
                  field = (
                    <div
                      className={classnames(
                        styles['multi-field-item'],
                        styles['multi-field-item-remove'],
                      )}
                      key={'network-' + index}
                    >
                      <div className={styles['multi-field-item-input-wrapper']}>
                        <TextInput
                          className={styles['multi-field-item-input']}
                          name="networks[]"
                          placeholder={'Network Name'}
                          value={network.value}
                          onChange={(e) => handleMultifieldChange(e, index)}
                          error={network.error}
                          // disabled={mode !== 'add'}
                          disabled={network.id ? true : false}

                          // disabled={true}
                        />
                      </div>
                      <IconButton
                        className={styles['multi-field-remove-button']}
                        size="medium"
                        aria-label="Remove"
                        onClick={() => {
                          handleNetworkAddRemove('remove', index);
                        }}
                        // disabled={true}
                      >
                        <SquareDelete />
                      </IconButton>
                    </div>
                  );
                  break;
              }
              return field;
            })}

            {mode === 'add' && networks.length === maxNetworks && (
              <p className={styles['multi-field-max-length-text']}>
                Max networks allowed per site are {maxNetworks}. Contact support if more networks
                are needed.
              </p>
            )}
          </div>

          <div className={styles['button-container']}>
            <ButtonCustom
              text="Cancel"
              buttonType="disabled"
              onClick={() => togglePopup('AddOrEditSite')}
            />
            <ButtonCustom
              text={mode === 'add' ? 'Add' : 'Save'}
              onClick={mode === 'add' ? handleCreateSite : handleUpdateSite}
              disabled={disabled}
            />
          </div>
        </div>
      </Modal>
      <ModalAlert
        showModal={showDeleteNetworkPopup}
        closeModal={() => {
          setShowDeleteNetworkPopup(false);
        }}
        title={'Delete Network'}
        description={`Are you sure you want to delete this network, ${
          currentNetworkId ? currentNetworkId.name : ''
        } ?`}
        modalType="danger"
        handleSubmit={handleDeleteNetwork}
      />
    </>
  );
};

export default AddOrEditSiteModal;
