import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Divider, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Modal from '../Modals/Modal';
import styles from '../../../src/styles/modals.module.scss';

import { doLogout } from '../../redux/thunks/authentication';
import { doCreateNotification } from '../../redux/actionCreators/miscellaneous';
import Button from '../Buttons/Button';

const UnskippablePopup = ({ open, title, message }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      showModal={open}
      maxWidth="sm"
      // fullWidth={true}
      style={{ width: 500 }}
      PaperProps={{ style: { borderRadius: 15 } }}
    >
      <div className={styles['wrapper']}>
        <DialogTitle style={{ height: 70, textAlign: 'center' }}>
          <h1 style={{ fontSize: 30 }}>{title}</h1>
        </DialogTitle>
        <DialogContent
          style={{
            textAlign: 'center',
            fontSize: 20,
            textTransform: 'capitalize',
          }}
        >
          <Typography
            variant="h1"
            style={{
              fontSize: 20,
              color: '#f46526',
            }}
          >
            {message}
          </Typography>
        </DialogContent>
        <Divider style={{ marginBottom: 10 }}></Divider>
        <DialogActions
          style={{
            dispay: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => {
              dispatch(doLogout());
              dispatch(doCreateNotification({ isVisible: false, message: '' }));
            }}
            style={{
              fontWeight: 'bold',
              fontSize: 22,
              textTransform: 'capitalize',
              width: 120,
              height: 40,
            }}
            text="OK"
          ></Button>
        </DialogActions>
      </div>
    </Modal>
  );
};

export default UnskippablePopup;
