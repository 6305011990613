import React, { Fragment, useRef, useState } from 'react';

// packages
import 'react-phone-input-2/lib/style.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// styles
import inputStyles from '../styles.module.scss';
import '../input.scss';

// helpers
import { userEntersNumber } from '../../../helpers/utils/formValidations';

// icons
import EyeCloseSvg from '../icons/EyeClose';
import EyeOpenSvg from '../icons/EyeOpen';

const TextInput = ({ error, description = '', name, maxLength, type = 'text', ...props }) => {
  const inputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <Fragment>
      <div
        className={classnames(
          inputStyles['input_field'],
          error && inputStyles['danger'],
          (focused || inputRef?.current?.value) && inputStyles['focused'],
          'input_field_wrapper',
          props?.disabled && inputStyles['disabled'],
        )}
        onClick={() => {
          inputRef?.current?.focus();
        }}
      >
        {props?.placeholder && (
          <p
            className={classnames(
              inputStyles['label'],
              focused || inputRef?.current?.value || props?.value
                ? inputStyles['label_active']
                : inputStyles['label_inactive'],
            )}
          >
            {props?.placeholder}
          </p>
        )}
        <input
          ref={inputRef}
          maxLength={maxLength}
          name={name}
          onKeyPress={(event) => {
            if (!userEntersNumber(event?.key) && type === 'number') event.preventDefault();
          }}
          onFocus={(e) => {
            props?.onFocus && props.onFocus(e);
            setFocused(true);
          }}
          onBlur={(e) => {
            props?.onBlur && props?.onBlur(e);
            setFocused(false);
          }}
          type={!showPassword && props?.value !== '' ? type : 'text'}
          {...props}
          placeholder={focused ? '' : props?.placeholder}
        />
        {type === 'password' && (
          <div
            className={inputStyles['eye_icon']}
            onClick={() => {
              if (props?.value !== '') {
                setShowPassword(!showPassword);
              }
            }}
          >
            {showPassword ? (
              <EyeOpenSvg className={inputStyles['svg']} />
            ) : (
              <EyeCloseSvg className={inputStyles['svg']} />
            )}
          </div>
        )}
        {description !== '' && (
          <div className={inputStyles['description-container']}>
            <p>{description}</p>
          </div>
        )}
      </div>
      {error && <p className={inputStyles['error']}>{error}</p>}
    </Fragment>
  );
};

TextInput.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default React.memo(TextInput);
