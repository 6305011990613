import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './constants/routes';
import BaseLayout from './layouts/BaseLayout';
import DashboardLayout from './layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';

import Login from './pages/public/Login';
import ForgotPassword from './pages/public/ForgotPassword';
import ResetPassword from './pages/public/ResetPassword';
import DashboardHome from './pages/dashboard/DashboardHome';
import CustomerList from './pages/dashboard/CustomerList';
import CustomerDetails from './pages/dashboard/CustomerDetails';
import UserList from './pages/dashboard/UserList';
import NewCustomer from './pages/dashboard/NewCustomer';

import PageNotFound from './components/PageNotFound';

import {
  selectCurrentPartner,
  selectPartners,
  selectIsLoggedIn,
} from './redux/selectors/authentication';
import NewPassword from './pages/public/NewPassword/NewPassword';
import { doReadProfile } from './redux/thunks/authentication';
import { refreshAccessToken } from './utils/Accounts';

const PublicRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to={routes.public.login} />;
        }}
      />
      <BaseLayout exact path={routes.public.login} component={Login} />
      <BaseLayout exact path={routes.public.changePassword} component={NewPassword} />
      <BaseLayout exact path={routes.public.forgotPassword} component={ForgotPassword} />
      <BaseLayout exact path={routes.public.resetPassword.phoneNumber} component={ResetPassword} />
      <BaseLayout path={'*'} component={PageNotFound} />
    </Switch>
  );
};

const DashboardRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to={routes.dashboard.home} />;
        }}
      />
      <Route
        exact
        path="/login"
        render={() => {
          return <Redirect to={routes.dashboard.home} />;
        }}
      />
      <DashboardLayout exact path={routes.dashboard.home} component={DashboardHome} />
      <DashboardLayout exact path={routes.customer.list} component={CustomerList} />
      <DashboardLayout exact path={routes.customer.addNew} component={NewCustomer} />
      <DashboardLayout
        exact
        path={routes.customer.customerId.details}
        component={CustomerDetails}
      />

      <DashboardLayout exact path={routes.user.list} component={UserList} />
      <DashboardLayout path={'*'} component={PageNotFound} />
    </Switch>
  );
};

const Router = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const get = async () => {
      await refreshAccessToken();
    };
    get();

    dispatch(doReadProfile());
  }, [dispatch]);
  const { currentUser, partners, isLoggedIn } = useSelector((state) => ({
    currentUser: selectCurrentPartner(state),
    partners: selectPartners(state),
    isLoggedIn: selectIsLoggedIn(state),
  }));
  return !!currentUser && !!partners && isLoggedIn ? DashboardRoutes() : PublicRoutes();
};

export default Router;
