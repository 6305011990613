import React, { Fragment, useRef, useState } from 'react';

// packages
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// styles
import inputStyles from '../styles.module.scss';
import '../input.scss';

// helpers
import { userEntersNumber } from '../../../helpers/utils/formValidations';

const PhoneNumberInput = ({
  error,
  name,
  defaultCountryCode = '91',
  handleCountryCode,
  ...props
}) => {
  const inputRef = useRef(null);
  const [focused, setFocused] = useState(false);

  return (
    <Fragment>
      <div
        className={classnames(
          inputStyles['input_field'],
          error && inputStyles['danger'],
          (focused || inputRef?.current?.value) && !error && inputStyles['focused'],
        )}
      >
        <Fragment>
          <div className={classnames(inputStyles['phone-input-wrapper'])}>
            <p className={classnames(inputStyles['iso-text'])}>{`+${defaultCountryCode} `}</p>
            <PhoneInput
              value={defaultCountryCode}
              enableSearch
              onChange={(_, data) => {
                handleCountryCode(data?.dialCode);
              }}
            />
          </div>
        </Fragment>
        {props?.placeholder && (
          <p
            className={classnames(
              inputStyles['label'],
              focused || inputRef?.current?.value || props?.value
                ? inputStyles['label_active']
                : inputStyles['label_none'],
            )}
          >
            {props?.placeholder}
          </p>
        )}
        <div
          onClick={() => {
            inputRef?.current?.focus();
          }}
        >
          <input
            ref={inputRef}
            name={name}
            placeholder={!focused ? props?.placeholder : ''}
            onKeyPress={(event) => {
              if (!userEntersNumber(event?.key) && props?.type === 'number') event.preventDefault();
            }}
            onFocus={(e) => {
              props?.onFocus && props.onFocus(e);
              setFocused(true);
            }}
            onBlur={(e) => {
              props?.onBlur && props?.onBlur(e);
              setFocused(false);
            }}
            type={'number'}
            onWheel={(event) => event.currentTarget.blur()}
            autoComplete={true}
            {...props}
          />
        </div>
      </div>
      {error && <p className={inputStyles['error']}>{error}</p>}
    </Fragment>
  );
};

PhoneNumberInput.propTypes = {
  defaultCountryCode: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  handleCountryCode: PropTypes.func.isRequired,
};

export default React.memo(PhoneNumberInput);
