import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectMessageModal } from '../../redux/selectors/miscellaneous';
import { doDisplayMessageModal } from '../../redux/actionCreators/miscellaneous';
import styles from './style.module.scss';
import Modal from '../../components/Modals/Modal';
import Button from '../../components/Buttons/Button';

const resetState = {
  messageModal: {
    show: false,
    type: '',
    title: '',
    description: '',
    handleClose: null,
    handleSubmit: null,
    cancelButtontext: '',
    submitButtontext: '',
  },
};

const MessageModal = () => {
  const messageModalData = useSelector(selectMessageModal);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(doDisplayMessageModal(resetState));
  };
  return (
    <Fragment>
      <Modal showModal={messageModalData.show} type={messageModalData.type}>
        <div className={styles['modal_container']}>
          {messageModalData.title && (
            <p className={styles['modal_container_header']}>{messageModalData.title}</p>
          )}
          {messageModalData.description && (
            <p className={styles['modal_container_text']}>{messageModalData.description}</p>
          )}

          <div className={styles['modal_container_button_container']}>
            <Button
              onClick={messageModalData.handleClose ? messageModalData.handleClose : closeModal}
              shadow={false}
              buttonType={messageModalData.handleSubmit ? 'secondary' : 'danger'}
              buttonSize="button-small"
              text={
                messageModalData.cancelButtontext ? messageModalData.cancelButtontext : 'Cancel'
              }
            />
            {messageModalData.handleSubmit && (
              <Button
                onClick={messageModalData.handleSubmit}
                shadow={false}
                buttonType="danger"
                buttonSize="button-small"
                text={
                  messageModalData.submitButtontext ? messageModalData.submitButtontext : 'Done'
                }
              />
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default MessageModal;
