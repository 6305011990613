import { useState, useCallback, useEffect } from 'react';

// routes
import { useParams } from 'react-router-dom';

// helpers
import { isValidPhoneNumber } from 'libphonenumber-js';

// components
import PhoneInput from '../../../components/Inputs/PhoneInput';
import Button from '../../../components/Buttons/Button';

// styles
import styles from '../styles.module.scss';

// redux
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../../redux/selectors/miscellaneous';
import { getCountryCode } from '../../../utils/countryCode';

const RecoverPasswordForm = ({ handleSubmitOtp }) => {
  const { phoneNumber } = useParams();
  const [countryCode, setCountryCode] = useState('91');
  const [mobileNumber, setMobileNumber] = useState({
    value: phoneNumber || '',
    error: '',
  });
  const isLoading = useSelector(selectIsLoading);

  const disabled = mobileNumber.value === '' || mobileNumber.error !== '' || isLoading;

  useEffect(() => {
    try {
      const code = getCountryCode();
      if (code) setCountryCode(code?.countryCode);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handlesubmit = useCallback(
    (e) => {
      if (!disabled) {
        e.preventDefault();
        handleSubmitOtp({ phoneNumber: `+${countryCode}${mobileNumber.value}` });
      }
    },
    [countryCode, disabled, handleSubmitOtp, mobileNumber.value],
  );

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e?.currentTarget;
      const errorText = 'Please enter a valid';
      if (name === 'phoneNumber') {
        const error = !isValidPhoneNumber(`+${countryCode}${value}`)
          ? `${errorText} Phone Number`
          : '';
        setMobileNumber({ value, error });
      }
    },
    [countryCode],
  );

  return (
    <div className={styles['form']}>
      <h1>Recover Password</h1>
      <form>
        <PhoneInput
          name="phoneNumber"
          placeholder="Phone Number"
          type="number"
          value={mobileNumber.value}
          onChange={handleChange}
          error={mobileNumber.error}
          handleCountryCode={(code) => setCountryCode(code)}
        />
        <div className={styles['buttonContainer']}>
          <Button onClick={handlesubmit} type="submit" text="SEND OTP" disabled={disabled} />
        </div>
      </form>
    </div>
  );
};

export default RecoverPasswordForm;
