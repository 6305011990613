const Device = () => {
  return (
    <svg
      id="noun_device_1139320"
      xmlns="http://www.w3.org/2000/svg"
      width="41.962"
      height="41.959"
      viewBox="0 0 41.962 41.959"
    >
      <g id="Group_8164" data-name="Group 8164" transform="translate(0 0)">
        <path
          id="Path_2104"
          data-name="Path 2104"
          d="M18.371,17.946l1.1,1.357a9.52,9.52,0,0,1,5.66-1.553,9.74,9.74,0,0,1,5.51,1.437l1.036-1.409A11.337,11.337,0,0,0,25.132,16,11.118,11.118,0,0,0,18.371,17.946Z"
          transform="translate(-4.059 -3.763)"
          fill="#353f94"
        />
        <path
          id="Path_2105"
          data-name="Path 2105"
          d="M34.885,12.447C32.537,10.29,28.71,9,24.649,9c-4.293,0-8.24,1.4-10.561,3.757l1.245,1.228c1.969-2,5.538-3.236,9.316-3.236,3.635,0,7.019,1.115,9.053,2.984Z"
          transform="translate(-3.521 -2.882)"
          fill="#353f94"
        />
        <path
          id="Path_2106"
          data-name="Path 2106"
          d="M24.334,3.75c4.6,0,8.882,1.416,11.464,3.788L36.981,6.25C34.085,3.59,29.357,2,24.334,2,19.025,2,14.148,3.733,11.289,6.633l1.245,1.228C15.072,5.287,19.483,3.75,24.334,3.75Z"
          transform="translate(-3.168 -2.002)"
          fill="#353f94"
        />
        <path
          id="Path_2107"
          data-name="Path 2107"
          d="M23.856,35.986V28.868a3.5,3.5,0,1,0-1.748,0v7.117H2V46.476H43.962V35.986ZM11.617,42.108H7.246V40.36h4.371Zm27.1,0H34.347V40.36h4.371Z"
          transform="translate(-2 -4.517)"
          fill="#353f94"
        />
      </g>
    </svg>
  );
};

export default Device;
