import { Box, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import styles from '../../../../../../styles/modals.module.scss';
import Button from '../../../../../../components/Buttons/Button';
import Modal from '../../../../../../components/Modals/Modal';
import ModalAlert from '../../../../../../components/Modals/ModalAlert';
import {
  emailVerification,
  phonenumberVerification,
  textVerification,
} from '../../../../../../utils/formValdations';

import { findPhoneNumbersInText } from 'libphonenumber-js';
import TextInput from '../../../../../../components/Inputs/TextInput';
import PhoneInput from '../../../../../../components/Inputs/PhoneInput';
import SquareEdit from '../../../../../../components/SquareEdit/SquareEdit';

// import EditCustomerAdminPopup from './EditCustomerAdminPopup';
// import EditCustomerAdminPopup from './EditCustomerAdminPopup';

const errorText = 'Please enter a valid';

const HandOverPopup = ({ showModal, closeModal, onClick, adminData, handleUpdateAdmin }) => {
  const [showHandoverWarning, setShowHandoverWarning] = useState(false);
  const [adminName, setAdminName] = useState({ value: '', error: '' });
  const [adminEmail, setAdminEmail] = useState({ value: '', error: '' });
  const [adminPhoneNumber, setAdminPhoneNumber] = useState({ value: '', error: '' });
  const [countryCode, setCountryCode] = useState('91');
  const [mode, setMode] = useState('add');

  // const [showEditModal, setShowEditModal] = useState(false);
  const WhiteTextTypography = withStyles({
    root: {
      color: '#483f94',
    },
  })(Typography);

  const boxStyle = {
    border: '1px solid #D3D3D3',
    borderRadius: '8px',
    padding: '20px',
    marginTop: '12px',
  };

  const handleClick = () => {
    setShowHandoverWarning(true);
  };

  useEffect(() => {
    if (adminData) {
      setAdminName({ ...adminEmail, value: adminData?.name || '' });
      setAdminEmail({ ...adminEmail, value: adminData?.email || '' });
      try {
        const mobileNumber = findPhoneNumbersInText(adminData?.phone)[0];

        const { number } = mobileNumber;
        setCountryCode(number?.countryCallingCode || '91');
        setAdminPhoneNumber({ ...adminPhoneNumber, value: number?.nationalNumber || '' });
      } catch (error) {
        setAdminPhoneNumber({ ...adminPhoneNumber, value: adminData.phone || '' });
      }
      // setEditForm(admin?.editable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminData]);

  const handleSubmit = () => {
    onClick();
    setShowHandoverWarning(false);
    closeModal();
  };
  const handleSubmitEdit = () => {
    const phoneNumber = `+${countryCode}${adminPhoneNumber.value}`;
    const payload = {
      name: adminName.value,
      phone: phoneNumber,
      email: adminEmail.value,
      adminType: 2,
    };

    handleUpdateAdmin(payload);
    closeModal();
  };

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;

    let error = '';
    switch (name) {
      case 'adminName':
        error = textVerification(value, 2) ? `${errorText} admin name` : '';
        setAdminName({ value, error });
        break;
      case 'adminEmail':
        error = !emailVerification(value) ? `${errorText} admin email` : '';
        setAdminEmail({ value, error });
        break;
      case 'adminPhoneNumber':
        error = !phonenumberVerification(`+${countryCode}${value}`)
          ? `${errorText} phone number`
          : '';
        setAdminPhoneNumber({ value, error });
        break;

      default:
        break;
    }
  };

  const disabled = () => {
    if (adminEmail.value === '' || adminName.value === '' || adminPhoneNumber.value === '') {
      return true;
    }
    if (adminEmail.error !== '' || adminName.error !== '' || adminPhoneNumber.error !== '') {
      return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        showModal={showModal}
        onClose={closeModal}
        style={{
          width: '100%',
          maxWidth: '600px',
        }}
      >
        {mode === 'add' ? (
          <div className={styles['wrapper']}>
            <h1 style={{ fontSize: '22px' }}>Handover Organisation</h1>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '-15px',
              }}
            >
              <WhiteTextTypography variant="h3">
                Once handover is complete partner admin will be deleted and replaced by customer
                super admin. Please confirm super admin details before handover
              </WhiteTextTypography>
            </Box>
            <Box style={boxStyle}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3" style={{ color: 'black' }}>
                  {adminData?.name}
                </Typography>
                <SquareEdit
                  color={'#ff535a'}
                  onPress={() => {
                    setMode('edit');
                  }}
                  style={{ height: '30px', width: '30px' }}
                />
              </Box>
              <Box display="flex" marginTop={1}>
                <Typography variant="caption" style={{ color: '#7f7f7f', fontSize: '14px' }}>
                  Phone : {adminData?.phone}
                </Typography>
                <Box marginLeft={3}></Box>
                <Typography
                  variant="caption"
                  style={{ marginLeft: '10px', color: '#7f7f7f', fontSize: '14px' }}
                >
                  Email : {adminData?.email}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              margin={3}
              width="90%"
            >
              <Button
                text="Cancel"
                buttonSize="button-small"
                buttonType="secondary"
                onClick={closeModal}
              />
              <Button
                text="Handover"
                buttonSize="button-small"
                onClick={handleClick}
                // style={{ width: '100px' }}
              />
            </Box>
          </div>
        ) : (
          <div className={styles['wrapper']}>
            <h1 style={{ fontSize: '22px' }}>Edit super admin details</h1>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '-15px',
              }}
            >
              <WhiteTextTypography variant="h3">
                Enter super admin details. Initiate handover once user signs up
              </WhiteTextTypography>
              {/*
               */}
            </Box>
            <Box style={boxStyle}>
              <Box display="flex" justifyContent="space-between">
                <TextInput
                  name="adminName"
                  placeholder="Admin Name"
                  value={adminName.value}
                  onChange={handleChange}
                  disabled={false}
                  error={adminName.error}
                />
                <div style={{ marginLeft: '10px' /* Adjust the value to control the gap */ }} />
                <PhoneInput
                  name="adminPhoneNumber"
                  placeholder="Admin Phone Number"
                  type="number"
                  defaultCountryCode={countryCode}
                  value={adminPhoneNumber.value}
                  onChange={handleChange}
                  disabled={false}
                  error={adminPhoneNumber.error}
                  handleCountryCode={(code) => setCountryCode(code)}
                />
              </Box>

              <TextInput
                name="adminEmail"
                placeholder="Admin Email Address"
                type="email"
                value={adminEmail.value}
                onChange={handleChange}
                disabled={false}
                error={adminEmail.error}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              margin={3}
              width="90%"
            >
              <Button
                text="Cancel"
                buttonSize="button-small"
                buttonType="secondary"
                onClick={() => {
                  setMode('add');
                }}
              />
              <Button
                text="Save"
                buttonSize="button-small"
                onClick={handleSubmitEdit}
                disabled={disabled()}
                // style={{ width: '100px' }}
              />
            </Box>
          </div>
        )}
      </Modal>
      {showHandoverWarning && (
        <ModalAlert
          showModal={showHandoverWarning}
          closeModal={() => setShowHandoverWarning(false)}
          handleSubmit={() => handleSubmit()}
          title={`Warning!`}
          description={`Once handover is complete partner admin  will lose all access to the organisation and will be deleted from the organisation. Are you sure you want to continue?`}
          modalType={'danger'}
          understand={true}
        />
      )}
    </>
  );
};

export default HandOverPopup;
