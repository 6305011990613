export const intersection = (a, b) => {
  return a.filter((value) => !b.find((o) => o === value.serialNumber));
};

export const recLookup = (obj, path) => {
  if (!path) return undefined;

  // eslint-disable-next-line prefer-const
  let paths = String(path).split('.'),
    current = { ...obj },
    i;

  if (paths.length <= 1) {
    return current[paths[0]];
  }

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]]) {
      current = current[paths[i]];
    } else {
      return undefined;
    }
  }
  return current;
};

export const intersectionMesh = (a, b) => {
  return a.filter((value) => b.find((o) => o.serialNumber === value.serialNumber));
};

export const statusText = (status) => {
  switch (status) {
    case 0:
      return <p className="text-red">{` ${capitalizeFirstLetter('offline')}`}</p>;
    case 1:
      return <p className="text-green">{` ${capitalizeFirstLetter('online')}`}</p>;

    default:
      return <p className="text-dark-blue">{` ${capitalizeFirstLetter('unassigned')}`}</p>;
  }
};

export const meshConfiguredStateRender = (status) => {
  switch (status) {
    case 1:
      return <p className="text-red">{` ${capitalizeFirstLetter('Not configured')}`}</p>;
    case 2:
      return <p className="text-green">{` ${capitalizeFirstLetter('Configured')}`}</p>;
    case 3:
      return <p className="text-dark-blue">{` ${capitalizeFirstLetter('Reset Pending')}`}</p>;
    case 4:
      return <p className="text-dark-blue">{` ${capitalizeFirstLetter('Configure Pending')}`}</p>;
    case 5:
      return <p className="text-dark-blue">{` ${capitalizeFirstLetter('Reconfigure Pending')}`}</p>;
    case 6:
      return <p className="text-red">{` ${capitalizeFirstLetter('Configure Failed')}`}</p>;
    case 7:
      return <p className="text-red">{` ${capitalizeFirstLetter('Reset Failed')}`}</p>;

    default:
      return <p className="text-dark-blue">{` ${capitalizeFirstLetter('Unindentified')}`}</p>;
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
