/* eslint-disable import/prefer-default-export */

export const extractErrorMessage = (error) => {
  let errorMessage = null;
  if (error.response) {
    const message = error.response.data.message;
    errorMessage = message;
  }

  if (error.message && error.message === 'Incorrect username or password.') {
    errorMessage = error.message;
  }

  return errorMessage;
};
