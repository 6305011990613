// todo compare with original file

import { useState, useCallback, useEffect } from 'react';

// components
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Buttons/Button';
import PhoneInput from '../../../components/Inputs/PhoneInput';

// styles
import styles from '../styles.module.scss';

//  helpers

// redux
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../../redux/selectors/miscellaneous';
import { getCountryCode } from '../../../utils/countryCode';
import { phonenumberVerification } from '../../../utils/formValdations';

const LoginForm = ({ handleLoginAttempt }) => {
  const [countryCode, setCountryCode] = useState('91');
  const [phoneNumber, setPhoneNumber] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });

  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    try {
      const code = getCountryCode();
      if (code) setCountryCode(code?.countryCode);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;

    const errorText = 'Please enter a valid';
    if (name === 'phoneNumber') {
      const error = !phonenumberVerification(`+${countryCode}${value}`)
        ? `${errorText} Phone Number`
        : '';
      setPhoneNumber({ value, error });
    }
    if (name === 'password') {
      const error = value.length <= 4 ? `${errorText} password` : '';
      setPassword({ value, error });
    }
  };

  const handlesubmit = useCallback(
    (e) => {
      e.preventDefault();
      handleLoginAttempt({
        phoneNumber: `+${countryCode}${phoneNumber?.value}`,
        password: password.value,
      });
    },
    [handleLoginAttempt, countryCode, phoneNumber?.value, password.value],
  );

  const disabled = () => {
    if (
      phoneNumber?.value === '' ||
      phoneNumber?.error !== '' ||
      password?.value === '' ||
      password?.error !== '' ||
      isLoading
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles['form']}>
      <h1>Login</h1>
      <form>
        <PhoneInput
          name="phoneNumber"
          placeholder="Phone number"
          type="number"
          value={phoneNumber?.value}
          onChange={handleChange}
          error={phoneNumber?.error}
          defaultCountryCode={countryCode}
          handleCountryCode={(code) => setCountryCode(code)}
        />

        <TextInput
          name="password"
          placeholder="Password"
          type="password"
          value={password?.value}
          onChange={handleChange}
          error={password.error}
        />
        <div className={styles['buttonContainer']}>
          <Button onClick={handlesubmit} type="submit" text="Login" disabled={disabled()} />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
