import { push } from 'connected-react-router';
import * as actionCreators from '../actionCreators/authentication';
import * as UseractionCreators from '../actionCreators/user';
import * as misc from '../actionCreators/miscellaneous';
import * as authenticationAPIs from '../../services/api/authentication';
import { logout, setAccessToken } from '../../utils/Accounts';
import routes from '../../constants/routes';
import { reverse } from 'named-urls';
import { extractErrorMessage } from '../helpers';

export const doLogin = (payload) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch(actionCreators.doLogin());
    await authenticationAPIs.Login(payload, dispatch);
    await Promise.allSettled([dispatch(doReadProfile())]);

    dispatch(actionCreators.doLoginSuccess());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message: 'Logged In Successfully' }));
    dispatch(push(routes.dashboard.home));
  } catch (error) {
    const message = extractErrorMessage(error);

    dispatch(actionCreators.doLoginFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doReadProfile = () => async (dispatch) => {
  try {
    dispatch(actionCreators.doReadProfile());
    await setAccessToken();
    const { data } = await authenticationAPIs.ReadProfile();
    const { data: messageData } = await authenticationAPIs.ReadErrorMessage();
    dispatch(actionCreators.doReadProfileSuccess(data));
    return dispatch(UseractionCreators.doReadMessageSuccess(messageData));
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
    return dispatch(actionCreators.doReadProfileFailure());
  }
};

export const doReadPartners = () => async (dispatch) => {
  try {
    dispatch(actionCreators.doReadPartners());
    await setAccessToken();
    const { data } = await authenticationAPIs.ReadPartners();
    return dispatch(actionCreators.doReadPartnersSuccess(data));
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
    return dispatch(actionCreators.doReadPartnersFailure());
  }
};

export const doLogout = () => (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch({ type: 'auth/AUTH_LOGOUT_REQUESTED' });
    logout();
    dispatch({ type: 'auth/AUTH_LOGOUT_SUCCEEDED' });
    dispatch(push(routes.public.login));
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message: 'Logged Out Successfully' }));
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(actionCreators.doLogoutFailure());
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const doSendForgotPasswordOTP =
  ({ phoneNumber }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doSendForgotPasswordOTP());
      await authenticationAPIs.SendForgotPasswordOTP({ phoneNumber });
      setTimeout(() => {
        dispatch(actionCreators.doSendForgotPasswordOTPSuccess());
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({ isVisible: true, message: 'Check Phone for the code' }),
        );
        dispatch(push(reverse(`${routes.public.resetPassword.phoneNumber}`, { phoneNumber })));
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      setTimeout(() => {
        dispatch(actionCreators.doSendForgotPasswordOTPFailure());
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(misc.doCreateNotification({ isVisible: true, message }));
      }, 3000);
    }
  };

export const doResetPassword =
  ({ phoneNumber, code, newPassword }) =>
  async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      dispatch(actionCreators.doResetPassword());
      await authenticationAPIs.ResetPassword({ phoneNumber, code, newPassword });
      setTimeout(() => {
        dispatch(actionCreators.doResetPasswordSuccess());
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(
          misc.doCreateNotification({ isVisible: true, message: 'Password Changed Successfully' }),
        );
        dispatch(push(routes.public.login));
      }, 3000);
    } catch (error) {
      const message = extractErrorMessage(error);
      setTimeout(() => {
        dispatch(actionCreators.doResetPasswordFailure());
        dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
        dispatch(misc.doCreateNotification({ isVisible: true, message }));
      }, 3000);
    }
  };
export const doChangePassword = (payload, value) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    dispatch(actionCreators.doResetPassword());
    await authenticationAPIs.NewPassword(payload, value, dispatch);

    // await Promise.allSettled([dispatch(doLogin(payload))]);
    // setTimeout(() => {
    dispatch(actionCreators.doResetPasswordSuccess());

    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(
      misc.doCreateNotification({ isVisible: true, message: 'Password Changed Successfully' }),
    );
    dispatch(push(routes.dashboard.home));
    // }, 3000);
  } catch (error) {
    const message = extractErrorMessage(error);
    setTimeout(() => {
      dispatch(actionCreators.doResetPasswordFailure());
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
    }, 3000);
  }
};
