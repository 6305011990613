import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/role';

const defaultState = {
  roleList: null,
};

const roleReducer = handleActions(
  {
    [actionCreators.doReadRoleList]: (state) => {
      return { ...state };
    },
    [actionCreators.doReadRoleListSuccess]: (
      state,
      {
        payload: {
          message: { roles },
        },
      },
    ) => {
      return {
        ...state,
        roleList: roles,
      };
    },
    [actionCreators.doReadRoleListFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doRoleListReset]: (state) => {
      return { ...state, roleList: null };
    },
  },
  defaultState,
);

export default roleReducer;
