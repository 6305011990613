import React, { useCallback } from 'react';
import styles from './Table.module.scss';
import { recLookup } from '../../utils/Common';

const TableData = ({ data, columns, emptyValue }) => {
  const renderData = useCallback(
    (rowdata, index, columnProps, colIndex) => {
      // console.log('rowdata,index, columnProps, colIndex', rowdata, index, columnProps, colIndex);
      if (columnProps?.action) {
        return (
          <span
            data-row={index}
            data-col={colIndex}
            {...columnProps.action?.props}
            onClick={(e) => columnProps.action?.props?.onClick(e, rowdata)}
          >
            {columnProps.action?.icon ? (
              <></>
            ) : columnProps.action?.component ? (
              <columnProps.action.component.type
                {...columnProps.action.component.props}
                data-row={index}
                data-col={colIndex}
                row={index}
                col={colIndex}
                name={columnProps?.name}
              />
            ) : columnProps.action?.func ? (
              columnProps.action?.func({
                row: index,
                col: colIndex,
                data: rowdata,
                name: columnProps?.name,
                title: columnProps?.title,
              })
            ) : null}
          </span>
        );
      } else if (columnProps?.render) {
        return columnProps.render(columnProps, rowdata, index, colIndex) || emptyValue;
      } else if (columnProps?.field) {
        const val = recLookup(rowdata, columnProps.field);
        //   if (c.type) {
        //     if (c.type == 'date' || c.type == 'daterange') {
        //       let l = c?.locale || options?.locale || locale.fullVS;
        //       return (val && formatDate(val, l)) || emptyValue;
        //     } else if (c.type == 'time') {
        //       return (val && formatDate(val, c?.locale || 'p')) || emptyValue;
        //     }
        //   }

        //   return val || c?.emptyValue || emptyValue;
        return val || emptyValue;
      }
    },
    [emptyValue],
  );

  const TData = useCallback(
    (index, rowdata) => {
      return columns?.map((columnProps, colIndex) => {
        return (
          <td
            key={colIndex}
            className={columnProps.action ? 'action' : ''}
            {...columnProps?.props?.cell}
          >
            {renderData(rowdata, index, columnProps, colIndex)}
          </td>
        );
      });
    },
    [columns, renderData],
  );

  return (
    <>
      {Array.isArray(data) &&
        data?.map((rowdata, index) => {
          return (
            <tr className={[styles['t_r']]} key={index}>
              {TData(index, rowdata)}
            </tr>
          );
        })}
    </>
  );
};

export default TableData;
