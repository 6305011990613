import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/organisation';

const defaultState = {
  organisationList: null,
  organisation: null,
  sites: [],
  gateways: [],
  accessPoints: [],
  admins: null,
  acus: null,
  repeaters: [],
  gatewayLog: [],
  acuLog: null,
};

const organisationReducer = handleActions(
  {
    [actionCreators.doReadOrganisationList]: (state) => {
      return { ...state };
    },
    [actionCreators.doReadOrganisationListSuccess]: (state, { payload: { message = {} } }) => {
      return {
        ...state,
        organisationList: message,
      };
    },
    [actionCreators.doReadOrganisationListFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationListReset]: (state) => {
      return { ...state, organisationList: null };
    },
    [actionCreators.doCreateOrganisation]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisation]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisation]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doReadOrganisation]: (state) => {
      return { ...state, organisation: null };
    },
    [actionCreators.doReadOrganisationSuccess]: (state, { payload: { message = {} } }) => {
      return {
        ...state,
        organisation: message,
      };
    },
    [actionCreators.doReadOrganisationFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationReset]: (state) => {
      return { ...state, organisation: null };
    },
    [actionCreators.doReadOrganisationSites]: (state) => {
      return { ...state, sites: null };
    },
    [actionCreators.doReadOrganisationSitesSuccess]: (
      state,
      {
        payload: {
          message: { sites = null },
        },
      },
    ) => {
      return {
        ...state,
        sites,
      };
    },
    [actionCreators.doReadOrganisationSitesFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationSitesReset]: (state) => {
      return { ...state, sites: null };
    },
    [actionCreators.doReadOrganisationGateways]: (state) => {
      return { ...state, gateways: null };
    },
    [actionCreators.doReadOrganisationGatewaysSuccess]: (
      state,
      {
        payload: {
          message: { gateways = null },
        },
      },
    ) => {
      return {
        ...state,
        gateways,
      };
    },
    [actionCreators.doReadOrganisationGatewaysFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationGatewaysReset]: (state) => {
      return { ...state, gateways: [] };
    },
    [actionCreators.doReadOrganisationAdmins]: (state) => {
      return { ...state, admins: null };
    },
    [actionCreators.doReadOrganisationAdminsSuccess]: (
      state,
      {
        payload: {
          message: { admins = null },
        },
      },
    ) => {
      return {
        ...state,
        admins,
      };
    },
    [actionCreators.doReadOrganisationAdminsFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationAdminsReset]: (state) => {
      return { ...state, admins: null };
    },
    [actionCreators.doReadOrganisationAccessPoints]: (state) => {
      return { ...state, accessPoints: null };
    },
    [actionCreators.doReadOrganisationAccessPointsSuccess]: (
      state,
      {
        payload: {
          message: { accessPoints = null },
        },
      },
    ) => {
      return {
        ...state,
        accessPoints,
      };
    },
    [actionCreators.doReadOrganisationAccessPointsFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationAccessPointsReset]: (state) => {
      return { ...state, accessPoints: null };
    },
    [actionCreators.doReadOrganisationACUS]: (state) => {
      return { ...state, acus: null };
    },
    [actionCreators.doReadOrganisationACUSSuccess]: (
      state,
      {
        payload: {
          message: { acus = null },
        },
      },
    ) => {
      return {
        ...state,
        acus,
      };
    },
    [actionCreators.doReadOrganisationACUSFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationACUSReset]: (state) => {
      return { ...state, acus: null };
    },
    [actionCreators.doReadOrganisationRepeaters]: (state) => {
      return { ...state, repeaters: null };
    },
    [actionCreators.doReadOrganisationRepeatersSuccess]: (
      state,
      {
        payload: {
          message: { repeaters = null },
        },
      },
    ) => {
      return {
        ...state,
        repeaters,
      };
    },
    [actionCreators.doReadOrganisationRepeatersFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationRepeatersReset]: (state) => {
      return { ...state, repeaters: null };
    },
    [actionCreators.doReadOrganisationGatewayLog]: (state) => {
      return { ...state, gatewayLog: null };
    },
    [actionCreators.doReadOrganisationGatewayLogSuccess]: (
      state,
      {
        payload: {
          message: { gatewayLog = null },
        },
      },
    ) => {
      return {
        ...state,
        gatewayLog,
      };
    },
    [actionCreators.doReadOrganisationGatewayLogFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationGatewayLogReset]: (state) => {
      return { ...state, gatewayLog: null };
    },
    [actionCreators.doReadOrganisationACULog]: (state) => {
      return { ...state, acuLog: null };
    },
    [actionCreators.doReadOrganisationACULogSuccess]: (
      state,
      {
        payload: {
          message: { acuLog = null },
        },
      },
    ) => {
      return {
        ...state,
        acuLog,
      };
    },
    [actionCreators.doReadOrganisationACULogFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doOrganisationACULogReset]: (state) => {
      return { ...state, acuLog: null };
    },
    [actionCreators.doCreateOrganisationSite]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationSiteSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationSiteFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationSite]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationSiteSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationSiteFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationSite]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationSiteSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationSiteFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationAccessPoint]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationAccessPointSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationAccessPointFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationAccessPoint]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationAccessPointSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationAccessPointFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationAccessPoint]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationAccessPointSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationAccessPointFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationGateway]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationGatewaySuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationGatewayFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationGateway]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationGatewaySuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationGatewayFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationGateway]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationGatewaySuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationGatewayFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationAdmin]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationAdminSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doCreateOrganisationAdminFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationAdmin]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationAdminSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doUpdateOrganisationAdminFailure]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationAdmin]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationAdminSuccess]: (state) => {
      return { ...state };
    },
    [actionCreators.doDeleteOrganisationAdminFailure]: (state) => {
      return { ...state };
    },
  },
  defaultState,
);

export default organisationReducer;
