import { Container, Grid, Step, StepButton, Stepper, Typography } from '@material-ui/core';
import { useImmer } from 'use-immer';
import Dropdown from '../../../../../../components/Inputs/Dropdown/Dropdown';
import TextInput from '../../../../../../components/Inputs/TextInput/TextInput';
import Modal from '../../../../../../components/Modals/Modal/Modal';
import styles from '../../../../../../styles/modals.module.scss';
import CustomButton from '../../../../../../components/Buttons/Button/Button';

import '../Controllers.scss';
import ControllerChannel from '../Components/ControllerChannel';
import OutlineButton from '../../../../../../components/OutlineButton/OutlineButton';
import {
  CONTROLLER_CONFIGS,
  controllerSerialConfigs,
} from '../../../../../../constants/list/AccessPointTypeList';
import { useSelector, useDispatch } from 'react-redux';
import { selectOrganisationSites } from '../../../../../../redux/selectors/organisation';
import { doAddController } from '../../../../../../redux/thunks/controllers';
import SingleChannelContoller from '../Components/SingleChannelContoller';
import { useEffect, useState } from 'react';
// import { useState } from 'react';

const INCORRECT_ERROR = "Serial number doesn't support the configuration";

const AddControllerDialog = ({ currentUser, customerId, isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const { sites } = useSelector((state) => ({
    sites: selectOrganisationSites(state),
  }));

  const [activeStep, setActiveStep] = useImmer(0);
  const [serialNumber, setSerialNumber] = useImmer({ value: '', description: '', error: '' });
  const [installationMethod, setInstallationMethod] = useImmer({ name: '', value: '' });
  const [configuration, setConfiguration] = useImmer({ name: '', value: '', validSerials: [] });
  const [site, setSite] = useImmer({ name: '', value: '' });
  const [network, setNetwork] = useImmer({ name: '', value: '' });
  const [channel1enabled, setChannel1enabled] = useImmer(false);
  const [channel2enabled, setChannel2enabled] = useImmer(false);
  const [error, setError] = useState('');

  const [type, setType] = useImmer('');

  const [channel1, setChannel1] = useState({
    accessPointName: '',
    serialNumber: '',
    lockingMechanism: '',
    relayTime: 6,
    invertRelay: false,
  });
  const [channel2, setChannel2] = useState({
    accessPointName: '',
    serialNumber: '',
    lockingMechanism: '',
    relayTime: 6,
    invertRelay: false,
  });

  const submitForm = () => {
    let payload;

    if (type === '1door') {
      if (configuration.value === 3) {
        payload = [
          {
            name: channel1.accessPointName,
            installationMethod: installationMethod.value,
            configuration: configuration.value,
            lockingMechanism: channel1.lockingMechanism.value,
            siteId: site?.value,
            networkId: network.id,
            channelNo: 0,
            devices: [
              {
                serialNumber: serialNumber.value,
                deviceType: 3,
              },
              {
                serialNumber: channel1.serialNumber,
                deviceType: 1,
              },
            ],
            RelaySettings: {
              relayOnTime: channel1?.relayTime,
              invertRelayLogic: channel1?.invertRelay,
            },
            enableAttendance: false,
          },
        ];
      } else {
        payload = [
          {
            name: channel1.accessPointName,
            installationMethod: installationMethod.value,
            configuration: configuration.value,
            lockingMechanism: channel1.lockingMechanism.value,
            siteId: site?.value,
            networkId: network.id,
            channelNo: 0,
            devices: [
              {
                serialNumber: serialNumber.value,
                deviceType: 3,
              },
            ],
            RelaySettings: {
              relayOnTime: channel1?.relayTime,
              invertRelayLogic: channel1?.invertRelay,
            },
            enableAttendance: false,
          },
        ];
      }
    } else {
      if (configuration.value === 3) {
        if (channel2enabled) {
          payload = [
            {
              name: channel1.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel1.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.id,
              channelNo: 1,
              devices: [
                {
                  serialNumber: channel1.serialNumber,
                  deviceType: 1,
                },
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel1?.relayTime,
                invertRelayLogic: channel1?.invertRelay,
              },
              enableAttendance: false,
            },
            {
              name: channel2.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel2.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.id,
              channelNo: 2,
              devices: [
                {
                  serialNumber: channel2.serialNumber,
                  deviceType: 1,
                },
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel2?.relayTime,
                invertRelayLogic: channel2?.invertRelay,
              },
              enableAttendance: false,
            },
          ];
        } else {
          payload = [
            {
              name: channel1.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel1.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.id,
              channelNo: 1,
              devices: [
                {
                  serialNumber: channel1.serialNumber,
                  deviceType: 1,
                },
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel1?.relayTime,
                invertRelayLogic: channel1?.invertRelay,
              },
              enableAttendance: false,
            },
          ];
        }
      } else {
        if (channel2enabled) {
          payload = [
            {
              name: channel1.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel1.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.id,
              channelNo: 1,
              devices: [
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel1?.relayTime,
                invertRelayLogic: channel1?.invertRelay,
              },
              enableAttendance: false,
            },
            {
              name: channel2.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel2.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.id,
              channelNo: 2,
              devices: [
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel2?.relayTime,
                invertRelayLogic: channel2?.invertRelay,
              },
              enableAttendance: false,
            },
          ];
        } else {
          payload = [
            {
              name: channel1.accessPointName,
              installationMethod: installationMethod.value,
              configuration: configuration.value,
              lockingMechanism: channel1.lockingMechanism.value,
              siteId: site?.value,
              networkId: network.id,
              channelNo: 1,
              devices: [
                {
                  serialNumber: serialNumber.value,
                  deviceType: 4,
                },
              ],
              RelaySettings: {
                relayOnTime: channel1?.relayTime,
                invertRelayLogic: channel1?.invertRelay,
              },
              enableAttendance: false,
            },
          ];
        }
      }
    }

    handleCreateController(payload);
  };

  const handleCreateController = (payload) => {
    return dispatch(
      doAddController(currentUser.partnerId, customerId, network.id, payload, handleClose),
    );

    // return dispatch(doGetControllerList(currentUser.partnerId, customerId));
  };

  const nextStepClicked = () => {
    if (!activeStep) {
      setActiveStep(1);
    } else {
      submitForm();
    }
  };

  useEffect(() => {
    const checkDoors = (val) => {
      val = val.substring(0, 4);
      if (String(val) === '1027') {
        setType('1door');
      } else {
        setType('');
      }
    };
    checkDoors(serialNumber.value);
  }, [serialNumber.value, setType]);

  const backClicked = () => {
    if (activeStep) {
      setActiveStep(0);
    }
  };

  function checkError(stringValue) {
    if (!controllerSerialConfigs.includes(stringValue?.substring(0, 4))) {
      return INCORRECT_ERROR;
    } else if (stringValue.length !== 14) {
      return 'Please enter a valid Serial Number';
    } else {
      return '';
    }
  }

  const handleSerialDescription = (stringValue) => {
    const switchCase = stringValue.substring(0, 4);
    let description = '';
    switch (String(switchCase)) {
      case '1027':
        description = '1 door controller';
        break;
      case '102C':
        description = '2 door controller';
        break;
      default:
        break;
    }

    let controllerSerialNumberError = '';

    controllerSerialNumberError = checkError(stringValue);

    setSerialNumber({
      description: description,
      value: stringValue,
      error: controllerSerialNumberError,
    });
    setError(error);
  };

  const renderSteps = () => {
    if (!activeStep) {
      return (
        <Container className="step step1">
          <div className="stepHeader">
            <div className="counter">1</div>
            <Typography>Basic Details</Typography>
          </div>
          <form>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextInput
                  placeholder="Controller Serial Number"
                  maxLength={14}
                  description={serialNumber?.description}
                  error={serialNumber?.error}
                  onChange={(e) => {
                    handleSerialDescription(e.target.value);
                  }}
                  value={serialNumber.value}
                />
              </Grid>
              <Grid item sm={6}>
                <Dropdown
                  name="installationMethod"
                  placeholder="Installation Method"
                  value={installationMethod?.name}
                  onChange={(data) => {
                    setInstallationMethod({ name: data.name, value: data.value });
                    setConfiguration({ name: '', value: '' });
                  }}
                  data={[
                    { name: 'New install', value: 1 },
                    // { name: 'Retrofit Type 1', value: 'retrofit' },
                  ]}
                />
              </Grid>
              <Grid item sm={6}>
                <Dropdown
                  name="site"
                  placeholder="Site"
                  value={site?.name}
                  onChange={(data) => {
                    setSite({ name: data.name, value: data.id });
                    setNetwork({ name: '', value: '' });
                  }}
                  data={sites}
                />
              </Grid>
              <Grid item sm={6}>
                <Dropdown
                  name="network"
                  placeholder="Network"
                  value={network.name}
                  disabled={site.value === ''}
                  onChange={(data) => {
                    setNetwork({ ...data });
                  }}
                  data={currentNetworks}
                />
              </Grid>
              <Grid item sm={6}>
                <Dropdown
                  name="configuration"
                  placeholder="Configuration"
                  value={configuration?.name}
                  onChange={(data) => {
                    setConfiguration({
                      name: data.name,
                      value: data.value,
                      validSerials: data.validSerials.entry,
                    });
                  }}
                  data={CONTROLLER_CONFIGS}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      );
    }

    if (type === '1door') {
      return (
        <Container className="step step2">
          <div className="stepHeader">
            <div className="counter">2</div>
            <Typography>Door Configuration</Typography>
          </div>
          <form>
            <SingleChannelContoller
              channelName="Channel 1"
              value={channel1}
              onChange={setChannel1}
              configuration={configuration}
              validSerials={configuration.validSerials}
              onEnable={setChannel1enabled}
              setError={setError}
            />
          </form>
        </Container>
      );
    } else {
      return (
        <Container className="step step2">
          <div className="stepHeader">
            <div className="counter">2</div>
            <Typography>Door Configuration</Typography>
          </div>
          <form>
            <ControllerChannel
              channelName="Channel 1"
              value={channel1}
              onChange={setChannel1}
              onEnable={setChannel1enabled}
              configuration={configuration}
              validSerials={configuration.validSerials}
              setError={setError}
            />
            <ControllerChannel
              channelName="Channel 2"
              value={channel2}
              onChange={setChannel2}
              validSerials={configuration.validSerials}
              onEnable={setChannel2enabled}
              setError={setError}
              configuration={configuration}
            />
          </form>
        </Container>
      );
    }
  };

  const selectedSite = site?.value ? sites.find((siteValue) => siteValue.id === site?.value) : [];
  const currentNetworks = selectedSite?.networks?.length > 0 ? selectedSite.networks : [];

  const validateForm = () => {
    if (activeStep === 1) {
      if (!channel1enabled && !channel2enabled) {
        return false;
      }

      if (channel1enabled) {
        if (channel1.accessPointName === '') {
          return false;
        }
        if (configuration?.value === 3 && channel1.serialNumber === '') {
          return false;
        }
        if (channel1.lockingMechanism === '') {
          return false;
        }
        if (channel1?.relayTime === 0) {
          return false;
        }
      }

      if (!channel1enabled && channel2enabled) {
        return false;
      }

      if (channel2enabled) {
        if (channel2.accessPointName === '') {
          return false;
        }
        if (configuration?.value === 3 && channel2.serialNumber === '') {
          return false;
        }
        if (channel2.lockingMechanism === '') {
          return false;
        }
        if (channel2?.relayTime === 0) {
          return false;
        }
      }
    }
    if (error !== '') {
      return false;
    }
    if (serialNumber.error !== '') {
      return false;
    }

    if (serialNumber.value.length === 0) {
      return false;
    }
    if (installationMethod.value === '') {
      return false;
    }
    if (configuration.value === '') {
      return false;
    }
    if (site.value === '') {
      return false;
    }
    if (network.value === '') {
      return false;
    }

    return true;
  };

  return (
    <Modal
      showModal={isOpen}
      closeModal={() => handleClose()}
      style={{ width: '100%', maxWidth: '694px' }}
      className="add-controller"
    >
      <div className={styles['wrapper']}>
        <div className="add-controller">
          <h1
            style={{
              color: '#353f94',
              fontFamily: 'UrbanistSemiBold',
              fontSize: '1.5rem',
            }}
          >
            Add Controller
          </h1>
          <Grid container>
            <Grid item xs={8}>
              <Stepper className="stepper" activeStep={activeStep}>
                <Step key={0}>
                  <StepButton></StepButton>
                </Step>
                <Step key={1}>
                  <StepButton></StepButton>
                </Step>
              </Stepper>
            </Grid>
          </Grid>

          {renderSteps()}

          <div className={styles['button-container']}>
            {activeStep !== 0 && (
              <OutlineButton text="Cancel" color="secondary" onClick={handleClose} />
            )}
            <CustomButton
              text={activeStep === 0 ? 'Cancel' : 'Back'}
              buttonType="disabled"
              onClick={() => {
                if (activeStep === 0) {
                  handleClose();
                } else {
                  backClicked();
                }
              }}
            />
            <CustomButton
              text={activeStep ? 'Save' : 'Next'}
              onClick={nextStepClicked}
              disabled={!validateForm()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddControllerDialog;
