import * as misc from '../actionCreators/miscellaneous';
import * as firepanelApis from '../../services/api/firepanel';
import * as firepanel from '../actionCreators/firepanel';
import { extractErrorMessage } from '../helpers';

export const firePanelStatus = (partnerId, organisationId) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    const { data } = await firepanelApis.GetFirePanelStatus(partnerId, organisationId);
    dispatch(firepanel.setFirePanelStatus(data.message.isEnabled));
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
  }
};

export const updateFirePanelStatus =
  (partnerId, organisationId, payload, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      await firepanelApis.SetFirePanelStatus(partnerId, organisationId, payload);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      callBack('success');
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };

export const firePanelList = (partnerId, organisationId) => async (dispatch) => {
  try {
    dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
    const { data } = await firepanelApis.GetFirePanelList(partnerId, organisationId);
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(firepanel.setFirePanelList(data.message.firepanels));
  } catch (error) {
    const message = extractErrorMessage(error);
    dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
    dispatch(misc.doCreateNotification({ isVisible: true, message }));
    dispatch(firepanel.setFirePanelList([]));
  }
};

export const firePanelDetail =
  (partnerId, organisationId, firePanelId, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      const { data } = await firepanelApis.GetFirePanelDetail(
        partnerId,
        organisationId,
        firePanelId,
      );
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      callBack('success', data.message);
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error', '');
    }
  };

export const createFirePanel =
  (partnerId, networkId, organisationId, payload, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      await firepanelApis.CreateFirePanel(partnerId, networkId, organisationId, payload);
      firePanelList(partnerId, organisationId);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      callBack('success');
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };

export const editFirePanel =
  (partnerId, organisationId, firePanelId, payload, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      await firepanelApis.EditFirePanel(partnerId, organisationId, firePanelId, payload);
      firePanelList(partnerId, organisationId);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      callBack('success');
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };

export const deleteFirePanel =
  (partnerId, organisationId, firepanelId, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      await firepanelApis.DeleteFirePanel(partnerId, organisationId, firepanelId);

      await new Promise((resolve) => {
        setTimeout(resolve, 3000);
      });

      const { data } = await firepanelApis.GetFirePanelList(partnerId, organisationId);
      dispatch(firepanel.setFirePanelList(data.message.firepanels));

      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      callBack('success');
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };

export const detachMeshIo =
  (partnerId, organisationId, firepanelId, serialNumber, callBack) => async (dispatch) => {
    try {
      dispatch(misc.doDisplayLoadingBar({ isLoading: true }));
      await firepanelApis.DetachMeshIo(partnerId, organisationId, firepanelId, serialNumber);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      callBack('success');
    } catch (error) {
      const message = extractErrorMessage(error);
      dispatch(misc.doDisplayLoadingBar({ isLoading: false }));
      dispatch(misc.doCreateNotification({ isVisible: true, message }));
      callBack('error');
    }
  };
