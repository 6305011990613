import React from 'react';

import style from './statCard.module.scss';

import RightArrow from '../../assets/images/dashboard/rightArrow.svg';

const StatCard = ({ icon, title, total, monthTotal, navigate }) => {
  return (
    <div className={style['container']}>
      <div className={style['container__left_section']}>
        <div className={style['icon_container']}>
          <img src={icon} alt={title} />
        </div>
        <div className={style['left_bottom']}></div>
      </div>

      <div className={style['container__right_section']}>
        <div className={style['text_container']}>
          <h1>{title}</h1>
          <h2>{total}</h2>
        </div>
        <div className={style['right_bottom']}>
          {navigate && (
            <div onClick={navigate} className={style['nav_container']}>
              <img src={RightArrow} alt="nav" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatCard;
