import { axiosInstance } from '../api';

export const ReadUserList = async (partnerId) => {
  const url = `/partnerManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/users`;
  return await axiosInstance({ method: 'GET', url: url });
};

export const CreateUser = async ({ partnerId, payload }) => {
  const url = `/partnerManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/users`;
  return await axiosInstance({ method: 'POST', url: url, data: payload });
};

export const UpdateUser = async ({ partnerId, userId, payload }) => {
  const url = `/partnerManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/users/${userId}`;
  return await axiosInstance({ method: 'PATCH', url: url, data: payload });
};

export const DeleteUser = async ({ partnerId, userId }) => {
  const url = `/partnerManagement/${process.env.REACT_APP_BASE_API_VER_V3}/partners/${partnerId}/users/${userId}`;
  return await axiosInstance({ method: 'DELETE', url: url });
};

export const ReadErrorMessage = async () => {
  const url = `/partnerManagement/${process.env.REACT_APP_BASE_API_VER_V4}/configurations`;
  return await axiosInstance({ method: 'GET', url: url });
};
