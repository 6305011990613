import * as React from 'react';
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41.047} height={40} {...props}>
    <g data-name="noun_wifi repeater_1964008" fill="#353f94">
      <path
        data-name="Path 3678"
        d="M24.467 27.419h4.453a8.789 8.789 0 0 1-13.791 7.2 1.914 1.914 0 1 0-2.192 3.138 12.617 12.617 0 0 0 19.81-10.338h4.107l-6.193-6.193Z"
      />
      <path
        data-name="Path 3679"
        d="M11.332 27.407a8.789 8.789 0 0 1 13.764-7.3 1.914 1.914 0 1 0 2.172-3.152 12.617 12.617 0 0 0-19.75 10.373l-3.988.033 6.063 6.063 6.008-6.009Z"
      />
      <path
        data-name="Path 3680"
        d="M40.509 8.279a28.264 28.264 0 0 0-39.971 0 1.838 1.838 0 0 0 2.6 2.6 24.594 24.594 0 0 1 34.78 0 1.838 1.838 0 1 0 2.6-2.6Z"
      />
      <path
        data-name="Path 3681"
        d="M8.981 15.818a15.851 15.851 0 0 1 22.417 0 1.669 1.669 0 0 0 2.36-2.359 19.188 19.188 0 0 0-27.137 0 1.669 1.669 0 0 0 2.36 2.359Z"
      />
    </g>
  </svg>
);
export default SvgComponent;
