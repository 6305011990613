import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
// import style from "./style.module.scss";

const BackButton = ({ route, label, rightComponent, color }) => {
  return (
    <Fragment>
      <Grid container justifyContent="space-between" alignContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} container alignContent="center">
          <Button component={Link} to={route} color="secondary">
            <Typography
              style={{
                textTransform: 'capitalize',
                color: color ? color : 'grey',
                fontWeight: 500,
              }}
            >
              {label}
            </Typography>
          </Button>
        </Grid>
        <Grid item>{rightComponent}</Grid>
      </Grid>
    </Fragment>
  );
};

export default BackButton;
