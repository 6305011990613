import { createAction } from 'redux-actions';

import * as actionTypes from '../actionTypes/user';

export const doReadUserList = createAction(actionTypes.USER_LIST_READ_REQUESTED);
export const doReadUserListSuccess = createAction(actionTypes.USER_LIST_READ_SUCCEEDED);
export const doReadUserListFailure = createAction(actionTypes.USER_LIST_READ_FAILED);
export const doUserListReset = createAction(actionTypes.USER_LIST_RESET);

export const doCreateUser = createAction(actionTypes.USER_CREATE_REQUESTED);
export const doCreateUserSuccess = createAction(actionTypes.USER_CREATE_SUCCEEDED);
export const doCreateUserFailure = createAction(actionTypes.USER_CREATE_FAILED);

export const doUpdateUser = createAction(actionTypes.USER_UPDATE_REQUESTED);
export const doUpdateUserSuccess = createAction(actionTypes.USER_UPDATE_SUCCEEDED);
export const doUpdateUserFailure = createAction(actionTypes.USER_UPDATE_FAILED);

export const doDeleteUser = createAction(actionTypes.USER_DELETE_REQUESTED);
export const doDeleteUserSuccess = createAction(actionTypes.USER_DELETE_SUCCEEDED);
export const doDeleteUserFailure = createAction(actionTypes.USER_DELETE_FAILED);
export const doReadMessageSuccess = createAction(actionTypes.ERROR_MESSAGE_READ_SUCCEEDED);
