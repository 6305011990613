import React, { useEffect, useState, useMemo, useCallback } from 'react';
import voca from 'voca';

// styles
import styles from '../../../../../../styles/modals.module.scss';

// routes
import { useParams } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganisationSites } from '../../../../../../redux/selectors/organisation';
import { selectCurrentPartner } from '../../../../../../redux/selectors/authentication';
import { doCreateOrganisationGateway } from '../../../../../../redux/thunks/organisation';
import { selectIsLoading } from '../../../../../../redux/selectors/miscellaneous';

// components
import Modal from '../../../../../../components/Modals/Modal';
import Button from '../../../../../../components/Buttons/Button';
import TextInput from '../../../../../../components/Inputs/TextInput';
import Dropdown from '../../../../../../components/Inputs/Dropdown';
// helpers
import { isHex, textVerification } from '../../../../../../utils/formValdations';

const AddOrEditGatewayPopup = ({ showGatewayPopup, onClose, gateway, togglePopup }) => {
  const [siteId, setSiteId] = useState('');
  const [serialNumber, setSerialNumber] = useState({ value: '', error: '', description: '' });
  const [siteSelected, setSite] = useState('');
  const [network, setNetwork] = useState({ id: '', name: '' });

  const dispatch = useDispatch();
  const { customerId } = useParams();
  const { currentUser, sites } = useSelector((state) => ({
    currentUser: selectCurrentPartner(state),
    sites: selectOrganisationSites(state),
  }));
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (siteSelected) {
      const selectedSiteIdObj = sites.filter((site) => site.name === siteSelected);
      setSiteId(selectedSiteIdObj[0].id);
    }
  }, [siteId, siteSelected, sites]);

  useEffect(() => {
    if (gateway) {
      setSiteId(gateway.siteId);
      setSerialNumber(gateway.serialNumber);
    }
  }, [gateway]);

  const handleCreateOrganisation = useCallback(
    (payload) => {
      dispatch(
        doCreateOrganisationGateway({
          partnerId: currentUser.partnerId,
          organisationId: customerId,
          networkId: network.id,
          payload,
          callback: () => onClose('AddOrEditGateway'),
        }),
      );
    },
    [currentUser.partnerId, customerId, network, dispatch, onClose],
  );

  const handleChange = (e) => {
    const validSerials = ['101D', '101E', '101C', '100B', '102A'];
    const { name, value } = e?.currentTarget;
    const errorText = 'Please enter a valid Serial Number';
    const switchCase = value.substring(0, 4);
    // console.log(value.length);
    let error = '';
    let description = '';
    switch (name) {
      case 'serialNumber':
        if (!validSerials.includes(switchCase)) error = `${errorText}`;
        if (textVerification(value, 13, 14)) error = `${errorText}`;
        if (!isHex(value)) error = error = `${errorText}`;
        if (validSerials.includes(switchCase)) description = `Gateway`;
        setSerialNumber({ value, error, description });

        break;
      default:
        break;
    }
  };

  const disabled = useMemo(() => {
    if (isLoading) return true;
    if (siteSelected === '' || serialNumber?.value === '' || serialNumber?.error !== '') {
      return true;
    }
    return false;
  }, [isLoading, serialNumber?.error, serialNumber?.value, siteSelected]);

  const selectedSite = siteId ? sites.find((siteValue) => siteValue.id === siteId) : [];
  const currentNetworks = selectedSite?.networks?.length > 0 ? selectedSite.networks : [];

  return (
    <Modal showModal={showGatewayPopup} closeModal={onClose}>
      <div className={styles['wrapper']}>
        <h1>Add Gateway</h1>
        <Dropdown
          className={styles['modal-input']}
          value={siteSelected}
          placeholder="Select Site"
          data={sites}
          onChange={(value) => {
            setSite(value?.name);
            setNetwork({ id: '', name: '' });
          }}
        />
        <Dropdown
          className={styles['modal-input']}
          value={network.name}
          disabled={siteSelected === ''}
          placeholder="Select Network"
          data={currentNetworks}
          onChange={(data) => {
            setNetwork({ ...data });
          }}
        />
        <TextInput
          name="serialNumber"
          placeholder="Gateway Serial Number"
          value={serialNumber?.value}
          onChange={handleChange}
          error={serialNumber?.error}
          description={serialNumber?.description}
        />
        <div className={styles['button-container']}>
          <Button
            text="Cancel"
            type="disabled"
            onClick={() => togglePopup('AddOrEditGateway')}
            buttonType="disabled"
          />
          <Button
            disabled={disabled}
            text="Add"
            buttonSmall={true}
            onClick={() =>
              handleCreateOrganisation({
                siteId,
                serialNumber: voca.upperCase(serialNumber?.value),
                networkId: network.id,
              })
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddOrEditGatewayPopup;
