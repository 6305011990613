import React from 'react';
import Modal from '../../../../../../components/Modals/Modal';
import styles from '../../../../../../styles/modals.module.scss';
import ButtonCustom from '../../../../../../components/Buttons/Button';
import { Box, Typography } from '@material-ui/core';

const ViewNetworks = ({ site, showNetworksView, onClose, togglePopup }) => {
  return (
    <Modal
      showModal={showNetworksView}
      onClose={onClose}
      style={{
        width: '100%',
        maxWidth: '400px',
      }}
    >
      <div className={styles['wrapper']}>
        <h1> View Networks</h1>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <h3>Site Name</h3>
            <p>{site?.name}</p>
          </Box>
          <Box>
            <h3>Site Location</h3>
            <p>{site?.location}</p>
          </Box>
        </Box>
        <Box style={{ marginTop: 40 }}>
          {site?.networks?.map((item) => (
            <Typography
              style={{
                border: '1px solid #D3D3D3',
                borderRadius: '15px',
                padding: 10,
                fontSize: 15,
                marginTop: 20,
              }}
            >
              {item?.name}
            </Typography>
          ))}
        </Box>
        <Box className={styles['button-container']}>
          <ButtonCustom
            text="Close"
            buttonType="disabled"
            onClick={() => togglePopup('View')}
            style={{ width: '100px' }}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default ViewNetworks;
