import React, { useState, useEffect, useCallback } from 'react';
// import Checkbox from '@material-ui/core/Checkbox';
// import Checkbox from '../../../../../../components/Inputs/Checkbox/Checkbox';
import { useImmer } from 'use-immer';
import styles from '../HardwareIntegration.module.scss';
import ViewMesh from '../ViewMesh/ViewMesh';
import CreateFirePanel from '../CreateFirePanel/CreateFirePanel';
import EditFirePanel from '../EditFirePanel/EditFirePanel';
import { useParams } from 'react-router-dom';

import DeleteMeshIOModal from '../DeletMeshIoModal';
//redux
import { useSelector, useDispatch } from 'react-redux';
import {
  firePanelStatus,
  // updateFirePanelStatus,
  firePanelList,
  deleteFirePanel,
} from '../../../../../../redux/thunks/firepanel';
import { doReadOrganisationSites } from '../../../../../../redux/thunks/organisation';
import OutlineButton from '../../../../../../components/OutlineButton/OutlineButton';
import Table from '../../../../../../components/Table/Table';
import SquareEdit from '../../../../../../components/SquareEdit/SquareEdit';
import SquareDelete from '../../../../../../components/SquareDelete/SquareDelete';
import Button from '../../../../../../components/Buttons/Button';
import { Search } from '../../../../../../utils/Search';

const FirePanel = () => {
  const { customerId } = useParams();
  //redux
  const dispatch = useDispatch();
  const currentPartner = useSelector((state) => state.auth.currentPartner);
  const sites = useSelector((state) => state.organisation.sites);
  const firepanel = useSelector((state) => state.firepanel);

  //state
  const [showCreatePanel, setPanel] = useState(false);
  const [showEditPanel, setEditPanel] = useState(false);
  const [showViewPanel, setViewPanel] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [selectedRow, setRow] = useState({});

  const [filters, setFilters] = useImmer({ name: '' });
  const [pagination, setPagination] = useImmer({ total: 0, currentPage: 1, perPage: 7 });

  useEffect(() => {
    setPagination((draft) => {
      draft.total = firepanel?.length ? firepanel?.length : 0;
    });
  }, [firepanel?.length, setPagination]);

  //table column
  const columns = [
    { title: 'Id', field: 'id', name: 'id' },
    { title: 'Fire Panel', field: 'name', name: 'name' },
    {
      title: 'Mesh I/O',
      field: 'site.name',
      render: (columnProps, rowData, row) => (
        <div className={styles.row}>
          <p className={styles.mr_1}>{rowData?.meshios?.length}</p>
          <OutlineButton
            onClick={() => {
              setRow(rowData);
              setViewPanel(true);
            }}
            type="primary"
            variant="outlined"
            buttonSmall
            text="View"
          />
        </div>
      ),
    },
    {
      title: 'Action',
      field: 'resourceState',
      render: (columnProps, rowData, row) => (
        <div className={styles.row}>
          <SquareEdit
            color={'#ff535a'}
            onPress={() => {
              setRow(rowData);
              setEditPanel(true);
            }}
          />

          <SquareDelete
            color={'#ff535a'}
            onPress={() => {
              setRow(rowData);
              showDeleteModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const getStatus = useCallback(() => {
    dispatch(firePanelStatus(currentPartner.partnerId, customerId));
  }, [dispatch, currentPartner.partnerId, customerId]);

  // const updateStatus = useCallback(
  //   (status) => {
  //     dispatch(
  //       updateFirePanelStatus(currentPartner.partnerId, customerId, { enable: status }, (s) => {
  //         if (s === 'success') {
  //           getStatus();
  //         }
  //       }),
  //     );
  //   },
  //   [currentPartner.partnerId, customerId, dispatch, getStatus],
  // );

  const getFirePanel = useCallback(() => {
    dispatch(firePanelList(currentPartner.partnerId, customerId));
  }, [currentPartner.partnerId, customerId, dispatch]);

  const getSite = useCallback(() => {
    if (currentPartner) {
      dispatch(
        doReadOrganisationSites({
          partnerId: currentPartner.partnerId,
          organisationId: customerId,
          displayNotification: false,
        }),
      );
    }
  }, [currentPartner, customerId, dispatch]);

  useEffect(() => {
    // getStatus();
    getFirePanel();
    getSite();
  }, [currentPartner.partnerId, customerId, dispatch, getFirePanel, getSite, getStatus]);

  const deleteFPanel = useCallback(() => {
    dispatch(
      deleteFirePanel(currentPartner.partnerId, customerId, selectedRow.id, (s) => {
        if (s === 'success') {
          showDeleteModal(false);
        }
      }),
    );
  }, [currentPartner.partnerId, customerId, dispatch, selectedRow.id]);

  const hSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((draft) => {
      draft[name] = value;
    });
  };

  const search = {
    onChange: hSearchChange,
  };

  const filteredData = useCallback(() => {
    let data = firepanel.firePanelList ? firepanel.firePanelList : [];
    if (filters.name !== '') {
      data = Search(data, 'name', filters.name);
    }
    data = data.slice(
      (pagination.currentPage - 1) * 7,
      pagination.currentPage * pagination.perPage,
    );
    return data;
  }, [filters.name, firepanel, pagination.currentPage, pagination.perPage]);

  const changePage = (p) => {
    const { page, per_page } = p;

    setPagination((draft) => {
      draft.currentPage = page;
      draft.perPage = per_page;
    });
  };

  return (
    <>
      <div className="header-container">
        <p className="header">Hardware Integrations</p>

        <div className="button-cont">
          <Button
            onClick={() => setPanel(true)}
            text="Create Fire Panel"
            disabled={sites == null || sites.length === 0}
          />
        </div>
      </div>

      <div className={styles.table_container}>
        <Table
          columns={columns}
          data={filteredData() || []}
          emptyContent={'No fire panels found'}
          options={{ search }}
          pagination={pagination}
          changePage={changePage}
        />
      </div>

      {showViewPanel && (
        <ViewMesh
          togglePopup={(status) => {
            setViewPanel(false);
            if (status) {
              setEditPanel(true);
            }
          }}
          data={selectedRow}
        />
      )}
      {showCreatePanel && (
        <CreateFirePanel
          togglePopup={(s) => {
            if (s === 'success') {
              getFirePanel();
            }
            setPanel(false);
          }}
          useMesh={filteredData() || []}
        />
      )}
      {showEditPanel && (
        <EditFirePanel
          togglePopup={(s) => {
            if (s === 'success') {
              getFirePanel();
            }
            setEditPanel(false);
          }}
          data={selectedRow}
          useMesh={filteredData() || []}
        />
      )}
      {deleteModal && (
        <DeleteMeshIOModal
          showModal={deleteModal}
          title={'Delete Fire Panel'}
          description={`Are you sure you want to delete Fire panel "${selectedRow?.name} - ${selectedRow?.id}" ? This action is non reversible and will cause you to lose all data associated with the Fire Panel`}
          onClose={() => {
            showDeleteModal(false);
          }}
          onSubmit={() => {
            deleteFPanel();
          }}
        />
      )}
    </>
  );
};

export default FirePanel;
