import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 490 490"
    style={{
      enableBackground: 'new 0 0 490 490',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M245 456.701 490 33.299H0z" />
  </svg>
);

export default SvgComponent;
