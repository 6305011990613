// styles
import styles from './styles.module.scss';

// components
import UILink from '../Buttons/UILink';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={styles['footer']}>
      <p className={styles['header']}>{`Spintly Partners | ${year} Admin`}</p>
      <div className={styles['bottom_section']}>
        <p className={styles['subheader']}>{`© ${year} All Rights Reserved`}</p>
        <div className={styles['links']}>
          <UILink text="About Us" alternate handleSubmit={() => {}} />
          <p>|</p>
          <UILink text="Contact Us" alternate handleSubmit={() => {}} />
          <p>|</p>
          <UILink text="Privacy" alternate handleSubmit={() => {}} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
