import React from 'react';
const Devices = () => {
  return (
    <svg width="41.962" height="41.959" viewBox="0 0 489 489" version="1.1" id="Capa_1">
      <g>
        <g>
          <g>
            <path
              fill="#353F94"
              d="M249.5,443v-18.8H56.3V64.8h247.6v31c5.1-1.2,10.3-1.9,15.7-1.9h37.7V51.3V45v-5.6c0-16.3-9.6-30.3-23.3-36.3
				c-4.6-2-9.6-3.1-14.8-3.1H170.7H41c-5.3,0-10.3,1.1-14.9,3.1c-13.7,6-23.3,20-23.3,36.3v7.1v355v48.1c0,21.7,17.1,39.4,38.1,39.4
				h67l0,0h62.7H261c-7.2-11.2-11.5-24.6-11.5-39L249.5,443L249.5,443z M197.9,469.1h-35.7c-5.3,0-9.6-4.5-9.6-10s4.3-10,9.6-10
				h35.7c5.3,0,9.6,4.5,9.6,10C207.5,464.7,203.2,469.1,197.9,469.1z M448.5,126.2h-129c-20.8,0-37.7,17.6-37.7,39v11.5v266.4v6.9
				c0,21.4,17,39,37.7,39h129c20.7,0,37.7-17.6,37.7-39v-6.9v-72.6V198.6v-22v-11.5C486.2,143.8,469.2,126.2,448.5,126.2z
				 M400.3,472.6h-36.4c-5.4,0-9.8-4.6-9.8-10.1c0-5.6,4.4-10.1,9.8-10.1h36.4c5.4,0,9.8,4.6,9.8,10.1
				C410.1,468,405.6,472.6,400.3,472.6z M448.6,436c-0.2,0-0.5,0.1-0.7,0.1H320c-0.2,0-0.5-0.1-0.7-0.1V179.4c0.2,0,0.5-0.1,0.7-0.1
				h128c0.2,0,0.4,0.1,0.6,0.1V436z"
            />
            <rect
              id="Rectangle_269"
              data-name="Rectangle 269"
              width="1.751"
              height="1.751"
              transform="translate(24.337 20.407)"
              fill="#353f94"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Devices;
