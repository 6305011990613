import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import history from '../history';

import authenticationReducer from './authenticationReducer';
import miscellaneousReducer from './miscellaneousReducer';
import organisationReducer from './organisationReducer';
import userReducer from './userReducer';
import roleReducer from './roleReducer';
import meshReducer from './meshReducer';
import controllerReducer from './controllerReducer';
import firePanelReducer from './firepanelReducer';
import showModalReducer from './showModal';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['router', 'auth', 'miscellaneous', 'organisation', 'user', 'role'],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['miscellaneous'],
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: persistReducer(authPersistConfig, authenticationReducer),
  miscellaneous: miscellaneousReducer,
  organisation: organisationReducer,
  user: userReducer,
  role: roleReducer,
  mesh: meshReducer,
  controller: controllerReducer,
  firepanel: firePanelReducer,
  modal: showModalReducer,
});

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedRootReducer;
