export const selectPartner = (partners, currentUser) => {
  const partner = partners.find((ptnr) => ptnr.partnerId === currentUser?.partnerId);

  if (partner) {
    return partner;
  } else {
    return partners[0];
  }
};

export const selectIntegrationId = (partners, currentUser) => {
  const newIntegration = partners.find((partner) => partner.id === currentUser.partnerId);

  if (newIntegration) {
    const [integrator] = newIntegration.integrators;

    return integrator?.id;
  }

  return '';
};

export const selectNewCurrentUser = (profiles, partnerId) => {
  const newCurrentUser = profiles.find((profile) => profile.partnerId === partnerId);

  return newCurrentUser;
};

export const selectNewIntegrationId = (partners, partnerId) => {
  const newIntegration = partners.find((partner) => partner.partnerId === partnerId);
  if (newIntegration) {
    const [integrator] = newIntegration.integrator;

    return integrator?.id;
  }

  return '';
};

export const selectNewPartner = (partners, partnerId) => {
  const newPartner = partners.find((partner) => partner.partnerId === partnerId);

  if (newPartner) {
    return newPartner;
  }

  return null;
};
