import { createStore, applyMiddleware } from 'redux';
// import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
// import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import history from './history';
import persistedRootReducer from './reducers';

const middlewares = [routerMiddleware(history), thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  // const logger = createLogger();
  // middlewares.push(logger);
}

const configureStore = (preloadedState = undefined) => {
  const middlewareEnhancer = applyMiddleware(...middlewares);
  // const composedEnhancers = composeWithDevTools(middlewareEnhancer);

  const store = createStore(persistedRootReducer, preloadedState, middlewareEnhancer);

  const persistor = persistStore(store);

  return { store, persistor };
};

const configuredStore = configureStore();

export default configuredStore;
