import { Box, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';

import styles from '../../../../../../../src/styles/modals.module.scss';
import Button from '../../../../../../components/Buttons/Button';
import Modal from '../../../../../../components/Modals/Modal';
import TextInput from '../../../../../../components/Inputs/TextInput';
import PhoneInput from '../../../../../../components/Inputs/PhoneInput';

import {
  emailVerification,
  phonenumberVerification,
  textVerification,
} from '../../../../../../utils/formValdations';

const errorText = 'Please enter a valid';

const NewAdminPopup = ({ showModal, closeModal, onClick }) => {
  const [adminName, setAdminName] = useState({ value: '', error: '' });
  const [adminEmail, setAdminEmail] = useState({ value: '', error: '' });
  const [adminPhoneNumber, setAdminPhoneNumber] = useState({ value: '', error: '' });
  const [countryCode, setCountryCode] = useState('91');

  const WhiteTextTypography = withStyles({
    root: {
      color: '#483f94',
    },
  })(Typography);

  const boxStyle = {
    // border: '1px solid #D3D3D3',
    // borderRadius: '8px',
    padding: '20px',
    // marginTop: '12px',
  };

  const handleClick = () => {
    const phoneNumber = `+${countryCode}${adminPhoneNumber.value}`;
    const payload = {
      name: adminName.value,
      phone: phoneNumber,
      email: adminEmail.value,
      adminType: 2,
    };
    onClick(payload);
    closeModal();
  };

  const handleChange = (e) => {
    const { name, value } = e?.currentTarget;

    let error = '';
    switch (name) {
      case 'adminName':
        error = textVerification(value, 2) ? `${errorText} admin name` : '';
        setAdminName({ value, error });
        break;
      case 'adminEmail':
        error = !emailVerification(value) ? `${errorText} admin email` : '';
        setAdminEmail({ value, error });
        break;
      case 'adminPhoneNumber':
        error = !phonenumberVerification(`+${countryCode}${value}`)
          ? `${errorText} phone number`
          : '';
        setAdminPhoneNumber({ value, error });
        break;

      default:
        break;
    }
  };

  const disabled = () => {
    if (adminEmail.value === '' || adminName.value === '' || adminPhoneNumber.value === '') {
      return true;
    }
    if (adminEmail.error !== '' || adminName.error !== '' || adminPhoneNumber.error !== '') {
      return true;
    }
    return false;
  };

  return (
    <Modal
      showModal={showModal}
      onClose={closeModal}
      style={{
        width: '100%',
        maxWidth: '600px',
      }}
    >
      <div className={styles['wrapper']}>
        <h1 style={{ fontSize: '22px' }}>Add super admin details</h1>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '-15px',
          }}
        >
          <WhiteTextTypography variant="h3">
            Enter super admin details. Initiate handover once user signs up
          </WhiteTextTypography>
          {/*
           */}
        </Box>
        <Box style={boxStyle}>
          <Box display="flex" justifyContent="space-between">
            <TextInput
              name="adminName"
              placeholder="Admin Name"
              value={adminName.value}
              onChange={handleChange}
              disabled={false}
              error={adminName.error}
            />
            <div style={{ marginLeft: '10px' /* Adjust the value to control the gap */ }} />
            <PhoneInput
              name="adminPhoneNumber"
              placeholder="Admin Phone Number"
              type="number"
              defaultCountryCode={countryCode}
              value={adminPhoneNumber.value}
              onChange={handleChange}
              disabled={false}
              error={adminPhoneNumber.error}
              handleCountryCode={(code) => setCountryCode(code)}
            />
          </Box>

          <TextInput
            name="adminEmail"
            placeholder="Admin Email Address"
            type="email"
            value={adminEmail.value}
            onChange={handleChange}
            disabled={false}
            error={adminEmail.error}
          />
        </Box>

        <Box display="flex" alignItems="center" justifyContent="flex-end" margin={3} width="90%">
          <Button
            text="Cancel"
            buttonSize="button-small"
            buttonType="secondary"
            onClick={closeModal}
          />
          <Button
            text="Add"
            buttonSize="button-small"
            onClick={handleClick}
            disabled={disabled()}
            // style={{ width: '100px' }}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default NewAdminPopup;
