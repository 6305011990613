import { Fragment, useState, useEffect } from 'react';
// rooutes
import { useHistory } from 'react-router-dom';
import routes from '../../../constants/routes';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { doChangePassword, doLogin } from '../../../redux/thunks/authentication';
import { selectModalState } from '../../../redux/selectors/showModal';

// style
import styles from '../styles.module.scss';

// Logo
import CompanyLogo from '../../../assets/images/logo.png';

// components
import LoginForm from './LoginForm';
import UILink from '../../../components/Buttons/UILink';
import Footer from '../../../components/Footer';
import NewPasswordForm from '../NewPassword/NewPasswordForm';
import { doHideModal } from '../../../redux/actionCreators/showModal';

const Login = () => {
  const [valuess, setValues] = useState(null);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  // Retrieve the values of the query parameters

  const showModal = useSelector(selectModalState);

  const param1 = params.get('module');

  useEffect(() => {
    const res = async () => {
      if (showModal && valuess) {
        setModal(true);
      } else {
        setModal(false);
      }
    };

    res();
  }, [param1, modal, showModal, valuess]);

  const handleLoginAttempt = (values) => {
    setValues(values);
    dispatch(doLogin(values));
  };

  const handlesubmit = async (values) => {
    await setValues((prevValues) => {
      return { ...prevValues, ...values };
    });
    dispatch(doChangePassword(valuess, values));
  };

  const displayLeftSection = () => (
    <div className={styles['left_section']}>
      <div className={styles['login_image']}></div>
    </div>
  );

  const displayChangePasswordLeftSection = () => (
    <div className={styles['left_section']}>
      <div className={styles['login_image']}></div>
    </div>
  );

  const displayChangePasswordRightSection = () => (
    <div className={styles['right_section']}>
      <div className={styles['logo_container']}>
        <img src={CompanyLogo} alt="Spintly" />
        <h2> | </h2>
        <h1> Partner</h1>
      </div>
      <div className={'ml-2'}>
        <h2 className={styles['secodary_header']}>
          Since this is your first time here we will need you to set a new password
        </h2>
        <NewPasswordForm handleResetPassword={handlesubmit} />
        <div className="mt-8">
          <UILink text="Back" disabled={false} handleSubmit={() => dispatch(doHideModal())} />
        </div>

        <Footer />
      </div>
    </div>
  );

  const displayRightSection = () => (
    <div className={styles['right_section']}>
      <div className={styles['logo_container']}>
        <img src={CompanyLogo} alt="Spintly" />
        <h2> | </h2>
        <h1> Partner</h1>
      </div>
      <div className={'ml-2'}>
        <h2 className={styles['secodary_header']}>Welcome to Spintly Partner</h2>
        <LoginForm handleLoginAttempt={handleLoginAttempt} />
        <div className="mt-8">
          <UILink
            text="Forgot Password"
            disabled={false}
            handleSubmit={() => history.push(routes.public.forgotPassword)}
          />
        </div>
        <Footer />
      </div>
    </div>
  );

  return (
    <Fragment>
      {!modal ? (
        <div className={styles['container']}>
          {displayLeftSection()}
          {displayRightSection()}
        </div>
      ) : (
        <div className={styles['container']}>
          {displayChangePasswordLeftSection()}
          {displayChangePasswordRightSection()}
        </div>
      )}
    </Fragment>
  );
};

export default Login;
