import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useDispatch, useSelector } from 'react-redux';

import { selectIsVisible, selectNotificationMessage } from '../../redux/selectors/miscellaneous';
import { doCreateNotification } from '../../redux/actionCreators/miscellaneous';
import UnskippablePopup from './PopUp';
import { selectMessageList } from './../../redux/selectors/user';

// import style from "./style.module.scss";

const NotificationSnackbar = () => {
  const dispatch = useDispatch();

  const isVisible = useSelector(selectIsVisible);
  const message = useSelector(selectNotificationMessage);
  const list = useSelector(selectMessageList);

  const loadMessages = list?.messages;

  const handleClose = () => {
    dispatch(doCreateNotification({ isVisible: false, message: '' }));
  };

  let newErrorMessage = message;

  if (message?.errorCode) {
    const foundError = loadMessages?.find((error) => parseInt(error.code) === message.errorCode);

    // If a matching error message is found, use it; otherwise, use the received error message

    newErrorMessage = foundError ? foundError.message : message.errorMessage;
  }

  return (
    <Fragment>
      {message === 'Authorization token has expired.' ? (
        <UnskippablePopup open={isVisible} title="Session Expired" message="Please Login Again" />
      ) : (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={isVisible}
          autoHideDuration={6000}
          onClose={() => handleClose()}
          message={newErrorMessage}
          action={
            <Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => handleClose()}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Fragment>
          }
        />
      )}
    </Fragment>
  );
};

export default NotificationSnackbar;
