import React, { useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';
import Mesh from '../Mesh/Mesh';
import Modal from '../../../../../../components/Modals/Modal';

import styles from './ViewMesh-styles.module.scss';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { firePanelDetail } from '../../../../../../redux/thunks/firepanel';
import classnames from 'classnames';

import Dropdown from '../../../../../../components/Inputs/Dropdown';

import Button from '../../../../../../components/Buttons/Button';
import OutlineButton from '../../../../../../components/OutlineButton/OutlineButton';

const ViewMesh = ({ togglePopup, data }) => {
  const { customerId } = useParams();
  //redux
  const dispatch = useDispatch();
  const currentPartner = useSelector((state) => state.auth.currentPartner);
  //state
  const [selectedMesh, setMesh] = useImmer(undefined);
  const [meshData, setMeshData] = useImmer(() => data || {});

  // console.log(meshData.meshios);

  const popupToggle = (status) => {
    togglePopup && togglePopup(status);
  };

  const handleChange = useCallback(
    (mesh) => {
      setMesh(mesh);
    },
    [setMesh],
  );

  const getDetail = useCallback(() => {
    dispatch(
      firePanelDetail(currentPartner.partnerId, customerId, data.id, (status, details) => {
        if (status === 'success') {
          setMeshData(details);
        }
      }),
    );
  }, [currentPartner.partnerId, customerId, data.id, dispatch, setMeshData]);

  useEffect(() => {
    // getDetail();
    // setMeshData(data);
  }, [getDetail, setMeshData, data]);

  useEffect(() => {
    if (meshData?.meshios && meshData?.meshios.length > 0) {
      setMesh(meshData?.meshios[0]);
    }
  }, [meshData?.meshios, setMesh]);

  return (
    <Modal showModal={true} closeModal={() => popupToggle(false)}>
      <div className={classnames(styles['container'])}>
        <div className={classnames(styles['header'])}>
          <p className="modal-title">View Mesh I/O</p>
          <div className={styles['dropDown']}>
            {meshData?.meshios && (
              <Dropdown
                name="site"
                type="dropDown"
                value={selectedMesh?.name ? selectedMesh?.name : ''}
                onChange={handleChange}
                data={meshData?.meshios || []}
              />
            )}
          </div>
        </div>
        <div className={styles['mrb-2']}>
          <div className={styles['mesh_details']}>
            {meshData?.name && (
              <div item xs={4} sm={4} md={4} lg={4} direction="column">
                <p className={styles['details_title']}>Fire Panel</p>
                <p className={styles['details_subtitle']}>{meshData?.name}</p>
              </div>
            )}

            {meshData?.siteName && (
              <div item xs={4} sm={4} md={4} lg={4} direction="column">
                <p className={styles['details_title']}>Site Name</p>
                <p className={styles['details_subtitle']}>{meshData?.siteName}</p>
              </div>
            )}
          </div>

          <Mesh
            title={selectedMesh?.name ? selectedMesh?.name : ''}
            pinData={selectedMesh?.pins ? selectedMesh?.pins : []}
          />
        </div>

        <div className={styles['mesh_details']}>
          <OutlineButton
            type="primary"
            variant="outlined"
            buttonSmall
            text="Edit Mesh I/O"
            onClick={() => {
              popupToggle(true);
            }}
          />
          <div className={styles['small-button']}>
            <Button
              color="primary"
              onClick={() => {
                popupToggle(false);
              }}
              buttonSmall
              text="Done"
            />
          </div>
        </div>
      </div>
    </Modal>
    // </Dialog>
  );
};

export default ViewMesh;
