import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectOrganisationSites } from '../../../../../redux/selectors/organisation';
import AddorEditSiteModal from './AddOrEditSiteModal';
// import DeleteSiteModal from './DeleteSiteModal';
import SquareDelete from '../../../../../components/SquareDelete/SquareDelete';
import SquareEdit from '../../../../../components/SquareEdit/SquareEdit';
// import SquareEdit from '../../../../../components/SquareEdit/SquareEdit';
import Table from '../../../../../components/Table/Table';
import { useImmer } from 'use-immer';
import {
  doDeleteOrganisationSite,
  doReadOrganisationSites,
} from '../../../../../redux/thunks/organisation';
import { selectCurrentPartner } from '../../../../../redux/selectors/authentication';
import { useParams } from 'react-router-dom';
import { Search } from '../../../../../utils/Search';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import Button from '../../../../../components/Buttons/Button';
import ModalAlert from '../../../../../components/Modals/ModalAlert';
import { Box, Typography } from '@material-ui/core';
import ViewNetworks from './ViewNetworks/ViewNetworks';

const SiteTab = () => {
  const dispatch = useDispatch();
  const [popupTitle, setPopupTitle] = useState('Add');
  const [showSitePopup, setShowSitePopup] = useState(false);
  const [showNetworksView, setShowNetworksView] = useState(false);
  const [showDeleteSitePopup, setShowDeleteSitePopup] = useState(false);
  const [site, setSite] = useState(null);
  const [filters, setFilters] = useImmer({ id: '', name: '', location: '' });
  const [pagination, setPagination] = useImmer({ total: 0, currentPage: 1, perPage: 7 });

  const { customerId } = useParams();

  const { sites, currentUser } = useSelector((state) => ({
    sites: selectOrganisationSites(state),
    currentUser: selectCurrentPartner(state),
  }));

  useEffect(() => {
    setSite(sites);
  }, [sites]);

  //tabel config

  useEffect(() => {
    setPagination((draft) => {
      draft.total = sites?.length ? sites?.length : 0;
    });
  }, [sites?.length, setPagination]);

  const columns = [
    {
      title: 'Id',
      field: 'id',
      name: 'id',
    },
    {
      title: 'Site Name',
      field: 'name',
      name: 'name',
    },
    {
      title: 'Site Location',
      field: 'location',
      name: 'location',
    },
    {
      title: 'Networks',
      field: 'networks',
      // name: 'networks',
      render: (columnProps, rowData) => {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-center',
              alignItems: 'center',
              maxWidth: '150px',
            }}
          >
            <p>{rowData?.networks?.length.toString().padStart(2, '0')}</p>
            <button
              style={{
                width: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // padding: '0.1rem 0.1rem',
                paddingTop: '0.1rem',
                borderRadius: '8px',
                border: '2px solid #03bfff',
                margin: 10,
              }}
              onClick={() => {
                setSite(rowData);
                togglePopup('View');
              }}
            >
              <Typography style={{ color: ' #03bfff', fontSize: 15 }}>View</Typography>
            </button>
          </Box>
        );
      },
    },
    {
      title: 'Time zone',
      field: 'timezone',
      // name: 'timezone',
    },
    {
      title: 'Action',
      render: (columnProps, rowData) => {
        return (
          <div className="button-row">
            {/** Temporarily disabling site edit, as it is causing bugs with site names out sync
             * untill we move to id in
             */}
            <SquareEdit
              color={'#ff535a'}
              onPress={() => {
                setPopupTitle('Edit');
                togglePopup('AddOrEditSite');
                setSite(rowData);
                // togglePopup('AddOrEditAccessPoint');
              }}
            />
            <SquareDelete
              color={'#ff535a'}
              onPress={(e) => {
                // deleteMesh(rowData.serialNumber);
                setSite(rowData);
                togglePopup('DeleteSite');
              }}
            />
          </div>
        );
      },
      // customFilterAndSearch: (term, row) => statusSearcher(term, row.cloudStatus),
    },
  ];

  const togglePopup = (popupName) => {
    if (popupName === 'AddOrEditSite') {
      setShowSitePopup(!showSitePopup);
      if (showSitePopup) {
        setSite(null);
      }
    }

    if (popupName === 'DeleteSite') {
      setShowDeleteSitePopup(!showDeleteSitePopup);
      if (showDeleteSitePopup) {
        setSite(null);
      }
    }
    if (popupName === 'View') {
      setShowNetworksView(!showNetworksView);
      if (showNetworksView) {
        setSite(null);
      }
    }
  };

  const point = useBreakpoint();

  useEffect(() => {
    if (currentUser) {
      dispatch(
        doReadOrganisationSites({
          partnerId: currentUser.partnerId,
          organisationId: customerId,
          displayNotification: false,
        }),
      );
    }
  }, [dispatch, point, currentUser, customerId]);

  //table
  const hSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((draft) => {
      draft[name] = value;
    });
  };

  const search = {
    onChange: hSearchChange,
  };

  const filteredData = useCallback(() => {
    let data = sites ? sites : [];
    if (filters.id !== '') {
      data = Search(data, 'id', filters.id);
    }
    if (filters.location !== '') {
      data = Search(data, 'location', filters.location);
    }
    if (filters.name !== '') {
      data = Search(data, 'name', filters.name);
    }

    data = data.slice(
      (pagination.currentPage - 1) * 7,
      pagination.currentPage * pagination.perPage,
    );

    return data;
  }, [
    filters.id,
    filters.location,
    filters.name,
    pagination.currentPage,
    pagination.perPage,
    sites,
  ]);

  const changePage = (p) => {
    const { page, per_page } = p;

    setPagination((draft) => {
      draft.currentPage = page;
      draft.perPage = per_page;
    });
  };

  const handleDeleteSite = () => {
    dispatch(
      doDeleteOrganisationSite({
        partnerId: currentUser.partnerId,
        organisationId: customerId,
        siteId: site.id,
        siteName: site.name,
      }),
    );
    setSite(null);
    setShowDeleteSitePopup(false);
  };

  return (
    <Fragment>
      <div className="spacer__1" />
      <div>
        <div>
          {showNetworksView && (
            <ViewNetworks
              {...{ site, togglePopup, showNetworksView, onClose: () => togglePopup('View') }}
            />
          )}
          {showSitePopup && (
            <AddorEditSiteModal
              {...{
                popupTitle,
                showSitePopup,
                togglePopup,
                site,
                setSite,
                mode: popupTitle?.toLocaleLowerCase(),
                onClose: () => togglePopup('AddOrEditSite'),
              }}
            />
          )}
          {showDeleteSitePopup && (
            // <DeleteSiteModal {...{ popupTitle, showDeleteSitePopup, togglePopup, site, setSite }} />

            <ModalAlert
              showModal={showDeleteSitePopup}
              closeModal={() => {
                setShowDeleteSitePopup(false);
              }}
              title={'Delete Site'}
              description={`Are you sure you want to delete ${
                site ? site.name : ''
              } ? This action is non
            reversible and will cause you to lose all data associated with this site/ s`}
              modalType="danger"
              handleSubmit={handleDeleteSite}
            />
          )}
          <div className="header-container">
            <p className="header">Sites</p>
            <div className="button-cont">
              <Button
                text={'Add Sites'}
                onClick={() => {
                  setPopupTitle('Add');
                  togglePopup('AddOrEditSite');
                }}
              />
            </div>
          </div>
          <div>
            <Table
              columns={columns}
              data={filteredData()}
              options={{ search }}
              pagination={pagination}
              changePage={changePage}
              emptyContent="No Records found"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SiteTab;
