import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/showModal';

const defaultState = {
  showModal: false,
};

const showModalReducer = handleActions(
  {
    [actionCreators.doShowModal]: (state) => {
      return { ...state, showModal: true };
    },
    [actionCreators.doHideModal]: (state) => {
      return { ...state, showModal: false };
    },
  },
  defaultState,
);

export default showModalReducer;
